import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { MODAL_OPEN, MODAL_CLOSE } from '../../constants/actionTypes';
import ImageSwipe from '../Viewers/ImageViewers/ImageSwipe/ImageSwipe';
import ImageCarousel, { CarouselNavigationMode } from '../Viewers/ImageViewers/ImageCarousel/ImageCarousel';
import ImageList from '../Viewers/ImageViewers/ImageList/ImageList';
import ImageView from '../Viewers/ImageViewers/ImageView/ImageView';
import { ImageViewerMode, ImageObjectFit } from '../../models/CommonEnums';
import { TOGGLE_ZOOM } from '../../reducers/common/common.actions';
import GideImage from '../Shared/Image/GideImage';


const mapStateToProps = (state, ownProps) => ({
  currentUser: state.common.currentUser,
  viewMode: ownProps.viewMode ? ownProps.viewMode : state.common.viewMode,
  zoomed: state.common.zoomed,
});

const mapDispatchToProps = dispatch => ({
  onZoom: () => dispatch({ type: TOGGLE_ZOOM }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload: payload }),
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
});

/**
 * Creates a Slide using a specific imageFile file from an imageSlide ImageFile
 * @param slide 
 * @param imageFile 
 * @param position 
 */
export const buildImageSlideFromSlideFile = (slide, imageFile, position) => {
  const slideForFile = {
    ...slide,
    position,
    data: {
      files: [{
        ...imageFile,
        onSelect: undefined, // PREVENT INFINITE ABILITY TO SELECT
        caption: '',
        audioCaption: undefined,
        attribution: '',
      }],
      caption: imageFile.caption,
      audioCaption: imageFile.audioCaption,
      attribution: imageFile.attribution,
    },
    autoAdvanceSlide: imageFile.audioCaption ? imageFile.audioCaption.autoAdvance : false,
    autoPlayAudioCaption: imageFile.audioCaption ? imageFile.audioCaption.autoAdvance : false,
  };
  return slideForFile;
};
class ImageSlide extends Component {
  getSlideShowSlides() {
    const slides = this.props.slide.data.files.map((image, i) => {
      return buildImageSlideFromSlideFile(this.props.slide, image, i);
    });
    const swipeableItems = slides.map((slide, i) => {
      const component = <ImageSlide key={i} {...this.props}
        slide={slide} viewMode="SWIPE"
      />;
      return {
        component,
        slide
      }
    });
    return swipeableItems;
  }

  render() {
    const { slide, viewMode, fileNum, onZoom, view } = this.props;

    let style = {};

    if (slide.floatHorizontal === 'LEFT') {
      style.float = 'left';
    } else if (slide.floatHorizontal === 'RIGHT') {
      style.float = 'right';
    }
    if (slide.maxHeight) {
      style.maxHeight = `${slide.maxHeight}px`;
    }
    if (slide.stretch) {
      // TODO
    }

    if (!slide.data.files) {
      return <div />;
    }
    if (viewMode === 'OFF') {
      if (slide.data.files.length === 1) {
        return (
          <div>
            <section>
              <div>
                {slide.data.files && (
                  <ul>
                    {slide.data.files.map(({ name, url, caption }, i) => (
                      <li key={i}>
                        <GideImage src={url} alt={name} className="coverImage" />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </section>
          </div>
        );
      }
      if (slide.data.files.length === 2) {
        return (
          <div>
            <section>
              <div className="two">
                {slide.data.files && (
                  <ul>
                    {slide.data.files.map(({ name, url, caption }, i) => (
                      <li key={i}>
                        <GideImage src={url} alt={name} />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </section>
          </div>
        );
      }
      if (slide.data.files.length >= 3) {
        return (
          <div>
            <section>
              <div className="threePlus">
                <ul>
                  <li className="left">
                    <GideImage src={slide.data.files[0].url} alt={`□`} />
                  </li>
                  <li className="right">
                    <ul>
                      <li>
                        <GideImage src={slide.data.files[1].url} alt={`□`} />
                      </li>
                      <li>
                        {slide.data.files.length > 3 && (
                          <div>
                            <div className="mask" />
                            <span className="additionalCount">
                              +{slide.data.files.length - 3}
                            </span>
                          </div>
                        )}
                        <GideImage src={slide.data.files[2].url} alt={`□`} />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        );
      }
    } else if (viewMode === 'SCROLL' || viewMode === 'SLIDE') {
      if (slide.data.files.length > 1 &&
        (slide.data.displayInCarousel
          || (slide.data.slideTypeSpecificSettings
            && slide.data.slideTypeSpecificSettings.displayType === ImageViewerMode.Carousel)
        )) {
        return (
          <ImageCarousel
            onClick={() => {
              this.props.openModal({
                modalType: 'SwipeViewModal',
                modalProps: {
                  showHeader: true,
                  closeModal: this.props.closeModal,
                  closeOnNavigatePastLastItem: true,
                  swipeableItems: this.getSlideShowSlides(),
                  currentUser: this.props.currentUser,
                  totalNumberOfSlides: this.props.totalNumberOfSlides
                },
              });
            }}
            style={style}
            imageList={slide.data.files}
            carouselNavigationMode={slide.data.slideTypeSpecificSettings
              && slide.data.slideTypeSpecificSettings.carouselNavigationMode
              ? slide.data.slideTypeSpecificSettings.carouselNavigationMode
              : CarouselNavigationMode.Thumbnail // Default from original component.
            }
            respectEdge={
              !isNullOrUndefined(slide.data.slideTypeSpecificSettings)
              && slide.data.slideTypeSpecificSettings.imageObjectFit === ImageObjectFit.AutoCrop
            }
            carouselAdvanceSpeed={slide.data.slideTypeSpecificSettings && slide.data.slideTypeSpecificSettings.carouselAdvanceSpeed
              ? slide.data.slideTypeSpecificSettings.carouselAdvanceSpeed
              : 0
            }
            maxResponsiveHeight={300}
            maxWidescreenHeight={
              view === 'website'
                && slide.maxHeight
                && slide.maxHeight !== '0'
                ? slide.maxHeight
                : 400
            }
          />
        );
      } else if (slide.data.files.length > 1 &&
        (slide.data.slideTypeSpecificSettings
          && slide.data.slideTypeSpecificSettings.displayType === ImageViewerMode.Gallery)) {
        return (
          <ImageSwipe
            onClick={(imageIndex) => {
              this.props.openModal({
                modalType: 'SwipeViewModal',
                modalProps: {
                  showHeader: true,
                  closeModal: this.props.closeModal,
                  closeOnNavigatePastLastItem: true,
                  swipeableItems: this.getSlideShowSlides(),
                  currentUser: this.props.currentUser,
                  totalNumberOfSlides: this.props.slide.data.files? this.props.slide.data.files.length : 1,
                  startAtIndex: imageIndex
                },
              });
            }}
            imageList={slide.data.files}
            width={slide.widthPercentage ? slide.widthPercentage : undefined}
            view={view}
          />
        )
      } else { // Image List
        return (
          <ImageList
            imageList={slide.data.files}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            slide={this.props.slide}
          />
        );
      }
    } else if (viewMode === 'SWIPE') {
      const image = slide.data.files[fileNum || 0];
      if (image.type !== 'SLIDEFILE') {
        return (
          <div className="swipeImage">
            {this.props.viewMode !== 'SWIPE' && (
              <GideImage src={image.url} alt={image.name} onClick={onZoom} />
            )}
            {this.props.viewMode === 'SWIPE' &&
              image && (
                <div id="imageViewer">
                  <div className="zoomableImage">
                    <GideImage className="media" src={image.url} alt={image.name} />
                  </div>
                </div>
              )}
          </div>
        );
      }
      else {
        return (
          <ImageView
            imageFile={image}
            isReferenceType={true}
          />

        )
      }
    } else {
      return null;
    }
  }
  componentDidUpdate() {
    if (this.props.viewMode !== 'SWIPE') {
      const imageContainer = this.getImageContainer();
      if (!isNullOrUndefined(imageContainer)) {
        // Need to ensure it is scaled to match what is in props because the user can click the next or back button
        // and if we don't adjust the scale back to match props it could be out of sync with redux state and this.props (zoomed)
        imageContainer.style.transform =
          this.props.zoomed === true ? 'scale(2)' : 'scale(1)';
      }
    }
  }
  componentDidMount() {
    if (this.props.viewMode !== 'SWIPE') {
      // Setup image panning
      const imageContainer = this.getImageContainer();
      if (!isNullOrUndefined(imageContainer)) {
        imageContainer.onmousedown = this.handleImageMouseDownEvent.bind(this);
        imageContainer.onmousemove = this.handlePanEvent.bind(this);
        // Need to ensure it is scaled to match what is in props because the user can click the next or back button
        // and if we don't adjust the scale back to match props it could be out of sync with redux state and this.props (zoomed)
        imageContainer.style.transform =
          this.props.zoomed === true ? 'scale(2)' : 'scale(1)';
      }
    } else {
      window.gides.imageViewer.initialize();
    }
  }
  componentWillUnmount() {
    if (this.props.viewMode === 'SWIPE') {
      window.gides.imageViewer.dispose();
    }
  }
  getImageContainer() {
    const swipeImageNodeItems = document.getElementsByClassName('swipeImage');
    if (
      !isNullOrUndefined(swipeImageNodeItems) &&
      swipeImageNodeItems.length > 0
    ) {
      // There should ever only be one element that matches so it is safe to select the
      // first element in the array.
      return swipeImageNodeItems[0];
    }
    return null;
  }

  handlePanEvent(e) {
    const imageContainer = this.getImageContainer();
    const additionalOffset = this.props.showChrome === true ? 61 : -10;
    const transformOriginValue =
      e.pageX / imageContainer.clientWidth * 100 +
      '% ' +
      (e.pageY - additionalOffset - imageContainer.offsetTop) /
      imageContainer.clientHeight *
      100 +
      '%';
    imageContainer.style.transformOrigin = transformOriginValue;
  }
  handleImageMouseDownEvent(e) {
    // This fires before reducer updates state and zoomed property updates so it is in reverse.
    if (this.props.viewMode !== 'SWIPE') {
      e.currentTarget.style.transform =
        this.props.zoomed === true ? 'scale(1)' : 'scale(2)';
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ImageSlide);
