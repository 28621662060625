import React, { useState, useRef, useEffect, ReactNode } from 'react';
import styles from './_media-chooser.module.scss';
import classNames from 'classnames';
import { withTheme } from 'styled-components';
import icons from '../../../../assets/icons';
import { CircleIconButton } from '../../../Shared/CircleIconButton/CircleIconButton';
import { ensureImageFileOrientation, isMobileDevice, dataURIToBlob, formatMinuteAndSeconds, calculateDurationInSecondsFromCurrentTime } from '../../../../utils/helperFunctions';
import Dropzone from 'react-dropzone';
import { assertNever } from 'office-ui-fabric-react/lib/Utilities';

export type MediaChooserAction =
  | { type: 'StartCamera' }
  | { type: 'StopCamera' }
  | { type: 'UploadFile' };
export type FileType = 'PHOTO' | 'UPLOAD' | 'VIDEO' | 'RECORDED';
export type CameraType = 'Native' | 'InApp';
export enum MultiMediaType {
  Image = 'Image',
  Video = 'Video',
  ImageGallery = 'ImageGallery',
}
export interface  MediaChooserProps {
  style?: React.CSSProperties;
  theme?: string;
  mediaType: MultiMediaType
  maxUploadSizeActual: number;
  maxUploadSizeDisplay: number;
  onCameraStarted: (cameraType: CameraType) => void;
  onSavingMedia: () => void;
  onMediaCreated: (mediaDataUrls: string[], fileType: FileType) => void;
  onError: (error: string) => void;
  action?: MediaChooserAction,
  additionalActions?: {
    label: string;
    button: React.ReactNode;
  } [],
}
interface MediaChooserState {
  stream?: any;
  videoSrc?: string | null;
  cameraStarted: boolean;
  recorder?: any;
  videoRecordingInProgress: boolean;
  videoRecordingStartTime?: Date;
}
export function MediaChooser (props:  MediaChooserProps) {
  const [state, setState] = useState<MediaChooserState>({
    cameraStarted: false,
    videoRecordingInProgress: false
  });
  const stateRef = useRef({state, setState});
  useEffect(() => {
    stateRef.current = {state, setState};
  }, [state, setState]);
  const [videoRecordingDuration, setVideoRecordingDuration] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (props.action) {
      switch (props.action.type) {
        case "StartCamera":
          if(props.mediaType === MultiMediaType.Image) {
            startCameraBasedOnDevice()
          } else {
            startVideoCameraBasedOnDevice();
          }
          break;
        case "StopCamera":
          stopCamera();
          break;
        case "UploadFile":
          uploadElementNoAutoCameraOpen.current.fileInputEl.click();
          break;
        default:
          assertNever(props.action);
      }
    }
    return () => {
      stopCamera();
    }
  }, [props.action]);

  const calculateVideoRecordingDuration = () => {
    const duration = videoRecordingDuration !== undefined
    && state.videoRecordingStartTime
      ? calculateDurationInSecondsFromCurrentTime(state.videoRecordingStartTime)
      : undefined;

    setVideoRecordingDuration(duration);
  }
  useEffect(() => {
    if(state.videoRecordingStartTime !== undefined) {
      window.setTimeout(() => {
        calculateVideoRecordingDuration();
      }, 1000)
    }
  }, [videoRecordingDuration]);

  const videoElement: any = useRef(null);
  const canvasElement: any = useRef(null);
  const uploadElementWithAutoCameraOpen = useRef<any>(null);
  const uploadElementNoAutoCameraOpen = useRef<any>(null);
  const mediaRecorderElement = useRef<any>(null);

  /**
   * Initialize the webcam if one exists. TODO: What about if on phone??
   */
  const startCamera = (): void => {
    if (!state.stream) {
      const n: any = navigator;
      n.getUserMedia =
        n.getUserMedia ||
        n.webkitGetUserMedia ||
        n.mozGetUserMedia ||
        n.msGetUserMedia ||
        n.oGetUserMedia;
      if (n.getUserMedia) {
        n.getUserMedia(
          { video: true },
          handleVideo,
          (error: any) => {
            props.onError(`The following error occurred when attempting to turn on the camera: ${error}`);
          },
        );
      }
    } else {
      props.onError('Unable to start Camera');
    }
  };

  const handleVideo = (stream: any) => {
    if (videoElement && videoElement.current) {
      if ('srcObject' in videoElement.current) {
        videoElement.current.srcObject = stream;
      } else {
        videoElement.current.src = window.URL.createObjectURL(stream);
      }
      setState({
        ...state,
        stream: stream,
        videoSrc: videoElement.current.srcObject ? videoElement.current.srcObject : videoElement.current.src,
        cameraStarted: true
      });
      props.onCameraStarted('InApp');
      videoElement.current.play();
    }
  };

  const takePhotoFromHybridApp = (imageData: string) => {
    props.onSavingMedia();
    const dataURL: any = `data:image/png;base64,${imageData}`;
    ensureImageFileOrientation(dataURL).then(imageFile => {
      props.onMediaCreated([imageFile], 'PHOTO');
      setState({
        cameraStarted: false,
        videoRecordingInProgress: false,
      });
    });
  };

  const takePhoto = () => {
    props.onSavingMedia();
    const ctx = canvasElement.current.getContext('2d');
    // TODO: The actual aspect ratio of my webcam is 640 X 480. which means the width is 1.33 times as wide as the height.
    // This may differ on different devices. I may need to get the aspect ratio or resolution of the camera for the given
    // device.
    ctx.canvas.width = 600 * 1.33;
    ctx.canvas.height = 600;
    ctx.drawImage(videoElement && videoElement.current ? videoElement.current : videoElement.current, 0, 0, 600 * 1.33, 600);
    let dataURL = canvasElement.current.toDataURL('image/png');
    ensureImageFileOrientation(dataURL).then(imageFile => {
      stopCamera();
      props.onMediaCreated([imageFile], 'PHOTO');
    });
  };
  const stopCamera = (): void => {
    const {state, setState} = stateRef.current;
    if (state.stream) {
      state.stream.getTracks().forEach((track: any) => {
        track.stop();
      });
    }
    if (mediaRecorderElement.current && mediaRecorderElement.current.stream) {
      mediaRecorderElement.current.stream.getTracks().forEach((t: any) => {
        t.stop();
      });
    }
    setState({cameraStarted: false, videoRecordingInProgress: false });
    if(mediaRecorderElement.current) {
      mediaRecorderElement.current = undefined;
    }
  };

  const onDropFiles = async (acceptedFiles: any[], rejectedFiles: any[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      props.onSavingMedia();
      const files: string[] = [];
      const requests = acceptedFiles.map(async (f: any) => {
        if(props.mediaType === MultiMediaType.Image) {
          const mediaFile = await ensureImageFileOrientation(f);
          files.push(mediaFile);
        } else {
          files.push(f);
        }
      });
      await Promise.all(requests);
      props.onMediaCreated(files, 'UPLOAD');
    }
    if (rejectedFiles && rejectedFiles.length > 0) {
      props.onError(`Unable to upload files. Max file size is ${props.maxUploadSizeDisplay}MB.`);
    }
  };

  const startNativeCamera = () => {
    uploadElementWithAutoCameraOpen.current.fileInputEl.click();
  }

  const startCameraBasedOnDevice = () => {
      if (!isMobileDevice()) {
        startCamera();
      } else {
        if((window as any).cordova) {
          if(navigator && (navigator as any).camera) {
            setState({
              ...state,
              cameraStarted: true
            });
            props.onCameraStarted('Native');
            ((navigator as any).camera as any).getPicture(takePhotoFromHybridApp, props.onError, {
              quality: 100,
              sourceType: 1,
              destinationType: 0,
              allowEdit: true,
              mediaType: 0,
              targetWidth: window.innerWidth,
              targetHeight: window.innerHeight,
              correctOrientation: true,
              saveToPhotoAlbum: true,
              encodingType: 0,
            });
          } else {
            props.onError(navigator ? 'Could not get camera': 'could not get camera');
          }
        } else {
          startNativeCamera();
        }
      }
  };

  const  startVideoRecording = async () => {
    const recorder: any = await recordVideo();
    setState({...state, recorder,
      videoRecordingInProgress: true,
      videoRecordingStartTime: new Date(),
    });
    setVideoRecordingDuration(0);
    recorder.start();
  }

  const recordVideo = () => {
    return new Promise(resolve => {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(stream => {
          // handle depricated createObjectURL method in new browser versions
          if (videoElement.current) {
            if ('srcObject' in videoElement.current) {
              videoElement.current.srcObject = stream;
            } else {
              videoElement.current.src = window.URL.createObjectURL(stream);
            }
            setState(state => ({
              ...state,
              stream,
              videoSrc: videoElement.current.srcObject ? videoElement.current.srcObject : videoElement.current.src,
            }));
            const playPromise = videoElement.current.play();


            if(playPromise) {
              playPromise.then((_: any) => {
                // Video playback started ;)
                const mediaRecorder = new MediaRecorder(stream, {
                  mimeType: 'video/webm',
                  bitsPerSecond: 128000,
                });
                const audioChunks: any[] = [];

                mediaRecorder.addEventListener('dataavailable', (event: any) => {
                  audioChunks.push(event.data);
                });

                mediaRecorderElement.current = mediaRecorder;
                const start = () => {
                  mediaRecorderElement.current.start();
                };

                const stop = () => {
                  return new Promise(resolve => {
                    mediaRecorderElement.current.addEventListener('stop', () => {
                      const audioBlob = new Blob(audioChunks, { type: 'audio/mp4' });
                      const audioUrl = URL.createObjectURL(audioBlob);
                      const audio = new Audio(audioUrl);
                      const play = () => {
                        audio.play();
                      };

                      resolve({ audioBlob, audioUrl, play });
                    });
                    mediaRecorderElement.current.stop();
                  });
                };
                resolve({ start, stop });
              })
              .catch((e: any) => {
                props.onError(e);
              })
            }
          } else {
            resolve({start:() => {props.onError('camera recording not initialized')}, stop:() => {props.onError('camera recording not initialized')}});
          }
        });
    });
  }
  const completeVideoRecording = async () => {
    if (!mediaRecorderElement.current) return;

    if (mediaRecorderElement.current.state === 'inactive') {
      props.onError('Not Recording')
      return;
    }
    if (mediaRecorderElement.current.state === 'recording') {
      props.onSavingMedia();
      const video = await state.recorder.stop();
      props.onMediaCreated([video.audioBlob as any], 'RECORDED');

      if (mediaRecorderElement.current && mediaRecorderElement.current.stream) {
        mediaRecorderElement.current.stream.getTracks().forEach((t: any) => {
          t.stop();
        });
      }

      setState({
        cameraStarted: false,
        videoRecordingInProgress: false
      });
    }

    // this.setState({ mediaRecorder: null, videoSrc: null, videoRecordingInProgress: false });
    stopCamera();
  }
  const takeVideoFromHybridApp = (mediaFiles: any[]): void => {
    // This is to handle a bug in IOS when recording videos with Cordova App
    if(mediaFiles && mediaFiles.length > 0) {
      let videoUrl =  mediaFiles[0].fullPath.replace('/private', '');
      if (!/file/.test(videoUrl)) {
        videoUrl = `file://${videoUrl}`;
      }
      (window as any).resolveLocalFileSystemURL(
        videoUrl,
        (entry: any) => {
          entry.file(
            (file: any) => {
              readBinaryFile(entry, file.type);
            },
            (err: any) => {
              props.onError(err);
            }
          );
        },
        (err: any) => {
          props.onError(err);
        }
      );
    } else {
      alert('no media files');
    }
  };


  const readBinaryFile = (fileEntry: any, type: string) => {
    fileEntry.file((file: any) => {
        var reader = new FileReader();
        reader.onloadend = function() {
            var blob = new Blob([new Uint8Array(this.result as any)], { type: type });
            props.onMediaCreated([blob as any], 'RECORDED');
            setState({
              cameraStarted: false,
              videoRecordingInProgress: false
            });
        };
        reader.readAsArrayBuffer(file);
    }, () => {});
  };
  const startVideoCameraBasedOnDevice = () => {

      if(navigator && (navigator as any).device && (navigator as any).device.capture) {

        var permissions = (window as any).cordova.plugins.permissions;
        // Check READ_EXTERNAL_STORAGE
        permissions.checkPermission(permissions.READ_EXTERNAL_STORAGE, (status: {hasPermission: boolean}) => {
          if(status.hasPermission) {
            (navigator as any).device.capture.captureVideo(takeVideoFromHybridApp, (error: any) => {
              props.onError(`Video Capture Error: Error code: ${error.code}`);
            }, {limit:1});
          } else {
            permissions.requestPermission(permissions.READ_EXTERNAL_STORAGE, (status: {hasPermission: boolean}) => {
              if(status.hasPermission) {
                (navigator as any).device.capture.captureVideo(takeVideoFromHybridApp, (error: any) => {
                  props.onError(`Video Capture Error: Error code: ${error.code}`);
                }, {limit:1});
              } else {
                props.onError(`Permission to external storage was denied.`);
              }
            }, () => {
              props.onError(`Permission to external storage is not enabled.`);
            });
          }
        }, () => {
          props.onError(`Permission to external storage is not enabled.`);
        });
        setState({
          ...state,
          cameraStarted: true
        });
        props.onCameraStarted('Native');
      } else if (!isMobileDevice()) {
        startCamera();
      } else {
        startNativeCamera();
      }
  };

  const additionalActions = props.additionalActions && props.additionalActions
    .map((additionalAction: {label: string, button: ReactNode}, index: number) => {
    return (
      <div className={styles.labeledButtonContainer}
        key={index}
        style={{
          marginLeft: "22px",
        }}
      >
        {additionalAction.button}
        <span className={styles.uploadLabel}>{additionalAction.label}</span>
      </div>
    );
  });

  const rightActions = (!state.cameraStarted && (!props.action || props.action.type !== "StartCamera")) &&
  <>
    <div className={styles.rightActions}>
      <div className={styles.labeledButtonContainer}>
        <CircleIconButton
          backgroundColor='var(--COLOR-BLUE-100)'
          onClick={() => {
            if(state.cameraStarted) {
              stopCamera();
            }
            uploadElementNoAutoCameraOpen.current.fileInputEl.click();
          }}
        >
          <icons.SlideType_Image_Main
            color='var(--WHITES-NORMAL-1000)'
          />
        </CircleIconButton>
        <span className={styles.uploadLabel}>Upload</span>
      </div>
      {additionalActions}
    </div>
    <div className={styles.otherOptions}>
      Other options
    </div>
  </>;

  return (
    <div style={props.style} className={classNames(styles.mediaChooserContainer, props.theme === 'dark' ?  styles.darkMode : undefined)}>
      <div className={classNames(styles.selectionContainer, props.theme === 'dark' ?  styles.darkMode : undefined)}>

        <Dropzone
          style={{
            display: "none"
          }}
          onDrop={onDropFiles}
          ref={(mcu: any) => {
              if (mcu && mcu.fileInputEl) {
                mcu.fileInputEl.accept = props.mediaType === MultiMediaType.Image
                  ? "image/*"
                  : "video/*";
                mcu.fileInputEl.capture = "environment";
              }
              uploadElementWithAutoCameraOpen.current = mcu;
          }}
          maxSize={props.maxUploadSizeActual * 1000000}
          />
        <Dropzone
          className={classNames(styles.dropContainer, props.theme === 'dark' ?  styles.darkMode : undefined, state.cameraStarted ? styles.inactive : undefined)}
          onDrop={onDropFiles}
          ref={(mcu: any) => {
            if (mcu && mcu.fileInputEl) {
              mcu.fileInputEl.accept = props.mediaType === MultiMediaType.Image
                ? "image/*"
                : "video/*";
            }
            uploadElementNoAutoCameraOpen.current = mcu;
          }}
          maxSize={props.maxUploadSizeActual * 1000000}
        >
          <icons.SlideType_Image_Main
            width={48} height={48}
            color="var(--WHITES-NORMAL-1000)"
          />
          <div className={styles.title}>{`Tap to upload an ${props.mediaType.toLowerCase()}`}</div>
          <div className={styles.subTitle}>{`Maximum size ${props.maxUploadSizeDisplay}MB`}</div>
        </Dropzone>
        <canvas
          id="c"
          ref={canvasElement}
          style={{ display: 'none' }}
        />
        {!isMobileDevice() && (
          <video
            src={state.videoSrc as string}
            autoPlay={true}
            className={classNames(styles.webcamDisplay, !state.cameraStarted ? styles.inactive : undefined)}
            ref={videoElement}
          />
        )}
      </div>
      <div className={styles.actionContainer}>
        {/* spacer */}
        <span className={styles.leftSpacer}></span>
        <div className={styles.primaryButtonAndLabel}>
          <div className={classNames(
            styles.primaryButtonContainer,
            state.cameraStarted ? styles.cameraStarted : undefined,
            props.mediaType === MultiMediaType.Video
              ? styles.video
              : undefined
            )}
            onClick={() => {
              if(state.cameraStarted) {
                if(props.mediaType === MultiMediaType.Image) {
                  takePhoto();
                } else {
                  if(state.videoRecordingInProgress === true) {
                    completeVideoRecording();
                  } else {
                    startVideoRecording();
                  }
                }
              } else {
                if(props.mediaType === MultiMediaType.Image) {
                  startCameraBasedOnDevice()
                } else {
                  startVideoCameraBasedOnDevice();
                }
              }
            }}
          >
            {props.mediaType === MultiMediaType.Video && (
              <>
                {state.cameraStarted === false && (
                  <icons.SlideType_Video_Main
                    color={'var(--COLOR-PRIMARY-600)'}
                    width={48} height={48}
                  />
                )}
                {state.cameraStarted === true && state.videoRecordingInProgress === false && (
                  <icons.Nav_Media_Player_Record
                    color={'var(--WHITES-NORMAL-1000)'}
                    width={48} height={48}
                  />
                )}
                {state.cameraStarted === true && state.videoRecordingInProgress === true && (
                  <icons.Nav_Media_Player_Stop
                    color={'var(--WHITES-NORMAL-1000)'}
                    width={48} height={48}
                  />
                )}
              </>
            )}
            {props.mediaType !== MultiMediaType.Video && (
              <icons.ContentAlteration_Camera_Alt
                color={state.cameraStarted
                  ? 'var(--WHITES-NORMAL-1000)'
                  : 'var(--COLOR-PRIMARY-600)'}
                width={48} height={48}
              />
            )}

          </div>

          <div className={styles.startTitle}>
            {state.cameraStarted ?
              props.mediaType === MultiMediaType.Video && state.videoRecordingInProgress
                ? formatMinuteAndSeconds(videoRecordingDuration as number) // TODO: set this to duration of video recording
                :`${props.mediaType === MultiMediaType.Image || props.mediaType === MultiMediaType.ImageGallery ? 'Take a photo' : 'Tap to start recording'}`
              : `Press to start camera`
            }
          </div>
        </div>
        <div className={styles.rightActionsContainer}>
          <div className={styles.rightActionsInnerContainer}>
            {rightActions}
          </div>
        </div>
      </div>
      <div className={classNames(styles.actionContainer, styles.mobileActionContainer)}>
        {
          rightActions
        }
      </div>
    </div>
  );
}

export default withTheme(MediaChooser);
