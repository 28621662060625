/* eslint-disable no-undef */
import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Modal, Icon, Input, Menu, Button, List, Checkbox, Image } from 'semantic-ui-react';
import DropZone from 'react-dropzone';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import ImageMapper from '../ImageMapper';
import agent from '../../agent';
import Map from '../maps/Map';
import PlacesWithStandaloneSearchBox from '../maps/PlacesWithStandaloneSearchBox';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  MODAL_OPEN,
  UPDATE_POINTS,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';

import { contentMetaData } from '../../constants/contentMetaData';
import slideTools from '../../slideTools';
import { history } from '../../store';
import { Loading } from '../Shared/Loading/Loading';
import Tools from '../../lib/SketchField/tools';
import { v4 } from 'uuid';

const SketchField = lazy(() => import('../../lib/SketchField/index'));


let dropZoneStyles = {
  display: 'none',
  width: '0',
  height: '0',
};

const mapStateToProps = (state, ownProps) => ({
  ...slideTools.mapStateToProps(state, ownProps),
  points: state.common.points,
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) => dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  openModal: payload => dispatch({ type: MODAL_OPEN, payload }),
  updatePoints: payload => dispatch({ type: UPDATE_POINTS, payload }),
  updateSlideAttachmentInfo: payload => dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class MapModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'MAP',
        data: {
          audioCaption: null,
          title: '',
          caption: '',
          lat: 32.776475,
          long: -79.931051,
          showMarker: true,
          imageURL: null,
          mapTypeId: 'roadmap'
        },
      },
      locationChooserType: '',
      zip: '',
      fetchingGPS: false,
      imageEditing: null,
      selectedPoint: null,
      createRouteMap: false,
      mode: '',
      map: {
        name: 'my-map',
        areas: [],
      },
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
      if (props.editSlide.data.imageUrl) {
        this.state.locationChooserType = 'FILE';
      } else {
        this.state.locationChooserType = 'MAP';
      }
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.setLocationChooserType = field => ev => {
      this.setState({ locationChooserType: field });
    };

    this.updateState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateStateCheckbox = field => (ev, data) => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: data.checked });
      if (!newState.createRouteMap && this.props.points.length) {
        newState.lat = this.props.points[0].lat;
        newState.lng = this.props.points[0].lng;
      }
      this.setState(newState);
    };

    this.updateCreateRouteMap = (ev, data) => {
      const createRouteMap = data.checked;
      if (data.checked && this.props.points.length < 2) {
        alert('At least 2 points are needed for routing.');
        return;
      }
      this.deselectPoints();
      this.setState({
        createRouteMap,
        selectedPoint: null,
      });
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: this.props.selection,
        allowComments: this.props.editSlide ? this.state.slide.allowComments : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide ? this.state.slide.allowQuestions : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide ? this.state.slide.position : slidePosition,
      };
      slide.data.points = this.props.points;
      if (this._map) {
        slide.data.zoom = this._map.getZoom();
      }
      // Removing client side mapId that doesn't need to be saved, see comment below
      const updatedSlide = {
        ...slide,
        data: {
          ...slide.data,
          mapId: undefined,
        }
      }
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = updatedSlide.slide;
        updatedSlide.slide = null;
        payload = await agent.Slides.replace(this.props.article, replaceSlideId, updatedSlide);
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, updatedSlide);
        } else {
          payload = await agent.Slides.create(this.props.article, updatedSlide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(this.props.childArticleEditInfo.ownerSlide.id, this.props.updateSlideAttachmentInfo);
          }
        }
      }
      // The mapId is used on the client for the google maps to refresh properly
      // Anytime that the map options are modified, then a new key needs to be generated.
      // This key serves no other purpose, so it doesn't need to be saved to the server.
      const updatedPayload = {
        ...payload,
        mode: this.props.mode,
        slide: {
          ...payload.slide,
          data: {
            ...payload.slide.data,
            mapId: v4()
          }
        }
      }
      this.props.onSubmitSlide
        ? this.props.onSubmitSlide(updatedPayload)
        : this.props.onSubmit(this.state.replaceMode, updatedPayload);
      history.goBack()
    };

    this.editImage = file => {
      let sketch = this._sketch;
      let reader = new FileReader();
      reader.addEventListener(
        'load',
        () =>
          sketch.setBackgroundFromDataUrl(reader.result, {
            stretched: true,
            stretchedX: false,
            stretchedY: false,
            originX: 'left',
            originY: 'top',
          }),
        false,
      );
      reader.readAsDataURL(file);
    };

    this.linkSlide = () => {
      this.props.openModal({
        modalType: 'SlideSelectModal',
        modalProps: {},
      });
    };

    this.onRemovePoint = idx => {
      setTimeout(() => {
        let points = this.props.points.slice();
        points.splice(idx, 1);
        this.props.updatePoints({ points });
        this.setState(prevState => {
          const areas = points.map(point => {
            let area = {
              shape: 'circle',
              coords: [point.x, point.y, 15],
            };
            return area;
          });
          return {
            selectedPoint: null,
            map: {
              name: prevState.map.name,
              areas: areas,
            },
          };
        });
      }, 500);
    };

    this.onPointListItemSelected = index => {
      let points = this.props.points.slice();
      points.forEach((p, i) => {
        p.selected = false;
      });
      points[index].selected = true;
      this.props.updatePoints({ points });
      this.setState({
        selectedPoint: index,
        selectedPointByClick: true,
      });
    };

    this.onUpdatePoint = i => ev => {
      let points = this.props.points.slice();
      points[i].label = ev.target.value;
      this.props.updatePoints({ points });
    };

    this.startCreateMode = () => {
      if (this.state.mode === 'CREATE_POINT') {
        this.setState({ mode: '' });
      } else {
        this.deselectPoints();
        this.setState({
          mode: 'CREATE_POINT',
          selectedPoint: null,
        });
      }
    };
  }

  onDrag() {
    const slide = Object.assign({}, this.state.slide);
    slide.data.lat = this._map.getCenter().lat();
    slide.data.long = this._map.getCenter().lng();
    this.setState({
      slide,
    });
  }

  componentDidUpdate(a, b) {
    setTimeout(() => {
      // this.loadSketch()
    }, 500);
  }

  deselectPoints() {
    let points = this.props.points.slice();
    points.forEach(p => {
      p.selected = false;
    });
    this.props.updatePoints({ points });
  }

  loadSketch() {
    let sketch = this._sketch;
    sketch.setBackgroundFromDataUrl(this.state.imageURL, {
      stretched: true,
      stretchedX: false,
      stretchedY: false,
      originX: 'left',
      originY: 'top',
    });
  }

  handlePointClick(p, i) {
    this.onPointListItemSelected(i);
  }

  onUseOnlineMap() {
    this.setState({
      locationChooserType: 'MAP',
      // mode: 'CREATE_POINT',
    });
  }

  onUploadImage() {
    this._dropZone.open();
  }

  onDrop(acceptedFiles, rejectedFiles) {
    if (!acceptedFiles.length) return;
    const file = acceptedFiles[0];
    if (this.state.imageEditing) window.URL.revokeObjectURL(this.state.imageEditing);
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.setState({
          imageURL: reader.result,
          locationChooserType: 'FILE',
          imageEditing: file,
        });
      },
      false,
    );
    reader.readAsDataURL(file);
  }

  onMapClick(ev) {
    if (this.state.mode !== 'CREATE_POINT') return;
    const point = {
      label: 'foo',
      lat: ev.latLng.lat(),
      lng: ev.latLng.lng(),
      selected: true,
    };
    let points = this.props.points.slice();
    points.forEach(p => {
      p.selected = false;
    });
    points.push(point);
    this.props.updatePoints({ points });
    setTimeout(() => {
      this.setState({ mode: '' });
    }, 300);
  }

  handlePlacesChanged(places) {
    if (!places.length) return;
    const place = places[0];
    this.setState({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    // this._map.panTo({
    //   lat:place.geometry.location.lat(),
    //   lng:place.geometry.location.lng()
    // });
    // this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(
    //   {lat:place.geometry.location.lat(),
    //     lng:place.geometry.location.lng()
    //   })
    // // this._map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setOptions({
    // //   zoom: 1
    // // })
  }

  onAreaClick(area, index, ev) {
    let points = this.props.points.slice();
    points.forEach((p, i) => {
      p.selected = false;
    });
    points[index].selected = true;
    this.props.updatePoints({ points });
    this.setState({
      selectedPoint: index,
      selectedPointByClick: true,
    });
  }

  onAreaEnter(area, index, ev) {
    let points = this.props.points.slice();
    points.forEach((p, i) => {
      p.selected = false;
    });
    points[index].selected = true;
    this.props.updatePoints({ points });
    this.setState({
      selectedPoint: index,
      selectedPointByClick: false,
    });
  }

  onAreaLeave(area, index, ev) {
    if (!this.state.selectedPointByClick) {
      let points = this.props.points;
      points[index].selected = false;
      this.props.updatePoints({ points });
      this.setState({
        selectedPoint: null,
      });
    }
  }

  onImageClick(e) {
    if (this.state.mode !== 'CREATE_POINT') return;
    const point = {
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
      label: 'foo',
      selected: false,
    };
    let points = this.props.points.slice();
    points.forEach(p => {
      p.selected = false;
    });
    points.push(point);
    this.props.updatePoints({ points });
    this.setState(prevState => {
      const areas = points.map(point => {
        let area = {
          shape: 'circle',
          coords: [point.x, point.y, 15],
        };
        return area;
      });
      return {
        // selectedPoint: points.length - 1,
        map: {
          name: prevState.map.name,
          areas: areas,
        },
        mode: '',
      };
    });
  }

  render() {
    const { mode, locationChooserType, createRouteMap, slide } = this.state;
    const { points } = this.props;
    let imageMapperStyle = {
      position: 'absolute',
      zIndex: createRouteMap ? -100 : 100,
    };
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        className="mapModal"
        size="large"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div className="modalHeader" style={{ background: 'rgb(31, 142, 117)' }}>
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <span>Map Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-maps.svg" style={{ zIndex: 10 }} />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              {locationChooserType === '' && (
                <Menu fluid vertical style={{ margin: '0 0 0 0' }}>
                  <Menu.Item onClick={this.onUploadImage.bind(this)}>
                    Upload Map Image
                    <DropZone
                      onDrop={this.onDrop.bind(this)}
                      disableClick={true}
                      style={dropZoneStyles}
                      multiple={false}
                      ref={c => (this._dropZone = c)}
                    />
                  </Menu.Item>
                  <Menu.Item onClick={this.onUseOnlineMap.bind(this)}>Use Online Map</Menu.Item>
                </Menu>
              )}
              {locationChooserType === 'FILE' && (
                <section>
                  <ImageMapper
                    style={imageMapperStyle}
                    src={slide.data.imageURL}
                    map={slide.data.map}
                    width={400}
                    height={500}
                    onImageClick={this.onImageClick.bind(this)}
                    onClick={this.onAreaClick.bind(this)}
                    onMouseEnter={this.onAreaEnter.bind(this)}
                    onMouseLeave={this.onAreaLeave.bind(this)}
                    selectedPoint={this.state.selectedPoint}
                    strokeColor={'rgba(100, 200, 250, 0.5)'}
                    lineWidth={1}
                    alwaysShow
                    stacked
                    onTop={mode === 'CREATE_POINT'}
                  />
                  <Suspense fallback={<Loading />}>
                    <SketchField
                      width="400px"
                      height="400px"
                      tool={Tools.Pencil}
                      lineColor="black"
                      lineWidth={3}
                      ref={c => (this._sketch = c)}
                    />
                  </Suspense>
                </section>
              )}
              {this.state.locationChooserType === 'MAP' && (
                <section>
                  <Map
                    mapId={slide.data.mapId}
                    refMap={el => (this._map = el)}
                    onClick={this.onMapClick.bind(this)}
                    onMapTypeIdChanged={() => {
                      slide.data.mapTypeId = this._map.getMapTypeId();
                    }}
                    lat={slide.data.lat}
                    lng={slide.data.long}
                    points={points}
                    showDirections={this.state.createRouteMap}
                    zoom={slide.data.zoom}
                    onPointClick={this.handlePointClick.bind(this)}
                    onDrag={this.onDrag.bind(this)}
                    defaultMapTypeId={slide.data.mapTypeId ? slide.data.mapTypeId : 'roadmap'}
                    options={{gestureHandling: 'auto'}}
                  />
                  <div className="searchBox">
                    <PlacesWithStandaloneSearchBox placesChanged={this.handlePlacesChanged.bind(this)} />
                  </div>
                </section>
              )}
              {locationChooserType !== '' && (
                <div style={{ background: 'rgb(31, 142, 117)' }}>
                  {mode === 'CREATE_POINT' && <h3 style={{ textAlign: 'center', paddingTop: '6px' }}>Pick a point.</h3>}
                  <List divided relaxed>
                    {points.map((point, i) => {
                      return (
                        <List.Item key={i} active={i === this.state.selectedPoint} onClick={() => this.onPointListItemSelected(i)}>
                          <List.Icon
                            name="x"
                            verticalAlign="middle"
                            size="large"
                            onClick={() => this.onRemovePoint(i)}
                            style={{ cursor: 'pointer' }}
                          />
                          <List.Content>
                            <List.Description>
                              <Input placeholder="Label (optional)" value={point.label} onChange={this.onUpdatePoint(i)} />
                              <span onClick={this.linkSlide}>Link Slide</span>
                              {point.slide && <span>Slide type: {point.slide.slideType}</span>}
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                  <section>
                    <section>
                      <Checkbox label="CREATE ROUTE MAP" checked={this.state.createRouteMap} onChange={this.updateCreateRouteMap} />
                    </section>
                    <Input fluid placeholder="Title" value={slide.data.title} onChange={this.updateDataState('title')} />
                    <div>
                      <Button onClick={this.startCreateMode}>
                        <Icon name="map pin" /> {this.state.mode === 'CREATE_POINT' ? 'Exit' : 'Enter'} Point Mode
                      </Button>
                    </div>
                  </section>
                </div>
              )}
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                style={{ fontColor: 'black' }}
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(this.state.slide, this.props.article.type)}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        {this.state.locationChooserType !== '' && (
          <Modal.Actions style={{ background: contentMetaData['LOCATION'].primaryColor }}>
            <SlideModalActions
              caption={slide.data.caption}
              captionChanged={this.updateDataState('caption').bind(this)}
              audioCaption={slide.data.audioCaption}
              onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
              showSettings={this.state.showSettings}
              canNavigateBack={true}
              backClicked={this.setLocationChooserType('')}
              settingsClicked={() => this.setState({ showSettings: !this.state.showSettings })}
              nextClicked={this.createSlide}
              settings={getSlideSettings(this.state.slide, this.props.article.type)}
              onSettingsChanged={this.updateSlideSettings.bind(this)}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MapModal);
