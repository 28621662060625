import React, { CSSProperties } from 'react';
import { RadioButton as RadioButtonComponent } from '../../../../Shared/RadioButton/RadioButton';
import styles from './_radio-button.module.scss';
import classNames from 'classnames';

export interface RadioButtonProps {
  checked: boolean | undefined;
  label: string;
  groupName: string;
  value: string | number | undefined;
  style?: CSSProperties;
  onSelected: (value: string | number | undefined) => void;
}

export default function RadioButton(props: RadioButtonProps) {
  return (
    <div style={props.style} className={classNames(styles.radioButtonContainer, props.checked ? styles.selected : undefined)}>
      <RadioButtonComponent 
        checked={props.checked}
        label={props.label}
        name={props.groupName}
        value={props.value ? props.value.toString() : undefined}
        change={(args) => {
          props.onSelected(props.value);
        }}
      />
    </div>
  );
}
