import React from 'react';

export const useLanguage = (code?: string) => {
  const language = React.useMemo(() => languages.find(l => l.code === code), [code]);
  return language;
}

export interface Language {
  display: string;
  code: string;
  flag: string;
}

export const findCountryCodeByLanguageCode = (lanuageCode: string) => {
  return languages.find(l => l.code === lanuageCode)?.flag;
}

export const languages: Language[] = [
  { display: 'Arabic', code: 'ar', flag: 'sa' }, // TODO - Use a custom flag instead of Saudi flag?
  { display: 'Czech', code: 'cs', flag: 'cz' },
  { display: 'Danish', code: 'da', flag: 'dk' },
  { display: 'English', code: 'en', flag: 'gb' },
  { display: 'Finnish', code: 'fi', flag: 'fi' },
  { display: 'French', code: 'fr', flag : 'fr' },
  { display: 'German', code: 'de', flag: 'de' },
  { display: 'Greek', code: 'el', flag: 'gr' },
  { display: 'Hebrew', code: 'he', flag: 'il' },
  { display: 'Hindi', code: 'hi', flag: 'in' },
  { display: 'Hungarian', code: 'hu', flag: 'hu' },
  { display: 'Indonesian', code: 'id', flag: 'id' },
  { display: 'Italian', code: 'it', flag: 'it' },
  { display: 'Japanese', code: 'ja', flag: 'jp' },
  { display: 'Korean', code: 'ko', flag: 'kr' },
  { display: 'Norwegian', code: 'no', flag: 'no' },
  { display: 'Polish', code: 'pl', flag: 'pl' },
  { display: 'Portuguese', code: 'pt', flag: 'br' },
  { display: 'Romanian', code: 'ro', flag: 'ro' },
  { display: 'Russian', code: 'ru', flag: 'ru' },
  { display: 'Slovak', code: 'sk', flag: 'sk' },
  { display: 'Spanish', code: 'es', flag: 'es' },
  { display: 'Thai', code: 'th', flag: 'th' },
  { display: 'Turkish', code: 'tr', flag: 'tr' },
];

// export const languages: Language[] = [
//   { display: 'Afrikaans', code: 'af', flag: 'za' },
//   { display: 'Albanian', code: 'sq', flag: 'al' },
//   { display: 'Arabic (Algeria)', code: 'ar-dz', flag: 'dz' },
//   { display: 'Arabic (Bahrain)', code: 'ar-bh', flag: 'bh' },
//   { display: 'Arabic (Egypt)', code: 'ar-eg', flag: 'eg' },
//   { display: 'Arabic (Iraq)', code: 'ar-iq', flag: 'iq' },
//   { display: 'Arabic (Jordan)', code: 'ar-jo', flag: 'jo' },
//   { display: 'Arabic (Kuwait)', code: 'ar-kw', flag: 'kw' },
//   { display: 'Arabic (Lebanon)', code: 'ar-lb', flag: 'lb' },
//   { display: 'Arabic (Libya)', code: 'ar-ly', flag: 'ly' },
//   { display: 'Arabic (Morocco)', code: 'ar-ma', flag: 'ma' },
//   { display: 'Arabic (Oman)', code: 'ar-om', flag: 'om' },
//   { display: 'Arabic (Qatar)', code: 'ar-qa', flag: 'qa' },
//   { display: 'Arabic (Saudi Arabia)', code: 'ar-sa', flag: 'sa' },
//   { display: 'Arabic (Syria)', code: 'ar-sy', flag: 'sy' },
//   { display: 'Arabic (Tunisia)', code: 'ar-tn', flag: 'tn' },
//   { display: 'Arabic (U.A.E.)', code: 'ar-ae', flag: 'ae' },
//   { display: 'Arabic (Yemen)', code: 'ar-ye', flag: 'ye' },
//   { display: 'Basque', code: 'eu', flag: 'es' },
//   { display: 'Belarusian', code: 'be', flag: 'by' },
//   { display: 'Bulgarian', code: 'bg', flag: 'bg' },
//   { display: 'Catalan', code: 'ca', flag: 'es' },
//   { display: 'Chinese (Hong Kong)', code: 'zh-hk', flag: 'hk' },
//   { display: 'Chinese (PRC)', code: 'zh-cn', flag: 'cn' },
//   { display: 'Chinese (Singapore)', code: 'zh-sg', flag: 'sg' },
//   { display: 'Chinese (Taiwan)', code: 'zh-tw', flag: 'tw' },
//   { display: 'Croatian', code: 'hr', flag: 'hr' },
//   { display: 'Czech', code: 'cs', flag: 'cz' },
//   { display: 'Danish', code: 'da', flag: 'dk' },
//   { display: 'Dutch', code: 'nl', flag: 'nl' },
//   { display: 'Dutch (Belgium)', code: 'nl-be', flag: 'be' },
//   { display: 'English', code: 'en', flag: 'us' },
//   { display: 'English (Australia)', code: 'en-au', flag: 'au' },
//   { display: 'English (Belize)', code: 'en-bz', flag: 'bz' },
//   { display: 'English (Canada)', code: 'en-ca', flag: 'ca' },
//   { display: 'English (Ireland)', code: 'en-ie', flag: 'ie' },
//   { display: 'English (Jamaica)', code: 'en-jm', flag: 'jm' },
//   { display: 'English (New Zealand)', code: 'en-nz', flag: 'nz' },
//   { display: 'English (South Africa)', code: 'en-za', flag: 'za' },
//   { display: 'English (Trinidad)', code: 'en-tt', flag: 'tt' },
//   { display: 'English (United Kingdom)', code: 'en-gb', flag: 'gb uk' },
//   { display: 'English (United States)', code: 'en-us', flag: 'us' },
//   { display: 'Estonian', code: 'et', flag: 'ee' },
//   { display: 'Faeroese', code: 'fo', flag: 'dk' },
//   { display: 'Farsi', code: 'fa', flag: 'ir' },
//   { display: 'Finnish', code: 'fi', flag: 'fi' },
//   { display: 'French', code: 'fr', flag : 'fr' },
//   { display: 'French (Belgium)', code: 'fr-be', flag: 'be' },
//   { display: 'French (Canada)', code: 'fr-ca', flag: 'ca' },
//   { display: 'French (Luxembourg)', code: 'fr-lu', flag: 'lu' },
//   { display: 'French (Switzerland)', code: 'fr-ch', flag: 'ch' },
//   { display: 'Gaelic (Scotland)', code: 'gd', flag: 'gb sct' },
//   { display: 'German', code: 'de', flag: 'de' },
//   { display: 'German (Austria)', code: 'de-at', flag: 'at' },
//   { display: 'German (Liechtenstein)', code: 'de-li', flag: 'li' },
//   { display: 'German (Luxembourg)', code: 'de-lu', flag: 'lu' },
//   { display: 'German (Switzerland)', code: 'de-ch', flag: 'ch' },
//   { display: 'Greek', code: 'el', flag: 'gr' },
//   { display: 'Hebrew', code: 'he', flag: 'il' },
//   { display: 'Hindi', code: 'hi', flag: 'in' },
//   { display: 'Hungarian', code: 'hu', flag: 'hu' },
//   { display: 'Icelandic', code: 'is', flag: 'is' },
//   { display: 'Indonesian', code: 'id', flag: 'id' },
//   { display: 'Irish', code: 'ga', flag: 'ie' },
//   { display: 'Italian', code: 'it', flag: 'it' },
//   { display: 'Italian (Switzerland)', code: 'it-ch', flag: 'ch' },
//   { display: 'Japanese', code: 'ja', flag: 'jp' },
//   { display: 'Korean', code: 'ko', flag: 'kr' },
//   { display: 'Kurdish', code: 'ku', flag: 'iq' },
//   { display: 'Latvian', code: 'lv', flag: 'lv' },
//   { display: 'Lithuanian', code: 'lt', flag: 'lt' },
//   { display: 'Macedonian (FYROM)', code: 'mk', flag: 'mk' },
//   { display: 'Malayalam', code: 'ml', flag: 'in' },
//   { display: 'Malaysian', code: 'ms', flag: 'my' },
//   { display: 'Maltese', code: 'mt', flag: 'mt' },
//   { display: 'Norwegian', code: 'no', flag: 'no' },
//   { display: 'Norwegian (Bokmål)', code: 'nb', flag: 'no' },
//   { display: 'Norwegian (Nynorsk)', code: 'nn', flag: 'no' },
//   { display: 'Polish', code: 'pl', flag: 'pl' },
//   { display: 'Portuguese (Brazil)', code: 'pt-br', flag: 'br' },
//   { display: 'Portuguese (Portugal)', code: 'pt', flag: 'pt' },
//   { display: 'Punjabi', code: 'pa', flag: 'in' },
//   { display: 'Rhaeto-Romanic', code: 'rm', flag: 'ch' },
//   { display: 'Romanian', code: 'ro', flag: 'ro' },
//   { display: 'Romanian (Republic of Moldova)', code: 'ro-md', flag: 'md' },
//   { display: 'Russian', code: 'ru', flag: 'ru' },
//   { display: 'Russian (Republic of Moldova)', code: 'ru-md', flag: 'md' },
//   { display: 'Serbian', code: 'sr', flag: 'cs' },
//   { display: 'Slovak', code: 'sk', flag: 'sk' },
//   { display: 'Slovenian', code: 'sl', flag: 'si' },
//   { display: 'Sorbian', code: 'sb', flag: 'de' },
//   { display: 'Spanish (Argentina)', code: 'es-ar', flag: 'ar' },
//   { display: 'Spanish (Bolivia)', code: 'es-bo', flag: 'bo' },
//   { display: 'Spanish (Chile)', code: 'es-cl', flag: 'cl' },
//   { display: 'Spanish (Colombia)', code: 'es-co', flag: 'co' },
//   { display: 'Spanish (Costa Rica)', code: 'es-cr', flag: 'cr' },
//   { display: 'Spanish (Dominican Republic)', code: 'es-do', flag: 'do' },
//   { display: 'Spanish (Ecuador)', code: 'es-ec', flag: 'ec' },
//   { display: 'Spanish (El Salvador)', code: 'es-sv', flag: 'sv' },
//   { display: 'Spanish (Guatemala)', code: 'es-gt', flag: 'gt' },
//   { display: 'Spanish (Honduras)', code: 'es-hn', flag: 'hn' },
//   { display: 'Spanish (Mexico)', code: 'es-mx', flag: 'mx' },
//   { display: 'Spanish (Nicaragua)', code: 'es-ni', flag: 'ni' },
//   { display: 'Spanish (Panama)', code: 'es-pa', flag: 'pa' },
//   { display: 'Spanish (Paraguay)', code: 'es-py', flag: 'py' },
//   { display: 'Spanish (Peru)', code: 'es-pe', flag: 'pe' },
//   { display: 'Spanish (Puerto Rico)', code: 'es-pr', flag: 'pr' },
//   { display: 'Spanish (Spain)', code: 'es', flag: 'es' },
//   { display: 'Spanish (Uruguay)', code: 'es-uy', flag: 'uy' },
//   { display: 'Spanish (Venezuela)', code: 'es-ve', flag: 've' },
//   { display: 'Swedish', code: 'sv', flag: 'se' },
//   { display: 'Swedish (Finland)', code: 'sv-fi', flag: 'fi' },
//   { display: 'Thai', code: 'th', flag: 'th' },
//   { display: 'Tsonga', code: 'ts', flag: 'za' },
//   { display: 'Tswana', code: 'tn', flag: 'za' },
//   { display: 'Turkish', code: 'tr', flag: 'tr' },
//   { display: 'Ukrainian', code: 'uk', flag: 'ua' },
//   { display: 'Urdu', code: 'ur', flag: 'pk' },
//   { display: 'Venda', code: 've', flag: 'za' },
//   { display: 'Vietnamese', code: 'vi', flag: 'vn' },
//   { display: 'Welsh', code: 'cy', flag: 'gb wls' },
//   { display: 'Xhosa', code: 'xh', flag: 'za' },
//   { display: 'Yiddish', code: 'ji', flag: 'il' },
//   { display: 'Zulu', code: 'zu', flag: 'za' },
// ];
