import React, { useState, useCallback, CSSProperties } from 'react';
import styles from './_gide-title-slide.module.scss';
import sharedStyles from '../../_shared-styles.module.scss';
import { Slide } from '../../../models/Slide';
import isNil from 'ramda/es/isNil';
import { GideTitleSlideFormat } from '../../DataEntry/Pickers/Picker/Picker';
import { SlideSelectionInfo } from '../../../models/SlideSelectionInfo';
import { User } from '../../../models/User';
import { Article } from '../../../models/Article';
import GideImage from '../../Shared/Image/GideImage';
import classNames from 'classnames';
import cssAspectRatio from '../../_css-aspect-ratio.module.scss';
import { LabeledTextInput } from '../../DataEntry/CreationProcess/LabeledTextInput/LabeledTextInput';
import icons from '../../../assets/icons';
import { displayGideTitle, humanizedDurationBetweenDates } from '../../../utils/helperFunctions';
import typography from '../../../../assets/_gides-typography.module.scss';
import moment from 'moment';
import UserTile, { UserTileStyle } from '../../Shared/Previews/UserTile/UserTile';
import { getUserFullName } from '../../../utils/userExtensions';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import { findCountryCodeByLanguageCode } from '../../../../src/utils/useLanguage';
import { useDispatch, useSelector } from 'react-redux';
import { FOLLOW_USER, UNFOLLOW_USER } from '../../../constants/actionTypes';
import agent from '../../../agent';
import { AppState } from '../../../reducer';

export interface GideTitleSlideProps {
  slide: Slide;
  slideSelectionModeDetail?: SlideSelectionInfo;
  viewMode: string;
  currentUser: User;
  article: Article;
  view: string;
  onSubmitSlideZero?: (articleId: string, slideZero: Slide, articleSlug: string, tagList?: string[], description?: string, descriptionSlide?: string) => void;
  openModal?: (payload: any) => void;
  onClick?: () => void;
  onModifiedTitle?: (newTitle: string) => void;
  isFollowing?: boolean;
  followUser?: (username: string) => void;
  unFollowUser?: (username: string) => void;
  slideCount: number;
}

// TODO: MOVE THIS TO SHARED SO IT CAN BE USED BY OTHER COMPONENTS
const Tag = (props: {
    backgroundColor: string,
    iconBackgroundColor: string,
    tagTitle: string, 
    icon: JSX.Element,
    style?: CSSProperties,
    className?: string;
    titleClassName?: string;
    titleStyle?: CSSProperties,
    onClick?: () => void;
  }) => {
  const containerStyle = props.style ? {
      ...props.style, 
      backgroundColor: props.backgroundColor} : {backgroundColor: props.backgroundColor,
    };
  return (
    <div className={classNames(styles.tagContainer, props.className)} style={containerStyle}
      onClick={() => {
        if(props.onClick) {
          props.onClick();
        }
      }}
    >
      <div className={styles.iconContainer} style={{backgroundColor: props.iconBackgroundColor}}>
        {props.icon}
      </div>
      <div className={classNames(styles.tagTitle, props.titleClassName)} style={props.titleStyle}>
        {props.tagTitle}
      </div>
    </div>
  );
}

const Title = (props: {
    beginEditingTitle: () => void,
    canEditTitle: boolean;
    title: string;
    articleType?: string;
  }) => {
  return (
    <div className={styles.gideTitle} onClick={props.beginEditingTitle}>
      {props.canEditTitle && <span><icons.Nav_Edit_EditMain /></span>}
      {displayGideTitle(props.title, props.articleType!)}
    </div>
  );
};
export default function GideTitleSlide(props: GideTitleSlideProps) {
  const canEditTitle = !!props.onModifiedTitle;
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const beginEditingTitle = useCallback(() => {
    canEditTitle && setIsEditingTitle(true);
  }, [setIsEditingTitle]);
  const finishEditingTitle = useCallback((newTitle: string) => {
    setIsEditingTitle(false);
    props.onModifiedTitle && props.onModifiedTitle(newTitle);
  }, [setIsEditingTitle, props.onModifiedTitle]);
  const onChange = useCallback((newTitle: string) => {
    props.onModifiedTitle && props.onModifiedTitle(newTitle);
  }, [props.onModifiedTitle]);

  const gideTitleIsSet = props.slide.data.title;
  const gideImageIsSet = props.slide.data.image;
  const format = !isNil(props.slide.data.slideTypeSpecificSettings) && !isNil(props.slide.data.slideTypeSpecificSettings.format)
    ? props.slide.data.slideTypeSpecificSettings.format
    : GideTitleSlideFormat.ImageAndText;

  const onClear = () => {};
  const articleCreateDate = moment(props.article ? props.article.createdAt : new Date());

  const dispatch = useDispatch();
  const actions: {followUser: (username: string) => void, unFollowUser: (username: string) => void} = {
    followUser: (username: string) =>   dispatch({
      type: FOLLOW_USER,
      payload: agent.Profile.follow(username),
    }),
    unFollowUser: (username: string) =>
      dispatch({
        type: UNFOLLOW_USER,
        payload: agent.Profile.unfollow(username),
      }),
  };
  return (
    <div
      className={styles.gideTitleSlideContainer}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      {gideImageIsSet && format === GideTitleSlideFormat.Blog && props.article && (
        <div className={styles.gideTitleBlogHeader}>
          <div className={styles.gideMetadata}>
              <div style={{width: '24px', height: '18px', marginBottom: '12px'}} className={classNames('flag-icon', `flag-icon-${findCountryCodeByLanguageCode(props.article.language!)}`)}></div>
              <div style={{background: 'var(--COLOR-SECONDARY-500)', margin: '0 12px 12px 12px'}} className={sharedStyles.verticalSeparator} /> <span style={{whiteSpace: 'nowrap', marginBottom: '12px'}}>{articleCreateDate.format('MMMM Do, YYYY')}</span> 
              <div style={{background: 'var(--COLOR-SECONDARY-500)', margin: '0 12px 12px 12px'}} className={sharedStyles.verticalSeparator} /> <span style={{whiteSpace: 'nowrap', marginBottom: '12px'}}>{articleCreateDate.format('hh:mm a')} </span>
              <div style={{background: 'var(--COLOR-SECONDARY-500)', margin: '0 12px 12px 12px'}} className={sharedStyles.verticalSeparator} /> <span style={{whiteSpace: 'nowrap', marginBottom: '12px'}}>Updated {humanizedDurationBetweenDates(props.article.updatedAt, new Date())}</span>
              <div style={{background: 'var(--COLOR-SECONDARY-500)', margin: '0 12px 12px 12px'}} className={sharedStyles.verticalSeparator} /> <span style={{whiteSpace: 'nowrap', marginBottom: '12px'}}>{props.slideCount} Slides</span> 
              <div style={{background: 'var(--COLOR-SECONDARY-500)', margin: '0 12px 12px 12px'}} className={sharedStyles.verticalSeparator} /> <span style={{whiteSpace: 'nowrap', marginBottom: '12px'}}>{props.article.viewCount} Views</span>
          </div>
          {gideTitleIsSet && (
            <Title 
              beginEditingTitle={beginEditingTitle}
              canEditTitle={canEditTitle}
              title={props.slide.data.title}
              articleType={props.article?.type}
            />
          )}
          {props.article && props.article.description && (
            <div className={styles.gideDescription}>
              {props.article.description}
            </div>
          )}
          {(props.slide.geotagLocation?.coordinates || props.article?.tagList.length > 0) && (
            <div className={styles.titleSlideTagContainer}>            
              {props.slide.geotagLocation?.coordinates && props.slide.geotagLocation.coordinates.length > 1 && (
                <Tag
                  style={{marginRight: '16px', marginBottom: '12px'}}
                  titleStyle={{color: 'var(--SUCCESS-100)'}}
                  backgroundColor="rgba(104, 207, 97, 0.1)"
                  iconBackgroundColor="var(--SUCCESS-100)"
                  icon={<icons.SlideType_Location_Location color="var(--WHITES-NORMAL-1000)" />}
                  tagTitle={`Location: ${props.slide.geotagLocation.coordinates[0].toFixed(5)}, ${props.slide.geotagLocation.coordinates[1].toFixed(5)}`}
                  onClick={() => {
                    
                  }}
                />
              )}
              {props.article?.tagList && props.article?.tagList.length > 0 && (
                <Tag
                  style={{marginBottom: '12px'}}
                  className={classNames(styles.tagListHashTagContainer, props.slide.geotagLocation?.coordinates && props.slide.geotagLocation.coordinates.length ? undefined : styles.noGeoTags)}
                  titleClassName={styles.tagListHashTags}
                  // titleStyle={{color: 'var(--LINK-100)', maxWidth: 'calc(100vw - 100px)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  backgroundColor="rgba(50, 179, 224, 0.1)"
                  iconBackgroundColor="var(--LINK-100)"
                  icon={<icons.ContentAlteration_Hashtag color="var(--WHITES-NORMAL-1000)" />}
                  tagTitle={props.article.tagList.toString().replace(/,/g, ', ')}
                />
              )}
            </div>
          )}
        </div>
      )}
      {format === GideTitleSlideFormat.NoTitle && props.viewMode === 'SLIDE' && (
        <div className={styles.noTitleFormatContianer}>
          <div className={styles.message}>
            <span className={styles.bold}>Your title slide</span> <span>will not show to your viewers</span>
          </div>
        </div>
      )}
      {gideImageIsSet && (format === GideTitleSlideFormat.ImageAndText || format === GideTitleSlideFormat.ImageOnly || format === GideTitleSlideFormat.Blog) && (
        <div className={classNames(styles.gideTitleImage, cssAspectRatio.aspectRatio)}>
          <GideImage src={props.slide.data.image} alt={'Gide Image'} />
        </div>
      )}   
      {!isEditingTitle && <>
          {!gideTitleIsSet &&
            <div className={`${styles.gideTitle} ${styles.missing}`} onClick={beginEditingTitle}>
              {canEditTitle && <span><icons.Nav_Edit_EditMain /></span>}
              Give the gide a title!
            </div>}
          {gideTitleIsSet && (format === GideTitleSlideFormat.ImageAndText || format === GideTitleSlideFormat.TextOnly) && (
            <Title 
              beginEditingTitle={beginEditingTitle}
              canEditTitle={canEditTitle}
              title={props.slide.data.title}
              articleType={props.article?.type}
            />
          )}
        </>
      }
      {isEditingTitle &&
        <LabeledTextInput
          label=""
          sublabel=""
          text={props.slide.data.title}
          theme="light"
          skipAcceptStep
          submitOnEnterKey
          placeholder="Give the gide a title!"
          isAcceptable={(text: string) => true}
          onAccept={async (text: string) => true}
          onConfirm={finishEditingTitle}
          onClear={onClear}
          onChange={onChange}
          onBlur={finishEditingTitle}
          hideAcceptButton
          style={{width: '100%'}}
        />
      }

      {gideImageIsSet && format === GideTitleSlideFormat.Blog && props.article && (
        <div className={styles.gideTitleBlogFooter}>
          <UserTile
            tileStyle={UserTileStyle.ProfileListMain}
            userImage={props.article?.author?.image ? props.article.author.image : ''}
            username={props.article?.author ? props.article.author.username : ''}
            fullName={props.article?.author ? getUserFullName(props.article.author) : ''}
            userLanguage={props.article?.author?.language ? props.article.author.language : 'en'}
            category={props.article?.author?.businessFocus ? props.article.author.businessFocus : '' }
            followers={props.article?.author?.connections?.following ? props.article.author.connections.following.length : 0}
            gides={props.article?.author?.publishedGides ? props.article.author.publishedGides : 0}
            channels={props.article?.author?.publicChannels ? props.article?.author?.publicChannels : 0} // TODO:
            isShortcut={false}
            country={props.article?.author?.country ? props.article.author.country : '' }
          />
          {props.view !== 'SlideZeroEditor' &&
            props.currentUser &&
            props.article.author.username !== props.currentUser.username && (
            <RoundedCornerButton
              label={props.article.author.following ? 'Unfollow' : 'Follow'}
              style={{backgroundColor: 'var(--COLOR-PRIMARY-300)', marginRight: '16px'}}
              labelColor='white'
              onClick={() => {
                if(props.article) {
                  props.article.author.following
                    ? actions.unFollowUser(props.article.author.username)
                    : actions.followUser(props.article.author.username)
                  }
                }
              }
            />
          )}
        </div>
      )}
    </div>
  );
}
