import React from 'react';
import { CompactPicker } from 'react-color';
import { Checkbox, Dropdown, Input } from 'semantic-ui-react';

import { fonts } from '../../../constants/fonts';
import CarouselSettings from './CarouselSettings/CarouselSettings';
import {
  ExpirationSettings,
  DateTimeExpiration,
  ExpirationAction,
  ExpireBy,
  Expiration,
  DurationExpiration,
  DurationUnit,
} from '../../../models/ExpirationSettings';
import { Slide } from '../../../models/Slide';
import { RadioButton as RadioButtonComponent } from '../../Shared/RadioButton/RadioButton';
import { TimePicker } from '../../Shared/TimePicker/TimePicker';
import { DatePicker } from '../../Shared/DatePicker/DatePicker';
import { TextKeyValue } from '../../../models/KeyValue';
import { unitTimeOptions } from '../../../utils/helperFunctions';
import { CarouselNavigationMode } from '../../Viewers/ImageViewers/ImageCarousel/ImageCarousel';
import SlideDimensionsPicker from '../../DataEntry/Pickers/Modals/SlideDimensionsPicker/SlideDimensionsPicker';
import { ImageObjectFit } from '../../../models/CommonEnums';

const widthOptions: TextKeyValue<string, string>[] = [
  { key: 'NORMAL', value: 'NORMAL', text: 'Normal (margin)' },
  { key: 'EDGE', value: 'EDGE', text: 'Edge to Edge' },
  { key: 'HALF', value: 'HALF', text: 'Half/Half' },
];

const floatHorizontalOptions: TextKeyValue<string, string>[] = [
  { key: '', value: '', text: 'None' },
  { key: 'LEFT', value: 'LEFT', text: 'Left' },
  { key: 'RIGHT', value: 'RIGHT', text: 'Right' },
];

const floatVerticalOptions: TextKeyValue<string, string>[] = [
  { key: '', value: '', text: 'None' },
  { key: 'TOP', value: 'TOP', text: 'Top' },
  { key: 'BOTTOM', value: 'BOTTOM', text: 'Bottom' },
];

export interface Settings {
  allowEmbed: boolean;
  autoAdvanceSlide?: boolean;
  autoPlayAudioCaption?: boolean;
  requireAttribution: boolean;
  allowLink: boolean;
  backgroundColor?: string;
  fontColor: string;
  font: string;
  floatHorizontal?: string;
  floatVertical?: string;
  contentPaddingTop?: string;
  contentPaddingRight?: string;
  contentPaddingBottom?: string;
  contentPaddingLeft?: string;
  contentVerticalAlignment?: string;
  contentHorizontalAlignment: string;
  isTemplate?: boolean;
  allowComments?: boolean;
  allowQuestions?: boolean;
  maxHeight?: string;
  stretch?: boolean;
  widthPercentage: number;
  width: string;
  selection?: string; // TODO: Ask Dale what this is for.
  useNativeResolution?: boolean;
  expirationSettings?: ExpirationSettings;
}
export const getSlideSettings = (
  slide: Slide,
  articleType: string,
): Settings => {
  return {
    width: slide.width || 'NORMAL',
    widthPercentage: slide.widthPercentage || 100,
    allowEmbed: slide.allowEmbed || false,
    requireAttribution: slide.requireAttribution || false,
    allowLink: slide.allowLink || false,
    backgroundColor: slide.backgroundColor,
    fontColor: slide.fontColor || '#000',
    font: slide.font || 'arial',
    floatHorizontal: slide.floatHorizontal,
    floatVertical: slide.floatVertical,
    maxHeight: slide.maxHeight,
    useNativeResolution: slide.useNativeResolution,
    contentPaddingTop: slide.contentPaddingTop,
    contentPaddingRight: slide.contentPaddingRight,
    contentPaddingBottom: slide.contentPaddingBottom,
    contentPaddingLeft: slide.contentPaddingLeft,
    contentVerticalAlignment: slide.contentVerticalAlignment,
    contentHorizontalAlignment: slide.contentHorizontalAlignment || 'CENTER',
    stretch: slide.stretch,
    autoPlayAudioCaption: slide.autoPlayAudioCaption || false,
    autoAdvanceSlide: slide.autoAdvanceSlide || false,
    // NOTE: For a TEMPLATE GIDE, undefined or true will be considered true.
    isTemplate:
      slide.isTemplate ||
      (articleType === 'TEMPLATE' && slide.isTemplate === undefined) ||
      false,
    allowComments: slide.allowComments,
    allowQuestions: slide.allowQuestions,
    expirationSettings: slide.expirationSettings,
  };
};

export const updateSlideSettings = (slide: Slide, settings: Settings) => {
  return {
    ...slide,
    width: settings.width,
    widthPercentage: settings.widthPercentage,
    allowEmbed: settings.allowEmbed,
    requireAttribution: settings.requireAttribution,
    allowLink: settings.allowLink,
    backgroundColor: settings.backgroundColor,
    fontColor: settings.fontColor,
    font: settings.font,
    floatHorizontal: settings.floatHorizontal,
    floatVertical: settings.floatVertical,
    contentPaddingTop: settings.contentPaddingTop,
    contentPaddingRight: settings.contentPaddingRight,
    contentPaddingBottom: settings.contentPaddingBottom,
    contentPaddingLeft: settings.contentPaddingLeft,
    contentVerticalAlignment: settings.contentVerticalAlignment,
    contentHorizontalAlignment: settings.contentHorizontalAlignment,
    maxHeight: settings.maxHeight,
    useNativeResolution: settings.useNativeResolution,
    stretch: settings.stretch,
    autoPlayAudioCaption: settings.autoPlayAudioCaption,
    autoAdvanceSlide: settings.autoAdvanceSlide,
    isTemplate: settings.isTemplate,
    allowComments: settings.allowComments,
    allowQuestions: settings.allowQuestions,
    expirationSettings: settings.expirationSettings,
  };
};
export interface SlideSettingsProps {
  hideTemplateIndicator: boolean;
  settings: Settings;
  slideType?: string;
  allowNativeResolutionSetting?: boolean;
  slideTypeSpecificSettings?: any;

  onCloseSettings: () => void;
  onSettingsChanged: (settings: Settings) => void;
  onslideTypeSpecificSettingsChanged?: (settings: any) => void;
}
export default (props: SlideSettingsProps) => {
  return (
    <div className="slideSettings">
      <h2>Settings</h2>
      <span
        className="link"
        style={{ padding: '10px', display: 'block' }}
        onClick={props.onCloseSettings}
      >
        Close Settings
      </span>
      {props.hideTemplateIndicator !== true && (
        <fieldset className="form-group">
          <Checkbox
            onChange={(evt, data) => {
              const settings = { ...props.settings, isTemplate: data.checked };
              props.onSettingsChanged(settings);
            }}
            toggle
            checked={props.settings.isTemplate}
            label="Is Template"
          />
        </fieldset>
      )}
      <fieldset className="form-group">
        <Checkbox
          onChange={(evt, data) => {
            const settings = { ...props.settings, allowComments: data.checked };
            props.onSettingsChanged(settings);
          }}
          toggle
          checked={props.settings.allowComments}
          label="Allow Slide Comments"
        />
      </fieldset>
      <fieldset className="form-group">
        <Checkbox
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              allowQuestions: data.checked,
            };
            props.onSettingsChanged(settings);
          }}
          toggle
          checked={props.settings.allowQuestions}
          label="Allow Slide Questions"
        />
      </fieldset>
      <h3>Float Horizontal</h3>
      <div>
        <Dropdown
          placeholder="Float Horizontal"
          options={floatHorizontalOptions}
          value={props.settings.floatHorizontal}
          onChange={(evt, data) => {
            const settings: Settings = {
              ...props.settings,
              floatHorizontal: data.value as string | undefined,
            };
            props.onSettingsChanged(settings);
          }}
        />
      </div>
      <h3>Float Vertical (if applicable in layout situation)</h3>
      <div>
        <Dropdown
          placeholder="Float Vertical"
          options={floatVerticalOptions}
          value={props.settings.floatVertical}
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              floatVertical: data.value as string | undefined,
            };
            props.onSettingsChanged(settings);
          }}
        />
      </div>

      {/* <h3>Content Horizontal Alignment (if applicable in layout situation)</h3>
      <div>
        <Dropdown
          placeholder="Content Horizontal Alignment"
          options={contentHorizontalAlignmentOptions}
          value={props.settings.contentHorizontalAlignment}
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              contentHorizontalAlignment: data.value,
            };
            props.onSettingsChanged(settings);
          }}
        />
      </div> */}

      {/* {props.showVerticalAlignmentSettings === true && (
        <>
          <h3>
            Content Vertical Alignment (if applicable in layout situation)
          </h3>
          <div>
            <Dropdown
              placeholder="Content Vertical Alignment"
              options={contentVerticalAlignmentOptions}
              value={props.settings.contentVerticalAlignment}
              onChange={(evt, data) => {
                const settings = {
                  ...props.settings,
                  contentVerticalAlignment: data.value,
                };
                props.onSettingsChanged(settings);
              }}
            />
          </div>
        </>
      )} */}

      
      <SlideDimensionsPicker
        slideAlignment={props.settings.contentHorizontalAlignment || 'CENTER'}
        slideWidthPercentage={props.settings.widthPercentage || 100}
        slideWidthType={props.settings.width || 'NORMAL'}
        displayAsNonModal={true}
        onChange={(slideWidthType: string, slideAlignment: string, slidePercentage: number) => {        
          const settings: Settings = {
            ...props.settings,
            contentHorizontalAlignment: slideAlignment,
            widthPercentage: slidePercentage,
            width: slideWidthType,
          };
          props.onSettingsChanged(settings);

        }}
      />
      {props.slideType === 'IMAGE' && (
        <>
          {props.allowNativeResolutionSetting && (
            <fieldset className="form-group">
              <Checkbox
                onChange={(evt, data) => {
                  const settings = {
                    ...props.settings,
                    useNativeResolution: data.checked,
                  };
                  props.onSettingsChanged(settings);
                }}
                toggle
                checked={props.settings.useNativeResolution}
                label="Use Native Resolution (Only Images 800px or less)"
              />
            </fieldset>
          )}
          <CarouselSettings
            carouselSettingsData={{
              carouselAdvanceSpeed: props.slideTypeSpecificSettings
                ? props.slideTypeSpecificSettings.carouselAdvanceSpeed
                : 0,
              imageObjectFit: props.slideTypeSpecificSettings
                ? props.slideTypeSpecificSettings.imageObjectFit
                : ImageObjectFit.AutoCrop,
              carouselNavigationMode: props.slideTypeSpecificSettings
                .carouselNavigationMode
                ? props.slideTypeSpecificSettings.carouselNavigationMode
                : CarouselNavigationMode.Thumbnail,
              maxHeight: props.settings.maxHeight
                ? props.settings.maxHeight
                : '400',
            }}
            onCarouselSettingsChanged={carouselSettings => {
              const slideTypeSpecificSettings = {
                ...props.slideTypeSpecificSettings,
                carouselNavigationMode: carouselSettings.carouselNavigationMode,
                carouselAdvanceSpeed: carouselSettings.carouselAdvanceSpeed,
                imageObjectFit: carouselSettings.imageObjectFit,
              };
              if (props.onslideTypeSpecificSettingsChanged) {
                props.onslideTypeSpecificSettingsChanged(
                  slideTypeSpecificSettings,
                );
              } else {
                console.log('ERROR: missing event handler prop');
              }
              if (carouselSettings.maxHeight !== props.settings.maxHeight) {
                const settings = {
                  ...props.settings,
                  maxHeight: carouselSettings.maxHeight,
                };
                props.onSettingsChanged(settings);
              }
            }}
          />
        </>
      )}
      {(props.slideType !== 'IMAGE' ||
        !props.allowNativeResolutionSetting) && (
          <>
            <h3>Width</h3>
            <div>
              <Dropdown
                placeholder="Width"
                options={widthOptions}
                value={props.settings.width}
                onChange={(evt, data) => {
                  const settings = {
                    ...props.settings,
                    width: data.value as string || 'NORMAL',
                  };
                  props.onSettingsChanged(settings);
                }}
              />
            </div>
          </>
        )}

      <fieldset className="form-group">
        <Checkbox
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              stretch: data.checked,
            };
            props.onSettingsChanged(settings);
          }}
          toggle
          checked={props.settings.stretch}
          label="Stretch"
        />
      </fieldset>
      <fieldset className="form-group">
        {/* Currently the image slide has its own settings which uses
            maxHeight to limit the content height. Considering changing this
            to use maxContentHeight property instead.
        */}
        {props.slideType !== 'IMAGE' && (
          <>
            <label>Max Height</label>
            <Input
              className="form-control"
              type="text"
              placeholder="optional"
              value={props.settings.maxHeight || ''}
              onChange={ev => {
                const settings = {
                  ...props.settings,
                  maxHeight: ev.target.value,
                };
                props.onSettingsChanged(settings);
              }}
            />
          </>
        )}

        <label>Content Top Padding</label>
        <Input
          className="form-control"
          type="text"
          placeholder="optional"
          value={props.settings.contentPaddingTop || ''}
          onChange={ev => {
            const settings = {
              ...props.settings,
              contentPaddingTop: ev.target.value,
            };
            props.onSettingsChanged(settings);
          }}
        />
        <label>Content Right Padding</label>
        <Input
          className="form-control"
          type="text"
          placeholder="optional"
          value={props.settings.contentPaddingRight || ''}
          onChange={ev => {
            const settings = {
              ...props.settings,
              contentPaddingRight: ev.target.value,
            };
            props.onSettingsChanged(settings);
          }}
        />
        <label>Content Bottom Padding</label>
        <Input
          className="form-control"
          type="text"
          placeholder="optional"
          value={props.settings.contentPaddingBottom || ''}
          onChange={ev => {
            const settings = {
              ...props.settings,
              contentPaddingBottom: ev.target.value,
            };
            props.onSettingsChanged(settings);
          }}
        />
        <label>Content Left Padding</label>
        <Input
          className="form-control"
          type="text"
          placeholder="optional"
          value={props.settings.contentPaddingLeft || ''}
          onChange={ev => {
            const settings = {
              ...props.settings,
              contentPaddingLeft: ev.target.value,
            };
            props.onSettingsChanged(settings);
          }}
        />
      </fieldset>
      <fieldset className="form-group">
        <Checkbox
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              allowLink: data.checked as boolean,
            };
            props.onSettingsChanged(settings);
          }}
          toggle
          checked={props.settings.allowLink}
          label="Allow others to link to this slide"
        />
      </fieldset>
      <h3>Audio Caption</h3>
      <fieldset className="form-group">
        <Checkbox
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              autoPlayAudioCaption: data.checked,
            };
            props.onSettingsChanged(settings);
          }}
          toggle
          checked={props.settings.autoPlayAudioCaption}
          label="Auto Play Caption"
        />
      </fieldset>
      <fieldset className="form-group">
        <Checkbox
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              autoAdvanceSlide: data.checked,
            };
            props.onSettingsChanged(settings);
          }}
          toggle
          checked={props.settings.autoAdvanceSlide}
          label="Auto Advance on Caption Ended"
        />
      </fieldset>
      <h3>Copyright and Attribution</h3>
      <fieldset className="form-group">
        <Checkbox
          onChange={(evt, data) => {
            const settings = {
              ...props.settings,
              allowEmbed: data.checked as boolean,
              requireAttribution: false,
            };
            props.onSettingsChanged(settings);
          }}
          toggle
          checked={props.settings.allowEmbed}
          label="Allow Embed"
        />
      </fieldset>
      {props.settings.allowEmbed && (
        <fieldset className="form-group">
          <Checkbox
            onChange={(evt, data) => {
              const settings = {
                ...props.settings,
                requireAttribution: data.checked as boolean,
              };
              props.onSettingsChanged(settings);
            }}
            toggle
            checked={props.settings.requireAttribution}
            label="Require Attribution"
          />
        </fieldset>
      )}
      <fieldset>
        <Checkbox
          onChange={(event, data) => {
            const settings = {
              ...props.settings,
              expirationSettings: data.checked
                ? {
                    action: ExpirationAction.Hide,
                    expireBy: ExpireBy.Global,
                    source: new DateTimeExpiration(new Date()),
                  }
                : {
                    action: ExpirationAction.Undefined,
                  },
            };
            props.onSettingsChanged(settings);
          }}
          toggle
          checked={props.settings.expirationSettings !== undefined}
          label="Expire Slide"
        />
        {props.settings.expirationSettings && (
          <div className="expireSlideSettings">
            <div className="expireSlideOptions">
              <RadioButtonComponent
                checked={
                  props.settings.expirationSettings.source &&
                  props.settings.expirationSettings.source.expiration ===
                    Expiration.DateTime
                }
                label="Specific Time"
                name="expireSlideType"
                value={Expiration.DateTime.toString()}
                change={(e: any) => {
                  // When user switches to this selection use these default settings for Specific Time
                  if (e.value === Expiration.DateTime.toString()) {
                    const settings = {
                      ...props.settings,
                      expirationSettings: {
                        action: ExpirationAction.Hide,
                        expireBy: ExpireBy.Global,
                        source: new DateTimeExpiration(new Date()),
                      },
                    };
                    props.onSettingsChanged(settings);
                  }
                }}
              />
              <div className="expireSlideByDuration">
                <RadioButtonComponent
                  style={{ marginLeft: '20px' }}
                  checked={
                    props.settings.expirationSettings.source &&
                    props.settings.expirationSettings.source.expiration ===
                      Expiration.Duration
                  }
                  label="Duration"
                  name="expireSlideType"
                  value={Expiration.Duration.toString()}
                  change={(e: any) => {
                    // When user switches to this selection use these default settings for Duration
                    if (e.value === Expiration.Duration.toString()) {
                      const settings = {
                        ...props.settings,
                        expirationSettings: {
                          action: ExpirationAction.Hide,
                          expireBy: ExpireBy.Global,
                          source: new DurationExpiration(
                            30,
                            DurationUnit.Second,
                          ),
                        },
                      };
                      props.onSettingsChanged(settings);
                    }
                  }}
                />
              </div>
            </div>
            <div className="expireSlideOptionSettings">
              {props.settings.expirationSettings.source &&
                props.settings.expirationSettings.source.expiration ===
                  Expiration.Duration && (
                  <div className="expireSlideDurationSettings">
                    <Input
                      placeholder="Expiration Duration"
                      value={
                        (props.settings.expirationSettings
                          .source as DurationExpiration).duration
                      }
                      onChange={(e: any) => {
                        const settings = {
                          ...props.settings,
                          expirationSettings: {
                            ...(props.settings
                              .expirationSettings as ExpirationSettings),
                            source: {
                              ...((props.settings
                                .expirationSettings as ExpirationSettings)
                                .source as DurationExpiration),
                              duration: e.target.value,
                            },
                          },
                        };
                        props.onSettingsChanged(settings);
                      }}
                      type="number"
                      min="1"
                    />
                    <Dropdown
                      style={{ marginLeft: '5px' }}
                      options={unitTimeOptions}
                      value={
                        (props.settings.expirationSettings
                          .source as DurationExpiration).durationUnits
                      }
                      onChange={(e: any, data: any) => {
                        const settings = {
                          ...props.settings,
                          expirationSettings: {
                            ...(props.settings
                              .expirationSettings as ExpirationSettings),
                            source: {
                              ...((props.settings
                                .expirationSettings as ExpirationSettings)
                                .source as DurationExpiration),
                              durationUnits: data.value,
                            },
                          },
                        };
                        props.onSettingsChanged(settings);
                      }}
                    />
                  </div>
                )}
              {props.settings.expirationSettings.source &&
                props.settings.expirationSettings.source.expiration ===
                  Expiration.DateTime && (
                  <div className="expireSlideTimeSettings">
                    <div className="expireSlideTime">
                      <div className="expireSlideTimeSettingsDate">
                        <DatePicker
                          value={
                            (props.settings.expirationSettings
                              .source as DateTimeExpiration).dateTime
                          }
                          onChange={(value) => {
                            const settings = {
                              ...props.settings,
                              expirationSettings: {
                                ...(props.settings
                                  .expirationSettings as ExpirationSettings),
                                source: {
                                  ...((props.settings
                                    .expirationSettings as ExpirationSettings)
                                    .source as DateTimeExpiration),
                                  dateTime: value,
                                },
                              },
                            };
                            props.onSettingsChanged(settings);
                          }}
                        />
                      </div>
                      <div className="expireSlideTimeSettingsTime">
                        <TimePicker
                          value={
                            (props.settings.expirationSettings
                              .source as DateTimeExpiration).dateTime
                          }
                          onChange={(value: any) => {
                            const settings = {
                              ...props.settings,
                              expirationSettings: {
                                ...(props.settings
                                  .expirationSettings as ExpirationSettings),
                                source: {
                                  ...((props.settings
                                    .expirationSettings as ExpirationSettings)
                                    .source as DateTimeExpiration),
                                  dateTime: value,
                                },
                              },
                            };
                            props.onSettingsChanged(settings);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              <div className="expirationActionOptions">
                <RadioButtonComponent
                  checked={
                    props.settings.expirationSettings &&
                    props.settings.expirationSettings.action ===
                      ExpirationAction.Hide
                  }
                  label="Hide"
                  name="expireSlideAction"
                  value={ExpirationAction.Hide.toString()}
                  change={(e: any) => {
                    // When user switches to this selection use these default settings for Specific Time
                    if (e.value === ExpirationAction.Hide.toString()) {
                      const settings = {
                        ...props.settings,
                        expirationSettings: {
                          ...(props.settings
                            .expirationSettings as ExpirationSettings),
                          action: ExpirationAction.Hide,
                        },
                      };
                      props.onSettingsChanged(settings);
                    }
                  }}
                />

                <RadioButtonComponent
                  checked={
                    props.settings.expirationSettings &&
                    props.settings.expirationSettings.action ===
                      ExpirationAction.Bye
                  }
                  label="Bye"
                  name="expireSlideAction"
                  value={ExpirationAction.Bye.toString()}
                  change={(e: any) => {
                    // When user switches to this selection use these default settings for Specific Time
                    if (e.value === ExpirationAction.Bye.toString()) {
                      const settings = {
                        ...props.settings,
                        expirationSettings: {
                          ...(props.settings
                            .expirationSettings as ExpirationSettings),
                          action: ExpirationAction.Bye,
                        },
                      };
                      props.onSettingsChanged(settings);
                    }
                  }}
                />
                {/* Only DateTime can currently be "Deleted" (Should put in Trash)  */}
                {props.settings.expirationSettings.source &&
                  props.settings.expirationSettings.source.expiration ===
                    Expiration.DateTime && (
                    <RadioButtonComponent
                      checked={
                        props.settings.expirationSettings &&
                        props.settings.expirationSettings.action ===
                          ExpirationAction.Delete
                      }
                      label="Delete"
                      name="expireSlideAction"
                      value={ExpirationAction.Delete.toString()}
                      change={(e: any) => {
                        // When user switches to this selection use these default settings for Specific Time
                        if (e.value === ExpirationAction.Delete.toString()) {
                          const settings = {
                            ...props.settings,
                            expirationSettings: {
                              ...(props.settings
                                .expirationSettings as ExpirationSettings),
                              action: ExpirationAction.Delete,
                            },
                          };
                          props.onSettingsChanged(settings);
                        }
                      }}
                    />
                  )}
              </div>
            </div>
          </div>
        )}
      </fieldset>
      <h3>Background Color</h3>
      <CompactPicker
        color={props.settings.backgroundColor}
        onChange={color => {
          const settings = { ...props.settings, backgroundColor: color.hex };
          props.onSettingsChanged(settings);
        }}
      />
      <h3>Font Family</h3>
      <div>
        <Dropdown
          placeholder="Font"
          options={fonts}
          value={props.settings.font}
          onChange={(evt, data) => {
            const settings = { ...props.settings, font: data.value as string };
            props.onSettingsChanged(settings);
          }}
        />
      </div>
      <h3>Font Color</h3>
      <CompactPicker
        color={props.settings.fontColor}
        onChange={color => {
          const settings = { ...props.settings, fontColor: color.hex };
          props.onSettingsChanged(settings);
        }}
      />
      <span
        className="link"
        style={{ padding: '10px', display: 'block' }}
        onClick={props.onCloseSettings}
      >
        Close Settings
      </span>
    </div>
  );
};
