import React, { CSSProperties, useState, useRef } from 'react';
import { Article } from '../../../models/Article';
import styles from './_gide-preview-list.module.scss';
import GideTilePreview from '../../Shared/Previews/GidePreview/GideTilePreview/GideTilePreview';
import isNil from 'ramda/es/isNil';
import { User } from '../../../models/User';
import icons from '../../../assets/icons';
import { displayGideTitle } from '../../../utils/helperFunctions';
export interface GidePreviewListProps {
  currentUser?: User | null;
  gidePreviewId?: string;
  gidePreviewTitle?: string;
  gideList: Article[];
  searchText?: string;
  style?: CSSProperties;
  onLoadMoreGides?: (gideGroupId: string, page?: number) => void;
  isReadOnly?: boolean;
  onDeleteGide?: (gideId: string) => void;
}

export default function GidePreviewList(props: GidePreviewListProps) {
  const [showMoreComponentWidth, setShowMoreComponentWidth] = React.useState(0);
  const [showMoreComponentMargin, setShowMoreComponentMargin] = React.useState(0);
  const [gideListPageNumber, setGideListPageNumber] = React.useState(0);
  const previewContainerRef = useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const handleWindowResize = () => {
      if(previewContainerRef.current !== null) {
        const tileWidthNeeded = window.innerWidth < 365 ? 180 : window.innerWidth < 375 ? 182 : window.innerWidth < 520 ? 184 : 188;

        const maxNumberOfTiles = Math.min(6, Math.floor(window.innerWidth / tileWidthNeeded));
        const moreComponentWidth = maxNumberOfTiles * tileWidthNeeded;
        // const moreComponentMargin = (previewContainerRef.current!.clientWidth - maxNumberOfTiles * tileWidthNeeded) / 2;
        setShowMoreComponentWidth(moreComponentWidth);
        // setShowMoreComponentMargin(moreComponentMargin);
      }
    };
    handleWindowResize(); // Run it initially then the resize event will take over
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const buildGidePreviewGrid = (gideList: Article[]) => {
    return gideList.map((gide: Article, index: number) => (
      <GideTilePreview
        id={gide.id}
        slug={gide.slug}
        key={`${gide.slug}-${index}`}
        image={
          !isNil(gide.slideZero) && !isNil(gide.slideZero.data)
            ? (gide.slideZero.data.image as string)
            : !isNil(gide.image)
            ? gide.image
            : ''
        }
        title={displayGideTitle(gide.title, gide.type)}
        description={gide.description}
        authorImage={gide.author.image}
        author={gide.author.username}
        language={!isNil(gide.language) ? (gide.language as string) : 'English'}
        hasGeotag={!isNil(gide.slideZero) && (gide.slideZero.geotagLocation && gide.slideZero.geotagLocation.type === 'Point') ? true : false}
        views={gide.viewCount}
        slideCount={!isNil(gide.slideCount) ? (gide.slideCount as number) : 0}
        gideCost={gide.price}
        priceType={gide.priceType}
        isOwner={!isNil(props.currentUser) && props.currentUser.username === gide.author.username}
        subscriptionPlan={gide.subscribeType}
        createdAt={new Date(gide.createdAt)}
        updatedAt={new Date(gide.updatedAt)}
        isReadOnly={gide.type === 'SPECIAL' || props.isReadOnly || !Boolean(props.currentUser && (props.currentUser.username === 'gides' || props.currentUser.id === gide.author.id))}
        allowToAddToCollections={Boolean(props.currentUser && props.currentUser.username === 'gides')}
        onDeleteGide={props.onDeleteGide}
      />
    ));
  };

  return (
    <div className={styles.gidePreviewListContainer} ref={previewContainerRef} style={props.style}>
      <div className={`${styles.gidePreviewList} ${props.searchText ? styles.searchResults : ''}`}>
        <div className={styles.gidePreviewTitleContainer}
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            minWidth: showMoreComponentWidth,
            maxWidth: showMoreComponentWidth,
            alignSelf: 'center',
          }}
        >
          {props.gidePreviewTitle && <div className={styles.gidePreviewTitle}>{props.gidePreviewTitle}</div>}
        </div>
        <div className={styles.gidePreviewGrid}>
          {buildGidePreviewGrid(props.gideList)}
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
          <span className={styles.emptyGidePreview} />
        </div>
        {props.onLoadMoreGides && (
          <div
            onClick={() => {
              const newGideListPageNumber = gideListPageNumber + 1;
              setGideListPageNumber(newGideListPageNumber);
              props.onLoadMoreGides && props.gidePreviewId && props.onLoadMoreGides(props.gidePreviewId, newGideListPageNumber);
            }}
            style={{
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              minWidth: showMoreComponentWidth,
              maxWidth: showMoreComponentWidth,
              alignSelf: 'center',
              marginBottom: '38px',
            }}
          >
            <icons.Nav_Arrow_ArrowDown style={{ marginRight: '12px' }} />
            <span className="TEXTSUBTITLEblackmedium-emphasisleft" style={{ marginRight: '12px' }}>
              View more
            </span>
            <div className={styles.moreItemsHorizontalLine} />
          </div>
        )}
      </div>
    </div>
  );
}
