import { MockAuthor } from './../../models/Author';
import { Article } from './../../models/Article';
export const MockGides: Article[] = [
  {
    slug: 'slug1',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
  {
    slug: 'slug2',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
  {
    slug: 'slug3',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
  {
    slug: 'slug4',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
  {
    slug: 'slug5',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
  {
    slug: 'slug6',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
  {
    slug: 'slug7',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
  {
    slug: 'slug8',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
  {
    slug: 'slug9',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
  {
    slug: 'slug10',
    allowBlame: false,
    allowComments: true,
    allowLink: true,
    allowRequestAccess: true,
    allowEmbed: true,
    allowScrollView: true,
    allowSlideComments: true,
    allowSlideQuestions: false,
    allowSlideView: true,
    allowSwipeView: true,
    author: {
      id: '123',
      username: 'slimShades',
      image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4163776-blob',
      connections: {following: []},
      favorites: [],
      following: false,
      verified: true,
      views: [],
    },
    slideZero: {
      data: {
        image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
        title: 'Top 10 Places in Louisiana to Get a Great Burger',
      },
      slideType: 'GIDETITLE',
      position: 1,
      id: '',
      author: MockAuthor,
    },
    backgroundColor: 'white',
    channel: 'Wrapping for Rappers',
    body: 'What is body for?',
    cleanView: true,
    createdAt: new Date(2019, 11, 5, 10, 30, 32),
    defaultView: 'SLIDE',
    title: 'Top 10 Places in Louisiana to Get a Great Burger',
    description: 'Take a trip to get 10 of the best burgers you will every eat.',
    favorited: true,
    favoritesCount: 23,
    font: 'Arial',
    headersCollapsed: false,
    id: '5d4073f66e85450016fb17a4',
    image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3411619-blob',
    pauseSlideComments: false,
    pauseSlideQuestions: false,
    price: 19.99,
    requireApprovalForInquiries: true,
    requireAttribution: true,
    revealPresence: true,
    showAttending: false,
    subscribed: true,
    subscribersCount: 20,
    slideCount: 450,
    language: 'English',
    viewCount: 200,
    tagList: ['Wrapping', 'Gifts', 'Rapping'],
    type: 'NORMAL',
    updatedAt: new Date(),
    useAttending: false,
    userList: [],
    viewerList: [],
    public: true,
    purchased: true,
  },
];
