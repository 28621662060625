import React, { useState } from 'react';
import styles from './_profile-editor.module.scss';
import { Profile, ProfileUpdate, getProfileUpdate } from '../../../models/Profile';
import classNames from 'classnames';
import { Input, TextArea, TextAreaProps, InputOnChangeData } from 'semantic-ui-react';
import { CheckBox as CheckBoxComponent } from '../../Shared/CheckBox/CheckBox';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import icons from '../../../assets/icons';
import { hasValue } from '../../../utils/helperFunctions';
import { ProfileMode } from '../../modals/AccountProfileModal/AccountProfileModal';
import { ImagePicker } from '../../Shared/ImagePicker/ImagePicker';
import { API_ROOT } from '../../../constants/paths';
import { history } from '../../../store';
import superagent from 'superagent';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { NotificationType } from '../../../constants/strings';
import contains from 'ramda/es/contains';
import { LanguagePicker } from '../../Shared/LanguagePicker/LanguagePicker';
import { CountryPicker } from '../../Shared/CountryPicker/CountryPicker';

export interface ProfileEditorProps {
  profile: Profile;
  mode: ProfileMode;
  onCancelChanges: () => void;
  onConfirmChanges: (profile: ProfileUpdate) => void;
  onEditImage: (type: 'profile' | 'cover') => void;
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) => void;
}

export const RequiredTextboxWithLabel = (props: {
  label: string;
  type: 'textarea' | 'input' | 'password';
  error?: string;
  onChange: (value: string | number | undefined) => void;
  placeholder?: string;
  value?: string;
  inputClass?: string;
  className?: string;
  name?: string;
  autoFocus?: boolean;
  onKeyDown?: (e: any) => void;
}): JSX.Element => {
  return (
    <div className={classNames(styles.requiredTextboxWithLabel, props.className)}>
      <div className={classNames(styles.label, hasValue(props.error) ? styles.isError : '')}>
        <span className={styles.labelText}>{props.label}</span>
        <span className={styles.required}>*</span>
      </div>
      {props.type === 'textarea' && (
        <TextArea
          name={props.name}
          className={classNames(styles.requiredTextArea, props.inputClass, hasValue(props.error) ? styles.textAreaError : '')}
          value={props.value}
          placeholder={props.placeholder}
          onChange={(event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => props.onChange(data.value)}
        />
      )}
      {(props.type === 'input' || props.type === 'password') && (
        <Input
          name={props.name}
          error={hasValue(props.error)}
          type={props.type}
          className={classNames(styles.requiredTextboxInput, props.inputClass)}
          value={props.value}
          placeholder={props.placeholder}
          onChange={(event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => props.onChange(data.value)}
          autoFocus={props.autoFocus}
          onKeyDown={props.onKeyDown}
        />
      )}
      {hasValue(props.error) && <span className={styles.inputError}>{props.error}</span>}
    </div>
  );
};

export default function ProfileEditor(props: ProfileEditorProps) {
  const [profileUpdate, setProfileUpdate] = useState<ProfileUpdate>(getProfileUpdate(props.profile));
  const [newPassword, setPassword] = useState<string>();
  const [confirmationPassword, setConfirmationPassword] = useState<string>();
  const [errors, updateErrors] = useState<ProfileValidationProperty[]>([]);

  enum ProfileValidationProperty {
    UserName = 'user-name',
    FirstName = 'first-name',
    SecondName = 'second-name',
    BioDescription = 'bio-description',
    Email = 'email',
    Phone = 'phone',
    BusinessFocus = 'business-focus',
    Password = 'password',
  }

  const validateProfileFields = (): boolean => {
    const validationErrors:ProfileValidationProperty[] = [];
    if (!hasValue(profileUpdate.username)) validationErrors.push(ProfileValidationProperty.UserName);
    if (!hasValue(profileUpdate.firstName)) validationErrors.push(ProfileValidationProperty.FirstName);
    if (!hasValue(profileUpdate.secondName)) validationErrors.push(ProfileValidationProperty.SecondName);
    if (!hasValue(profileUpdate.bio)) validationErrors.push(ProfileValidationProperty.BioDescription);
    if (!hasValue(profileUpdate.email)) validationErrors.push(ProfileValidationProperty.Email);
    if (!hasValue(profileUpdate.phone)) validationErrors.push(ProfileValidationProperty.Phone);
    if (hasValue(newPassword) && confirmationPassword !== newPassword) validationErrors.push(ProfileValidationProperty.Password);
    if (profileUpdate.business && !hasValue(profileUpdate.businessFocus)) validationErrors.push(ProfileValidationProperty.BusinessFocus);

    updateErrors(validationErrors);
    return validationErrors.length === 0;
  };

  const editProfilePicture = () => {
    props.onEditImage('profile');
  };

  const editCoverImage = () => {
    props.onEditImage('cover');
  };

  return (
    <div className={styles.profileEditorContainer}>
      {(props.mode === ProfileMode.PickCoverImage || props.mode === ProfileMode.PickProfileImage) && (
        <ImagePicker
          aspectRatio={props.mode === ProfileMode.PickCoverImage ? 3.104 : 1}
          title={props.mode === ProfileMode.PickProfileImage ? 'Choose Profile Image' : 'Choose Cover Image'}
          onSave={async image => {
            const response = await superagent.post(`${API_ROOT}/util/upload`).attach('theseNamesMustMatch', image.dataUrl);
            setProfileUpdate({
              ...profileUpdate,
              image: props.mode === ProfileMode.PickProfileImage ? response.body.url : profileUpdate.image,
              brandingImage: props.mode === ProfileMode.PickCoverImage ? response.body.url : profileUpdate.brandingImage,
            });
            history.goBack();
          }}
          onCancel={() => history.goBack()}
          onError={error => {
            props.showNotification({ message: error, type: NotificationType.ERROR });
          }}
        />
      )}
      {!(props.mode === ProfileMode.PickCoverImage || props.mode === ProfileMode.PickProfileImage) && (
        <>
          <div className={styles.leftContent}>
            <div className={classNames(styles.profilePicture, styles.card)}>
              <div className={styles.actionHeader}>
                <span>Profile picture</span>
                <span className={styles.button} onClick={editProfilePicture}>
                  edit
                </span>
              </div>
              <img src={profileUpdate.image} className={styles.avatar} />
            </div>
            <div className={classNames(styles.profileBranding, styles.card)}>
              <div className={styles.actionHeader}>
                <span>Cover image</span>
                <span className={styles.button} onClick={editCoverImage}>
                  edit
                </span>
              </div>
              <img
                className={styles.brandingImage}
                src={
                  profileUpdate.brandingImage
                    ? profileUpdate.brandingImage
                    : 'https://images.unsplash.com/photo-1571501679680-de32f1e7aad4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2058&q=80'
                }
              />
            </div>
            <div className={classNames(styles.profileName, styles.card)}>
              <div className={styles.userName}>
                <RequiredTextboxWithLabel
                  type={'input'}
                  label={'Username'}
                  placeholder={'Username'}
                  value={profileUpdate.username}
                  error={contains(ProfileValidationProperty.UserName, errors) ? 'Username is a required field.' : undefined}
                  onChange={(value: string | number | undefined) => {
                    setProfileUpdate({
                      ...profileUpdate,
                      username: value as string,
                    });
                  }}
                />
              </div>
              <div className={styles.properName}>
                <span>
                  <RequiredTextboxWithLabel
                    type={'input'}
                    label={'First Name'}
                    placeholder={'First Name'}
                    value={profileUpdate.firstName}
                    error={contains(ProfileValidationProperty.FirstName, errors) ? 'First name is a required field.' : undefined}
                    inputClass={styles.name}
                    onChange={(value: string | number | undefined) => {
                      setProfileUpdate({
                        ...profileUpdate,
                        firstName: value as string,
                      });
                    }}
                  />
                </span>
                <span style={{ marginLeft: '11px' }}>
                  <RequiredTextboxWithLabel
                    type={'input'}
                    label={'Second Name'}
                    placeholder={'Second Name'}
                    error={contains(ProfileValidationProperty.SecondName, errors) ? 'Second name is a required field.' : undefined}
                    value={profileUpdate.secondName}
                    inputClass={styles.name}
                    onChange={(value: string | number | undefined) => {
                      setProfileUpdate({
                        ...profileUpdate,
                        secondName: value as string,
                      });
                    }}
                  />
                </span>
              </div>
            </div>
            <div className={classNames(styles.businessAccount, styles.card, profileUpdate.business ? styles.isBusiness : '')}>
              <div className={styles.businessCheck}>
                <CheckBoxComponent
                  checked={profileUpdate.business}
                  change={(e: any) => {
                    setProfileUpdate({
                      ...profileUpdate,
                      business: !profileUpdate.business,
                    });
                  }}
                />
                <span style={{ paddingLeft: '5px' }}>Is this a business account?</span>
              </div>
              {profileUpdate.business && (
                <div className={styles.userName}>
                  <RequiredTextboxWithLabel
                    type={'input'}
                    error={contains(ProfileValidationProperty.BusinessFocus, errors) ? 'Business Focus is a required field.' : undefined}
                    label={'What is your business focus'}
                    placeholder={'Business Focus'}
                    value={profileUpdate.businessFocus}
                    onChange={(value: string | number | undefined) => {
                      setProfileUpdate({
                        ...profileUpdate,
                        businessFocus: value as string,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.rightContent}>
            <div className={classNames(styles.profileDescription, styles.card)}>
              <div>
                <RequiredTextboxWithLabel
                  type={'textarea'}
                  label={'Description'}
                  placeholder={'Description'}
                  value={profileUpdate.bio}
                  error={contains(ProfileValidationProperty.BioDescription, errors) ? 'Bio description is a required field.' : undefined}
                  onChange={(value: string | number | undefined) => {
                    setProfileUpdate({
                      ...profileUpdate,
                      bio: value as string,
                    });
                  }}
                />
              </div>
            </div>
            <div className={classNames(styles.profileContact, styles.card)}>
              <div className={styles.businessCheck}>
                <CheckBoxComponent
                  checked={profileUpdate.allowEmailContact}
                  change={(e: any) => {
                    setProfileUpdate({
                      ...profileUpdate,
                      allowEmailContact: !profileUpdate.allowEmailContact,
                    });
                  }}
                />
                <span style={{ paddingLeft: '5px' }}>Allow users to contact your email</span>
              </div>
              <div className={styles.userName}>
                <RequiredTextboxWithLabel
                  type={'input'}
                  label={'Email'}
                  placeholder={'Email address'}
                  value={profileUpdate.email}
                  error={contains(ProfileValidationProperty.Email, errors) ? 'Email is a required field.' : undefined}
                  onChange={(value: string | number | undefined) => {
                    setProfileUpdate({
                      ...profileUpdate,
                      email: value as string,
                    });
                  }}
                />
              </div>
              <div className={styles.userName}>
                <RequiredTextboxWithLabel
                  type={'input'}
                  label={'Phone'}
                  placeholder={'Phone number'}
                  error={contains(ProfileValidationProperty.Phone, errors) ? 'Phone is a required field.' : undefined}
                  value={profileUpdate.phone}
                  onChange={(value: string | number | undefined) => {
                    setProfileUpdate({
                      ...profileUpdate,
                      phone: value as string,
                    });
                  }}
                />
              </div>
            </div>
            <div className={classNames(styles.profilePassword, styles.card)}>
              <div className={styles.userName}>
                <RequiredTextboxWithLabel
                  type={'password'}
                  label={'Password'}
                  placeholder={'Password'}
                  onChange={(value: string | number | undefined) => {
                    setPassword(value as string);
                  }}
                />
              </div>
              <div className={styles.userName}>
                <RequiredTextboxWithLabel
                  type={'password'}
                  error={contains(ProfileValidationProperty.Password, errors) ? 'Confirm password must match Password.' : undefined}
                  label={'Confirm Password'}
                  placeholder={'Confirm Password'}
                  value={confirmationPassword}
                  onChange={(value: string | number | undefined) => {
                    setConfirmationPassword(value as string);
                    const confirmedPassword = value as string;
                    if (hasValue(confirmedPassword) && confirmedPassword === newPassword) {
                      setProfileUpdate({
                        ...profileUpdate,
                        password: confirmedPassword,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className={styles.card}>
              <LanguagePicker
                code={profileUpdate.language}
                onChange={(code) => setProfileUpdate({
                  ...profileUpdate,
                  language: code || "",
                })}
              />
            </div>
            <div className={styles.card}>
              <CountryPicker
                code={profileUpdate.country}
                onChange={(code) => setProfileUpdate({
                  ...profileUpdate,
                  country: code || "",
                })}
              />
            </div>
            <div className={styles.profileButton}>
              <RoundedCornerButton
                label="Cancel"
                style={{ backgroundColor: '#fafafa' }}
                labelColor="grey"
                onClick={props.onCancelChanges}
              />
              <RoundedCornerButton
                label="Confirm"
                style={{
                  backgroundColor: 'var(--COLOR-PRIMARY-700)',
                  marginLeft: '12px',
                  width: '105px',
                }}
                labelColor="white"
                icon={<icons.ContentAlteration_Check_Main color="white" />}
                imagePosition="right"
                onClick={() => {
                  validateProfileFields() && props.onConfirmChanges(profileUpdate);
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
