import React, { Component } from 'react';
import { extractHostname, hasValue, getSlideFileUrl, getMediaLinkUrl, isCordovaIOSDevice } from '../../../../utils/helperFunctions';
import { v4 } from 'uuid';
import SlideFileCaption from '../../../Slides/SlideFileCaption/SlideFileCaption';
import { VideoFile } from '../../../../models/SlideFile';

export interface VideoViewerProps {
  videoFile: VideoFile;
  className?: string;
}
export interface VideoViewerState {
  playing: boolean;
  generatedId: string;
}
export default class VideoViewer extends Component<VideoViewerProps, VideoViewerState> {
  constructor(props: VideoViewerProps) {
    super(props);
    this.state = {
      playing: false,
      generatedId: v4(),
    }
  }
  videoPlayer?: HTMLVideoElement | undefined;
  playVideo(e: any): void {
    if (this.videoPlayer) {
      this.videoPlayer.onended = () => {
        this.setState({ playing: false });
      }
      this.videoPlayer.play();
      this.setState({ playing: true });
    }
  }

  pauseVideo(e: any): void {
    if (this.videoPlayer) {
      this.videoPlayer.pause();
      this.setState({ playing: false });
    }
  }
  render() {
    const isSlideFileUrl = (this.props.videoFile.type === 'SLIDEFILE' 
      && (this.props.videoFile.slideFileReference as any)?.referenceFile?.type === 'URL');
    const useVideoViewer = (this.props.videoFile.type === 'UPLOAD' 
      || this.props.videoFile.type === 'RECORDED'
      || !isSlideFileUrl)
      && (this.props.videoFile.type !== 'URL' && !isSlideFileUrl);
    const url = getSlideFileUrl(this.props.videoFile);
    const id = this.props.videoFile.id || this.state.generatedId; // url
    const mediaLinkUrlInfo = getMediaLinkUrl(url);
    const isValidMediaLinkUrl = mediaLinkUrlInfo.type === 'video';
    return (
      <div className={`videoViewOuterContainer ${this.props.className && !(hasValue(this.props.videoFile.caption) || hasValue(this.props.videoFile.audioCaption)) ? this.props.className : ''}`}>
        <div className={`videoViewVideoContainer`}>
          {useVideoViewer && (
            <video key={id}
              className="video"
              id={id}
              controls
              ref={(videoPlayer: HTMLVideoElement) => { 
                this.videoPlayer = videoPlayer;
              }}
              preload="metadata"
            >
              <source src={isCordovaIOSDevice() && /blob:/.test(url) ? url : `${url}#t=0.5`} />
            </video>
          )}
          {(this.props.videoFile.type === 'URL' || isSlideFileUrl) && isValidMediaLinkUrl && (
            <div className="urlIFrameBasedVideo">
              <iframe
                title={url}
                src={mediaLinkUrlInfo.url}
                frameBorder="0"
                allowFullScreen
                className="video"
              />
            </div>
          )}
          {this.props.videoFile.type === 'URL' && !isValidMediaLinkUrl && (
            "Invalid Video Link"
          )}
          <SlideFileCaption
            slideType="Video"
            slideFile={this.props.videoFile}
            linkedFileAttribution={this.props.videoFile.type === 'LINK' ? extractHostname(getSlideFileUrl(this.props.videoFile)) : undefined}
          />
        </div>
      </div>
    );
  }
}
