import React, { useState } from 'react';
import styles from './_gides-app-header.module.scss';
import icons from '../../../assets/icons';
import SearchBar from '../../SearchBar';
import { User } from '../../../models/User';
import Avatar, { AvatarMenuLocation } from '../../Shared/Avatar/Avatar';
import Link from '../../Link';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import classNames from 'classnames';
import { isReadOnlyDevice } from '../../../utils/helperFunctions';
export interface SearchCriteria {
  startDate?: Date;
  endDate?: Date;
  searchText?: string;
  slideTypes?: string[];
  address?: string;
  latLng?: { lat: number; lng: number };
}
export interface GidesAppHeaderProps {
  leftSidebarOpen: boolean;
  currentUser?: User | null;
  loading?: boolean;
  searchText?: string;
  onOpenAvatarMenu?: (location: AvatarMenuLocation, openExpanded: boolean) => void;
  onOpenLeftSidebar?: () => void;
  onSearchArticles: (searchCriteria: SearchCriteria) => void;
  onOpenNotifications: () => void;
  onClearSearch: () => void;
  onSignUp: () => void;
  onSignIn: () => void;
}
const LoggedOutView = (props: { onSignUp: () => void, onSignIn: () => void, displayMobileSearch?: boolean }) => {

  return (
    <div className={classNames(styles.loggedOutView)}>
      {(isReadOnlyDevice() === true  || process.env.REACT_APP__USE_MAILCHIMP_SIGNUP === 'true') && (
        <>
          {!isReadOnlyDevice() && (
            <RoundedCornerButton
              label="Sign in"
              labelColor="rgba(0, 0, 0, 0.45)"
              className={props.displayMobileSearch ? styles.displayMobileSearch : undefined}
              style={{ backgroundColor: 'var(--COLOR-SECONDARY-200)', minWidth: '76px', width: '76px', height: '32px', marginRight: '12px' }}
              onClick={props.onSignIn}
            />
          )}
          <RoundedCornerButton
            className={styles.signUp}
            label="Request Access"
            style={{ backgroundColor: 'var(--COLOR-PRIMARY-700)', minWidth: '116px', width: '116px', height: '32px', marginRight: '12px' }}
            onClick={props.onSignUp}
          />
        </>
      )}
      {isReadOnlyDevice() !== true  && process.env.REACT_APP__USE_MAILCHIMP_SIGNUP !== 'true' && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RoundedCornerButton
              label="Sign in"
              labelColor="rgba(0, 0, 0, 0.45)"
              style={{ backgroundColor: 'var(--COLOR-SECONDARY-200)', minWidth: '76px', width: '76px', height: '32px' }}
              onClick={props.onSignIn}
            />
          <Link to="/register" className="login-link">
            <RoundedCornerButton
              label="Request Access"
              style={{
                backgroundColor: 'var(--COLOR-PRIMARY-700)',
                minWidth: '76px',
                width: '76px',
                marginLeft: '12px',
                height: '32px',
                marginRight: '12px',
              }}
            />
          </Link>
        </div>
      )}
    </div>
  );
};
export default function GidesAppHeader(props: GidesAppHeaderProps) {
  const [displayMobileSearch, setDisplayMobileSearch] = useState<boolean>(false);
  return (
    <div className={styles.gidesAppHeaderContainer}>
      {!displayMobileSearch && (
        <>
          <div className={styles.hamburgerMenu}>
            {props.leftSidebarOpen && (
              <icons.Nav_Hamburger_Closed
                color="var(--COLOR-SECONDARY-500)"
                onClick={props.onOpenLeftSidebar} // currently this just toggles but we should have an explicit open/close.
              />
            )}
            {!props.leftSidebarOpen && <icons.Nav_Hamburger_Open color="var(--COLOR-SECONDARY-500)" onClick={props.onOpenLeftSidebar} />}
          </div>
          <div className={styles.gidesLogoContainer}>
            <Link to={'/'}>
              <icons.Nav_Logo_LogoType_Home
                doNotFillFromColor={true}
                className={styles.logo}
                onClick={props.leftSidebarOpen ? props.onOpenLeftSidebar : () => {}} // TODO: Even though this says open, it will toggle it. Prefer to have explicit methods                
              />
            </Link>
          </div>
        </>
      )}
      <div className={styles.searchBar}>
        <div className={styles.widescreenSearch}>
          <SearchBar
            onSearch={props.onSearchArticles}
            onToggleSearch={props.onClearSearch}
            placeholder={'search'}
            searchText={props.searchText}
          />
        </div>
        {displayMobileSearch && (
          <div className={styles.mobileSearch}>
            <icons.Nav_Arrow_ArrowLeft
              color="var(--COLOR-SECONDARY-500)"
              width={20}
              height={20}
              onClick={() => {
                setDisplayMobileSearch(false);
                props.onClearSearch();
              }}
              style={{ marginRight: '11px' }}
            />
            <SearchBar onSearch={props.onSearchArticles} placeholder={'search'} onToggleSearch={props.onClearSearch} focusOnLoad={true} />
          </div>
        )}
      </div>
      {!displayMobileSearch && (
        <div className={styles.mobileSearchButton}>
          <icons.Nav_Search
            color="var(--COLOR-SECONDARY-500)"
            style={{ marginRight: '24px' }}
            onClick={() => setDisplayMobileSearch(true)}
          />
        </div>
      )}
      {props.currentUser && !displayMobileSearch && (
        <div className={styles.userSection}>
          {/* <icons.Nav_Notifications style={{ cursor: 'pointer' }} color="var(--COLOR-SECONDARY-500)" onClick={props.onOpenNotifications} /> */}
          <div className={styles.avatarSeparator} />
          <div className={styles.userAvatarMenuTrigger}>
            <Avatar
              avatarImage={props.currentUser.image}
              displayOuterBorder={true}
              outerBorderColor="var(--COLOR-PRIMARY-700)"
              onClick={() => props.onOpenAvatarMenu && props.onOpenAvatarMenu(AvatarMenuLocation.Right, true)}
              imageWidthAndHeight={32}
            />
          </div>
        </div>
      )}
      {!props.currentUser && props.loading !== true && <LoggedOutView onSignUp={props.onSignUp} onSignIn={props.onSignIn} displayMobileSearch={displayMobileSearch} />}
    </div>
  );
}
