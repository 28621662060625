import { LOAD_USERS_SUCCESS } from './administration.actions';
import { combineReducers, Reducer } from 'redux';
import { User } from './../../models/User';
import { GideAction } from '../../models/GideAction';
export interface AdministrationState {
  users: User[];
}

const INITIAL_STATE = {
  users: [],
}
const usersReducer = (state: User[] = INITIAL_STATE.users, action: GideAction): User[] => {
  switch(action.type) {
    case LOAD_USERS_SUCCESS:
      return action.payload.users;
    default:
      return state;
  }
}
export const administrationReducer: Reducer<AdministrationState> = combineReducers<AdministrationState>({
  users: usersReducer,
});
