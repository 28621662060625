import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { contains, append, reject } from 'ramda';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import { RadioButton as RadioButtonComponent } from '../../Shared/RadioButton/RadioButton';


export interface SlideFilterProps {
  slideTypes: string[];
  onFilter: (filter: SlideFilterState) => void;
  onCancel: () => void;
  slideFilter?: SlideFilterState;
  allowFilterOnComments: boolean;
  allowFilterOnQuestions: boolean;
  allowFilterOnPrivateNotes: boolean;
  allowFilterOnAttachments: boolean;
  style?: React.CSSProperties;
}
export enum ApprovalStatus {
  Approved = 1,
  Unapproved = 2,
  Any = 3
}
export interface SlideFilterState {
  selectedSlideTypes: string[];
  hasAttachments?: boolean;
  attachmentApprovalStatus: ApprovalStatus;
  hasComments?: boolean;
  commentApprovalStatus: ApprovalStatus;
  hasQuestions?: boolean;
  questionApprovalStatus: ApprovalStatus;
  hasPrivateNotes?: boolean;
}

export default class SlideFilter extends Component<SlideFilterProps, SlideFilterState> {
  constructor(props: SlideFilterProps) {
    super(props);

    this.state = {
      selectedSlideTypes: props.slideFilter ? [...props.slideFilter.selectedSlideTypes] : [...this.props.slideTypes], // Check all by default
      hasAttachments: props.slideFilter && props.slideFilter.hasAttachments,
      attachmentApprovalStatus: props.slideFilter && props.slideFilter.attachmentApprovalStatus ? props.slideFilter.attachmentApprovalStatus : ApprovalStatus.Any,
      hasComments: props.slideFilter && props.slideFilter.hasComments,
      commentApprovalStatus: props.slideFilter && props.slideFilter.commentApprovalStatus ? props.slideFilter.commentApprovalStatus : ApprovalStatus.Any,
      hasQuestions: props.slideFilter && props.slideFilter.hasQuestions,
      questionApprovalStatus: props.slideFilter && props.slideFilter.questionApprovalStatus ? props.slideFilter.questionApprovalStatus : ApprovalStatus.Any,
      hasPrivateNotes: props.slideFilter && props.slideFilter.hasPrivateNotes

    }
  }

  public render() {
    return (
      <div className="slideFilterContainer"
        style={this.props.style}
      >
        <div className="slideFilter"
          style={this.props.style}
        >
          <div className="slideFilterSlideTypes">
          <span className="filterDescription">Will only return slides that which match the selected slide types.</span>
            {this.props.slideTypes.map((st: string, index: number) =>
              <Checkbox
                key={`slideTypeFilter${index}`}
                onChange={(evt, data) => {
                  if (data.checked) {
                    this.setState({ selectedSlideTypes: append(st, this.state.selectedSlideTypes) });
                  } else {
                    this.setState({ selectedSlideTypes: reject(s => s === st, this.state.selectedSlideTypes) });
                  }
                }}
                toggle
                checked={contains(st, this.state.selectedSlideTypes)}
                label={st}
              />
            )}
          </div>
          {(this.props.allowFilterOnAttachments || this.props.allowFilterOnComments || this.props.allowFilterOnPrivateNotes || this.props.allowFilterOnQuestions) && (
            <div className="contextFilters">
              <span className="filterDescription">If any are checked then it will only return slides that contains at least one of the following selections. If all are blank then these filters will not apply.</span>
              {this.props.allowFilterOnAttachments && (
                <>
                  <Checkbox
                    onChange={(evt, data) => {
                      this.setState({ hasAttachments: data.checked });
                    }}
                    toggle
                    checked={this.state.hasAttachments === true}
                    label="has attachments"
                  />
                  {this.state.hasAttachments && (
                    <div className={'contextOptionList'}>
                      <RadioButtonComponent
                        checked={this.state.attachmentApprovalStatus === ApprovalStatus.Unapproved}
                        label="Unapproved"
                        name="attachmentOptions"
                        value={ApprovalStatus.Unapproved.toString()}
                        change={(args) => {
                          if (args && args.value === ApprovalStatus.Unapproved.toString()) {
                            this.setState({ attachmentApprovalStatus: ApprovalStatus.Unapproved });
                          }
                        }}
                      />
                      <RadioButtonComponent
                        checked={this.state.attachmentApprovalStatus === ApprovalStatus.Approved}
                        label="Approved"
                        name="attachmentOptions"
                        value={ApprovalStatus.Approved.toString()}
                        change={(args) => {
                          if (args && args.value === ApprovalStatus.Approved.toString()) {
                            this.setState({ attachmentApprovalStatus: ApprovalStatus.Approved });
                          }
                        }}
                      />
                      <RadioButtonComponent
                        checked={this.state.attachmentApprovalStatus === ApprovalStatus.Any}
                        label="Either"
                        name="attachmentOptions"
                        value={ApprovalStatus.Any.toString()}
                        change={(args) => {
                          if (args && args.value === ApprovalStatus.Any.toString()) {
                            this.setState({ attachmentApprovalStatus: ApprovalStatus.Any });
                          }
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              {this.props.allowFilterOnQuestions && (
                <>
                  <Checkbox
                    onChange={(evt, data) => {
                      this.setState({ hasQuestions: data.checked });
                    }}
                    toggle
                    checked={this.state.hasQuestions === true}
                    label="has questions"
                  />
                  {this.state.hasQuestions && (
                    <div className={'contextOptionList'}>
                      <RadioButtonComponent
                        checked={this.state.questionApprovalStatus === ApprovalStatus.Unapproved}
                        label="Unapproved"
                        name="questionOptions"
                        value={ApprovalStatus.Unapproved.toString()}
                        change={(args) => {
                          if (args && args.value === ApprovalStatus.Unapproved.toString()) {
                            this.setState({ questionApprovalStatus: ApprovalStatus.Unapproved });
                          }
                        }}
                      />
                      <RadioButtonComponent
                        checked={this.state.questionApprovalStatus === ApprovalStatus.Approved}
                        label="Approved"
                        name="questionOptions"
                        value={ApprovalStatus.Approved.toString()}
                        change={(args) => {
                          if (args && args.value === ApprovalStatus.Approved.toString()) {
                            this.setState({ questionApprovalStatus: ApprovalStatus.Approved });
                          }
                        }}
                      />
                      <RadioButtonComponent
                        checked={this.state.questionApprovalStatus === ApprovalStatus.Any}
                        label="Either"
                        name="questionOptions"
                        value={ApprovalStatus.Any.toString()}
                        change={(args) => {
                          if (args && args.value === ApprovalStatus.Any.toString()) {
                            this.setState({ questionApprovalStatus: ApprovalStatus.Any });
                          }
                        }}
                      />
                    </div>
                  )}                  
                </>
              )}
              {this.props.allowFilterOnComments && (
                <>
                  <Checkbox
                    onChange={(evt, data) => {
                      this.setState({ hasComments: data.checked });
                    }}
                    toggle
                    checked={this.state.hasComments === true}
                    label="has comments"
                  />
                  {this.state.hasComments && (
                    <div className={'contextOptionList'}>
                      <RadioButtonComponent
                        checked={this.state.commentApprovalStatus === ApprovalStatus.Unapproved}
                        label="Unapproved"
                        name="commentOptions"
                        value={ApprovalStatus.Unapproved.toString()}
                        change={(args) => {
                          if (args && args.value === ApprovalStatus.Unapproved.toString()) {
                            this.setState({ commentApprovalStatus: ApprovalStatus.Unapproved });
                          }
                        }}
                      />
                      <RadioButtonComponent
                        checked={this.state.commentApprovalStatus === ApprovalStatus.Approved}
                        label="Approved"
                        name="commentOptions"
                        value={ApprovalStatus.Approved.toString()}
                        change={(args) => {
                          if (args && args.value === ApprovalStatus.Approved.toString()) {
                            this.setState({ commentApprovalStatus: ApprovalStatus.Approved });
                          }
                        }}
                      />
                      <RadioButtonComponent
                        checked={this.state.commentApprovalStatus === ApprovalStatus.Any}
                        label="Either"
                        name="commentOptions"
                        value={ApprovalStatus.Any.toString()}
                        change={(args) => {
                          if (args && args.value === ApprovalStatus.Any.toString()) {
                            this.setState({ commentApprovalStatus: ApprovalStatus.Any });
                          }
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              {this.props.allowFilterOnPrivateNotes && (
                <Checkbox
                  onChange={(evt, data) => {
                    this.setState({ hasPrivateNotes: data.checked });
                  }}
                  toggle
                  checked={this.state.hasPrivateNotes === true}
                  label="has private Notes"
                />
              )}
            </div>
          )}
        </div>
        <div className="slideFilterActions">
          <RoundedCornerButton
            label="Cancel"
            image="/icons/content-alteration/check/main.svg"
            labelColor="#fab317"
            imagePosition="right"
            style={{ margin: '5px', width: '106px', backgroundColor: 'white' }}
            alt="cancel"
            onClick={this.props.onCancel}
          />
          <RoundedCornerButton
            label="Finish"
            image="/icons/content-alteration/check/main.svg"
            labelColor="white"
            imagePosition="right"
            style={{ margin: '5px', width: '106px', backgroundColor: '#fab317' }}
            alt="apply filter"
            onClick={() => {
              this.props.onFilter({ ...this.state });
            }}
          />
        </div>
      </div>
    );
  }
}
