import React, { useState, ChangeEvent } from 'react';
import { countries } from '../../../utils/useCountry';
import MenuItem from '@material-ui/core/MenuItem';
import { uuid4 } from '../../../lib/SketchField/utils';
import TextField from '@material-ui/core/TextField';

export interface CountryPickerProps {
  code?: string;
  onChange?: (code?: string) => void;
}

export const CountryPicker: React.FC<CountryPickerProps> = (props) => {
  const [id] = useState(() => uuid4());
  const code = props.code || '';
  return (
    <div
      style={{
        margin: "10px"
      }}
    >
      <TextField 
        id={id} 
        label="Nationality"
        fullWidth
        select
        value={code}
        onChange={(e: ChangeEvent<HTMLInputElement>) => props.onChange?.(e.target.value)}
      >
        <MenuItem value="">&nbsp;</MenuItem>
        {countries.map(c => 
          <MenuItem value={c.code}>
            <i className={c?.code + ' flag'}></i>
            {c.display}
          </MenuItem>
        )}      
      </TextField>
    </div>
  );
}
