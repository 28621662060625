import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  List,
  Button,
  Image,
  Form,
  Grid,
  Icon,
  Input,
} from 'semantic-ui-react';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import GideImage from '../Shared/Image/GideImage';
import { contentMetaData } from '../../constants/contentMetaData';
import agent from '../../agent';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  GIDE_MODAL_LOADED,
  GIDE_MODAL_SEARCHED,
  ARTICLES_LOADED,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
// import { hasValue } from '../../utils/helperFunctions';
import slideTools from '../../slideTools';
import { history } from '../../store';

const mapStateToProps = (state, ownProps) => ({
  ...slideTools.mapStateToProps(state, ownProps),
  currentUser: state.common.currentUser,
  // ...state.articleList,
  articles: state.common.articles,
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload => dispatch({ type: GIDE_MODAL_LOADED, payload }),
  onSearch: payload => dispatch({ type: GIDE_MODAL_SEARCHED, payload }),
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
  onArticlesLoaded: payload => dispatch({ type: ARTICLES_LOADED, payload }),
});

export class GideModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      layout: 'LIST',
      slide: {
        slideType: 'GIDE',
        data: {
          audioCaption: null,
          caption: '',
          gide: null,
          embed: false,
          displayBorder: true,
          defaultCollapsed: false,
          notCollapsible: false,
        },
      },
      showSettings: false,
      replaceMode: false,
      searchText: '',
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.setEmbed = embed => {
      this.setState({
        ...this.state,
        slide: {
          ...this.state.slide,
          data: {
            ...this.state.slide.data,
            embed: embed,
          },
        },
      });
    };

    this.setDisplayBorder = displayBorder => {
      this.setState({
        ...this.state,
        slide: {
          ...this.state.slide,
          data: {
            ...this.state.slide.data,
            displayBorder: displayBorder,
          },
        },
      });
    };

    this.setCollapsedByDefault = defaultCollapsed => {
      this.setState({
        ...this.state,
        slide: {
          ...this.state.slide,
          data: {
            ...this.state.slide.data,
            defaultCollapsed: defaultCollapsed,
          },
        },
      });
    };

    this.setNotCollapsible = notCollapsible => {
      this.setState({
        ...this.state,
        slide: {
          ...this.state.slide,
          data: {
            ...this.state.slide.data,
            notCollapsible: notCollapsible,
          },
        },
      });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      let slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.changeLayout = layout => {
      this.setState({ layout });
    };

    this.selectGide = gide => {
      const slide = {
        ...this.state.slide,
        data: {
          ...this.state.slide.data,
          gide: gide,
        },
      };
      this.setState({ slide });
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        data: {
          ...this.state.slide.data,
          displayBorder:
            this.state.slide.data.gide.author.username ===
            this.props.currentUser.username
              ? this.state.slide.data.displayBorder
              : false,
        },
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };

      if (!slide.data.gide) {
        return alert('No Gide selected.');
      }

      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      history.goBack();
    };
    this.searchTextChanged = ev => {
      this.setState({ searchText: ev.target.value });
    };
    this.searchGides = async () => {
      return alert('Coming soon.');
      // Needs byAuthor endpoint updated to allow search.
      // if (!hasValue(this.state.searchText)) {
      //   const payload = await agent.Articles.all();
      //   this.props.onSearch(payload);
      // } else {
      //   const searchText = `searchText=${this.state.searchText}`;
      //   const payload = await agent.Articles.bySearch(searchText);
      //   this.props.onSearch(payload);
      // }
    };
  }

  componentWillMount() {
    // this.props.onLoad(Promise.all([agent.Articles.all()]));
    this.props.onArticlesLoaded(
      agent.Articles.byAuthor(this.props.currentUser.username, null, 200),
    );
  }

  render() {
    const { layout, slide } = this.state;
    let { articles } = this.props;
    if (!articles) {
      articles = [];
    }
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        className="gideModal"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(237, 168, 52)' }}
          >
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <span>Embed Gide</span>
            <Button.Group className="layoutBtns">
              <Button
                icon="block layout"
                onClick={() => this.changeLayout('GRID')}
              />
              <Button
                icon="list layout"
                onClick={() => this.changeLayout('LIST')}
              />
            </Button.Group>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-gide.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description>
              <section>
                {(!articles || !articles.length) && (
                  <div>No Gides available.</div>
                )}
                <div>
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Search Gides"
                    value={this.state.searchText}
                    onChange={this.searchTextChanged}
                  />
                  <Button
                    style={{ marginLeft: '5px' }}
                    onClick={this.searchGides}
                  >
                    <Icon name="search" />
                    <span>Search</span>
                  </Button>
                </div>
                {layout === 'LIST' && (
                  <List selection divided relaxed>
                    {articles.map((g, i) => (
                      <List.Item
                        key={i}
                        onClick={() => this.selectGide(g)}
                        className={
                          slide.data.gide
                            ? slide.data.gide.slug === g.slug ? 'selected' : ''
                            : ''
                        }
                      >
                        <GideImage src={g.image} className="icon sm" alt={`□`} />
                        <List.Content>{g.title}</List.Content>
                      </List.Item>
                    ))}
                  </List>
                )}
                {layout === 'GRID' && (
                  <Grid columns={3} padded>
                    {articles.map((g, i) => (
                      <Grid.Column
                        key={i}
                        onClick={() => this.selectGide(g)}
                        className={
                          slide.data.gide
                            ? slide.data.gide.slug === g.slug ? 'selected' : ''
                            : ''
                        }
                      >
                        <GideImage src={g.image} alt={`□`} />
                      </Grid.Column>
                    ))}
                  </Grid>
                )}
              </section>

              <section>
                <fieldset>
                  <fieldset className="embed">
                    <Form.Radio
                      label="Embed"
                      name="embedRadioGroup"
                      checked={this.state.slide.data.embed}
                      onChange={() => this.setEmbed(true)}
                    />
                    <Form.Radio
                      label="Shortcut"
                      name="embedRadioGroup"
                      checked={!this.state.slide.data.embed}
                      onChange={() => this.setEmbed(false)}
                    />
                    {this.state.slide.data.gide &&
                      this.state.slide.data.gide.author.username ===
                        this.props.currentUser.username && (
                        <Form.Checkbox
                          label="Display Border"
                          checked={this.state.slide.data.displayBorder}
                          onChange={(e, data) =>
                            this.setDisplayBorder(data.checked)
                          }
                        />
                      )}
                    {this.state.slide.data.embed && (
                      <>
                        <Form.Checkbox
                          label="Not Collapsible"
                          checked={this.state.slide.data.notCollapsible}
                          onChange={(e, data) =>
                            this.setNotCollapsible(data.checked)
                          }
                        />
                        {!this.state.slide.data.notCollapsible && (
                          <Form.Checkbox
                            label="Collapsed By Default"
                            checked={this.state.slide.data.defaultCollapsed}
                            onChange={(e, data) =>
                              this.setCollapsedByDefault(data.checked)
                            }
                          />
                        )}
                      </>
                    )}
                  </fieldset>
                </fieldset>
                <div>
                  {this.state.embed
                    ? 'As an Embedded Gide, the selected Gide will be fully displayed within.'
                    : 'As a Gide Shortcut, the selected Gide will only be linked to.'}
                </div>
              </section>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions
          style={{ background: contentMetaData['GIDE'].primaryColor }}
        >
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            disableNextButton={!this.state.slide.data.gide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GideModal);
