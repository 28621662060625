import React, { Component } from 'react';
import { connect } from 'react-redux';
import SlideEditorModal, {
  LoadingInfo, SlideEditorProps, EditorState, ModalOverlayState, AudioFile,
  QuestionResponse, QuestionResponseInfo, QuestionInfo,
  ProgressIndicatorType, BackIconMode, deleteFileQuestionInfo, DELETE_FILE
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { SlideModel, Slide } from '../../../models/Slide';
import ExpandingIconButton, { HorizontalAlignment } from '../../Shared/ExpandingIconButton/ExpandingIconButton';
import { NotificationType } from '../../../constants/strings';
import { SlideFile, AudioSlideFile } from '../../../models/SlideFile';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import AudioRecorder from '../../media/AudioRecorder/AudioRecorder';
import { v4 } from 'uuid';
import { append, reject } from 'ramda';
import SlideView from '../../Gide/SlideView/SlideView';
import { isMobileDevice, hasValue, SlideType, getPreviewOverlayIconSvg, isCordovaIOSDevice } from '../../../utils/helperFunctions';
import { SlideFileReference } from '../../../models/SlideFileReference';
import SlideFileChooser from '../../Shared/SlideFileChooser/SlideFileChooser';
import AudioView from '../../Viewers/AudioViewers/AudioView/AudioView';
import DropZone from 'react-dropzone';
import superagent from 'superagent';
import { API_ROOT } from '../../../constants/paths';
import LinkInput from '../../Shared/LinkInput/LinkInput';
import classNames from 'classnames';
import { slideEditorModalMapDispatchToProps } from '../../modals/SlideEditorModal/SlideEditorModal.extensions';
import SlideFileTypeSelector from '../SlideFileTypeSelector/SlideFileTypeSelector';
import GideImage from '../../Shared/Image/GideImage';
import agent from '../../../agent';
import { AnonymousUser } from '../../../models/User';


export interface AudioSlideEditorState {
  slide: SlideModel;
  audioAddType: AudioAddType,
  audioEditType: AudioEditType,
  editorState: EditorState;
  selectedItemIndex: number;
  loadingInfo?: LoadingInfo;
  displaySlideAttribution: boolean;
  questionInfo?: QuestionInfo;
  previousState?: AudioSlideEditorState;
}
export enum AudioAddType {
  RecordAudio = 1,
  Upload = 2,
  Embed = 3,
  Slide = 4,
}

export enum AudioViewerMode {
  Stacked = 1,
  Carousel = 2,
}
export enum AudioEditType {
  Trim = 1,
  Selection = 2,
}
export class AudioSlideEditor extends Component<SlideEditorProps, AudioSlideEditorState> {
  constructor(props: SlideEditorProps) {
    super(props);

    const slide = props.editSlide ? {
      ...props.editSlide,
    } : {
        slideType: 'AUDIO',
        data: {
          audioCaption: null,
          files: [],
          caption: '',
          slideTypeSpecificSettings: {
            displayType: AudioViewerMode.Stacked,
          }
        },
        position: this.props.position,
      };


    this.state = {
      slide: slide,
      audioEditType: AudioEditType.Selection,
      editorState: (slide as Slide).id
        ? slide.data.files.length === 1
          ? EditorState.Edit
          : EditorState.Preview
        : EditorState.New,
      selectedItemIndex: slide.data.files.length === 1 ? 0 : -1,
      displaySlideAttribution: false,
      audioAddType: AudioAddType.RecordAudio,
    }
  }
  hiddenModalsState = {
    displaySlideAttribution: false,
    audioEditType: AudioEditType.Selection,
  }
  hideFooter = (): boolean => {
    return this.state.editorState === EditorState.New
      || (this.state.editorState === EditorState.Edit && this.state.audioEditType === AudioEditType.Trim)
      || (this.state.editorState === EditorState.Add);
  }
  private hideCommandBar = (): boolean => {
    return (this.state.editorState === EditorState.Add && this.state.audioAddType === AudioAddType.Slide);
  }
  headerActions = () => {
    const title = this.state.editorState === EditorState.New ? 'Audio'
      : this.state.audioAddType === AudioAddType.Upload ? 'Audio upload'
      : this.state.audioAddType === AudioAddType.Slide ? 'Audio slide'
      : this.state.audioAddType === AudioAddType.Embed ? 'Audio embed'
      : this.state.audioAddType === AudioAddType.RecordAudio ? 'Audio recording'
      : 'Audio';
    const icon = this.state.editorState === EditorState.New ? '/icons/slidetype/audio/main.svg'
      : this.state.audioAddType === AudioAddType.Upload ? '/icons/slidetype/audio/audio-file.svg'
      : this.state.audioAddType === AudioAddType.Slide ? '/icons/creationprocess/slide.svg'
      : this.state.audioAddType === AudioAddType.Embed ? '/icons/slidetype/links/main.svg'
      : this.state.audioAddType === AudioAddType.RecordAudio ? '/icons/slidetype/audio/main.svg'
      : '/icons/slidetype/audio/main.svg';

    return (this.state.editorState === EditorState.New || this.state.editorState === EditorState.Add)
    ? (
      <div className="headerActionsFlexEnd TEXTSUBTITLEblackhigh-emphasisleft">
        <div style={{
          marginRight: 6,
        }}>{title}</div>
        <CircleIconButton
          width={30}
          height={30}
          alt={title}
          backgroundColor="var(--COLOR-BLUE-100)"
          iconCssClass="whites-normal-1000-svg"
          image={icon}
          />
      </div>
    ) : (
      <>
        {this.state.editorState === EditorState.Edit && (
          <div className="headerActionsFlexEnd TEXTSUBTITLEwhitehigh-emphasisleft">
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="auto play"
              src="/icons/creationprocess/advance/auto-play.svg"
              iconCssClass={this.state.slide.data.files[this.state.selectedItemIndex]
                && this.state.slide.data.files[this.state.selectedItemIndex].autoPlay === true
                ? 'color-blue-100-svg'
                : undefined
              }
              expandWidth={100}
              label="Auto Play"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const slide = {
                  ...this.state.slide,
                  data: {
                    ...this.state.slide.data,
                    files: this.state.slide.data.files.map((audioFile: AudioSlideFile) => {
                      if(this.state.slide.data.files[this.state.selectedItemIndex].id === audioFile.id) {
                        return {
                          ...audioFile,
                          autoPlay: !audioFile.autoPlay,
                        }
                      }
                      return audioFile
                    })
                  }
                }
                this.setState({ slide });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={false}
              visible={this.state.audioEditType === AudioEditType.Selection}
            />

            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="Download Audio File"
              src="/icons/content-alteration/download.svg"
              expandWidth={80}
              label="Download"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {

              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={false}
              visible={this.state.audioEditType === AudioEditType.Selection}
            />
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="Auto Advance"
              src="/icons/creationprocess/advance/auto-advance.svg"
              iconCssClass={this.state.slide.data.files[this.state.selectedItemIndex]
                && this.state.slide.data.files[this.state.selectedItemIndex].autoAdvance === true
                ? 'color-blue-100-svg'
                : undefined
              }
              expandWidth={80}
              label="Auto Advance"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const slide = {
                  ...this.state.slide,
                  data: {
                    ...this.state.slide.data,
                    files: this.state.slide.data.files.map((audioFile: AudioSlideFile) => {
                      if(this.state.slide.data.files[this.state.selectedItemIndex].id === audioFile.id) {
                        return {
                          ...audioFile,
                          autoAdvance: !audioFile.autoAdvance,
                        };
                      }
                      return audioFile;
                    })
                  }
                }
                this.setState({ slide });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={false}
              visible={this.state.audioEditType === AudioEditType.Selection}
            />
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="trim"
              src="/icons/creationprocess/trim.svg"
              expandedSrc="/icons/creationprocess/trim.svg"
              iconBackgroundColor="var(--COLOR-BLUE-100)"
              iconCssClass="color-secondary-600-svg"
              expandedIconCssClass="whites-normal-1000-svg"
              expandWidth={87}
              label="Trim"
              labelClass="audioSlideEditorCommandTrimExpandingIconButtonLabel"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                this.setState({ audioEditType: AudioEditType.Trim });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.audioEditType === AudioEditType.Trim}
              visible={
                (this.state.audioEditType === AudioEditType.Selection
                  && this.state.slide.data.files[this.state.selectedItemIndex]
                  && (this.state.slide.data.files[this.state.selectedItemIndex].type === 'RECORDING'
                    || this.state.slide.data.files[this.state.selectedItemIndex].type === 'UPLOAD')
                )
                || this.state.audioEditType === AudioEditType.Trim}
            />
          </div>
        )}
        {this.state.editorState === EditorState.Preview && (
          <div className="headerActionsFlexEnd">
            <ExpandingIconButton
              alt="stacked"
              src="/icons/creationprocess/display-settings/stacked.svg"
              expandedSrc="/icons/creationprocess/display-settings/stacked.svg"
              iconBackgroundColor="transparent"
              iconCssClass="color-secondary-300-svg"
              expandedIconCssClass="whites-normal-1000-svg"
              style={{
                marginLeft: '24px',
                height: '32px',
                backgroundImage: this.state.slide.data.slideTypeSpecificSettings.displayType
                  === AudioViewerMode.Stacked
                  ? 'linear-gradient(290deg, #faa114, #fab317)' : ''
              }
              }
              expandWidth={110}
              label="Stacked"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const slide = {
                  ...this.state.slide,
                  data: {
                    ...this.state.slide.data,
                    slideTypeSpecificSettings: {
                      ...this.state.slide.data.slideTypeSpecificSettings,
                      displayType: AudioViewerMode.Stacked,
                    }
                  }
                }
                this.setState({ slide });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.slide.data.slideTypeSpecificSettings.displayType
                === AudioViewerMode.Stacked}
              visible={true}
            />
            <ExpandingIconButton
              alt="Carousel"
              src="/icons/creationprocess/display-settings/carocell.svg"
              expandedSrc="/icons/creationprocess/display-settings/carocell.svg"
              iconBackgroundColor="transparent"
              iconCssClass="color-secondary-300-svg"
              expandedIconCssClass="whites-normal-1000-svg"
              style={{
                marginLeft: '24px',
                height: '32px',
                backgroundImage: this.state.slide.data.slideTypeSpecificSettings.displayType
                  === AudioViewerMode.Carousel
                  ? 'linear-gradient(290deg, #faa114, #fab317)' : ''
              }
              }
              expandWidth={111}
              label="Carousel"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const slide = {
                  ...this.state.slide,
                  data: {
                    ...this.state.slide.data,
                    slideTypeSpecificSettings: {
                      ...this.state.slide.data.slideTypeSpecificSettings,
                      displayType: AudioViewerMode.Carousel,
                    }
                  }
                }

                this.setState({ slide });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.slide.data.slideTypeSpecificSettings.displayType
                === AudioViewerMode.Carousel}
              visible={true}
            />
          </div>
        )}
      </>
    );
  }
  allowedToAdd = () => {
    // Default for Mobile. TODO
    if (this.state.slide.data.files.length > 6) {
      this.props.showNotification({
        message: `You can only add 7 audio files.`,
        type: NotificationType.INFO,
      });
      return false;
    } else {
      return true;
    }
  }
  showBackNavigation = () => {
    return this.state.editorState === EditorState.Add
      || this.state.audioEditType === AudioEditType.Trim;
  }
  getModalOverlayState = (): ModalOverlayState => {
    if (this.showBackNavigation()) {
      return ModalOverlayState.Footer;
    }
    return ModalOverlayState.None;
  }
  closeAllModals = (): void => {
    this.setState({ ...this.hiddenModalsState });
  }

  /**
 * Sets the selected Image
 */
  setAudioSelection(index: number) {
    this.setState({
      audioEditType: AudioEditType.Selection,
      editorState: EditorState.Edit,
      selectedItemIndex: index
    });
  }

  private actionBrowseAudio = () => {
    if (this.allowedToAdd()){
      this.setState({
        audioAddType: AudioAddType.Upload,
        editorState: EditorState.Add,
      });
    }
  };

  private actionChooseSlide = () => {
    if (this.allowedToAdd()){
      this.setState({
        audioAddType: AudioAddType.Slide,
        previousState: this.state,
        editorState: EditorState.Add,
      });
    }
  };

  private actionUseLink = () => {
    if (this.allowedToAdd()){
      this.setState({
        audioAddType: AudioAddType.Embed,
        editorState: EditorState.Add,
      });
    }
  };

  private actionRecordAudio = () => {
    if (this.allowedToAdd()){
      this.setState({
        audioAddType: AudioAddType.RecordAudio,
        editorState: EditorState.Add,
      });
    }
  };

  audioEditorAddActions = () => {
    return (
      <>
        <CircleIconButton
          style={{ margin: '5px 10px 5px 5px' }}
          alt="browse for audio File"
          onClick={this.actionBrowseAudio}
          iconCssClass="whites-normal-1000-svg"
          backgroundColor="var(--COLOR-BLUE-100)"
          image="/icons/slidetype/audio/audio-file.svg"
        />
        <CircleIconButton
          style={{ margin: '5px 10px 5px 5px' }}
          alt="choose slide"
          onClick={this.actionChooseSlide}
          iconCssClass="whites-normal-1000-svg"
          backgroundColor="var(--COLOR-BLUE-100)"
          image="/icons/creationprocess/slide.svg"
        />
        <CircleIconButton
          style={{ margin: '5px 10px 5px 5px' }}
          alt="use embed"
          onClick={this.actionUseLink}
          iconCssClass="whites-normal-1000-svg"
          backgroundColor="var(--COLOR-BLUE-100)"
          image="/icons/slidetype/links/main.svg"
        />
        <CircleIconButton
          style={{ margin: '5px 18px 5px 5px' }}
          alt="record audio"
          onClick={this.actionRecordAudio}
          iconCssClass="whites-normal-1000-svg"
          backgroundColor="var(--COLOR-BLUE-100)"
          image="/icons/slidetype/audio/mic.svg"
        /> 
      </>
    );
  }
  confirmDeleteAudio = () => {
    this.setState({
      questionInfo: deleteFileQuestionInfo('audio'),      
    });
  }
  onDropFiles(acceptedFiles: any[], rejectedFiles: any) {
    if (acceptedFiles.length) {
      this.setState({
        loadingInfo: {
          message: 'Saving Audio Files',
          progressIndicatorType: ProgressIndicatorType.Indeterminate,
        }
      });

      const currentNumberOfFiles = this.state.slide.data.files.length;
      const maxAllowedFilesToBeAdded = (currentNumberOfFiles >= 0)
        ? Math.max(0, Math.min(7, 7 - currentNumberOfFiles))
        : 7;

      if (acceptedFiles.length > maxAllowedFilesToBeAdded) {
        this.props.showNotification({
          message: `You can only add ${maxAllowedFilesToBeAdded} additional files.`,
          type: NotificationType.INFO,
          timeoutMilliseconds: 4000,
        });
      }

      const filesToAdd = acceptedFiles.slice(0, maxAllowedFilesToBeAdded);

      filesToAdd.forEach((audioFile: any, index: number) => {
        superagent
          .post(`${API_ROOT}/util/upload`)
          .attach('theseNamesMustMatch', audioFile)
          .end((err, res) => {
            if (err) console.log(err);
            if (res.body && res.body.url) {
              const slide = {
                ...this.state.slide,
                data: {
                  ...this.state.slide.data,
                  files: [...this.state.slide.data.files, {
                    id: v4(),
                    name: audioFile.name,
                    url: res.body.url,
                    type: 'UPLOAD',
                    caption: '',
                  }],
                }
              };
              if (index === filesToAdd.length - 1) {
                this.setState({
                  slide, editorState: EditorState.Edit,
                  selectedItemIndex: this.state.slide.data.files.length,
                  loadingInfo: undefined
                });
              }
              else {
                this.setState({ slide });
              }
            }
          });
      });
    }
  }
  _mobileAudioUpload: any;
  public render() {
    const slideEditorClasses = classNames(
      'audioSlideEditor',
      this.state.slide.data.slideTypeSpecificSettings.displayType === AudioViewerMode.Stacked
        && this.state.editorState === EditorState.Preview
        ? ' stacked' : undefined,
      this.state.editorState === EditorState.Add && this.state.audioAddType === AudioAddType.RecordAudio
        ? ' recording' : undefined
    );
    const selectedAudioFile: AudioSlideFile = this.state.slide.data.files[this.state.selectedItemIndex];
    const audioPreviewItems = this.state.slide ? this.state.slide.data.files.map((audioFile: AudioSlideFile, i: number) => {
      const that = this;
      const Svg = getPreviewOverlayIconSvg(SlideType.Audio, audioFile.type);
      return {
        id: `${audioFile.id}`,
        content: <div className="slideFileSelectionContainer" key={`slideFileSelectionContainer${i}`}>
          <Svg style={{ width: "17px", height: "17px", visibility: "hidden" }} />

          <div className={`audioPreview${i === this.state.selectedItemIndex ? ' active' : ''}`}
            key={`ip${i}`}>
            <Svg
              className={'audioPreviewOverlayIcon'}
              color={i === this.state.selectedItemIndex ? 'var(--WHITES-NORMAL-1000)' : 'var(--COLOR-SECONDARY-300)'}
              onClick={() => { that.setAudioSelection(i); }}
            />
          </div>
          <div className={`itemPreviewLabel${this.state.selectedItemIndex === i ? ' active' : ''}`}
            key={`ipl${i}`} >
            <span>{i + 1}</span>
          </div>
        </div>
      };
    }) : [];
    const slideFileTypes = [
      {
        title: 'Upload audio',
        message: 'Share your gide to your friends.',
        alt: 'browser for audio',
        image: '/icons/slidetype/audio/audio-file.svg',
        onClick: this.actionBrowseAudio,
      },
      {
        title: 'Slide from a gide',
        message: 'Share your gide to your friends.',
        alt: 'choose slide',
        image: '/icons/creationprocess/slide.svg',
        onClick: this.actionChooseSlide,
      },
      {
        title: 'Link an audio file',
        message: 'Share your gide to your friends.',
        alt: 'use link',
        image: '/icons/slidetype/links/main.svg',
        onClick: this.actionUseLink,
      },
      {
        title: 'Create an audio track',
        message: 'Share your gide to your friends.',
        alt: 'record audio',
        image: '/icons/slidetype/audio/main.svg',
        onClick: this.actionRecordAudio,
      },
    ];

    const navigateBack = () => {
      if (this.state.editorState === EditorState.New) {
        this.props.closeModal();
      } else {
        this.closeAllModals();
        if (!this.state.slide.data.files.length) {
          this.setState({ editorState: EditorState.New });
        } else if (this.state.selectedItemIndex >= 0) {
          this.setState({ editorState: EditorState.Edit });
        } else {
          this.setState({ editorState: EditorState.Preview });
        }
      }
    }
    return (
      <SlideEditorModal
        article={this.props.article}
        backIconMode={this.showBackNavigation() ? BackIconMode.Light : BackIconMode.None}
        currentUser={this.props.currentUser}
        hideFooter={this.hideFooter()}
        hideCommandBar={this.hideCommandBar()}
        displaySlideAttribution={this.state.displaySlideAttribution}
        question={this.state.questionInfo}
        editorState={this.state.editorState}
        loadingInfo={this.state.loadingInfo}
        modalOverlayState={this.getModalOverlayState()}
        selectedItemIndex={this.state.selectedItemIndex}
        hideActionContainer={
          this.state.editorState === EditorState.New
          || (this.state.editorState === EditorState.Edit && this.state.audioEditType === AudioEditType.Trim)
          || (this.state.editorState === EditorState.Add)
        }
        showBackNavigation={this.showBackNavigation()}
        showDefaultActions={
          !this.showBackNavigation()
        }
        showDeleteFile={
          !this.showBackNavigation()
          && this.state.editorState === EditorState.Edit
        }
        slide={this.state.slide as Slide}
        position={this.props.position}

        createActions={
          <this.audioEditorAddActions />
        }
        headerActions={
          <this.headerActions />
        }
        previewItems={
          audioPreviewItems
        }
        onAnswerQuestion={(response: QuestionResponseInfo) => {
          if(response.questionId === DELETE_FILE) {
            navigateBack();
          }
          else if (selectedAudioFile && response.questionResponse === QuestionResponse.Ok) {
            const slide = {
              ...this.state.slide,
              data: {
                ...this.state.slide.data,
                files: reject((f: AudioSlideFile) => f.id === selectedAudioFile.id, this.state.slide.data.files)
              }
            }
            this.setState({ slide, questionInfo: undefined });
          } else {
            // TODO:
            this.setState({ questionInfo: undefined });
          }
        }}
        closeModal={this.props.closeModal}
        onCloseAllModals={() => {
          this.setState({
            ...this.hiddenModalsState
          });
        }}
        onNavigateBack={() => {
          navigateBack();
        }}
        onShowPreview={() => {
          this.setState({ editorState: EditorState.Preview, selectedItemIndex: -1 })
        }}
        onSlideUpdated={async (slide: Slide) => {
          const selectedSlideIndex = (this.state.selectedItemIndex < slide.data.files.length)
            ? this.state.selectedItemIndex : (slide.data.files.length - 1);
          this.setState({ slide, ...this.hiddenModalsState, selectedItemIndex: selectedSlideIndex });
        }}
        onSubmitSlide={this.props.onSubmitSlide}
        showNotification={this.props.showNotification}
      >
        <div className={slideEditorClasses}>
          {this.state.editorState === EditorState.New && (
            <div className="audioEditor" style={{flexDirection: 'column', justifyContent: 'center'}}>
              <SlideFileTypeSelector theme="light"
                slideFileTypes={slideFileTypes} />
            </div>
          )}
          {this.state.editorState === EditorState.Preview && (
            <div className="audioSlideViewerContainer">
              <SlideView
                slide={this.state.slide as Slide}
                slidePosition={(this.state.slide as Slide).position}
                currentUser={AnonymousUser}
                viewMode={"SCROLL"}
                view={'website'}
                disableExpiration={true}
                ignoreTheme={true}
              />
            </div>
          )}
          {this.state.editorState === EditorState.Add
            && this.state.audioAddType === AudioAddType.Upload && (
              <DropZone className="audioDropZone"
                accept="audio/mp3, audio/mpeg, audio/acc, audio/ogg, audio/wav, audio/webm"
                ref={(mau: any) => {
                  if (mau && mau.fileInputEl) {
                    // alert('allowing audio')
                    // mau.fileInputEl.accept = "audio/*";
                    // mau.fileInputEl.capture = "environment";
                    if (isMobileDevice()) {
                      mau.fileInputEl.click();
                    }
                  }
                }}
                onDrop={this.onDropFiles.bind(this)}>
                <div className="uploadIndicator">
                  Upload audio file
                  <GideImage src="/icons/slidetype/audio/audio-file.svg"
                    alt="Upload Audio Files"
                  />
                </div>
              </DropZone>
            )}
          {!isCordovaIOSDevice() && isMobileDevice() && this.state.editorState === EditorState.Add
            && this.state.audioAddType === AudioAddType.RecordAudio && (
            <DropZone className="audioDropZone"
              onDrop={this.onDropFiles.bind(this)}
              ref={(mau: any) => {
                alert('In the wrong place')
                if (mau && mau.fileInputEl) {
                  mau.fileInputEl.accept = "audio/*";
                  mau.fileInputEl.capture = "environment";
                }
                this._mobileAudioUpload = mau;
                if(this._mobileAudioUpload && this._mobileAudioUpload.fileInputEl) {
                  this._mobileAudioUpload.fileInputEl.click();
                }
              }}
            >
              <GideImage
                className='recordingControl color-secondary-600-svg'
                src="/icons/content-alteration/upload.svg"
                alt="initiate mobile upload"
              />
            </DropZone>
          )}
          {((!isMobileDevice() || isCordovaIOSDevice()) && this.state.editorState === EditorState.Add
            && this.state.audioAddType === AudioAddType.RecordAudio) && (
              <>
                {/* Spacer */}
                <span></span>
                <AudioRecorder
                 onError={(message: string) => this.props.showNotification ({message: message, type: NotificationType.ERROR})}
                  displayAutoPlayControls={false}
                  onDeleteAudioRecording={this.confirmDeleteAudio}
                  saveAudioRecording={(audioFile: AudioFile) => {
                    if (selectedAudioFile && this.state.editorState === EditorState.Edit) {
                      const slide = {
                        ...this.state.slide,
                        data: {
                          ...this.state.slide.data,
                          files: this.state.slide.data.files.map((f: SlideFile, index: number) => {
                            if (f.id === selectedAudioFile.id) {
                              return {
                                ...f,
                                url: audioFile.url,
                                name: audioFile.name,
                                autoPlay: audioFile.autoPlay,
                                autoAdvance: audioFile.autoAdvance
                              }
                            }
                            else {
                              return f;
                            }
                          })
                        }
                      }
                      this.setState({ slide, editorState: EditorState.Edit })
                    } else {
                      const audioSlideFile: AudioSlideFile = {
                        id: v4(),
                        url: audioFile.url,
                        name: audioFile.name,
                        autoPlay: audioFile.autoPlay,
                        autoAdvance: audioFile.autoAdvance,
                        type: 'RECORDING'
                      }
                      const slide = {
                        ...this.state.slide,
                        data: {
                          ...this.state.slide.data,
                          files: [...this.state.slide.data.files, audioSlideFile]
                        }
                      }
                      this.setState({ slide, editorState: EditorState.Edit, selectedItemIndex: slide.data.files.length - 1 })
                    }
                  }}
                  closeAudioRecording={() => {
                    navigateBack();
                  }}
                  iconCssClass="color-secondary-600-svg"
                  color="var(--COLOR-BLUE-100)"
                />
              </>
            )}
          {this.state.editorState === EditorState.Add
            && this.state.audioAddType === AudioAddType.Slide && (
              <div style={{ width: "100%", height: "100%", display: "flex" }}>
                <SlideFileChooser
                  title={'Find an Audio slide'}
                  subtitle={'Pick a gide that your audio resides in'}
                  hideActionBar={true}
                  slideTypes={['AUDIO']}
                  showNotification={this.props.showNotification}
                  currentUser={this.props.currentUser}
                  singleSelectionOnly={false}
                  onNavigateBack={() => {
                    if(this.state.previousState) {
                      this.setState({...this.state.previousState});
                    }
                  }}
                  addSlideFileReferences={(slideFileReference: SlideFileReference[]) => {
                    this.setState({
                      loadingInfo: {
                        message: 'Adding Linked Videos',
                        progressIndicatorType: ProgressIndicatorType.Indeterminate,
                      }
                    });
                    const newSlides = slideFileReference.map(sfr => {
                      return {
                        id: v4(),
                        slideFileReference: sfr,
                        type: 'SLIDEFILE',
                      };
                    });
                    const slide = {
                      ...this.state.slide,
                      data: {
                        ...this.state.slide.data,
                        files: [...this.state.slide.data.files, ...newSlides]
                      }
                    }
                    this.setState({
                      slide,
                      loadingInfo: undefined,
                      audioEditType: AudioEditType.Selection,
                      editorState: EditorState.Edit,
                      selectedItemIndex: slide.data.files.length - 1
                    });
                  }}
                  agent={agent}
                />
              </div>
            )}
          {(this.state.editorState === EditorState.Add) && (this.state.audioAddType === AudioAddType.Embed) && (
            <div className="audioLinkContainer">
              <LinkInput
                value=""
                placeholder='Paste an audio embed tag'
                iconLocation={HorizontalAlignment.Left}
                icon='/icons/slidetype/links/main.svg'
                iconCssClass="color-blue-100-svg"
                onFocus={() => {
                  const linkImage = {
                    id: v4(),
                    url: '',
                    caption: '',
                    type: 'EMBED',
                    name: '', attribution: ''
                  };
                  const slide = {
                    ...this.state.slide,
                    data: {
                      ...this.state.slide.data,
                      files: append(linkImage, this.state.slide.data.files)
                    },
                  };
                  this.setState({
                    slide,
                    editorState: EditorState.Edit,
                    selectedItemIndex: this.state.slide.data.files.length,
                    audioEditType: AudioEditType.Selection
                  });
                }}
              />
            </div>
          )}
          {this.state.editorState === EditorState.Edit &&
            this.state.audioEditType === AudioEditType.Selection && (
              <>
                {this.state.slide.data.files[this.state.selectedItemIndex] &&
                  this.state.slide.data.files[this.state.selectedItemIndex].type === "EMBED" && (
                    <div className="audioLinkContainer">
                      <LinkInput
                        autoFocus
                        value={this.state.slide.data.files[this.state.selectedItemIndex].url}
                        placeholder={'Paste an audio embed tag'}
                        iconLocation={HorizontalAlignment.Left}
                        icon='/icons/slidetype/links/main.svg'
                        iconCssClass="color-blue-100-svg"
                        onChange={(linkValue: string | number | undefined) => {
                          const selectedVideoId = this.state.slide.data.files[this.state.selectedItemIndex].id;
                          const slide = {
                            ...this.state.slide,
                            data: {
                              ...this.state.slide.data,
                              files: this.state.slide.data.files.map((f: AudioSlideFile, index: number) => {
                                if (f.id === selectedVideoId) {
                                  return {
                                    ...f,
                                    url: linkValue,
                                  }
                                } else {
                                  return f;
                                }
                              }),
                            },
                          }
                          this.setState({ slide });
                        }}
                      />
                    </div>
                  )}
                {selectedAudioFile && (selectedAudioFile.url || selectedAudioFile.slideFileReference) && (
                  <AudioView
                    audioFile={selectedAudioFile}
                    isReferenceType={selectedAudioFile && selectedAudioFile.type === 'SLIDEFILE'}
                    slide={this.state.slide as Slide}
                    autoPlay={false}
                  />
                )}
                {/* {selectedAudioFile && selectedAudioFile.type === "EMBED" && !hasValue(selectedAudioFile.url) && (
                  <div className={`embedIcons`}>
                    <GideImage src="/icons/socialmedia/spotify.svg"
                      alt="spotify"
                    />
                    <GideImage src="/icons/socialmedia/soundcloud.svg"
                      alt="soundcloud"
                    />
                    <GideImage src="/icons/socialmedia/youtube.svg"
                      alt="youtube"
                    />
                  </div>
                )} */}
              </>
            )}
        </div>
      </SlideEditorModal>
    );
  }
}


export default connect(null, slideEditorModalMapDispatchToProps)(
  AudioSlideEditor,
);
