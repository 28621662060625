import React from 'react';
import { New } from './New';
import { NotificationType } from '../../../constants/strings';
import { isMobileDevice, getSlideFileUrl, extractHostname, extractEmbedFeedIdentifier, getPreviewOverlayIconSvg, SlideType } from '../../../utils/helperFunctions';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import { CommonState, CommonDerivedState, CommonContext, CommonModes, CommonSlideEditorProps } from './Common';
import * as Common from './Common';
import { VideoFile } from '../../../models/SlideFile';
import { contains } from 'ramda';
import GideImage from '../../Shared/Image/GideImage';
import { v4 } from 'uuid';

export interface VideoSlideEditorProps extends CommonSlideEditorProps {
  set?: { mode: string };
  startCamera?: boolean;
  url?: string;
};

export const VideoModes = {
  ...CommonModes,
  Trim: 'Trim' as 'Trim',
};
export type State = CommonState & { mode: keyof typeof VideoModes };
export type DerivedState = CommonDerivedState & { mode: keyof typeof VideoModes };
export type Context = Common.Context<State, VideoSlideEditorProps>;

export const getHiddenMediaChooser = (context: CommonContext) => (
  <div style={{ display: 'none' }}>
    {New.getContent(context, true)}
  </div>
);

export const getCreateActionsForVideo = (context: CommonContext) => {
  const hiddenMediaChooser = getHiddenMediaChooser(context);
  const actions = Common.getCreateActionCallbacks(context, "videos");
  
  const createActions = (
    <>
      <CircleIconButton
        style={{ margin: '5px 10px 5px 5px' }}
        alt="use link"
        onClick={actions.actionUseLink}
        backgroundColor="var(--COLOR-BLUE-100)"
        iconCssClass="whites-normal-1000-svg"
        image="/icons/slidetype/links/main.svg"
      />
      <CircleIconButton
        style={{ margin: '5px 10px 5px 5px' }}
        alt="choose slide"
        onClick={actions.actionChooseSlide}
        backgroundColor="var(--COLOR-BLUE-100)"
        iconCssClass="whites-normal-1000-svg"
        image="/icons/creationprocess/slide.svg"
      />
      <CircleIconButton
        style={{ margin: '5px 10px 5px 5px' }}
        alt="browse for video"
        onClick={actions.actionBrowseFile}
        backgroundColor="var(--COLOR-BLUE-100)"
        iconCssClass="whites-normal-1000-svg"
        image="/icons/slidetype/image/main.svg"
      />
      <CircleIconButton
        style={{ margin: '5px 18px 5px 5px' }}
        alt="take video"
        onClick={actions.actionStartCamera}
        backgroundColor="var(--COLOR-BLUE-100)"
        iconCssClass="whites-normal-1000-svg"
        image="/icons/slidetype/video/main.svg"
      />
      {hiddenMediaChooser}
    </>
  );
  return createActions;
}

export const getVideoPreviewItems = (context: CommonContext, selectedItemIndex: number, setVideoSelection: (selectedItemIndex: number, context: CommonContext) => void) => {
  const videoPreviewItems = context.state.slide ? context.state.slide.data.files.map((video: VideoFile, i: number) => {
    const slideFileUrl = getSlideFileUrl(video);
    const hostName = extractHostname(slideFileUrl);
    let previewUrl = slideFileUrl;
    const isYoutube = contains('youtube', hostName.toLowerCase()) || contains('youtu.be', hostName.toLowerCase());
    if (isYoutube) {
      const videoId = extractEmbedFeedIdentifier(slideFileUrl);;
      previewUrl = `https://img.youtube.com/vi/${videoId}/1.jpg`;
    }

    const Svg = getPreviewOverlayIconSvg(SlideType.Video, video.type);

    return {
      id: `${video.id}`,
      content: <div className="slideFileSelectionContainer" key={`slideFileSelectionContainer${i}`}>
        <Svg style={{ width: "17px", height: "17px" }}
          color={ (i < 0) ? 'var(--WHITES-NORMAL-300)' : 'var(--COLOR-SECONDARY-500)' } />

        <div className="videoPreview" key={`ip${i}`}>
          {isYoutube && (
            <GideImage
              className="slideVideoThumbnail"
              key={`iep${i}`}
              src={previewUrl}
              alt={previewUrl}
              onClick={() => { setVideoSelection(i, context); }}
            />
          )}
          {!isYoutube && (
            <video className="slideVideoThumbnail"
              id={v4()}
              src={previewUrl}
              onClick={() => { setVideoSelection(i, context); }}
            />
          )}
        </div>
        <div className={`itemPreviewLabel${selectedItemIndex === i ? ' active' : ''}`}
          key={`ipl${i}`} >
          <span>{i + 1}</span>
        </div>
      </div>
    };
  }) : [];
  return videoPreviewItems;
}
