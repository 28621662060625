import React, { Component } from 'react';
import { Slide } from '../../../models/Slide';
import { User } from '../../../models/User';
import ChildArticleEditorSlide from '../ChildArticleEditor/ChildArticleEditorSlide';
import { Article } from '../../../models/Article';
import { ChildArticleEditorInfo } from '../../../models/ChildArticleEditorInfo';
import ChildArticleViewer from '../ChildArticleViewer/ChildArticleViewer';
import agent from '../../../agent';
import {
  ChildArticleType,
  getChildArticleTypeName,
} from '../../../models/ArticleLayoutEnum';
import AuthorizationComponent from './AuthorizationComponent';
import { contains } from 'ramda';
import { Icon } from 'semantic-ui-react';
import GideImage from '../../Shared/Image/GideImage';
import { ViewMode } from '../../Gide/SlideView/SlideView';
// import { ChildArticleEditInfo } from '../../../reducers/article';

export interface AuthorizeBlockSlideProps {
  currentUser: User;
  article: Article;
  slide: Slide;
  childArticleEditInfo: ChildArticleEditorInfo;
  view: string;
  viewMode: ViewMode;
  authorizedBlockSlideIds: string[];
  toggleChildArticleViewMode: (
    payload: { slideId: string; articleType: string },
  ) => void;
  openChildArticleModal?: (slide: Slide, childArticleTypeList: ChildArticleType[], selectedChildArticleType: ChildArticleType, selectedArticleId?: string, viewOnly?: boolean) => void;
  exitChildArticleEditor?: () => void;
  showNotification: (
    payload: { toasterMessageInfo: { message: string; type: any } },
  ) => void;
  onAuthorizeSlide: (
    slideId: string,
    authDetails: { lat: string; long: string } | { password: string } | {},
  ) => void;
  onUnauthenticatedAuthorizeSlide: (
    slideId: string,
    authDetails: { lat: string; long: string } | { password: string } | {},
  ) => void;
}

interface AuthorizeBlockSlideState {
  slides: Slide[];
  authorized: boolean;
  authorizeSlideArticle?: Article;
  collapsed: boolean;
}

export default class AuthorizeBlockSlide extends Component<
  AuthorizeBlockSlideProps,
  AuthorizeBlockSlideState
> {
  constructor(props: AuthorizeBlockSlideProps) {
    super(props);
    this.state = {
      slides: [],
      authorized: props.slide.data.authorized,
      collapsed: false,
    };
  }
  componentDidMount() {
    this.props.currentUser && this.checkIfSlideIsAuthenticated();
  }
  async currentPositionFound(position: any) {
    const authInfo = {
      lat: position.coords.latitude,
      long: position.coords.longitude,
    };
    const authResponse =
      (this.props.slide.author &&
        this.props.currentUser &&
        this.props.currentUser.username === this.props.slide.author.username) ||
      (await agent.Slides.isUserAuthorized(this.props.slide.id, authInfo));
    if (authResponse.authorized) {
      this.loadSlides();
    }
  }
  // error callback function for navigator.geolocation.getCurrentPosition
  errorFindingCurrentPosition(error: any) {
    console.warn(`ERROR(${error.code}): ${error.message}`);
  }
  async checkIfSlideIsAuthenticated() {
    // Determine what type of authInfo is needed based on the slide type
    const isAuthor =
      this.props.slide.author &&
      this.props.currentUser &&
      this.props.currentUser.username === this.props.slide.author.username;
    if (isAuthor) {
      this.loadSlides();
    } else {
      if (this.props.slide.data.passcodeType === 'GPS') {
        if (navigator.geolocation.getCurrentPosition) {
          navigator.geolocation.getCurrentPosition(
            this.currentPositionFound.bind(this),
            this.errorFindingCurrentPosition.bind(this),
          );
        } else {
          alert(
            'Sorry, geolocation does not seem to be supported by your browser.',
          );
        }
      } else {
        const authResponse = await agent.Slides.isUserAuthorized(
          this.props.slide.id,
        );
        if (authResponse.authorized) {
          this.loadSlides();
        }
      }
    }
  }
  componentDidUpdate(
    prevProps: AuthorizeBlockSlideProps,
    prevState: AuthorizeBlockSlideState,
  ) {
    if (
      !prevState.authorized && this.state.authorized &&
      prevProps.authorizedBlockSlideIds !==
        this.props.authorizedBlockSlideIds &&
      contains(this.props.slide.id, this.props.authorizedBlockSlideIds)
    ) {
      this.loadSlides();
    }
  }

  shouldComponentUpdate(
    nextProps: AuthorizeBlockSlideProps,
    nextState: AuthorizeBlockSlideState,
  ) {
    const shouldUpdate =
      nextProps.article !== this.props.article ||
      nextProps.childArticleEditInfo !== this.props.childArticleEditInfo ||
      nextProps.slide !== this.props.slide ||
      nextProps.viewMode !== this.props.viewMode ||
      nextProps.authorizedBlockSlideIds !==
        this.props.authorizedBlockSlideIds ||
      nextState.authorized !== this.state.authorized ||
      nextState.authorizeSlideArticle !== this.state.authorizeSlideArticle ||
      nextState.slides !== this.state.slides ||
      nextState.collapsed !== this.state.collapsed;

    return shouldUpdate;
  }
  /**
   * NOTE: this can be called when the component loads and the slide has already been authorized (componentDidMount)
   * OR if the component loads and then the user authenticates (componentDidUpdate)
   */
  async loadSlides() {
    const article = await agent.Slides.getSettings(
      this.props.slide.id,
      getChildArticleTypeName(ChildArticleType.AuthorizeBlock),
    );
    const resp = await agent.Slides.forArticle(article.article);
    this.setState({
      authorized: true,
      authorizeSlideArticle: article.article,
      slides: resp.slides,
    });
  }
  closeViewer(): void {
    this.props.toggleChildArticleViewMode({
      slideId: this.props.slide.id ? this.props.slide.id : '',
      articleType: this.props.article.type,
    });
  }
  handleShowingEditorOrViewer(e: any) {
    if (e) {
      e.stopPropagation();
    }
    this.props.openChildArticleModal &&
    this.props.openChildArticleModal(this.props.slide, [ChildArticleType.AuthorizeBlock], ChildArticleType.AuthorizeBlock, this.state.authorizeSlideArticle ? this.state.authorizeSlideArticle.id : undefined, false);
  }
  render() {
    const isAuthor =
      this.props.currentUser &&
      this.props.slide.author &&
      this.props.slide.author.username === this.props.currentUser.username;
    const slideId = this.props.slide.id ? this.props.slide.id : '';
    const isEditing =
      this.props.childArticleEditInfo &&
      this.props.slide.id === this.props.childArticleEditInfo.ownerSlide.id &&
      this.props.childArticleEditInfo.article.type === 'AUTHORIZEBLOCK';
    return (
      <div className={`authorizeBlockSlide`}>
        {this.props.slide.data &&
          isAuthor && (
            // this.props.viewMode === 'SLIDE' && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
              }}
            >
              {this.props.slide.data && this.props.slide.data.headerCaption ? (
                <span>{this.props.slide.data.headerCaption}</span>
              ) : (
                <div className="authorizationInfo">
                  <GideImage
                    style={{ width: '20px', height: '20px' }}
                    src="/icons/slidetype/authorize/main.svg"
                    alt="authorize slide"
                  />
                  {/* Need better icons */}
                    {this.props.slide.data.passcodeType === 'TEXT' && (
                      <GideImage
                        className="authorizationIcon"
                        src="/icons/content-alteration/textblock.svg"
                        alt="Text Passcode"
                      />
                    )}
                    {this.props.slide.data.passcodeType === 'GPS' && (
                      <GideImage
                        className="authorizationIcon"
                        src="/icons/slidetype/location/location.svg"
                        alt="Text Passcode"
                      />
                    )}
                    {this.props.slide.data.passcodeType === 'TIME' && (
                      <GideImage
                      className="authorizationIcon"
                        src="/icons/creationprocess/events.svg"
                        alt="Text Passcode"
                      />
                    )}
                    {this.props.slide.data.passcodeType === 'USER' && (
                      <GideImage
                        className="authorizationIcon"
                        src="/icons/slidetype/contact/main.svg"
                        style={{ width: '16px', height: '16px' }}
                        alt="Text Passcode"
                      />
                    )}
                </div>
              )}
              {isAuthor &&
                this.props.viewMode === 'SLIDE' && (
                  <GideImage
                    className={`editButton`}
                    src={
                      isEditing
                        ? '/icons/nav/exit/alternative.svg'
                        : '/icons/nav/edit/edit-main.svg'
                    }
                    alt="edit authorize slide contents"
                    onClick={(e: any) => {
                      this.handleShowingEditorOrViewer(e);
                    }}
                  />
                )}
              <Icon
                onClick={() => {
                  this.setState({ collapsed: !this.state.collapsed });
                }}
                circular
                name={this.state.collapsed ? 'plus' : 'close'}
                color="grey"
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
        {/* Show Editor */}
        {isEditing &&
          !this.state.collapsed && (
            <ChildArticleEditorSlide
              article={this.props.article}
              childArticleEditInfo={this.props.childArticleEditInfo}
              currentUser={this.props.currentUser}
              view={this.props.view}
              slide={this.props.slide}
              viewMode={this.props.viewMode}
            />
          )}
        {/* Show Viewer */}
        {!this.state.collapsed &&
          (!isEditing &&
            this.state.authorizeSlideArticle &&
            (this.state.authorized ||
              (this.props.slide.author &&
                this.props.currentUser &&
                this.props.slide.author.username ===
                  this.props.currentUser.username))) && (
            <ChildArticleViewer
              article={this.props.article}
              removeBorder={true}
              slideId={slideId}
              slide={this.props.slide}
              articleType={this.state.authorizeSlideArticle.type}
              view={this.props.view}
              hideFooter={true}
              currentUser={this.props.currentUser}
              viewMode={this.props.viewMode}
            />
          )}
        {!this.props.childArticleEditInfo &&
          !this.state.authorized &&
          !(
            this.props.slide.author &&
            this.props.currentUser &&
            this.props.slide.author.username === this.props.currentUser.username
          ) && (
            <AuthorizationComponent
              viewMode={this.props.viewMode}
              currentUser={this.props.currentUser}
              slide={this.props.slide}
              onAuthorizeSlide={this.props.onAuthorizeSlide}
              onUnauthenticatedAuthorizeSlide={
                this.props.onUnauthenticatedAuthorizeSlide
              }
            />
          )}
      </div>
    );
  }
}
