import { useEffect } from 'react';

interface UrlCounts {
  [url: string]: {
    count: number;
    script: HTMLScriptElement;
  }
}

const urlCounts: UrlCounts = {};
const reinitializeOnEveryUse = true;

export const useScript = (url: string) => {
  useEffect(() => {
    let urlCount = urlCounts[url] || { count: 0, script: null };
    urlCounts[url] = urlCount;

    // If this is the first use of this script, then create the script element.
    if (reinitializeOnEveryUse || !urlCount.count) {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      urlCount.script = script; // Save to the urlCounts.
    }

    // Increment the count.
    urlCount.count = urlCount.count + 1;

    return () => {
      // Subtract one from the urlCount.
      urlCounts[url].count = urlCounts[url].count - 1;
      // Remove from the dom if this was the last one.
      if (reinitializeOnEveryUse || !urlCounts[url].count) {
        try {
          document.body.removeChild(urlCounts[url].script);
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, [url]);
};
