import React, { Component } from 'react';
import { User } from '../../models/User';
import { Article } from '../../models/Article';
// import ViewBar from '../ViewBar/ViewBar';
import InputBar from '../InputBar/InputBar';
import { Slide, GideTitleImageInfo } from '../../models/Slide';
import { InputBarOpenMenu } from '../../constants/strings';
import {
  SlideSelectionInfo,
  SlideSelectionOperation,
} from '../../models/SlideSelectionInfo';
import { ToasterMessageInfo } from '../../models/ToasterMessageInfo';
import { MultiSlideInsertDetails } from '../Gide/GideViewerManagerModal/GideViewerManagerModal';
import { SlideRange } from '../../models/SlideRange';
import classNames from 'classnames';

import styles from './_footer.module.scss';
interface FooterProps {
  currentUser?: User | null;
  article?: Article | null;
  viewMode: string | null;
  numberOfSlides: number;
  currentSlidePosition?: number;
  canDisplayViewBar: boolean;
  viewBarAlwaysDisplayed: boolean;
  allowBlame: boolean;
  showBlame: boolean;
  allowSlideComments: boolean;
  pauseSlideComments: boolean;
  allowSlideQuestions: boolean;
  pauseSlideQuestions: boolean;
  renderColumns: boolean;
  displayCollapsedHeaders: boolean;
  hideEndSlides: boolean;
  placeholderText?: string;
  textSlideEditing: Slide | null;
  slideType: string | null;
  openMenu: InputBarOpenMenu;
  slideSelectionModeDetail?: SlideSelectionInfo | null;
  // slideTypes: string[];
  headerSlides?: Slide[];
  slideRangeList?: SlideRange[];
  showLeftSidebar: boolean;
  showRightSidebar: boolean;
  onCollapseAllSlides: (slides: Slide[]) => void;
  onExpandAllSlides: () => void;
  onToggleColumns: () => void;
  onToggleBlame: () => void;
  toggleTableOfContents: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  toggleCollapsedHeaderDisplay: () => void;
  toggleEndSlideDisplay: () => void;
  onCollapseAllInlineViewersByType?: (payload: any) => void;
  onExpandAllInlineViewersByType?: (payload: any) => void;
  enterSlideSelectionMode: (
    slideSelectionModeDetail: SlideSelectionInfo,
  ) => void;
  createArticle?: () => Promise<Article>;
  openModal: (payload: any) => void;
  closeModal: () => void;
  getAvailableHeaderLevelsForCurrentSlidePosition: () => any;
  setFooterInput?: (key: string, value: any) => void;
  onSubmitSlide: (payload: any) => void;
  // onInputBlurred: () => void;
  onSubmitMultipleSlides: (
    multiSlideInsertDetails: MultiSlideInsertDetails,
  ) => void;
  onUpdateArticleTitleAndImage?: (payload: GideTitleImageInfo) => void;
  updateSlideNumber: (number: number) => void;
  scrollToSlidePosition: (slidePosition: number) => void;
  setOpenMenu: (menuState: InputBarOpenMenu) => void;
  onSlideFilterClicked?: () => void;
  showSignup: () => void;
  ensureInputBarModalsClosedOnFocus?: boolean;
}

interface FooterState {
  displayViewBar: boolean;
  displayToolBar: boolean;
}

export default class Footer extends Component<FooterProps, FooterState> {
  constructor(props: FooterProps) {
    super(props);
    this.state = {
      displayViewBar: false,
      displayToolBar: false,
    };
  }

  toggleViewBarDisplay = () => {
    this.setState({ displayViewBar: !this.state.displayViewBar });
  };

  toggleToolBarDisplay = () => {
    this.setState({ displayToolBar: !this.state.displayToolBar });
  };

  render() {
    const authorViewing =
      this.props.article &&
      this.props.article.author &&
      this.props.currentUser && this.props.currentUser.username === this.props.article.author.username;
    const showInputBar = authorViewing || !this.props.article;
    return (
      <div className={classNames(styles.footerContainer, this.props.openMenu === InputBarOpenMenu.SLIDE
        || this.props.openMenu === InputBarOpenMenu.HEADER ? styles.showOverModalBackdrop : '')}>
        {(!this.props.slideSelectionModeDetail ||
          this.props.slideSelectionModeDetail.operation === SlideSelectionOperation.AddSlideAbove ||
            this.props.slideSelectionModeDetail.operation === SlideSelectionOperation.AddSlideBelow) &&
          showInputBar && (
            <div className={styles.footerInputBar}>
              <InputBar
                currentUser={this.props.currentUser}
                article={this.props.article}
                displayToolBar={this.state.displayToolBar}
                currentSlidePosition={this.props.currentSlidePosition}
                numberOfSlides={this.props.numberOfSlides}
                displayViewBar={this.state.displayViewBar}
                textSlideEditing={this.props.textSlideEditing}
                placeholderText={this.props.placeholderText}
                slideType={this.props.slideType}
                openModal={this.props.openModal}
                closeModal={this.props.closeModal}
                openMenu={this.props.openMenu}
                getAvailableHeaderLevelsForCurrentSlidePosition={this.props.getAvailableHeaderLevelsForCurrentSlidePosition}
                setFooterInput={this.props.setFooterInput}
                onSubmitSlide={this.props.onSubmitSlide}
                toggleViewBarDisplay={this.toggleViewBarDisplay}
                toggleToolBarDisplay={this.toggleToolBarDisplay}
                onSubmitMultipleSlides={this.props.onSubmitMultipleSlides}
                onUpdateArticleTitleAndImage={this.props.onUpdateArticleTitleAndImage}
                updateSlideNumber={this.props.updateSlideNumber}
                scrollToSlidePosition={this.props.scrollToSlidePosition}
                createArticle={this.props.createArticle}
                setOpenMenu={this.props.setOpenMenu}
                showNotification={this.props.showNotification}
                showLeftSidebar={this.props.showLeftSidebar}
                showRightSidebar={this.props.showRightSidebar}
                ensureInputBarModalsClosedOnFocus={this.props.ensureInputBarModalsClosedOnFocus}
                showSignup={this.props.showSignup}
              />
            </div>
          )}
      </div>
    );
  }
}
