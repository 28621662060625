import { AdministrationState, administrationReducer } from './reducers/administration/administration';

import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';

import slide from './reducers/slide';
import { FileProcessorState, fileProcessor } from './reducers/FileProcessing/fileProcessing';
import footer from './reducers/footer';
import {article, ArticleState} from './reducers/article';
import articleList from './reducers/articleList';
import auth from './reducers/auth';
import common, { CommonState } from './reducers/common/common';
import editor from './reducers/editor';
import home from './reducers/home';
import profile from './reducers/profile';
import settings from './reducers/settings';
import modalManager from './reducers/modalManager';
import channel from './reducers/channel';
import collectionModal from './reducers/collectionModal';

// export const history2 = createHistory();
export interface AppState {
  slide: any,
  fileProcessor: FileProcessorState,
  footer: any,
  article: ArticleState,
  articleList: any,
  auth: any,
  common: CommonState,
  editor: any,
  home: any,
  profile: any,
  settings: any,
  modalManager: any[],
  channel: any,
  collectionModal: any,
  administration: AdministrationState,
  router: RouterState
}
export const rootReducer = (history: any): Reducer<AppState> =>  combineReducers<AppState>({
  footer,
  slide,
  fileProcessor,
  article,
  articleList,
  auth,
  common,
  editor,
  home,
  profile,
  settings,
  modalManager,
  channel,
  collectionModal,
  administration: administrationReducer,
  router: connectRouter(history),
});
