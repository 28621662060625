import React, { Component, ReactNode, CSSProperties, useCallback } from 'react';
import { SlideUpdateInfo, Slide, SlideModel } from '../../../models/Slide';
import { Modal, TextArea, Dropdown, DropdownProps, Input, InputOnChangeData } from 'semantic-ui-react';
import { hasValue, prepareSlideFileForSave, areElementsOverlapping, noop, getSlideFileCaptionPlaceholder } from '../../../utils/helperFunctions';
import agent from '../../../agent';
import { Article } from '../../../models/Article';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { NotificationType } from '../../../constants/strings';
import ProgressBar from '../../Shared/ProgressBar/ProgressBar';
import SlideSettings, { getSlideSettings, updateSlideSettings } from '../../SlideEditors/SlideSettings/SlideSettings';
import { User } from '../../../models/User';
import { v4 } from 'uuid';
import AudioRecorder from '../../media/AudioRecorder/AudioRecorder';
import { SlideFile, SlideReferenceFile, MediaFile } from '../../../models/SlideFile';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import DragReorderableList, { DraggableListItem } from '../../Shared/DragReorderableList/DragReorderableList';
import SlideEditorCommandBar from '../../SlideEditors/SlideEditorCommandBar/SlideEditorCommandBar';
import { reject, contains, isNil } from 'ramda';
import { withTheme } from 'styled-components';
import icons from '../../../assets/icons';
import classNames from 'classnames';
import GideImage from '../../Shared/Image/GideImage';
import {SlideFileProcessorInfo, TitleSlideProcessorInfo, GallerySlideFileProcessorInfo} from '../../../reducers/FileProcessing/fileProcessing';
import localforage from 'localforage';
import { ImageGalleryFile } from '../../SlideEditors/ImageGallerySlideEditor/ImageGallerySlideEditor';
import { ImageViewerMode } from '../../../models/CommonEnums';
import InputBarEditor from '../../DataEntry/InputBar/InputBarEditor/InputBarEditor';
import { Prompt } from 'react-router-dom';
import MapWithAMarker from '../../maps/MapWithAMarker';
import { GOOGLE_MAPS_URL } from '../../../constants/paths';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import { isNullOrUndefined } from 'util';
import GeoLocationChooser, { DefaultGeoLocation, GeoLocation, UndefinedGeoLocation } from '../../Shared/GeoLocationChooser/GeoLocationChooser';
/**
 * This interface defines the props that will be passed into all slide editor components used by this modal. It is not
 * the props for this modal.
 *
 * If you use this modal for your slide editing component, then use these props in your editor component.
 */
export interface SlideEditorProps {
  currentUser: User;
  editSlide?: Slide;
  article: Article;
  position: number;
  modalType: string;
  // This is all handled from React's connect via dispatchToProps and does not need to be passed in
  closeModal: () => void;
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) => void;
  onSubmitSlide: (payload: SlideUpdateInfo) => Promise<void>;
}
export interface MediaSlideEditorProps extends SlideEditorProps {
  addMediaFileProcessingInfo: (slidefileProcessingInfoList: SlideFileProcessorInfo[]) => void;
}
export enum ModalOverlayState {
  None = 1,
  Header = 2,
  Footer = 3,
  All = 4,
  HeaderAndFooter = 5,
}
export enum QuestionType {
  Ok = 1,
  OkCancel = 2,
}
export enum QuestionResponse {
  Ok = 1,
  Cancel = 2,
}
export enum BackIconMode {
  Light = 1,
  Dark = 2,
  None = 3
}
export interface QuestionResponseInfo {
  questionId: string;
  questionResponse: QuestionResponse;
}
export interface QuestionInfo {
  questionId: string;
  title: string;
  okLabel?: string;
  cancelLabel?: string;
  questionType: QuestionType;
  location: ModalLocation;
}
export interface SlideEditorModalProps {

  validationError?: string;
  allowNativeResolution?: boolean;
  article: Article;
  // childArticleEditInfo?: ChildArticleEditInfo;
  backIconMode?: BackIconMode; // TODO: Refactor this
  currentUser: User;
  hideFooter: boolean;
  hideCommandBar?: boolean;
  displaySlideAttribution?: boolean;
  editorState: EditorState;
  loadingInfo?: LoadingInfo;
  modalOverlayState: ModalOverlayState;
  selectedItemIndex: number;
  hideActionContainer: boolean;
  showBackNavigation: boolean;
  showDefaultActions: boolean;
  showDeleteFile: boolean;
  slide: Slide;
  question?: QuestionInfo;
  position: number;
  tagList?: string[];
  theme?: any;

  // Child Sections that are customizable by hosted components
  createActions: ReactNode;
  headerActions: ReactNode;
  previewItems?: DraggableListItem[];

  // These 3 props are only set on SlideZero Editor (TEMP HACK)
  gideDescription?: string;
  gideDescriptionSlideId?: string;
  isSlideZero?: boolean;
  updateGideDescription?: (description: string) => void;
  // END SLIDE ZERO HACK

  onHashtagClicked?: () => void;
  closeModal: () => void;
  onAnswerQuestion?: (response: QuestionResponseInfo) => void;
  onCloseAllModals: () => void;
  onNavigateBack: () => void;
  onShowPreview: () => void;
  onSlideUpdated: (slide: Slide) => void;
  onSubmitSlide: (payload: SlideUpdateInfo) => Promise<void>;
  onCreate?: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  onDisplaySlideWidthSettings?: () => void;
  addMediaFileProcessingInfo?: (slideFileProcessorInfo: SlideFileProcessorInfo[]) => void;
  addTitleSlideProcessingInfo?: (titleSlideProcessorInfo: TitleSlideProcessorInfo[]) => void;
  addSlideGalleryFileProcessingInfo?: (gallerySlideFileProcessorInfo: GallerySlideFileProcessorInfo[]) => void;
  showAudioCaptionRecorder?: {};
  onShowAudioCaptionControls?: () => void;
  onHideAudioCaptionControls?: () => void;
}
export interface SlideEditorModalState {
  slideEditorQuickActions: SlideEditorComponent[];
  displaySettingsAction: boolean;
  questionModal?: QuestionModalInfo;
  displaySettings: boolean;
  slideWidthSettingsVisible: boolean;
  showAudioCaptionRecorder: boolean;
  imageWidth?: number;
  attribution?: string;
  showCreateActions: boolean;
  areSlideFilesAndCreateActionsOverlapping: boolean;
  saving: boolean;
  showGeotagMap: boolean;
  geoLocation?: GeoLocation;
}
export enum ModalLocation {
  TopLeft = 1,
  TopRight = 2,
  BottomLeft = 3,
  BottomRight = 4,
  Center = 5
}
export const DELETE_FILE = 'DELETE_FILE';
export const deleteFileQuestionInfo = (fileType: string): QuestionInfo => {
  return {
    questionId: DELETE_FILE,
    title: `Are you sure you want to delete this ${fileType} file?`,
    location: ModalLocation.TopRight,
    okLabel: 'Delete It',
    questionType: QuestionType.OkCancel
  };
}
export interface AudioFile {
  name: string;
  url: string;
  autoPlay: boolean;
  autoAdvance: boolean;
  duration?: number;
}
export enum EditorState {
  Preview = 1,
  Edit = 2,
  Add = 3,
  New = 4,
}
export enum ProgressIndicatorType {
  Determinate = 1,
  Indeterminate = 2,
}

export interface QuestionModalInfo {
  title: string;
  actions: SlideEditorAction[],
  location: ModalLocation,
  style: CSSProperties;
  className?: string;
}
export interface LoadingInfo {
  progressIndicatorType: ProgressIndicatorType,
  percentComplete?: number;
  message?: string;
}

export enum ActionLabelType {
  Button = 1,
  Label = 2,
  Link = 3,
}
export interface SlideEditorAction {
  image?: string;
  activeImage?: string;
  imageClassName?: string;
  className?: string;
  imageStyle?: any;
  label?: string;
  labelType?: ActionLabelType;
  action: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void);
  content?: any;
  isPrimary: boolean;
  displayCheck?: boolean;
}
export interface SlideEditorComponent {
  content: any;
}

export const getSlideForEditor = (slideType: string, position: number, displayType: number): SlideModel => {

  return {
    slideType: slideType,
    data: {
      audioCaption: null,
      files: [],
      caption: '',
      slideTypeSpecificSettings: {
        displayType: displayType,
      }
    },
    position
  }
}
export class SlideEditorModal extends Component<SlideEditorModalProps, SlideEditorModalState> {
  constructor(props: SlideEditorModalProps) {
    super(props);
    this.geotagLocationMap = React.createRef();
    this.state = {
      slideEditorQuickActions: [],
      displaySettingsAction: true,
      displaySettings: false,
      slideWidthSettingsVisible: false,
      showAudioCaptionRecorder: false,
      showCreateActions: false,
      areSlideFilesAndCreateActionsOverlapping: false,
      saving: false,
      showGeotagMap: false,
      geoLocation: props.slide.geotagLocation && props.slide.geotagLocation.coordinates.length >= 2 
        ? {
            lat: props.slide.geotagLocation.coordinates[0],
            lng: props.slide.geotagLocation.coordinates[1] 
          }
        : undefined, 
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.inspectAreSlideFilesAndCreateActionsOverlapping);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.inspectAreSlideFilesAndCreateActionsOverlapping);
  }

  componentDidUpdate(prevProps: SlideEditorModalProps) {

    if (this.props.showAudioCaptionRecorder !== prevProps.showAudioCaptionRecorder) {
      this.setState({ showAudioCaptionRecorder: this.props.showAudioCaptionRecorder ? true : false });
    }

    if (this.props.question && prevProps.question !== this.props.question) {
      const actions: SlideEditorAction[] = [];
      if (this.props.question.questionType === QuestionType.OkCancel) {
        actions.push({
          label: this.props.question.cancelLabel ? this.props.question.cancelLabel : 'Cancel',
          action: () => {
            this.closeQuestionModal();
            if (this.props.onAnswerQuestion) {
              this.props.onAnswerQuestion({ questionResponse: QuestionResponse.Cancel, questionId: (this.props.question as QuestionInfo).questionId })
            }
          },
          isPrimary: false
        })
      }
      actions.push({
        label: this.props.question.okLabel ? this.props.question.okLabel : 'Ok',
        action: () => {
          this.closeQuestionModal();
          if (this.props.onAnswerQuestion) {
            this.props.onAnswerQuestion({ questionResponse: QuestionResponse.Ok, questionId: (this.props.question as QuestionInfo).questionId });
          }
        },
        isPrimary: true
      });
      const questionModalInfo: QuestionModalInfo = {
        title: this.props.question.title,
        actions: actions,
        location: this.props.question.location,
        style: { width: '256px', height: '117px' },
      };

      this.displayQuestionModal(questionModalInfo);
    }

    if (this.props.displaySlideAttribution && prevProps.displaySlideAttribution !== this.props.displaySlideAttribution &&
      this.props.slide.data.files && this.props.slide.data.files[this.props.selectedItemIndex]) {
      this.setState({ attribution: this.props.slide.data.files[this.props.selectedItemIndex].attribution });
    }

    this.inspectAreSlideFilesAndCreateActionsOverlapping();
  }

  private inspectAreSlideFilesAndCreateActionsOverlapping = () => {
    const areSlideFilesAndCreateActionsOverlapping = areElementsOverlapping(this.slideFilesRef.current, this.createActionsRef.current);
    if (this.state.areSlideFilesAndCreateActionsOverlapping !== areSlideFilesAndCreateActionsOverlapping) {
      this.setState({ areSlideFilesAndCreateActionsOverlapping });
    }
  }
  private slideFilesRef = React.createRef<HTMLDivElement>();
  private createActionsRef = React.createRef<HTMLDivElement>();

  geotagLocationMap: React.RefObject<any>;

  private setInputBarHeight(input: any, value?: any) {
    setTimeout(() => {
      input.style.cssText = 'height:24px; padding:0';
      if (value) {
        input.style.cssText = 'height:' + value + 'px';
      } else {
        input.style.cssText = 'height:' + input.scrollHeight + 'px';
      }
    }, 0);
  }

  /**
   * Allows the child component to pass in a list of actions depending on the components context
   * which will be displayed across the top right hand corner of the SlideEditorModal. Allows the child component to dynamically
   * display different actions in (this) parent modal
   * @param slideEditorQuickActions - a list of SlideEditorAction objects
   * @param displaySettingsAction - boolean value indicating whether or not to display the All settings icon.
   * @param activeIndex - the index that is the currenlty selected quick action. This allows for highlighting of
   * the icon if necessary. When set the activeImage will be used if there is one instead of the image from the SlideEditorAction
   */
  public setQuickActions(slideEditorQuickActions: SlideEditorComponent[], displaySettingsAction: boolean, activeIndex: number): void {
    this.setState({
      slideEditorQuickActions: slideEditorQuickActions,
      displaySettingsAction: displaySettingsAction,
    });
  }

  setupBackgroundFileProcessing(slide: Slide, updatedSlide: Slide) {
    if(slide.slideType === 'GIDETITLE') {
      if(this.props.slide.data.processingId && this.props.slide.data.dataUrl) {
        const titleSlideProccessingInfo = {
          gideId: this.props.article.id,
          dataUrl: this.props.slide.data.dataUrl,
          processingId: this.props.slide.data.processingId,
        };
        this.props.addTitleSlideProcessingInfo &&
          this.props.addTitleSlideProcessingInfo(
            [titleSlideProccessingInfo]
          );
          const key = `TS_${titleSlideProccessingInfo.processingId}`;
          localforage.setItem(key, titleSlideProccessingInfo, (err: any, value: TitleSlideProcessorInfo) =>  {
            if(!isNil(err)) {
              const message = `Error adding titleSlideProccessingInfo with key - ${key}: ${err}: value: ${JSON.stringify(value)}`;
              this.props.showNotification({message: message, type: NotificationType.ERROR});
            }
          });
      }
    }


    const gallerySlideFileProcessingInfolist: GallerySlideFileProcessorInfo[] = [];
    const fileProcessingInfoList: SlideFileProcessorInfo[] = [];

    if(slide.data && slide.data.files) {
      slide.data.files.forEach((f: SlideFile) => {
        // ***  Find the files that need to be uploaded with the background process *********
        if ((f as MediaFile).processingId && !isNil((f as MediaFile).dataUrl)) {
          // Handle media files that need processing
          // Need to push the required information into the fileProcessingReducer

          // Both should always be true for newly added files
            fileProcessingInfoList.push({
              slideId: updatedSlide.id,
              slideFileId: f.id as string,
              processingId: (f as MediaFile).processingId as string,
              dataUrl: (f as MediaFile).dataUrl
            });
            // Delete the current ObjectURL
            if((f as MediaFile).dataUrl) {
              URL.revokeObjectURL((f as MediaFile).url as string);
            }
        } else if ((f as ImageGalleryFile).imageFiles) {
          (f as ImageGalleryFile).imageFiles.forEach(gf => {
            if(gf.processingId && gf.dataUrl) {
              gallerySlideFileProcessingInfolist.push({
                slideId: updatedSlide.id,
                slideFileId: f.id as string,
                processingId: gf.processingId as string,
                dataUrl: gf.dataUrl,
                galleryFileId: gf.id as string
              });
              if(gf.url) {
                URL.revokeObjectURL(gf.url as string);
              }
            }
          });
        }
        // ********************************************************************************* //
      });
    }

    if(fileProcessingInfoList.length > 0 && this.props.addMediaFileProcessingInfo) {
      this.props.addMediaFileProcessingInfo(fileProcessingInfoList);
      fileProcessingInfoList.forEach(slideFileProccessingInfo => {
        const key = `SMF_${slideFileProccessingInfo.processingId}`;
        localforage.setItem(key, slideFileProccessingInfo, (err: any, value: SlideFileProcessorInfo) => {
            if(!isNil(err)) {
              const message = `Error adding slideFileProccessingInfo with key - ${key}: ${err}: value: ${JSON.stringify(value)}`;
              this.props.showNotification({message: message, type: NotificationType.ERROR});
            }
          }
        );
      });
    }

    if(gallerySlideFileProcessingInfolist.length > 0 && this.props.addSlideGalleryFileProcessingInfo) {
      this.props.addSlideGalleryFileProcessingInfo(gallerySlideFileProcessingInfolist);
      gallerySlideFileProcessingInfolist.forEach(gallerySlideFileProcessingInfo => {
        const key = `IGMF_${gallerySlideFileProcessingInfo.processingId}`;
        localforage.setItem(key, gallerySlideFileProcessingInfo, (err: any, value: GallerySlideFileProcessorInfo) => {
            if(!isNil(err)) {
              const message = `Error adding slideGalleryMediaFileProcessorInfo with key - ${key}: ${err}: value: ${JSON.stringify(value)}`;
              this.props.showNotification({message: message, type: NotificationType.ERROR});
            }
          }
        );
      });
    }
  }
  async createSlide() {
    if (!this.props.slide) {
      alert('Slide is not set. Fatal Error. Exit dialog.')
      return;
    }
    if (this.props.validationError) {
      this.props.showNotification({
        type: NotificationType.ERROR,
        message: this.props.validationError,
      });
      return;
    }

    let payload: SlideUpdateInfo;
    const slide = {
      ...this.props.slide,
      allowComments: (this.props.slide as Slide).id
          ? this.props.slide.allowComments
          : this.props.article.allowSlideComments,
      allowQuestions: (this.props.slide as Slide).id
          ? this.props.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
      data: {
        ...this.props.slide.data,
        files: this.props.slide.data.files.map((f: SlideFile, index: number) => {

          if ((f as SlideReferenceFile).type === 'SLIDE'
            || (f as SlideReferenceFile).type === 'SLIDEFILE'
            || (f as SlideReferenceFile).type === 'GIDE'
            || (f as SlideReferenceFile).type === 'CHANNEL') {
            return prepareSlideFileForSave(f as SlideReferenceFile);
          } else if ((f as MediaFile).processingId) {
            // This means it is a new media file and the file needs to be processed.
            // set the url to undefined. The file processingId will be used later
            // once the saved slide is returned to handle placing it in the reducer
            // and local storage to get uploaded in the background.
            return {
              ...f,
              url: undefined, // Don't want to send the DataUrl to the server
              dataUrl: undefined
            };
          } else if ((f as ImageGalleryFile).imageFiles) {
              return {
                ...f,
                imageFiles: (f as ImageGalleryFile).imageFiles.map(igf => {
                  if(igf.processingId) {
                    return {
                      ...igf,
                      dataUrl: undefined,
                      url: undefined
                    };
                  } else {
                    return igf;
                  }
                }),
              }
          } else {
            return f;
          }
        })
      }
    }

    if (this.props.slide.slideType !== "GIDETITLE") {
      if ((this.props.slide as Slide).id) {
        payload = await agent.Slides.update((slide as Slide).id, slide);
      } else {
        payload = await agent.Slides.create(this.props.article, slide);
      }
    } else {
      payload = {
        slide: this.props.slide.data.processingId
          ? {
              ...this.props.slide,
              data: {
                ...this.props.slide.data,
                image: undefined,
                dataUrl: undefined,
              }
          }
          :this.props.slide,
        tagList: this.props.tagList,
        preventScrollToSlide: true,
        description: this.props.gideDescription,
        descriptionSlideId: this.props.gideDescriptionSlideId
      };
    }

    // Get a reference to the slide before it was saved to the database
    const s = this.props.slide as Slide;

    // Now update the slide based on whether it is
    // 1. a reference to another slide
    // 2. a media file that needs to be processed



    const updatedPayload = {
      ...payload,
      slide: {
        ...payload.slide,
        data: {
          ...payload.slide.data,
          files: payload.slide.data.files.map((f: SlideFile) => {
            if (contains((f as SlideReferenceFile).type, ['SLIDEFILE','GIDE', 'CHANNEL', 'SLIDE'] )) {
              const slideFile = s.data.files.find((sourceFile: SlideFile) => sourceFile.id === f.id);
              if (slideFile) { // Should always be true
                return {
                  ...f,
                  // Here we want to put the reference information back on the slide. No need
                  // to have this done server side since this information is already available here.
                  slideFileReference: slideFile.slideFileReference
                };
              } else {
                return f;
              }
            } else {
              return f;
            }
          })
        }
      }
    };
    this.setState({saving: true}, async () => {
      await this.props.onSubmitSlide({ ...updatedPayload });
      // This needs to go after the slide is saved
      this.setupBackgroundFileProcessing(this.props.slide, payload.slide);
      this.props.closeModal();
    });    
  }

  /**
   * Displays a modal with a question and a dynamic list of actions to choose from. This is called from
   * a child component using this modal.
   * @param questionModalInfo
   */
  displayQuestionModal(questionModalInfo: QuestionModalInfo) {
    this.setState({ questionModal: questionModalInfo });
  }

  /**
   * Close the question modal
   */
  closeQuestionModal() {
    this.setState({ questionModal: undefined });
  }
  updateSlideSettings(settings: any) {
    this.props.onSlideUpdated(updateSlideSettings(this.props.slide, settings));
  }
  onslideTypeSpecificSettingsChanged(settings: any) {
    const slide = {
      ...this.props.slide,
      data: {
        ...this.props.slide.data,
        slideTypeSpecificSettings: settings,
      }
    };
    this.props.onSlideUpdated(slide);
  }
  closeSettings() {
    this.setState({ displaySettings: false });
  }
  displaySlideWidthSettngs() {
    if (this.props.onDisplaySlideWidthSettings) {
      this.props.onDisplaySlideWidthSettings();
    }else {
      this.setState({ slideWidthSettingsVisible: true })
    }
  }
  hideSlideWidthSettings() {
    this.setState({ slideWidthSettingsVisible: false });
  }
  displayAllSettings() {
    this.setState({ displaySettings: true })
  }
  getModalLocationCssClass(location: ModalLocation) {
    switch (location) {
      case ModalLocation.TopLeft:
        return 'topLeft';
      case ModalLocation.TopRight:
        return 'topRight';
      case ModalLocation.BottomLeft:
        return 'bottomLeft';
      case ModalLocation.BottomRight:
        return 'bottomRight';
      case ModalLocation.Center:
        return 'center';
      default:
        return 'topLeft';
    }
  }
  private showAudioCaptionControls(): void {
    this.setState({ showAudioCaptionRecorder: true });
    if (this.props.onShowAudioCaptionControls) {
      this.props.onShowAudioCaptionControls();
    }
  }
  private hideAudioCaptionControls(): void {
    this.setState({ showAudioCaptionRecorder: false, questionModal: undefined });
    if (this.props.onHideAudioCaptionControls) {
      this.props.onHideAudioCaptionControls();
    }
  }

  onCancelGeotagSlide = (): void => {
    this.setState({ 
      showGeotagMap: false,
      geoLocation: this.props.slide.geotagLocation && this.props.slide.geotagLocation.coordinates.length >= 2 
      ? {
          lat: this.props.slide.geotagLocation.coordinates[0],
          lng: this.props.slide.geotagLocation.coordinates[1] 
        }
      : undefined
    });     
  }

  onSubmitGeotagSlide = (): void => {   
    const slide = {
      ...this.props.slide,
      geotagLocation: {
        type: 'Point',
        coordinates: [this.state.geoLocation!.lat, this.state.geoLocation!.lng]
      }
    }
    this.props.onSlideUpdated(slide);
    this.setState({ showGeotagMap: false});
  }

  getModalPointerTriangleLocationCssClass(location: ModalLocation) {
    switch (location) {
      case ModalLocation.TopLeft:
        return 'topLeftModalPointerTriangle';
      case ModalLocation.TopRight:
        return 'topRightModalPointerTriangle';
      case ModalLocation.BottomLeft:
        return 'bottomLeftModalPointerTriangle';
      case ModalLocation.BottomRight:
        return 'bottomRightModalPointerTriangle';
      case ModalLocation.Center:
        return 'centerModalPointerTriangle';
      default:
        return 'topLeft';
    }
  }
  updatePreviewItems(items: DraggableListItem[]) {
    const reorderedFiles: SlideFile[] = [];
    items.forEach((item: DraggableListItem) => {
      const slideFile = this.props.slide.data.files.find((i: SlideFile) => item.id === i.id);
      reorderedFiles.push(slideFile);
    });

    const slide = {
      ...this.props.slide,
      data: {
        ...this.props.slide.data,
        files: reorderedFiles,
      }
    };
    this.props.onSlideUpdated(slide as Slide);
  }
  closeAllModals = () => {
    this.setState({ attribution: undefined })
    this.props.onCloseAllModals();
  }
  updateSlideItemCaption = (caption: string): void => {
    if (this.props.selectedItemIndex > -1) {
      const slide = {
        ...this.props.slide,
        data: {
          ...this.props.slide.data,
          files: this.props.slide.data.files.map((f: SlideFile, index: number) => {
            if (index === this.props.selectedItemIndex) {
              return {
                ...f,
                caption: caption
              }
            }
            else {
              return f;
            }
          })
        }
      }
      this.props.onSlideUpdated(slide);
    } else {
      const slide = {
        ...this.props.slide,
        data: {
          ...this.props.slide.data,
          caption: caption
        }
      }
      this.props.onSlideUpdated(slide);
    }
  }


  public render() {
    const slideWidth = this.props.slide ? this.props.slide.width : '';
    const selectedSlideFile: SlideFile = this.props.selectedItemIndex > -1 && this.props.slide.data.files.length > this.props.selectedItemIndex
      ? this.props.slide.data.files[this.props.selectedItemIndex]
      : undefined

    const caption =
      this.props.isSlideZero
        ? this.props.gideDescription
        : selectedSlideFile
          ? selectedSlideFile.caption
            ? selectedSlideFile.caption
            : ''
          : this.props.slide.data.caption;

    const audioCaption = selectedSlideFile
      ? selectedSlideFile.audioCaption
      : this.props.slide.data.audioCaption;

    const attribution = selectedSlideFile
      ? selectedSlideFile.attribution
      : undefined;

    const location = selectedSlideFile
      ? selectedSlideFile.location
      : undefined;

    const hashTag = selectedSlideFile
      ? selectedSlideFile.location
      : undefined;

    const confirmClose = () => {
      if(this.props.slide?.data?.files?.length > 0) {
        respondToQuestion('Close without saving', ModalLocation.TopLeft, 'Yes', () => this.setState({saving: true}, () => this.props.closeModal()));
      } else {
        this.props.closeModal();
      }
    }

    const respondToQuestion = (title: string, location: ModalLocation, yesLabel: string, yesAction: () => void) => {
      this.displayQuestionModal({
        title: title,
        location: location,
        style: { width: '256px', height: '117px' },
        actions: [
          {
            label: 'Cancel',
            action: () => {
              this.closeQuestionModal();
            },
            isPrimary: false,
            className: 'TEXTSUBTITLEorangeleft',
          },
          {
            label: yesLabel,
            action: yesAction,
            isPrimary: true,
            displayCheck: true,
            imageClassName: 'whites-normal-1000-svg',
          },
        ],
      });
    }

    const submitSlide = () => {
      if (this.props.slide.data.files.length > 0) {
        this.createSlide();
      } else {
        this.props.showNotification({
          message: `You must add at least one ${this.props.slide.slideType.toLowerCase()}`,
          type: NotificationType.ERROR,
        });
      }
    };
    
    return (
      <>
      <Prompt
           when={
             this.props.slide?.data?.files?.length > 0 
             && 
             !this.state.saving
             && 
              (
                !this.props.isSlideZero 
                || 
                (
                  this.props.isSlideZero 
                  && 
                  (
                    hasValue(this.props.slide?.data?.image) === true
                    || 
                    hasValue(this.props.slide?.data?.title) === true
                  )
                )
              )              
          }
           message={location => `Are you sure you want to go to ${location.pathname}`} />
      <Modal
        className={classNames('slideEditorModal', slideWidth, (window as any).cordova ? 'cordova' : undefined)}
        closeOnEscape={true}
        onClose={confirmClose}
        size="fullscreen"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        {this.state.displaySettings === false && (
          <Modal.Content
            className={`slideEditorModalContent ${slideWidth} ${
              this.props.theme && this.props.theme.mode && this.props.editorState !== EditorState.Preview ? this.props.theme.mode : 'light'
            }`}
          >
            {this.props.hideCommandBar !== true && (
              <SlideEditorCommandBar
                showBackNavigationButton={this.props.showBackNavigation}
                showDefaultActions={this.props.showDefaultActions && this.props.editorState !== EditorState.New}
                showDeleteFile={this.props.showDeleteFile && this.props.slide.data.files.length > 1}
                backIconMode={this.props.backIconMode ? this.props.backIconMode : BackIconMode.Light}
                slideWidth={this.props.slide && this.props.slide.width}
                expandSettingsButton={this.state.displaySettings}
                disabled={this.state.slideWidthSettingsVisible}
                onDisplaySettings={() => this.displayAllSettings()}
                onNavigateBack={() => this.props.onNavigateBack()}
                onExit={confirmClose}
                onDisplaySlideWidthSettings={() => this.displaySlideWidthSettngs()}
                onDelete={() => {
                  // Delete the selected file.
                  const action = () => {
                    const slide = {
                      ...this.props.slide,
                      data: {
                        ...this.props.slide.data,
                        files: reject((f: SlideFile) => f.id === selectedSlideFile.id, this.props.slide.data.files),
                      },
                    };
                    this.closeQuestionModal();
                    this.props.onSlideUpdated(slide);
                  }
                  respondToQuestion(`Are you sure you want to delete slide no. ${this.props.selectedItemIndex + 1} ?`, ModalLocation.TopRight, 'Delete it', action);                  
                }}
              >
                {this.props.headerActions}
              </SlideEditorCommandBar>
            )}
            {this.props.children}
            {this.props.hideActionContainer !== true && (
              <div
                className={`slideEditorModalFooterActionContainer ${
                  this.props.theme && this.props.theme.mode && this.props.editorState !== EditorState.Preview ? this.props.theme.mode : ''
                }`}
              >
                {this.props.editorState !== EditorState.Add && (
                  <div
                    className="slideFileItemSelectionOptions"
                    ref={this.slideFilesRef}
                    style={{
                      visibility:
                        !this.state.showCreateActions || !this.state.areSlideFilesAndCreateActionsOverlapping ? 'visible' : 'hidden',
                    }}
                  >
                    {this.props.slide.data.files.length > 1 && (
                      <div className="previewIconContainer">
                        <div className="previewIconBackground">
                          <CircleIconButton
                            alt="preview"
                            onClick={() => {
                              this.props.onShowPreview();
                            }}
                            backgroundColor={
                              this.props.editorState === EditorState.Preview
                                ? 'var(--COLOR-PRIMARY-500)'
                                : this.props.theme && this.props.theme.mode && this.props.theme.mode === 'dark'
                                ? 'var(--WHITES-NORMAL-150)'
                                : 'white'
                            }
                            iconCssClass={
                              this.props.editorState === EditorState.Preview
                                ? 'whites-normal-1000-svg'
                                : this.props.theme && this.props.theme.mode && this.props.theme.mode === 'dark'
                                ? 'whites-normal-900-svg'
                                : 'color-primary-500-svg'
                            }
                            image="/icons/creationprocess/display/display.svg"
                          />
                        </div>
                        <div
                          className={`previewLabel${this.props.editorState === EditorState.Preview ? ' active' : ''}${
                            this.props.theme && this.props.theme.mode ? ' ' + this.props.theme.mode : ''
                          }`}
                        >
                          <span>Preview</span>
                        </div>
                      </div>
                    )}
                    <DragReorderableList
                      onItemsReordered={this.updatePreviewItems.bind(this)}
                      items={this.props.previewItems ? this.props.previewItems : []}
                    />
                  </div>
                )}
                {this.props.editorState === EditorState.Add && (
                  // This is for spacing. DO NOT DELETE
                  <div></div>
                )}
                {this.state.showCreateActions && (
                  <div className="createActions" ref={this.createActionsRef}>
                    {this.props.createActions}
                    <CircleIconButton
                      style={{ margin: '5px' }}
                      alt="close create actions"
                      onClick={() => this.setState({ showCreateActions: false })}
                      backgroundColor="white"
                      iconCssClass="color-blue-100-svg"
                      image="/icons/nav/exit/main.svg"
                    />
                  </div>
                )}
                {!this.state.showCreateActions && (
                  <div className="addSlideItemToList">
                    <CircleIconButton
                      style={{ margin: '5px' }}
                      alt={`add ${this.props.slide.slideType}`}
                      onClick={() => {
                        if (this.props.onCreate) {
                          this.props.onCreate();
                        } else {
                          this.setState({ showCreateActions: true });
                        }
                      }}
                      backgroundColor="white"
                      iconCssClass="color-blue-100-svg"
                      image="/icons/nav/plusicon.svg"
                    />
                  </div>
                )}
                {(this.props.modalOverlayState === ModalOverlayState.Footer ||
                  this.props.modalOverlayState === ModalOverlayState.HeaderAndFooter ||
                  this.props.modalOverlayState === ModalOverlayState.All) && (
                  <div className="footerOverlay" onClick={this.props.onCloseAllModals}></div>
                )}
              </div>
            )}
            {this.state.showGeotagMap && (
              <div className="locationTag">
                <div className="locationTitle">Tag a Location</div>
                {this.state.showGeotagMap && (
                  <GeoLocationChooser
                    showNotification={this.props.showNotification}
                    location={this.state.geoLocation 
                      ? {
                        lat: this.state.geoLocation.lat, lng: this.state.geoLocation.lng
                      } 
                      : undefined
                    }
                    onLocationChanged={(location: GeoLocation) => {
                      this.setState({
                        geoLocation: location
                      });
                    }}
                    key={this.props.article.id}
                  />
                )}
                {this.state.geoLocation && (
                  <div className="locationLatLng">{`Latitude: ${this.state.geoLocation.lat.toFixed(5)}  Longitude: ${this.state.geoLocation.lng.toFixed(5)}`}</div>
                )}
                
                <div className="geotagButton">
                  <RoundedCornerButton
                    label="Cancel"
                    style={{ backgroundColor: '#fafafa' }}
                    labelColor="grey"
                    onClick={this.onCancelGeotagSlide}
                  />
                  <RoundedCornerButton
                    label="Confirm"
                    style={{
                      backgroundColor: 'var(--COLOR-PRIMARY-700)',
                      marginLeft: '12px',
                      width: '105px',
                    }}
                    labelColor="white"
                    icon={<icons.ContentAlteration_Check_Main color="white" />}
                    imagePosition="right"
                    onClick={this.onSubmitGeotagSlide}
                  />
                </div>
              </div>
            )}
            {this.state.showAudioCaptionRecorder && (
              <AudioRecorder
                // displayQuestionModal={this.displayQuestionModal.bind(this)}
                // closeQuestionModal={this.closeQuestionModal.bind(this)}
                onError={(message: string) => this.props.showNotification({message: message, type: NotificationType.ERROR})}
                theme={this.props.theme && this.props.theme.mode}
                audioFile={audioCaption}
                displayAutoPlayControls={false}
                saveAudioRecording={(audioFile: AudioFile) => {
                  if (selectedSlideFile) {
                    const slide = {
                      ...this.props.slide,
                      data: {
                        ...this.props.slide.data,
                        files: this.props.slide.data.files.map((f: SlideFile, index: number) => {
                          if (f.id === selectedSlideFile.id) {
                            return {
                              ...f,
                              audioCaption: audioFile,
                            };
                          } else {
                            return f;
                          }
                        }),
                      },
                    };
                    this.props.onSlideUpdated(slide);
                  } else {
                    const slide = {
                      ...this.props.slide,
                      data: {
                        ...this.props.slide.data,
                        audioCaption: audioFile,
                      },
                    };
                    this.props.onSlideUpdated(slide);
                  }

                  this.hideAudioCaptionControls();
                }}
                closeAudioRecording={() => {
                  this.hideAudioCaptionControls();
                }}
                onDeleteAudioRecording={(audioFile: AudioFile) => {
                  const question = {
                    title: `Are you sure you want to delete this audio caption`,
                    location: ModalLocation.Center,
                    style: { width: '256px', height: '117px' },
                    actions: [
                      {
                        label: 'Cancel',
                        action: () => {
                          this.closeQuestionModal();
                        },
                        isPrimary: false,
                      },
                      {
                        label: 'Delete it',
                        action: () => {
                          this.hideAudioCaptionControls();
                          if (selectedSlideFile) {
                            const slide = {
                              ...this.props.slide,
                              data: {
                                ...this.props.slide.data,
                                files: this.props.slide.data.files.map((f: SlideFile, index: number) => {
                                  if (f.id === selectedSlideFile.id) {
                                    return {
                                      ...f,
                                      audioCaption: undefined,
                                    };
                                  } else {
                                    return f;
                                  }
                                }),
                              },
                            };
                            this.props.onSlideUpdated(slide);
                          } else {
                            const slide = {
                              ...this.props.slide,
                              data: {
                                ...this.props.slide.data,
                                audioCaption: undefined,
                              },
                            };
                            this.props.onSlideUpdated(slide);
                          }
                          // this.setState({
                          //   audioFile: undefined,
                          //   audio: undefined,
                          //   audioCaptionRecordingState: AudioCaptionRecordingState.Waiting
                          // });
                          // this.deleteAudioRecording();
                        },
                        isPrimary: true,
                        displayCheck: true,
                      },
                    ],
                  };
                  this.displayQuestionModal(question);
                }}
              />
            )}
          </Modal.Content>
        )}
        {this.state.displaySettings && (
          <Modal.Content
            className={`slideEditorModalContent ${
              this.props.theme && this.props.theme.mode && this.props.editorState !== EditorState.Preview ? this.props.theme.mode : 'light'
            }`}
          >
            <Modal.Description className="flexRowSpaceBetween" style={{ padding: '7px' }}>
              <icons.Nav_Exit_Main
                color={this.props.backIconMode === BackIconMode.Dark ? 'var(--WHITES-NORMAL-900)' : 'var(--COLOR-SECONDARY-500)'}
                className="hoverIcon"
                onClick={this.closeSettings.bind(this)}
                xlinkTitle="close dialog"
              />
            </Modal.Description>
            <div className="settingsPanel">
              <SlideSettings
                hideTemplateIndicator={true}
                settings={getSlideSettings(this.props.slide, 'TODO')}
                slideTypeSpecificSettings={
                  this.props.slide && this.props.slide.data.slideTypeSpecificSettings ? this.props.slide.data.slideTypeSpecificSettings : {}
                }
                slideType={(this.props.slide as Slide).slideType}
                allowNativeResolutionSetting={this.props.allowNativeResolution}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onslideTypeSpecificSettingsChanged={this.onslideTypeSpecificSettingsChanged.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </div>
          </Modal.Content>
        )}

        <Modal.Actions
          className={`preventSemanticActionStyle ${
            this.props.theme && this.props.theme.mode && this.props.editorState !== EditorState.Preview ? this.props.theme.mode : ''
          }`}
        >
          {this.props.hideFooter !== true && (
            <div
              className={`Footer ${
                this.props.theme && this.props.theme.mode && this.props.editorState !== EditorState.Preview ? this.props.theme.mode : ''
              }`}
            >
              <span className={classNames("flexRowFullCenter", "footerMenu", this.props.isSlideZero ? 'slideZero' : undefined)}>
                <div style={{ position: 'relative', marginRight: '5px', marginBottom: '7px' }}>
                  {/* <GideImage
                    className="pointer inputBarMenuButton smallRightMargin"
                    onClick={() => this.setState({ displaySettings: true })}
                    src="/icons/nav/settings.svg"
                    alt="Settings"
                  /> */}
                </div>
                <InputBarEditor
                  placeholderText={
                    this.props.slide.slideType === 'GIDETITLE'
                      ? 'Add a gide description'
                      : this.props.selectedItemIndex === -1 || this.props.slide.data.files.length < 2
                        ? `Add a slide caption`
                        : getSlideFileCaptionPlaceholder(this.props.slide.slideType)


                  }
                  inputBarValue={caption}
                  displayElipsisOnLostFocus={true}
                  onChange={(value: string) => {
                    if(this.props.isSlideZero) {
                      this.props.updateGideDescription && this.props.updateGideDescription(value);
                    } else {
                      this.updateSlideItemCaption(value);
                    }
                  }}
                  onAcceptInput={submitSlide}
                  onInputEditorFocusChanged={noop}
                >
                  <>
                    {this.props.isSlideZero && (
                      <icons.ContentAlteration_Hashtag
                        color='var(--COLOR-SECONDARY-500)'
                        style={{marginRight: '11px', marginBottom: '5px', cursor: 'pointer'}}
                        onClick={() => this.props.onHashtagClicked && this.props.onHashtagClicked()}
                      />
                    )}
                    {<icons.SlideType_Audio_Mic
                        color={audioCaption ? 'var(--COLOR-BLUE-100)' : 'var(--COLOR-SECONDARY-500)'}
                        style={{ marginRight: '11px', marginBottom: '5px', cursor: 'pointer' }}
                        onClick={() => this.showAudioCaptionControls()}
                      />
                    }
                    {
                      <icons.SlideType_Location_Main
                        color={audioCaption ? 'var(--COLOR-BLUE-100)' : 'var(--COLOR-SECONDARY-500)'}
                        style={{ marginRight: '11px', marginBottom: '5px', cursor: 'pointer' }}
                        onClick={() => {
                          this.setState({showGeotagMap: true})
                        }}
                      />
                    }
                  </>
                </InputBarEditor>
                <span className="flexRowFlexNormal slideNumberSlideCreateContainer" style={{ marginLeft: '5px' }}>
                  <span className="slideNumberCreate">{this.props.position + 1}</span>
                  <GideImage
                    style={{ height: '34px', width: '34px', marginBottom: '3px' }}
                    className={`pointer`}
                    onClick={submitSlide}
                    src="/icons/content-alteration/input-bar/slide/finalise.svg"
                    alt={`Create or Update Slide`}
                  />
                </span>
              </span>
              {(this.props.modalOverlayState === ModalOverlayState.Footer ||
                this.props.modalOverlayState === ModalOverlayState.HeaderAndFooter ||
                this.props.modalOverlayState === ModalOverlayState.All) && (
                <div className="footerOverlay" onClick={this.props.onCloseAllModals}></div>
              )}
            </div>
          )}
        </Modal.Actions>
        {this.state.questionModal && (
          <div className={`messageDialogContainer${this.state.questionModal ? '' : ' inactive'}`} onClick={this.props.onCloseAllModals}>
            <div className={this.getModalPointerTriangleLocationCssClass(this.state.questionModal.location)} />

            <div
              className={`messageDialog ${this.getModalLocationCssClass(this.state.questionModal.location)}`}
              style={this.state.questionModal.style}
            >
              <span className="messageDialogTitle">{this.state.questionModal.title}</span>
              <div className="messageDialogActions">
                {this.state.questionModal.actions.map((slideEditorAction: SlideEditorAction, i: number) => {
                  return (
                    <div
                      className={`messageDialogAction${slideEditorAction.isPrimary ? ' primary' : ''}
                      ${slideEditorAction.labelType === ActionLabelType.Button ? ' actionButton' : ''}
                      ${slideEditorAction.className ? slideEditorAction.className : ''}`}
                      key={`${v4()}${i}`}
                      onClick={slideEditorAction.action}
                    >
                      <span>{slideEditorAction.label ? slideEditorAction.label : 'Missing Label'}</span>
                      {slideEditorAction.isPrimary && slideEditorAction.displayCheck === true && (
                        <GideImage
                          className={slideEditorAction.imageClassName ? slideEditorAction.imageClassName : ''}
                          onClick={slideEditorAction.action}
                          src="/icons/content-alteration/check/main.svg"
                          alt={slideEditorAction.label ? slideEditorAction.label : 'Missing Label'}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {(this.state.slideWidthSettingsVisible || this.props.displaySlideAttribution) && (
          <div className={`slideWidthSettingsContainer`} onClick={this.closeAllModals}>
            <div className="slideWidthPointerTriangle"></div>
            <div
              className={`slideWidthSettingsContent${
                this.state.slideWidthSettingsVisible &&
                this.props.slide.slideType === 'IMAGE' &&
                this.props.slide.data.slideTypeSpecificSettings.displayType === ImageViewerMode.Carousel
                  ? ' displayHeight'
                  : ''
              }`}
            >
              <div className="slideWidthSettings">
                {this.state.slideWidthSettingsVisible && (
                  <div className="slideWidth">
                    <span>Slide width</span>
                    <Dropdown
                      className="slideWidthSettingDropdown"
                      value={this.props.slide ? this.props.slide.width : undefined}
                      placeholder="Select User(s)"
                      search
                      fluid
                      selection
                      closeOnChange
                      options={[
                        { key: 'NORMAL', text: 'Normal width', value: 'NORMAL' },
                        { key: 'HALF', text: 'Half screen', value: 'HALF' },
                        { key: 'EDGE', text: 'Edge to edge', value: 'EDGE' },
                      ]}
                      onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                        if (this.props.slide) {
                          const slide = {
                            ...this.props.slide,
                            width: data.value as string,
                            data: {
                              ...this.props.slide.data,
                            },
                          };
                          this.props.onSlideUpdated(slide);
                        }
                      }}
                    />
                  </div>
                )}
                {this.state.slideWidthSettingsVisible && (
                  <div className={`contentWidth`}>
                    <span>{`${this.props.slide ? this.props.slide.slideType.toLowerCase() : 'content'}`}</span>
                    <Input
                      label={{ basic: true, content: '%' }}
                      labelPosition="right"
                      type="number"
                      className="slideWidthSettingDropdown"
                      value={this.props.slide && this.props.slide.widthPercentage}
                      placeholder="content width"
                      onChange={(event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
                        if (this.props.slide) {
                          let widthPercentage = parseInt(data.value, 10);
                          if (widthPercentage > 100) {
                            widthPercentage = 100;
                          }
                          const slide = {
                            ...this.props.slide,
                            widthPercentage: widthPercentage,
                            data: {
                              ...this.props.slide.data,
                            },
                          };
                          this.props.onSlideUpdated(slide);
                        }
                      }}
                    />
                  </div>
                )}
                {this.props.displaySlideAttribution && (
                  <div
                    className="slideAttribution"
                    onClick={(e: React.SyntheticEvent<HTMLElement>) => {
                      e.stopPropagation();
                    }}
                  >
                    <span>
                      Attribute this {this.props.slide.slideType.toLowerCase()} to : <span className="slideAttributionAsterick">*</span>
                    </span>
                    <Input
                      type="text"
                      className="slideAttributionText"
                      value={this.state.attribution}
                      placeholder={`attribute this ${this.props.slide.slideType.toLowerCase()} to ...`}
                      onChange={(event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
                        this.setState({ attribution: data.value });
                      }}
                    />
                  </div>
                )}
              </div>
              {this.state.slideWidthSettingsVisible &&
                this.props.slide.slideType === 'IMAGE' &&
                this.props.slide.data.slideTypeSpecificSettings.displayType === ImageViewerMode.Carousel && (
                  <div className="slideWidthSettings">
                    {/* spacer */}
                    <span style={{ width: '50%' }}></span>
                    <div className={`contentHeight`}>
                      <span>{`${this.props.slide ? '' /*this.props.slide.slideType.toLowerCase()*/ : ''} max content height`}</span>
                      <Input
                        type="number"
                        className="slideWidthSettingDropdown"
                        value={this.props.slide && this.props.slide.maxHeight}
                        placeholder="slide content height"
                        onChange={(event: React.SyntheticEvent<HTMLElement>, data: InputOnChangeData) => {
                          if (this.props.slide) {
                            const slide = {
                              ...this.props.slide,
                              maxHeight: data.value,
                              data: {
                                ...this.props.slide.data,
                              },
                            };
                            this.props.onSlideUpdated(slide);
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              <div className="slideSettingsActions">
                <div
                  className={`messageDialogAction actionButton actionButton}`}
                  onClick={() => {
                    if (this.state.slideWidthSettingsVisible) {
                      this.hideSlideWidthSettings();
                    } else {
                      this.props.onCloseAllModals();
                    }
                  }}
                >
                  <span>Cancel</span>
                </div>
                <div
                  className={`messageDialogAction primary actionButton}`}
                  onClick={() => {
                    if (this.state.slideWidthSettingsVisible) {
                      this.hideSlideWidthSettings();
                    } else if (this.props.displaySlideAttribution) {
                      if (this.props.selectedItemIndex > -1) {
                        const slide = {
                          ...this.props.slide,
                          data: {
                            ...this.props.slide.data,
                            files: this.props.slide.data.files.map((f: SlideFile, index: number) => {
                              if (index === this.props.selectedItemIndex) {
                                return {
                                  ...f,
                                  attribution: this.state.attribution,
                                };
                              } else {
                                return f;
                              }
                            }),
                          },
                        };
                        this.props.onSlideUpdated(slide);
                      } else {
                        console.log('Slide attribution not yet supported.');
                        this.props.onCloseAllModals();
                      }
                    }
                  }}
                >
                  <span>Done</span>
                  <GideImage
                    onClick={this.hideSlideWidthSettings.bind(this)}
                    className="whites-normal-1000-svg"
                    src="/icons/content-alteration/check/main.svg"
                    alt={'Done'}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {this.props.modalOverlayState === ModalOverlayState.All && <div className="overlayAll" onClick={this.props.onCloseAllModals}></div>}
        {this.props.loadingInfo && (
          <div className="loadingOverlay">
            <div className="progressIndicator">
              <ProgressBar indicatorWidth={30} />
            </div>
            <span className="loadingMessage">{this.props.loadingInfo.message}</span>
          </div>
        )}
      </Modal>
      </>
    );
  }

}
export default withTheme(SlideEditorModal);
