import { ToasterMessageInfo } from './../models/ToasterMessageInfo';
import { IconProps } from './../assets/icons/index';
import { EmbedMediaSource } from './../models/EmbedMediaSource';
import { ArticleSummary } from './../models/ArticleSummary';
import { SlideFileReference } from './../models/SlideFileReference';
import { ChildArticleType, ChildArticleDetail } from './../models/ArticleLayoutEnum';
import { Slide } from './../models/Slide';
import { GideGroup } from './../models/GideEnum';
import { User } from './../models/User';
import { Article } from './../models/Article';
import { isNil, isEmpty, any, contains } from 'ramda';
import { isNullOrUndefined } from 'util';
import moment from 'moment';
import { HeaderLevel, NotificationType, HOST } from '../constants/strings';
import agent from '../agent';
import { getChildArticleTypeName } from '../models/ArticleLayoutEnum';
import { SlideReferenceFile } from '../models/SlideFile';
import { TextKeyValue } from '../models/KeyValue';
import { DurationUnit } from '../models/ExpirationSettings';
import { SlideRange } from '../models/SlideRange';
import icons from '../assets/icons';
import { SearchCriteria } from '../components/Header/GidesAppHeader/GidesAppHeader';
import { API_ROOT } from '../constants/paths';
import { History } from 'history';
import loadImage, { LoadImageCallback } from 'blueimp-load-image';
export const storyTellingImage = 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/7402810-visual-story-telling.png';
export const unitTimeOptions: TextKeyValue<DurationUnit, DurationUnit>[] = [
  { key: DurationUnit.Year, value: DurationUnit.Year, text: 'Years' },
  { key: DurationUnit.Month, value: DurationUnit.Month, text: 'Months' },
  { key: DurationUnit.Week, value: DurationUnit.Month, text: 'Weeks' },
  { key: DurationUnit.Day, value: DurationUnit.Day, text: 'Days' },
  { key: DurationUnit.Hour, value: DurationUnit.Hour, text: 'Hours' },
  { key: DurationUnit.Minute, value: DurationUnit.Minute, text: 'Minutes' },
  { key: DurationUnit.Second, value: DurationUnit.Second, text: 'Seconds' },
];
export const hasValue = (value: any): boolean => {
  return (value && !isNil(value) && !isEmpty(value) && value !== undefined) === true;
};

export const getNotificationTitle = (type: NotificationType): string => {
  if (type === NotificationType.INFO) {
    return 'Information';
  }
  if (type === NotificationType.WARNING) {
    return 'Warning';
  }
  if (type === NotificationType.ERROR) {
    return 'Error';
  }
  if (type === NotificationType.MESSAGE) {
    return 'Alert';
  }
  if (type === NotificationType.SUCCESS) {
    return 'Success';
  }
  return 'Invalid Type';
};

export const isValidIpAddress = (string: string) => {
  const expression = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
  const isValid = expression.test(string);
  return isValid;
}

  /*
   * getCustomDomain
   * This function is needed to differentiate between custom domains and known domains we host the site on.
   * If the current host (domain in the user's URL bar) is not recognized, then
   * it's should be looked up in Channel instances to see which website channel should be loaded.
   * The direct CloudFront urls are also listed here because in a few cases we want to load dev or qa from the direct cloudfront URLs:
   * reasons being 1) one (now referred to as legacy) was detached from a host a while back so that it could still be
   * viewed but the domain could be used for something else. 2) The new QA environment needs a URL, which will be fyilm.com but
   * the DNS hasn't propagated yet and the environment needed to be tested.
   */
export const getCustomDomain = (): string | undefined => {
  let hostname = window.location.hostname;
  if (
    hostname !== 'gides.com' &&
    hostname !== 'www.gides.com' &&
    hostname !== 'gidesbeta.com' &&
    hostname !== 'www.gidesbeta.com' &&
    hostname !== 'latett.net' &&
    hostname !== 'www.latett.net' &&
    hostname !== 'fyilm.com' &&
    hostname !== 'www.fyilm.com' &&
    hostname !== 'slideswiper.com' &&
    hostname !== 'www.slideswiper.com' &&
    hostname !== 'dyvvee.com' &&
    hostname !== 'www.dyvvee.com' &&
    hostname !== 'localhost' &&
    hostname !== 'd7cffazktq8sn.cloudfront.net' &&
    hostname !== 'gawa-web-prod.s3-website-us-east-1.amazonaws.com' &&
    hostname !== 'dv2eixh2go4id.cloudfront.net' &&
    hostname !== 'd8iauki3wxzk0.cloudfront.net' &&
    hostname !== 'gawa-web-prod-creators.s3-website-us-east-1.amazonaws.com' &&
    hostname !== 'd1ma8xr17sgj4k.cloudfront.net' &&
    hostname !== 'gawa-web-prod-public.s3-website-us-east-1.amazonaws.com' &&
    hostname !== 'd3alk971zrinh6.cloudfront.net' &&
    !isValidIpAddress(hostname)
    // &&
    // hostname !== 'slidesettings.com'
  ) {
    return hostname;
  }
};
export const extractHostname = (url: string): string => {

  if(!url) {
    return '';
  }
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
};
export const extractEmbedFeedIdentifier = (url: string): string => {
  var urlParts = url.split('/');
  return urlParts[urlParts.length - 1];
};
/**
 * Removes all of the html tags from an html segment
 * @param taggedString - htm segment
 */
export const stripTags = (taggedString: HTMLElement | string): string => {
  let hasTag, taggedStringCopy;
  if (!isNullOrUndefined(taggedString)) {
    taggedStringCopy = taggedString;
    if (typeof taggedStringCopy === 'object') {
      taggedStringCopy = taggedStringCopy.outerHTML;
    }
    taggedStringCopy = taggedStringCopy.replace('&nbsp;' ,'');
    hasTag = taggedStringCopy.match(/(<([^>]+)>)/gi);
    if (hasTag) {
      return taggedStringCopy.replace(/(<([^>]+)>)/gi, '').trim();
    } else {
      return taggedStringCopy.trim();
    }
  } else {
    throw new Error("The 'strip_tags' function expects one argument in the form of a string or object.");
  }
};
export const urlForChannels = (username: string) => {
  return `/@${username}/channels`;
}
export const urlForArticle = (
  article: Article | { slug: string; author: { username: string } },
  articleEditorLayoutAsWideScreen?: boolean,
) => {
  if (!article || !article.slug) return '';
  if (getCustomDomain()) {
    return `${HOST}/${article.author.username}/${article.slug}`;
  } else {
    if (!article.author) return '/';
    return `/${article.author.username}/${article.slug}${articleEditorLayoutAsWideScreen ? '?view=website' : ''}`;
  }
};
export const getHost = () => {
  if (getCustomDomain()) {
    return HOST;
  } else {
    return '';
  }
};
export const urlForUser = (user: User | string) => `/@${user && (user as User).username ? (user as User).username : user}`;

export const ensureImageFileOrientation = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    let imageType = file.type;
    if (imageType === "image/gif") {
      resolve(file);
    } else {
      const callback: LoadImageCallback = (img) => {
        if (img instanceof HTMLCanvasElement) {
          img.toBlob((blob: any) => {
            resolve(blob);
          }, imageType);
        } else {
          reject("Unable to load image file as a canvas");
        }
      };
      loadImage(
        file, 
        callback, 
        { 
          orientation: true,
          canvas: true,
        }
      );
    }
  });
};

export const dataURIToBlob = (dataURI: string, type: string) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs
  var byteString = atob(dataURI.split(',')[1]);

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], {type});
}
export const slideContainsOverlayItem = (slide: Slide) => {
  if (!isNullOrUndefined(slide)) {
    return (
      slide.data &&
      (slide.data.caption || slide.data.audioCaption || slide.link || hasChildArticleSlideTypes(slide.childArticlesSlideTypes))
    );
  }
  return false;
};

/**
 * Returns the range of the headerSlide for a list of slides
 *
 * @param {headerSlide the HEADER slide to get the range of slides} headerSlide
 * @param {list of slides to pull the range of the headerSlide} slides
 */
export const getHeaderRange = (headerSlide: Slide, slides: Slide[]) => {
  // generate the header ranges in the list of slides
  const headerRangeList = generateHeaderRangeList(slides ? slides : []);

  // get the header range for the headerSlide
  return headerRangeList.find((hr: any) => hr.rangeStartSlide.id === headerSlide.id);
};

export const getAvailableHeaderLevelsForSlidePosition = (slidePosition: number, slides: Slide[]) => {
  const slideRangeList = generateHeaderRangeList(slides ? slides : []);
  // get the header slides that can be ended for the slide position
  const headerSlidesToEnd = getHeadersToEndForPosition(slidePosition, slideRangeList, true);
  // get the all header ranges, but the title range
  const headerSlideRangeList = slideRangeList.filter((sr: any) => sr.slideType === 'HEADER' && sr.rangeStartSlide.data.level > 0);

  const parentHeaderRange = getParentRangeForPosition(slidePosition, headerSlideRangeList, true);

  // only the header ranges that includes the slide position
  const slidePositionHeaderRangeList = headerSlideRangeList.filter(
    (hrs: any) => hrs.startPosition < slidePosition && (!hrs.endPosition || hrs.endPosition >= slidePosition),
  );

  if (parentHeaderRange) {
    // get maxHeader level from the parent range for the slidePosition
    let maxHeaderLevel = parentHeaderRange.rangeMaxHeaderLevel;
    // get minHeader level of the all the ranges that include the slidePosition
    let minHeaderLevel = slidePositionHeaderRangeList.reduce((minHeaderLevel: number | null, nextRange: any) => {
      return minHeaderLevel && nextRange.rangeMinHeaderLevel && minHeaderLevel > nextRange.rangeMinHeaderLevel
        ? minHeaderLevel
        : nextRange.rangeMinHeaderLevel;
    }, null);

    // get the next header range after the slidePosition's parent header range
    // it cannot be header range for an H1 header, because that starts a new range of headers
    //  && hrs.rangeStartSlide.startLevel > HeaderLevel.H1
    // need to increase the minHeaderLevel by one
    // if the parent header range level is greater than the next header range level
    let nextHeaderRange = headerSlideRangeList
      .filter(hrs => hrs.startPosition >= slidePosition && hrs.rangeStartSlide)
      .reduce((minHeaderRange: any, nextRange: any) => {
        return minHeaderRange && minHeaderRange.startPosition < nextRange.startPosition ? minHeaderRange : nextRange;
      }, null);

    // rules to end header at slide position
    // the header cannot already have an END slide
    // the header cannot have a nextHeaderRange with a level greater than it's level
    // (i.e. H1 cannot be ended if the nextHeaderRange level is an H2)
    let canAddHeaderEnd = true;

    // can't add an H2 between an H3 and H4
    if (nextHeaderRange && nextHeaderRange.startLevel > parentHeaderRange.startLevel) {
      minHeaderLevel =
        minHeaderLevel !== null && minHeaderLevel < nextHeaderRange.startLevel - 1 ? nextHeaderRange.startLevel - 1 : minHeaderLevel;
      // the header cannot end if the nextHeaderRange has a level greater than it's level
      canAddHeaderEnd = false;
    }

    if (
      canAddHeaderEnd &&
      parentHeaderRange.rangeEndSlide &&
      parentHeaderRange.rangeEndSlide.slideType === 'HEADER' &&
      parentHeaderRange.rangeEndSlide.data.type === 'END' &&
      parentHeaderRange.rangeEndSlide.data.beginSlideId === parentHeaderRange.rangeStartSlide.id
    ) {
      canAddHeaderEnd = false;
    }

    // rules to reset a header range
    // reset ends the root header range (i.e. H1)
    // cannot reset if the root header range already has an END slide
    // cannot reset unless there are no nextHeaderRanges for the slidePosition
    // or the nextHeaderRange is an H1
    let canAddHeaderReset = false;
    let rootHeaderRange = null;
    if (
      canAddHeaderEnd &&
      slidePositionHeaderRangeList &&
      slidePositionHeaderRangeList.length > 0 &&
      (!nextHeaderRange || nextHeaderRange.startLevel === HeaderLevel.H1)
    ) {
      canAddHeaderReset = true;
      rootHeaderRange = slidePositionHeaderRangeList[0];
    }

    // the header cannot already have an END slide
    if (
      canAddHeaderReset &&
      rootHeaderRange &&
      rootHeaderRange.startLevel === HeaderLevel.H1 &&
      (rootHeaderRange.rangeEndSlide &&
        rootHeaderRange.rangeEndSlide.slideType === 'HEADER' &&
        rootHeaderRange.rangeEndSlide.data.type === 'END' &&
        rootHeaderRange.rangeEndSlide.data.beginSlideId === rootHeaderRange.rangeStartSlide.id)
    ) {
      canAddHeaderReset = false;
    }

    if (minHeaderLevel && maxHeaderLevel) {
      const headerLevels = Object.values(HeaderLevel)
        .filter(headerLevel => (minHeaderLevel === null || headerLevel >= minHeaderLevel) && headerLevel <= maxHeaderLevel)
        .map(headerLevel => headerLevel);

      // add HeaderLevel.Ti if no title slide
      if (!slides.find(s => s.data.level === 0)) {
        headerLevels.push(HeaderLevel.Ti);
      }

      let rangeStartSlide = parentHeaderRange.rangeStartSlide;

      return {
        rangeStartSlide: rangeStartSlide,
        rangeH1Slide: rootHeaderRange ? rootHeaderRange.rangeStartSlide : null,
        headerLevels: headerLevels,
        canAddHeaderEnd: canAddHeaderEnd,
        canAddHeaderReset: canAddHeaderReset,
        headerSlidesToEnd: headerSlidesToEnd,
      };
    }
  }

  // if in here there are no previous header slides if in column. OR if not in column then there is no previous header in article.
  // there are no HEADER slide in the article so the user can navigate between Title (Ti) and H1 only.
  // there also can only be one Title and it must come before any Header slides.
  return {
    headerLevels: slides && slides.find(s => s.data.level === 0) ? [HeaderLevel.H1] : [HeaderLevel.Ti, HeaderLevel.H1],
  };
};

/**
 * updates the end slide titles to include the title from it's associated begin slide
 * @param {list of slides} slides
 */
export const populateDisplayTitleForEndSlides = (slides: Slide[]) => {
  if (!slides) return [];
  const updateSlideList = slides.map(slide => {
    let beginSlide =
      slide.data.type && slide.data.type === 'END' && slide.slideType === 'HEADER' && slide.data.beginSlideId
        ? slides.find(s => s.id === slide.data.beginSlideId)
        : null;
    let newSlide = slide;
    if (beginSlide) {
      newSlide.data.title = `End Header Section: ${beginSlide.data.title}`;
    }
    return newSlide;
  });

  return updateSlideList;
};

/**
 * returns a list of all the slides with a position that is in the range of a parent slide in the collapseParentSlides list
 * @param {list of slide to collapse} slides
 * @param {list of slide ranges for the HEADER and COLLAPSE slides} slideRangeList
 * @param {list of the collapse HEADER and COLLAPSE slides} collapseParentSlides
 */
export const getSlidesInCollapsedRanges = (
  slides: Slide[],
  slideRangeList: any,
  collapseParentSlides: Slide[],
  displayCollapsedHeaders: boolean,
) => {
  const collapseParentSlideRangeList = slideRangeList.filter((sr: any) => any(cs => cs.id === sr.rangeStartSlide.id, collapseParentSlides));
  if (displayCollapsedHeaders) {
    // get slides that are in a collapsed range
    const slidesInCollapsedRange = slides.filter(s =>
      any((csr: any) => csr.startPosition < s.position && (!csr.endPosition || csr.endPosition > s.position), collapseParentSlideRangeList),
    );

    let collapsedSlides: Slide[] = [];
    // collapse only slides whose immediate parent is collapsed
    slidesInCollapsedRange.forEach(scr => {
      let slideParentRange = getParentRangeForPosition(scr.position, slideRangeList, true);
      // if the parent HEADER slide is not collapsible, then it's collapsed if it's parent is collapsed
      if (slideParentRange.rangeStartSlide.data.notCollapsible) {
        let slideParentParentRange = getParentRangeForPosition(slideParentRange.startPosition, slideRangeList, true);
        if (any(cs => cs.id === slideParentParentRange.startSlideId, collapseParentSlides)) {
          collapsedSlides.push(scr);
        }
      } else if (any(cs => cs.id === slideParentRange.startSlideId, collapseParentSlides)) {
        collapsedSlides.push(scr);
      }
    });
    // get any collapsible HEADER slides
    return collapsedSlides.filter(cs => cs.slideType !== 'HEADER' || (cs.slideType === 'HEADER' && cs.data.notCollapsible));
  }

  return slides.filter(s =>
    any((csr: any) => csr.startPosition < s.position && (!csr.endPosition || csr.endPosition > s.position), collapseParentSlideRangeList),
  );
};

export const saveInlineTextEdits = async (slide: Slide, updateSlideInReducer: Function, setInlineSlideTextEditInfo: Function, dataField: string) => {
  // if slide is null or undefined then no edits were captured so don't save.
  if (slide && slide.data) {
    // don't strip tags if RichText slide
    if (dataField !== 'body') {
      slide.data[dataField] = stripTags(slide.data[dataField]);
    }
    const updatedSlide = await agent.Slides.update(slide.id, slide);
    updateSlideInReducer({
      slide: updatedSlide.slide,
      isTextEdit: true,
      preventScrollToSlide: true,
    });
    setInlineSlideTextEditInfo({
      inlineSlideTextEditInfo: { inlineSlideTextEditId: null, type: null },
    });
  }
};
/**
 * Get's the header slides that can be ended for a slide position
 * @param slidePosition
 * @param slideRangeList
 * @param includeEndSlidePosition
 */
export const getHeadersToEndForPosition = (slidePosition: number, slideRangeList: any, includeEndSlidePosition: boolean) => {
  const slideRangeListForPosition = slideRangeList.filter(
    (sr: any) =>
      sr.startLevel > 0 &&
      sr.rangeStartSlide.position < slidePosition &&
      (!sr.rangeEndSlide ||
        (includeEndSlidePosition ? sr.rangeEndSlide.position >= slidePosition : sr.rangeEndSlide.position > slidePosition)),
  );

  const getHeadersToEndForPosition = slideRangeListForPosition.reduceRight((headersToEnd: any, nextHeader: any) => {
    if (
      nextHeader &&
      nextHeader.rangeStartSlide &&
      (isNullOrUndefined(nextHeader.endIsEnd) ||
        nextHeader.endIsEnd === false ||
        (nextHeader && nextHeader.rangeEndSlide && nextHeader.rangeEndSlide.data.beginSlideId !== nextHeader.rangeStartSlide.id)) &&
      (Object.keys(headersToEnd).length === 0 || headersToEnd[nextHeader.rangeStartSlide.data.level + 1])
    ) {
      headersToEnd[nextHeader.rangeStartSlide.data.level] = nextHeader.rangeStartSlide.id;
    }
    return headersToEnd;
  }, {});

  return getHeadersToEndForPosition;
};

/**
 * Get's the closest begin slide of the range for the slidePosition
 * @param {slidePosition} slidePosition
 * @param {slideRangeList} slideRangeList
 */
export const getParentRangeForPosition = (slidePosition: number, slideRangeList: any, includeEndSlidePosition: boolean) => {
  const slidePositionRangeList = slideRangeList.filter(
    (sr: any) =>
      sr.rangeStartSlide.position < slidePosition &&
      (!sr.rangeEndSlide ||
        (includeEndSlidePosition ? sr.rangeEndSlide.position >= slidePosition : sr.rangeEndSlide.position > slidePosition)),
  );

  const parentRange = slidePositionRangeList.reduce((slideRange: any, nextRange: any) => {
    return slideRange && slideRange.position > nextRange.position ? slideRange : nextRange;
  }, null);

  return parentRange;
};

export const generateHeaderRangeList = (slides: Slide[]) => {
  const rangeSlides = slides
    .filter(
      s =>
        s.slideType === 'HEADER' ||
        // && (s.data.level > 0 || s.data.type === 'END')) ||
        s.slideType === 'COLUMN',
    )
    .sort((a, b) => {
      return a.position - b.position;
    });

  // populate the slideRangeList with the slide ranges
  const slideRangeList = rangeSlides.filter(rs => !rs.data.type || (rs.data.type && rs.data.type !== 'END')).map(slide => {
    // create a slide range
    // the HEADER Title slide always spans the entire gide
    let endSlideRange = slide.slideType === 'HEADER' && slide.data.level === 0 ? null : getEndRangeSlide(slide, rangeSlides);
    let minHeaderLevel = HeaderLevel.H1;
    // max header level is slide level + 1 up to HeaderLevel.H5
    let maxHeaderLevel = slide.data.level === HeaderLevel.H5 ? HeaderLevel.H5 : slide.data.level + 1;
    // check if header range has a hard end (mapped to a HEADER END slide)
    if (endSlideRange && endSlideRange.slideType === 'HEADER' && endSlideRange.data.type === 'END') {
      // if the endSlideRange is associated with the slides parent header, then use parent to get min level
      if (endSlideRange.beginSlideId !== slide.id) {
        let beginSlideRange = slides.find(s => s.id === endSlideRange.data.beginSlideId);
        minHeaderLevel = !beginSlideRange || beginSlideRange.data.level === HeaderLevel.H5 ? null : beginSlideRange.data.level + 1;
      } else {
        minHeaderLevel = slide.data.level === HeaderLevel.H5 ? null : slide.data.level + 1;
      }
      // if the slide has an end and the minHeaderLevel is null, then the maxHeaderLevel needs to be null as well
      if (!minHeaderLevel) {
        maxHeaderLevel = null;
      }
    }
    // check header range has a soft end (endRangeSlide.level = startRangeSlide.level)
    if (
      endSlideRange &&
      endSlideRange.slideType === 'HEADER' &&
      endSlideRange.data.type !== 'END' &&
      endSlideRange.data.level === slide.data.level
    ) {
      minHeaderLevel = slide.data.level === HeaderLevel.H1 ? HeaderLevel.H1 : slide.data.level - 1;
    }

    return {
      rangeMinHeaderLevel: minHeaderLevel,
      rangeMaxHeaderLevel: maxHeaderLevel,
      startSlideId: slide.id,
      startPosition: slide.position,
      startType: slide.slideType,
      startTitle: slide.slideType === 'HEADER' ? slide.data.title : slide.slideType,
      startLevel: slide.slideType === 'HEADER' ? slide.data.level : -1,
      startIsEnd: slide.data.type === 'END',
      endSlideId: endSlideRange ? endSlideRange.id : null,
      endPosition: endSlideRange ? endSlideRange.position : null,
      endType: endSlideRange ? endSlideRange.slideType : null,
      endTitle: endSlideRange ? (endSlideRange.slideType === 'HEADER' ? endSlideRange.data.title : endSlideRange.slideType) : null,
      endLevel: endSlideRange ? (endSlideRange.slideType === 'HEADER' ? endSlideRange.data.level : -1) : null,
      endIsEnd: endSlideRange ? endSlideRange.data.type === 'END' : null,
      rangeStartSlide: slide,
      rangeEndSlide: endSlideRange,
      slideId: slide.id,
      slideType: slide.slideType,
      endRangeSlideId: !endSlideRange ? null : endSlideRange.id,
    };
  });

  return slideRangeList;
};

export const getSlideRangeList = (slides: Slide[]) => {
  const slideRangeList = generateSlideRangeList(slides);
  return slideRangeList.map(slideRange => {
    const newSlideRange: SlideRange = {
      rangeSlideId: slideRange.slideId,
      startingSlideId: slideRange.rangeStartSlide.id,
      endingSlideId: slideRange.rangeEndSlide ? slideRange.rangeEndSlide.id : undefined,
      startingLevel: slideRange.startLevel,
      endingLevel: slideRange.endLevel,
      startingPosition: slideRange.startPosition === 0 ? 1 : slideRange.startPosition,
      endingPosition: slideRange.endPosition,
    };
    return newSlideRange;
  });
};

export const generateTableOfContents = (slides: Slide[]) => {
  let headers: any[] = [];
  let levelCounts = [0, 0, 0, 0, 0, 0];
  slides.filter(s => s.slideType === 'HEADER' && s.data.type !== 'END').forEach(h => {
    if (h.data.level === 0) {
      let header: Slide = {
        id: h.id,
        position: h.position,
        slideType: 'HEADER',
        author: h.author,
        data: {
          level: h.data.level,
          title: h.data.title,
          idx: 0,
        },
      };
      headers.push(header);
    } else {
      levelCounts = levelCounts.map((lc, idx) => (idx + 1 > h.data.level ? 0 : lc));
      levelCounts[!h.data.level ? 0 : h.data.level - 1]++;
      let header: Slide = {
        id: h.id,
        position: h.position,
        slideType: 'HEADER',
        author: h.author,
        data: {
          level: h.data.level,
          title: h.data.title,
          idx: levelCounts
            .filter(i => i > 0)
            .map(i => i.toString())
            .reduce((idx, levelCount, currentIndex) => (currentIndex && currentIndex + 1 <= h.data.level ? idx + '.' + levelCount : idx)),
        },
      };
      headers.push(header);
    }
  });
  return headers;
};

/**
 * Creates a list of slideRange objects that contians details about a slide's range
 * for the slides types that span a range (HEADER, COLUMNS, COLLAPSE)
 */
export const generateSlideRangeList = (slides: Slide[]) => {
  const rangeSlides = slides
    .filter(
      s =>
        s.slideType === 'HEADER' ||
        // && (s.data.level > 0 || s.data.type === 'END')) ||
        s.slideType === 'COLLAPSE' ||
        s.slideType === 'COLUMN',
    )
    .sort((a, b) => {
      return a.position - b.position;
    });

  // populate the slideRangeList with the slide ranges
  const slideRangeList = rangeSlides.filter(rs => !rs.data.type || (rs.data.type && rs.data.type !== 'END')).map(slide => {
    // create a slide range
    // the HEADER Title slide always spans the entire gide, so it has no endSlideRange
    let endSlideRange = slide.slideType === 'HEADER' && slide.data.level === 0 ? null : getEndRangeSlide(slide, rangeSlides);

    return {
      startSlideId: slide.id,
      startPosition: slide.position,
      startType: slide.slideType,
      startTitle: slide.slideType === 'HEADER' ? slide.data.title : slide.slideType,
      startLevel: slide.slideType === 'HEADER' ? slide.data.level : -1,
      startIsEnd: slide.data.type === 'END',
      endSlideId: endSlideRange ? endSlideRange.id : null,
      endPosition: endSlideRange ? endSlideRange.position : null,
      endType: endSlideRange ? endSlideRange.slideType : null,
      endTitle: endSlideRange ? (endSlideRange.slideType === 'HEADER' ? endSlideRange.data.title : endSlideRange.slideType) : null,
      endLevel: endSlideRange ? (endSlideRange.slideType === 'HEADER' ? endSlideRange.data.level : -1) : null,
      endIsEnd: endSlideRange ? endSlideRange.data.type === 'END' : null,
      rangeStartSlide: slide,
      rangeEndSlide: endSlideRange,
      slideId: slide.id,
      slideType: slide.slideType,
      endRangeSlideId: !endSlideRange ? null : endSlideRange.id,
    };
  });

  return slideRangeList;
};

/**
 * Finds the end of a range in a list of rangeSlides (HEADER, COLUMNS, COLLAPSE) for a slide
 * Conditions that end the range of a HEADER slide
 *  HEADER slide with type = END and beginSlideId = the HEADER slide's Id
 *  HEADER slide with level <= the HEADER slide's level
 *  HEADER slide ends at first column slide
 *  HEADER slide in COLUMN will end at the above or at the next COLUMN slide
 *
 * Conditions that end the range of a COLLAPSE slide
 *  COLLAPSE slide with type = END and beginSlideId = the COLLAPSE slide's Id
 *  COLLAPSE slide in COLUMN will end at the above or at the next COLUMN slide
 *
 * Conditions that end the range of a COLUMN
 *  COLUMN slide
 */
export const getEndRangeSlide = (slide: Slide, rangeSlides: Slide[]) => {
  // check that the slide is not an END slide
  // shouldn't happen, but need to check
  if (slide.data.type && slide.data.type === 'END') {
    // slide is an END slide and has no range
    return slide;
  }

  // return END slide if the one is associated with the slide
  const endSlide = rangeSlides.find(
    rs => rs.data.type && rs.data.type === 'END' && rs.data.beginSlideId && rs.data.beginSlideId === slide.id,
  );
  if (endSlide) {
    return endSlide;
  }

  // need to determine if the slide is in a column
  // if slide is a COLUMN slide then set it to the beginColumnSlide
  // get a begin column slide that has a position greater than the slide's position
  var beginColumnSlide =
    slide.slideType === 'COLUMN'
      ? slide
      : rangeSlides
          .filter(cs => cs.slideType === 'COLUMN')
          .filter(
            cs => (slide.slideType === 'COLUMN' && cs.id === slide.id) || (slide.slideType !== 'COLUMN' && cs.position < slide.position),
          )
          .reduce((maxPrecedingColumn: any, nextColumnSlide: any) => {
            return maxPrecedingColumn && maxPrecedingColumn.position >= nextColumnSlide.position ? maxPrecedingColumn : nextColumnSlide;
          }, null);

  // if there is a beginColumn, then get the next column slide with a
  // position greater than the beginColumnSlide, this will be the endColumnSlide
  // the slide is in a column if the endColumnSlide exists
  // and the endColumnSlide is the closest to, but greater than the slide's position
  const endColumnSlide = rangeSlides
    .filter(
      cs => cs.slideType === 'COLUMN' && ((beginColumnSlide && cs.position > beginColumnSlide.position) || cs.position > slide.position),
    )
    .filter(cs => cs.position > slide.position)
    .reduce((closestFollowingColumn: any, nextColumnSlide: any) => {
      return closestFollowingColumn && closestFollowingColumn.position <= nextColumnSlide.position
        ? closestFollowingColumn
        : nextColumnSlide;
    }, null);

  // if slide is a COLUMN then the endColumnSlide is end of it's range
  if (slide.slideType === 'COLUMN') {
    return endColumnSlide;
  }

  const filteredRangeSlides = rangeSlides.filter(
    rs =>
      (slide.slideType !== 'COLLAPSE' || (slide.slideType === 'COLLAPSE' && rs.slideType !== 'HEADER')) &&
      rs.position > slide.position && // not in a column
      ((!beginColumnSlide && !endColumnSlide) || // above, but not below a column slide
      (!beginColumnSlide && endColumnSlide && rs.position <= endColumnSlide.position) || // in between a column slides
      (beginColumnSlide && endColumnSlide && (rs.position > beginColumnSlide.position && rs.position <= endColumnSlide.position)) || // in column with no end column slide
        (beginColumnSlide && !endColumnSlide && rs.position > beginColumnSlide.position)) &&
      (rs.slideType !== 'HEADER' ||
        (rs.slideType === 'HEADER' && ((!rs.data.type && rs.data.level <= slide.data.level) || (rs.data.type && rs.data.type === 'END')))),
  );

  // If slide is a HEADER
  if (slide.slideType === 'HEADER') {
    const filteredHeaders = rangeSlides.filter(
      rs =>
        rs.slideType === 'HEADER' && // not in a column
        ((!beginColumnSlide && !endColumnSlide) || // above, but not below a column slide
        (!beginColumnSlide && endColumnSlide && rs.position <= endColumnSlide.position) || // in between a column slides
        (beginColumnSlide && endColumnSlide && (rs.position > beginColumnSlide.position && rs.position <= endColumnSlide.position)) || // in column with no end column slide
          (beginColumnSlide && !endColumnSlide && rs.position > beginColumnSlide.position)),
    );
    // get HEADER END slides for HEADERS in the filteredRangeSlides
    const filteredHeaderEndSlidesInRange = filteredHeaders.filter(
      fs => fs.data.type === 'END' && any(s => fs.data.beginSlideId === s.id && s.position <= slide.position, rangeSlides),
    );

    // find the end for a HEADER
    // filter out HEADER END slides that don't have begin slides in filteredRangeSlides
    const endHeaderRangeSlide = filteredRangeSlides
      .filter(
        rs =>
          (rs.slideType !== 'HEADER' && rs.slideType !== 'COLLAPSE') ||
          (rs.slideType === 'HEADER' &&
            (!rs.data.type ||
              rs.data.type !== 'END' ||
              (rs.data.type && rs.data.type === 'END' && any(s => s.id === rs.id, filteredHeaderEndSlidesInRange)))),
      )
      .reduce((endRangeSlide: Slide | null, nextSlide: Slide) => {
        return endRangeSlide && endRangeSlide.position <= nextSlide.position ? endRangeSlide : nextSlide;
      }, null);

    return endHeaderRangeSlide;
  }

  // if slide is a COLLAPSE slide then
  const endRangeSlide = filteredRangeSlides.reduce(function(endRangeSlide: Slide | null, nextSlide: Slide) {
    return endRangeSlide &&
      (endRangeSlide.slideType !== 'COLUMN' &&
        (slide.slideType === nextSlide.slideType || nextSlide.slideType === 'COLUMN') &&
        endRangeSlide.position <= nextSlide.position &&
        (nextSlide.slideType !== 'HEADER' ||
          ((nextSlide.data.type && nextSlide.data.type === 'END') || nextSlide.data.level <= endRangeSlide.data.level)))
      ? endRangeSlide
      : nextSlide;
  }, null);

  return endRangeSlide;
};

/**
 * @param {*} slide
 * @param {*} slides
 */
export const getParentHeaderSlide = (slide: Slide, slides: Slide[]) => {
  if (slide && slides) {
    const parentHeaderSlides = getParentHeaderSlides(slide, slides);
    const parentHeaderSlide = parentHeaderSlides.length > 0 ? parentHeaderSlides[0] : null;
    return parentHeaderSlide;
  }
  return null;
};

export const getParentHeaderSlides = (slide: Slide, slides: Slide[]) => {
  /**
   * If the slide to get the parent header is a header,
   * then set the headerLevel to the slide header level.
   * If the slide is a header then its parent would be a level up.
   * (e.g. if it was an H2 then the previous H2 is not its parent, the previous H1 is)
   */
  const headerSlides = slides.filter(s => s.slideType === 'HEADER' && s.data.type !== 'END');
  // potentialParentHeaderSlides contains a list of all the headers that potentially could be parents of slide
  const potentialParentHeaderSlides = headerSlides
    ? headerSlides.filter(
        hs =>
          hs.position < slide.position &&
          (slide.slideType !== 'HEADER' || (slide.slideType === 'HEADER' && hs.data.level < slide.data.level)),
      )
    : [];

  if (potentialParentHeaderSlides.length > 0 && slide.data.level > 1) {
    let slideParents: Slide[] = [];
    // traverse backward through the potentialParentHeaderSlides array to find the slides parents
    // the slide parents are the first slides encountered of each level until the root level 1 parent is found
    potentialParentHeaderSlides.reverse().forEach(s => {
      // check if any slides have been added
      // if not, then the first slide in the array is a parent
      if (slideParents.length === 0) {
        slideParents.push(s);
      } else {
        // slide parent is found when a slide has a level < the last slide parent level added
        if (slideParents[slideParents.length - 1].data.level > s.data.level) {
          slideParents.push(s);
          // return when the root slide parent is found (level = 1 is a root header)
          if (s.data.level === 1) {
            return slideParents;
          }
        }
      }
    });
    return slideParents;
  }
  return [];
};

export const slideHasParentHeader = (slide: Slide, slides: Slide[]) => {
  const hasParentHeader = slide && slide.slideType !== 'HEADER' && getParentHeaderSlide(slide, slides) !== null;
  return hasParentHeader;
};
export const slideIsEmbeddedGide = (slide: Slide) => {
  const isEmbeddedGide = slide && slide.slideType === 'GIDE' && slide.data && slide.data.embed === true;
  return isEmbeddedGide;
};
export const slideContainsInfoPanelItem = (slide: Slide, slides: Slide[]) => {
  const containsInfoPanelItem = slideHasParentHeader(slide, slides) || slideIsEmbeddedGide(slide);
  return containsInfoPanelItem;
};

// TODO: Delete this out of SlideList and use this function once I merge Dale's branch into my branch
// after Dale finishes merging master into his.

/**
 * Returns a list of slides that are not collapsed under another collapsable slide
 * @param {slide[]} slides - the base list of slides.
 * @param {slide[]} collapsedSlides - the list of collapsed collapsable slides
 * (i.e. HEADER and COLLAPSE slideType slides that have their collapsed property set to true)
 * @returns {slide[]} A subset of the list of slides that are not collapsed.
 */

export const getSlidesThatAreNotCollapsed = (slides: Slide[], collapsedSlides: Slide[]) => {
  const slidesWithCollapsedHeader: Slide[] = [];
  const headerInColumn: any[] = [];
  if (isNullOrUndefined(slides) || isNullOrUndefined(collapsedSlides) || collapsedSlides.length < 1) {
    return slides;
  }
  slides.forEach(slide => {
    const beginColumnSlide = slides
      .filter(cs => cs.slideType === 'COLUMN')
      .filter(cs => (slide.slideType === 'COLUMN' && cs.id === slide.id) || (slide.slideType !== 'COLUMN' && cs.position < slide.position))
      .reduce((maxPreviousColumnEnd: any, columnEndSlide: Slide) => {
        return maxPreviousColumnEnd && maxPreviousColumnEnd.position >= columnEndSlide.position ? maxPreviousColumnEnd : columnEndSlide;
      }, null);

    const nextEndColumnSlide = slides
      .filter(cs => beginColumnSlide && cs.position > beginColumnSlide.position && cs.slideType === 'COLUMN')
      .filter(cs => cs.position > slide.position)
      .reduce((maxPreviousColumnEnd: any, columnEndSlide: Slide) => {
        return maxPreviousColumnEnd && maxPreviousColumnEnd.position <= columnEndSlide.position ? maxPreviousColumnEnd : columnEndSlide;
      }, null);

    if (slide.slideType === 'HEADER' && slide.data.type !== 'END' && beginColumnSlide && nextEndColumnSlide) {
      headerInColumn.push(slide);
    }

    const slideHeader = slides
      .filter(
        hs =>
          hs.slideType === 'HEADER' &&
          hs.data.type !== 'END' && //&& slide.data.level > 0
          hs.position < slide.position &&
          (slide.slideType !== 'HEADER' || slide.data.level > hs.data.level) &&
          ((beginColumnSlide &&
            nextEndColumnSlide &&
            (hs.position > beginColumnSlide.position && hs.position < nextEndColumnSlide.position)) ||
            (!beginColumnSlide && !nextEndColumnSlide && !headerInColumn.find(hc => hc.id === hs.id)) ||
            (beginColumnSlide && !nextEndColumnSlide && hs.position > beginColumnSlide.position)),
      )
      .reduce((maxHeaderSlide: Slide | null, headerSlide: Slide) => {
        return maxHeaderSlide && maxHeaderSlide.position > headerSlide.position ? maxHeaderSlide : headerSlide;
      }, null);

    if (
      slideHeader &&
      (collapsedSlides.find(cs => cs.id === slideHeader.id) || slidesWithCollapsedHeader.find(s => s.id === slideHeader.id))
    ) {
      slidesWithCollapsedHeader.push(slide);
    } else if (beginColumnSlide && nextEndColumnSlide && slidesWithCollapsedHeader.includes(beginColumnSlide)) {
      slidesWithCollapsedHeader.push(slide);
    }
  });
  const nonCollapsedSlides = slides.filter(s => !slidesWithCollapsedHeader.includes(s));
  return nonCollapsedSlides;
};

/**
 * Creates a map containing the header slide id as the key and the section (e.g. 1.1) as
 * @param {slide[]} slides - the list of headerSlides from which to build a map
 * @returns {[id: string]: string} map containig the TOC section label
 */
export const getHeaderSlideTocLabelMap = (headerSlides: Slide[]) => {
  const headerMap: { [key: string]: string } = {};
  let levelCounts = [0, 0, 0, 0, 0, 0];
  headerSlides.forEach(h => {
    if (h.data.level === 0) {
      headerMap[h.id] = 'Title';
    } else {
      levelCounts = levelCounts.map((lc, idx) => (idx + 1 > h.data.level ? 0 : lc));
      levelCounts[!h.data.level ? 0 : h.data.level - 1]++;
      let header = {
        id: h.id,
        sectionLabel: levelCounts
          .filter(i => i > 0)
          .reduce(
            (idx, levelCount, currentIndex) =>
              currentIndex && currentIndex + 1 <= h.data.level ? `${idx.toString()}.${levelCount.toString()}` : `${idx.toString()}`,
            '',
          ),
      };
      headerMap[header.id] = header.sectionLabel.toString();
    }
  });
  return headerMap;
};

export const titleToPath = (title: string) => {
  if (!title || !title.length) return '';
  title = title.trim().toLowerCase();
  var originallyHadEndingDash = title.slice(-1) === '-';
  title = title
    .replace(/\s/g, '_')
    .replace(/[^a-zA-Z0-9_]/g, '-');
  var hasEndingDashAfterReplacements = title.slice(-1) === '-';
  if (!originallyHadEndingDash && hasEndingDashAfterReplacements) {
    title = title.slice(0, -1);
  }
  return title;
};

/**
 * This assumes that the slide passed in is collapsable
 * @param {slide} the collapsable slide
 * @param {*} the complete list of slides
 */
export const getNumberOfSlidesThatCollapsUnderThisSlide = (slide: Slide, slides: Slide[]) => {
  let count = 0;
  const filteredSlides = slides.filter(s => s.position > slide.position);
  for (let i = 0; i < filteredSlides.length; i++) {
    if (
      (!filteredSlides[i].data || filteredSlides[i].data.type !== 'END') &&
      ((filteredSlides[i].slideType !== 'COLUMN' &&
        filteredSlides[i].slideType !== 'COLLAPSE' &&
        filteredSlides[i].slideType !== 'HEADER') ||
        (filteredSlides[i].slideType === 'HEADER' && filteredSlides[i].data.level > slide.data.level))
    ) {
      count++;
    } else {
      break;
    }
  }
  return count;
};

export type Interval = 'years' | 'months' | 'weeks' | 'days';
export const getFormattedDateDiff = (date1: Date, date2: Date) => {
  const momentDate1 = moment(date1),
    momentDate2 = moment(date2),
    intervals: Interval[] = ['years', 'months', 'weeks', 'days'],
    out = [];

  for (let i = 0; i < intervals.length; i++) {
    const diff: number = momentDate1.diff(momentDate2, intervals[i]);
    momentDate2.add(diff, intervals[i]);
    out.push(diff);
  }
  if (out[0] > 0) {
    return out[0] === 1 ? `${out[0]} year` : `${out[0]} years`;
  }
  if (out[1] > 0) {
    return out[1] === 1 ? `${out[1]} month` : `${out[1]} months`;
  }
  if (out[2] > 0) {
    return out[2] === 1 ? `${out[2]} week` : `${out[2]} weeks`;
  }
  if (out[3] > 0) {
    return out[3] === 1 ? `${out[3]} day` : `${out[3]} days`;
  }
  return '0 days';
};
/**
 * When overlay is toggled on and off need to determine the states 3 sections of the overlay system
 * 1. Header
 * 2. Info Panel
 * 3. Overlay Panel
 * @param {boolean} showChrome - whether the user toggled chrome on. Under most conditions this will
 * toggle chrome on, but if the slide that is currently navigated to has a caption and was navigated
 * to with chrome turned off, then this will still turn chrome off.
 * @param {boolean} showCaptionPanel - boolean indicating whether the caption panel is currently on
 * @param {Slide} slide - current slide being viewed
 * @param {Slide[]} slides - the full list of slides for the gide
 */
export const getOverlayState = (showChrome: boolean, showCaptionPanel: boolean, isEmbeddedGide: boolean, slide: Slide, slides: Slide[]) => {
  // If this is true then the slide currently being viewed is showing its caption even
  // though chrome was turned off. This is an exception to the normal on/off of the overlay system
  const captionOverlayOnFromNavigation = !showChrome && showCaptionPanel;

  const showChromeNextState = captionOverlayOnFromNavigation ? false : !showChrome;
  const showCaptionPanelNextState = showChromeNextState === true ? slideContainsOverlayItem(slide) : false;

  const showInfoPanelNextState =
    showChromeNextState === true || isEmbeddedGide ? isEmbeddedGide || slideContainsInfoPanelItem(slide, slides) : false;

  return {
    showChrome: showChromeNextState,
    showCaptionPanel: showCaptionPanelNextState,
    showInfoPanel: showInfoPanelNextState,
  };
};

// slide helper functions

// returns the preceeding BEGIN slide for a slide type that covers a range (HEADER, COLLAPSE)
// returns null in none exists
export const getPrecedingBeginSlideForSection = (filteredSlides: Slide[], slidePosition: number) => {
  // check if there is a preceding slide using the slideTypeFilter parameter
  //const filteredSlides = this.props.slides.filter(s => s.slideType === slideType);
  const filteredEndSlides = filteredSlides.filter(s => s.data.type === 'END');
  // get max preceding end slide if exists
  const maxPrecedingEndSlide = getPrecedingSlideByFilter(filteredEndSlides, (s: Slide) => s.position < slidePosition);
  const filteredSlidesWithoutEnds = filteredSlides.filter(
    fs =>
      fs.data.type !== 'END' &&
      !any(s => s.data.beginSlideId === fs.id, filteredEndSlides) &&
      (!maxPrecedingEndSlide || fs.position > maxPrecedingEndSlide.position),
  );
  // get the preceding slide of type slideType if it exists
  return getPrecedingSlideByFilter(filteredSlidesWithoutEnds, (s: Slide) => s.position < slidePosition);
};

export const getPrecedingSlideByFilter = (slides: Slide[], slideFilter: any) => {
  return slides.filter(slideFilter).reduce((maxPrecedingSlide: Slide | null, nextSlide: Slide) => {
    return maxPrecedingSlide && maxPrecedingSlide.position >= nextSlide.position ? maxPrecedingSlide : nextSlide;
  }, null);
};

export const getNextSlideByFilter = (slides: Slide[], slideFilter: any) => {
  return slides.filter(slideFilter).reduce((minNextSlide: Slide | null, nextSlide: Slide) => {
    return minNextSlide && minNextSlide.position <= nextSlide.position ? minNextSlide : nextSlide;
  }, null);
};

export const slideInCollapsedSection = (
  slide: Slide,
  slides: Slide[],
  collapsedSectionSlides: Slide[],
  slidesWithCollapsedParents: Slide[],
) => {
  var collapseTypeSlides = slides.filter(csc => csc.slideType === 'COLLAPSE');
  var precedingCollapsedSectionSlide: Slide | null = getPrecedingSlideByFilter(
    collapseTypeSlides,
    (s: Slide) => s.position < slide.position,
  );
  // return false if the is no preceding COLLAPSE slide or if the first preceding COLLAPSE slide is an END COLLAPSE slide
  if (!precedingCollapsedSectionSlide || precedingCollapsedSectionSlide.data.type === 'END') {
    return false;
  } else if (
    precedingCollapsedSectionSlide.data.type === 'BEGIN' &&
    (collapsedSectionSlides.find(cs => precedingCollapsedSectionSlide !== null && cs.id === precedingCollapsedSectionSlide.id) ||
      slidesWithCollapsedParents.find(cps => precedingCollapsedSectionSlide !== null && cps.id === precedingCollapsedSectionSlide.id))
  ) {
    // check to see if the first preceding COLLAPSE slide is a BEGIN COLLAPSE and that it is collapsed (in the collapsedSectionSlides)
    return true;
  }
  // not in a collapsed section
  return false;
};

export const getMaxLengthString = (numberOfCharacters: number, originalString: string) => {
  if (originalString && originalString.length > numberOfCharacters) {
    return originalString.substring(0, numberOfCharacters - 1) + '...';
  }
  return originalString;
};

export const slideIsValidForMultiEdit = (slide: Slide, mode: string) => {
  // if (mode === 'edit') {
    return !contains(slide.slideType, ['HEADER', 'COLUMN', 'COLLAPSE']);
  // }
  // return slide.slideType === 'TEXT';
};

/**
 * Conditionally get the article that the component requesting it, is currently interacting with. It can
 * be the primary article or the slide attachment article of a selected slide in the article.
 * @param {AppState} state
 */
// export const getArticleForCurrentEditing = (state: AppState): Article => {
//   return state.article.childArticleEditInfo
//     ? state.article.childArticleEditInfo.subChildArticleEditorInfo
//       ? state.article.childArticleEditInfo.subChildArticleEditorInfo.article
//       : state.article.childArticleEditInfo.article
//     : state.article.article as Article;
// };

/**
 * Conditionally get the article slug that the component requesting it, is currently interacting with. It can
 * be the primary article or the slide attachment article of a selected slide in the article.
 * @param {AppState} state
 */
// export const getArticleSlugForCurrentEditing = (state: AppState) => {
//   return state.article.childArticleEditInfo
//     ? state.article.childArticleEditInfo.subChildArticleEditorInfo
//       ? state.article.childArticleEditInfo.subChildArticleEditorInfo.article
//         .slug
//       : state.article.childArticleEditInfo.article.slug
//     : state.article && state.article.article ? state.article.article.slug : '';
// };

/**
 * Conditionally get the slides for the article that the component requesting it, is currently interacting with. It can
 * be the primary article's slides or a slide attachment article's slides.
 * NOTE: This can only be called from a components mapStateToProps because it takes AppState as the paramater.
 * @param {AppState} state
 */
export const getSlidesForCurrentEditing = (state: any /*: AppState */) => {
  return state.article.childArticleEditInfo
    ? state.article.childArticleEditInfo.subChildArticleEditorInfo
      ? state.article.childArticleEditInfo.subChildArticleEditorInfo.slides
      : state.article.childArticleEditInfo.slides
    : state.article.slides;
};

export const getNextSlideNumber = (state: any) => {
  return state.article.childArticleEditInfo
    ? state.article.childArticleEditInfo.subChildArticleEditorInfo
      ? state.article.childArticleEditInfo.subChildArticleEditorInfo.slideNumber
      : state.article.childArticleEditInfo.slideNumber
    : state.common.slideNumber;
};

/**
 * Returns whether or not there are any values for any keys
 * @param {{key: string : string[]}} childArticlesSlideTypes
 */
export const hasChildArticleSlideTypes = (childArticlesSlideTypes: any /** */) => {
  if (!hasValue(childArticlesSlideTypes)) return false;
  const keys = Object.keys(childArticlesSlideTypes);
  for (let i = 0; i < keys.length; i++) {
    if (childArticlesSlideTypes[keys[i]] && childArticlesSlideTypes[keys[i]].length > 0) {
      return true;
    }
  }
  return false;
};

// export const getAdditionArticleForSlide = async (slide: Slide) => {
//   try {
//     const articleTypeName = getChildArticleTypeName(ChildArticleType.Addition);
//     const articleTypeNameParam =
//       articleTypeName === 'attachments'
//         ? 'SETTINGS'
//         : articleTypeName.toUpperCase().replace(/\s/g, '');
//     const articleResponse = await agent.Slides.getSettings(
//       slide.id,
//       articleTypeNameParam,
//     );
//     return new Promise(resolve =>
//       resolve({
//         articleResponse,
//       }),
//     );
//   } catch (e) {
//     console.log('ERROR: getAdditionArticleForSlide => ', e);
//   }
// };

export const getChildArticlesForArticle = async (articleId: string, articleType: ChildArticleType): Promise<any> => {
  try {
    // return the article's articles for article type
    const articleTypeName = getChildArticleTypeName(articleType);
    const articleTypeNameParam = articleTypeName === 'attachments' ? 'SETTINGS' : articleTypeName.toUpperCase().replace(/\s/g, '');
    const response = await agent.Articles.forArticleByType(articleId, articleTypeNameParam);
    return new Promise(resolve =>
      resolve({
        articles: response.articles,
      }),
    );
  } catch (e) {
    console.log('Error in getChildArticlesForArticle ', e);
  }
};

export const getChildArticlesForSlide = async (slide: Slide, articleType: ChildArticleType): Promise<any> => {
  try {
    // return the slide's articles for article type
    const articleTypeName = getChildArticleTypeName(articleType);
    const articleTypeNameParam = articleTypeName === 'attachments' ? 'SETTINGS' : articleTypeName.toUpperCase().replace(/\s/g, '');
    const response = await agent.Articles.forSlideByType(slide.id, articleTypeNameParam);
    return new Promise(resolve =>
      resolve({
        articles: response.articles,
      }),
    );
  } catch (e) {
    console.log('Error in getChildArticlesForSlide ', e);
  }
};

export const getChildArticleForSlide = async (slide: Slide, articleType: ChildArticleType) => {
  try {
    const articleTypeName = getChildArticleTypeName(articleType);
    const articleTypeNameParam = articleTypeName === 'attachments' ? 'SETTINGS' : articleTypeName.toUpperCase().replace(/\s/g, '');
    const article = await agent.Slides.getSettings(slide.id, articleTypeNameParam);
    const response = await agent.Slides.forArticle(article.article);

    return new Promise<ChildArticleDetail>(resolve =>
      resolve({
        slidePosition: slide.position + 1,
        article: article.article,
        slides: response.slides,
        ownerSlide: slide,
        articleType: articleType,
      }),
    );
  } catch (e) {
    // this.setState({ loading: false });
  }
};

/**
 * Updates information on the slide on the server and then updates the addon information for a slide.
 * (i.e. number of questions, comments, private notes, attachment types)
 * @param slideId - id of the slide that needs to have its addon information updated. Currently this will always reside
 * in the root article in the Article reducer. (i.e. ArticleState.article)
 * @param - updateSlideAttachments - function used to update the reducer.
 */
export const getSlideAddonInformation = async (slideId: string, updateSlideAttachments: Function) => {
  const slideAddonResponse = await agent.Slides.getAttachmentDetails(slideId);
  updateSlideAttachments({
    slideId: slideId,
    childArticlesSlideTypes: slideAddonResponse.childArticlesSlideTypes,
    childArticlesSlideDetails: slideAddonResponse.childArticlesSlideDetails,
  });
};

export const isAddonSlideType = (slideType: string): boolean => {
  return contains(slideType, ['INQUIRYRESPONSE', 'COMMENTS', 'QUESTIONS', 'PRIVATENOTES', 'SETTINGS']);
};

export const formatMinuteAndSeconds = (seconds: number): string => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
};

function pad(string: number) {
  return ('0' + string).slice(-2);
}

export const capitalizeFirstLetter = (stringValue: string): string => {
  return stringValue[0].toUpperCase() + stringValue.slice(1).toLowerCase();
};

/**
 * Determine the mobile operating system.
 * This function returns true if device is 'iOS', 'Android', 'Windows Phone', or false otherwise.
 *
 * @returns {boolean}
 */
export const isMobileDevice = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return true; // "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return true; //"Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return true; //"iOS";
  }

  return false;
};

export const isIOSDevice = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return true; //"iOS";
  }

  return false;
};

export const isCordovaDevice = (): boolean => {
  return  (window as any).cordova !== undefined;
};

export const isCordovaIOSDevice = (): boolean => {
  return isIOSDevice() && isCordovaDevice();
};

export const isAndroidDevice = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  if (/android/i.test(userAgent)) {
    return true; //"Android";
  } 
  return false;
};

export const isCordovaAndroidDevice = (): boolean => {
  return isAndroidDevice() && isCordovaDevice();
};

export const prepareSlideFileForSave = (file: SlideReferenceFile): SlideReferenceFile => {
  const slideFileReference = file.slideFileReference as SlideFileReference;
  if (slideFileReference) {
    // If this is null then the file reference is no longer available so save as is.
    return {
      ...file,
      slideFileReference: {
        referencedfileId: slideFileReference.referenceFile ? (slideFileReference.referenceFile.id as string) : undefined,
        referencedSlideId: slideFileReference.referenceSlide ? slideFileReference.referenceSlide.id : undefined,
        referencedChannelId: slideFileReference.referenceChannel ? slideFileReference.referenceChannel.id : undefined,
        articleId: (file.slideFileReference as SlideFileReference).article
          ? ((file.slideFileReference as SlideFileReference).article as ArticleSummary).id
          : undefined,
      },
    };
  } else {
    return file;
  }
};
export const getSlideFileUrl = (file: SlideReferenceFile): string => {
  const referenceFile = file.slideFileReference ? (file.slideFileReference as SlideFileReference).referenceFile : undefined;
  if (file.type === 'SLIDEFILE' && referenceFile) {
    return referenceFile.url as string;
  }
  return file.url
    ? file.url
    : file.type === 'SLIDEFILE' ? '/icons/delete-when-replaced/content-unavailable.png' : '/icons/delete-when-replaced/missing-image.png';
};

export const getColumnClassName = (verticalAlignmentSetting: string) => {
  switch (verticalAlignmentSetting) {
    case 'TOP':
      return 'columnVerticalAlignmentTop';
    case 'CENTER':
      return 'columnVerticalAlignmentCenter';
    case 'BOTTOM':
      return 'columnVerticalAlignmentBottom';
    case 'SPACEBETWEEN':
      return 'columnVerticalAlignmentSpaceBetween';
    case 'SPACEAROUND':
      return 'columnVerticalAlignmentSpaceAround';
    default:
      return 'columnVerticalAlignmentTop';
  }
};

/**
 * Checks to see if the string passed in is a valid URL.
 * @param possibleURL - string to validate as url
 * @returns true if valid url, false otherwise.
 */
export const isValidURL = (possibleURL: string) => {
  let isValidURL = true;
  let url = possibleURL;
  try {
    if (!/http/.test(url)) {
      url = `http://${url}`;
    }
    // let regex = /\b(https?:\/\/.*?\.[a-z]{2,4}\b)/g;
    // let regex = /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    let regex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    if (url.match(regex)) {
      isValidURL = true;
    } else {
      isValidURL = false;
    }
  } catch (e) {
    isValidURL = false;
  }
  return isValidURL;
};
/**
 *
 * @param url - url of image to load
 */
export const getImageMeta = async (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = reject;
    image.src = url;
  });
};

export const getVideoMeta = async (url: string): Promise<HTMLVideoElement> => {
  return new Promise((resolve, reject) => {
    const video = new HTMLVideoElement();
    video.onload = (e) => resolve(video);
    video.onerror = reject;
    video.src = url;
  });
}

export const addHttpIfAbsentUrl = (url: string) => {
  try {
    let updatedUrl = url;
    let upgradedToHttps = false;
    if (!/^http/.test(url)) {
      updatedUrl = `https://${url}`;
    } else if (/^http:\/\//.test(url)) {
      updatedUrl = url.replace(/^http:\/\//, 'https://');
      upgradedToHttps = true;
    }
    return { updatedUrl, upgradedToHttps };
  } catch {
    return { updatedUrl: url, upgradedToHttps: false };
  }
}

export enum SlideType {
  Image = 1,
  ImageGallery = 2,
  Video = 3,
  Audio = 4,
  Link = 5,
}
export enum IconOverlayType {
  Upload = "UPLOAD",
  Link = "LINK",
  Url = "URL",
  Photo = "PHOTO",
  Video = "VIDEO",
  Slide = "SLIDE",
  SlideFile = "SLIDEFILE",
  Recorded = "RECORDED",
  Recording = "RECORDING",
  Gide = "GIDE",
  Channel = "CHANNEL",
  Embed = "EMBED",
}
export const getPreviewOverlayIcon = (slideType: SlideType, iconType: string): string => {
  switch (iconType) {
    case IconOverlayType.Upload:
      switch (slideType) {
        case SlideType.Image:
          return '/icons/slidetype/image/main.svg';
        case SlideType.Video:
          return '/icons/content-alteration/upload.svg';
        case SlideType.Audio:
          return '/icons/slidetype/audio/audio-file.svg';
        default:
          return '/icons/slidetype/image/main.svg';
      }
    case IconOverlayType.Embed:
    case IconOverlayType.Link:
    case IconOverlayType.Url:
      return '/icons/slidetype/links/main.svg';
    case IconOverlayType.Slide:
    case IconOverlayType.SlideFile:
      return '/icons/creationprocess/slide.svg';
    case IconOverlayType.Photo:
      return '/icons/content-alteration/camera/default.svg';
    case IconOverlayType.Recorded:
    case IconOverlayType.Video:
      return '/icons/slidetype/video/main.svg';
    case IconOverlayType.Recording:
      return '/icons/slidetype/audio/mic.svg';
    case IconOverlayType.Gide:
      return '/icons/nav/logo/logo-icon-sm.svg';
    case IconOverlayType.Channel:
      return '/icons/nav/channels.svg';
    default:
      return '/icons/content-alteration/questions.svg';
  }
}

export const getPreviewOverlayIconSvg = (slideType: SlideType, iconType: string) => {
  switch (iconType) {
    case IconOverlayType.Upload:
      switch (slideType) {
        case SlideType.Image:
          return icons.SlideType_Image_Main;
        case SlideType.Video:
          return icons.ContentAlteration_Upload;
        case SlideType.Audio:
          return icons.SlideType_Audio_AudioFile;
        default:
          return icons.SlideType_Image_Main;
      }
    case IconOverlayType.Embed:
    case IconOverlayType.Link:
    case IconOverlayType.Url:
      return icons.SlideType_Link_Main;
    case IconOverlayType.Slide:
    case IconOverlayType.SlideFile:
      return icons.CreationProcess_Slide;
    case IconOverlayType.Photo:
      return icons.ContentAlteration_Camera_Default;
    case IconOverlayType.Recorded:
    case IconOverlayType.Video:
      return icons.SlideType_Video_Main;
    case IconOverlayType.Recording:
      return icons.SlideType_Audio_Mic;
    case IconOverlayType.Gide:
      return icons.Nav_Logo_LogoIconSm;
    case IconOverlayType.Channel:
      return icons.Nav_Channels;
    default:
      return icons.ContentAlteration_Questions;
  }
}
export const calculateDurationInSecondsFromCurrentTime = (startDateTime: Date): number => {
  return (new Date().valueOf() / 1000) - (startDateTime.valueOf() / 1000);
}
export const convertToMilliseconds = (duration: number, unitFrom: DurationUnit) => {
  switch (unitFrom) {
    case DurationUnit.Year: // Year
      return 525600 * 60 * 1000 * duration;
    case DurationUnit.Month: // Month
      return 43800 * 60 * 1000 * duration;
    case DurationUnit.Week: // Week
      return 10080 * 60 * 1000 * duration;
    case DurationUnit.Day: // Day
      return 1440 * 60 * 1000 * duration;
    case DurationUnit.Hour: // Hour
      return 60 * 60 * 1000 * duration;
    case DurationUnit.Minute: // Minute
      return 60 * 1000 * duration;
    case DurationUnit.Second: // Seconds
      return 1000 * duration;
    default:
      return duration; // Assume milliseconds
  }
};

/**
 *
 * @param link - Links from Youtube, Spotify, Vimeo
 * eg.
 * https://www.youtube.com/watch?v=RROKALR1xrU
 * <iframe src="https://embed.spotify.com/?uri=spotify:user:thech053none:playlist:3epVhtO7ZWOB4DzuJFY5da" width="100%" height="80" frameborder="0" allowtransparency="true"></iframe>
 * <iframe src="https://open.spotify.com/embed/album/5FOy9CM3AZs86TIK7fsJTV" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
 * spotify:album:5FOy9CM3AZs86TIK7fsJTV
 * <iframe src="https://open.spotify.com/embed/track/3QZ7uX97s82HFYSmQUAN1D" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
 * https://open.spotify.com/track/6UGHk2cmbDC1oidVjXcCKo?si=v58Ljxx-TLaHBqSwwC63jQ
 * spotify:track:0K6yUnIKNsFtfIpTgGtcHm
 * https://open.spotify.com/album/2xg7iIKoSqaDNpDbJnyCjY?si=vpg0oMGWR2O4wv20dwQf_w
 * <iframe src="https://open.spotify.com/embed/album/2xg7iIKoSqaDNpDbJnyCjY" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
 * spotify:album:2xg7iIKoSqaDNpDbJnyCjY
 *
 */
export const getMediaLinkUrl = (link: string): { url: string, type?: "video" | "audio", isIframe?: boolean } => {
  // If the user is typing in html then we will assume that they know what the html code is that we can simply render vs a url or
  // render nothing if it is invalid.
  let isIframe = false;
  if (link) {
    if (link.startsWith('<iframe')) {
      isIframe = true;
      const iFrameContentParts = link.split('src=');
      if (iFrameContentParts.length > 1) {
        const iFrameSubParts = iFrameContentParts[1].split(' ');
        if (iFrameSubParts.length > 0) {
          // return {
          //   url: iFrameSubParts[0]
          //   .replace("'", '')
          //   .replace('"', '')
          //   .replace('"', ''),
          // };
          link = `src=${iFrameSubParts[0]}`;
        }
      }
    }


    const hostName = extractHostname(link);
    const mediaId = link.split('=');
    if (hostName) {
      if (mediaId.length === 2 && hostName === 'www.youtube.com') {
        return { 
          url: isIframe ? link : `https://${hostName}/embed/${mediaId[1]}`,
          type: 'video',
          isIframe
        };
      } else if (hostName === 'open.spotify.com') {
        return { 
          url: isIframe ? link : `https://embed.spotify.com/?uri=${link}`,
          type: 'audio',
          isIframe
        };
      } else if (link.startsWith('spotify:')) {
        const spotifyIdParts = link.split(':');
        if (spotifyIdParts.length === 3) {
          return {
            url: isIframe ? link : `https://embed.spotify.com/?uri=${link}`,
            type: 'audio',
            isIframe
          };
        }
      } else if (hostName === 'youtu.be') {
        const youTubeParts = link.split('/');
        if (youTubeParts.length > 0) return {
          url: `https://www.youtube.com/embed/${youTubeParts[youTubeParts.length - 1]}`,
          type: 'video',
          isIframe
        };
      } else if (hostName === 'embed.spotify.com') {
        return {
          url: link,
          type: 'audio',
          isIframe
        };
      } else if (hostName === 'www.youtube.com' && contains('www.youtube.com/embed/', link)) {
        return {
          url: link,
          type: 'video',
          isIframe,
        };
      } else if (hostName === 'vimeo.com' || hostName === "player.vimeo.com") {
        const parsedUrl = new URL(link);
        const path = parsedUrl.pathname; // video/377615125;
        const url = `https://player.vimeo.com/video${path}?color=f7b500&title=0&byline=0&portrait=0`;
        return { 
          url,
          type: 'video',
          isIframe
        };
      } else if (hostName === 'www.facebook.com') {        
        const parts = link.split('/').filter(x => !!x);
        const lastPart = parts[parts.length - 1];
        if (lastPart) {
          //const url = `https://www.facebook.com/video/embed?video_id=${lastPart}`;
          const url = `https://www.facebook.com/plugins/video.php?href=${link}`;
          return { url,
            type: 'video',
            isIframe,
          };
        }
      }
    }
  }
  // TODO: Return our own not found url to display.
  return { url: '' };
};

/**
 * Determines the Source of the embedded media, such as youtube, facebook, soundcloud, etc.
 * @param embedMediaUri url source set on audio, video slides. Can also be an iframe html
 */
export const getEmbedMediaSource = (embedMediaUri: string): EmbedMediaSource => {
  let uri = embedMediaUri;
  if (embedMediaUri && embedMediaUri.startsWith('<iframe')) {
    const iFrameContentParts = embedMediaUri.split('src=');
    if (iFrameContentParts.length > 1) {
      const iFrameSubParts = iFrameContentParts[1].split(' ');
      if (iFrameSubParts.length > 0) {
        uri = iFrameSubParts[0]
          .replace("'", '')
          .replace('"', '')
          .replace('"', '');
      }
    }
  }
  const hostName = extractHostname(uri);

  switch (hostName) {
    case 'www.youtube.com':
    case 'youtube.com':
    case 'youtu.be':
      return EmbedMediaSource.YouTube;
    case 'www.vimeo.com':
    case 'vimeo.com':
      return EmbedMediaSource.Vimeo;
    case 'www.spotify.com':
    case 'open.spotify.com':
    case 'embed.spotify.com':
    case 'spotify.com':
    case 'spotify':
      return EmbedMediaSource.Spotify;
    default:
      return EmbedMediaSource.NotFound;
  }
};
export const slideEditorModalLookup: { [key: string]: string } = {
  GIDETITLE: 'SlideZeroEditor',
  IMAGE: 'ImageSlideEditor',
  Image: 'ImageSlideEditor',
  ImageSlideEditor: 'ImageSlideEditor',
  IMAGECAMERA: 'ImageSlideEditor',
  ImageCamera: 'ImageSlideEditor',
  IMAGEGALLERY: 'ImageGallerySlideEditor',
  ImageGallery: 'ImageGallerySlideEditor',
  VIDEO: 'VideoSlideEditor',
  Video: 'VideoSlideEditor',
  AUDIO: 'AudioSlideEditor',
  Audio: 'AudioSlideEditor',
  LINKS: 'LinkSlideEditor',
  Link: 'LinkSlideEditor',
};

export const slideEditorModals = ['SlideZeroEditor','ImageSlideEditor','VideoSlideEditor','AudioSlideEditor','LinkSlideEditor'];
export const routedEditorModals = [
  ...slideEditorModals,
  'LocationModal',
  'ListModal',
  'FileModal',
  'ImageGallerySlideEditor',
  'SlideshowModal',
  'AuthorizeModal',
  'InputModal',
  'EmbedModal',
  'ContactsModal',
  'GideModal',
  'DoodleModal',
  'MapModal',
  'TextModal',
  'HeaderModal'
];
export const isRoutedModal = (modalType: string): boolean => {
  return contains(modalType, routedEditorModals);
}
export const headerIconLookup: { [key: string]:  (props: IconProps) => JSX.Element } = {
  Ti: icons.SlideType_Header_Ti,
  H1: icons.SlideType_Header_H1,
  H2: icons.SlideType_Header_H2,
  H3: icons.SlideType_Header_H3,
  H4: icons.SlideType_Header_H4,
  H5: icons.SlideType_Header_H5,
}

export const articleDisplayTime = (article: Article) => {
  return new Date(article.updatedAt).getDay() === new Date().getDay()
    ? new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(article.updatedAt))
    : new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(article.updatedAt));
};

export interface LinkDetail {
  url: string;
  title: string;
  image: {url: string};
  description: string;
}

export const getLinkPreviewDetails = (url: string): Promise<LinkDetail> => {
  return new Promise(async (resolve, reject) => {

    let updatedUrl = url;
    if (!/http/.test(url)) {
      updatedUrl = `https://${url}`;
    }

    // Handle google. It must have www.google. May be able to do this in general
    if(updatedUrl && updatedUrl.toString().startsWith('https://google.com')) {
      updatedUrl = updatedUrl.replace('https://google.com', 'https://www.google.com');
    }

    const ogData = await agent.Util.getLinkPreview(updatedUrl);
    if (ogData.success) {
      const image = Array.isArray(ogData.data.ogImage) && ogData.data.ogImage.length > 0 ? ogData.data.ogImage[0] : ogData.data.ogImage;
      const isRelative = image && (!/http/.test(image.url)); // Trying to handle the case where the response is relative to the current site e.g. /favicon.svg instead of complete url
      let imageUrl = '';
      if(image.url && image.url.startsWith("//")) {
        imageUrl = `https:${image.url}`;
      } else {
        imageUrl = isRelative ? `https://${extractHostname(ogData.requestUrl)}${image.url}` : image.url;
      }
      let ogUrl = ogData.requestUrl;

      const linkDetail: LinkDetail = {
        url: ogUrl,
        title: ogData.data.ogTitle,
        image: {url: imageUrl},
        description: ogData.data.ogDescription,
      };
      resolve(linkDetail);
    } else {
      //reject(ogData.data.error.error);
      const linkDetail: LinkDetail = {
        url: updatedUrl,
        title: "",
        image: { url: "" },
        description: "",
      };
      resolve(linkDetail);
    }
  });
};

export const formatDisplayTextWithSearchMarkup = (displayText: string, searchText: string) => {
  return displayText.replace(new RegExp(searchText, "gi"), (match) => `<mark search-${searchText}>${match}</mark>`);
};

export const areElementsOverlapping = (element1: Element | null, element2: Element | null): boolean => {
  if (!element1 || !element2) {
    return false;
  }
  const rect1 = element1.getBoundingClientRect();
  const rect2 = element2.getBoundingClientRect();
  const overlapping =
    rect1.top <= rect2.bottom
    && rect1.bottom >= rect2.top
    && rect1.left <= rect2.right
    && rect1.right >= rect2.left;
  return overlapping;
}

// temporary to make accessing PushNotification be possible without TypeScript compiler error
declare let PushNotification: any;
// temporary to make accessing window be possible without TypeScript compiler error
declare let window: any;

/*
 * This needs to be called when user logs in/registers.
 * Since this method returns if window.cordova is false, this method is safe to always call without checking for window.cordova.
 */
export const attemptToRegisterForPushNotifications = () => {
  if (!window.cordova) return;
  var push = PushNotification.init({
    android: {
      senderId: 439034299486
    },
    ios: {
        alert: "true",
        badge: "true",
        sound: "true"
    },
    windows: {}
    });
    push.on('registration', function (data: any) {
      console.log('Event=registration, registrationId=' + data.registrationId)

      // Persist the token to the backend
      let userData;
      if (window.cordova.platformId === 'ios') {
        userData = {
          deviceTokenApple: data.registrationId
        }
      } else if (window.cordova.platformId === 'android') {
        userData = {
          deviceTokenAndroid: data.registrationId
        }
      }
      // See User model and user put endpoint to see that deviceTokenApple and deviceTokenAndroid are array fields, but providing 1 in the put method pushes the new value into the array rather than setting it to this 1 only.
      // Also note that it will only be added once, even if this method is called again (it's common for these registration callbacks to repeat, sometimes with the same token and sometimes with a new one due to app reinstall or random reassignment by Apple or Android to keep devices safe from tracking).
      // So the endpoint is resillient to keep the device ids unique, and allow additional ones to be added in case of additional devices used with the same Gides user, or in the case of new device IDs being assigned to the same device as explained above.
      agent.Auth.save(userData);
    });
    push.on('notification', function (data: any) {
      // This will be called when application is in foreground or certain data format is sent.  See: https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/PAYLOAD.md#notification-vs-data-payloads
      console.log('Event=notification, message=')
      console.log(data)
    });
    push.on('error', function (err: any) {
      console.log('Event=error, message=' + err.message)
    });
}

export const getRecommendedGides = async () => {
  const gideUserProfile = await agent.Collections.getGides();
  const gideUserCollections = gideUserProfile.collections;
  const gideGroups: GideGroup[] = [];
  gideUserCollections && gideUserCollections.forEach((collection: any) => {
    // only display group with at least one gide
    if (collection.articles && collection.articles.length > 0) {
      gideGroups.push({ groupId: collection.id, groupTitle: collection.title, gideList: collection.articles, hasMoreGides: collection.hasMoreGides});
    }
  });
  return { gideGroups };
}

export const getNextGideGroupGides = async (gideGroupId: string, page: number) => {
  const nextGidesForGideGroup = await agent.Collections.getNextGides(gideGroupId, page);
  if (nextGidesForGideGroup && nextGidesForGideGroup.articles.length > 0) {
      return {gideGroup: { groupId: nextGidesForGideGroup.id, groupTitle: nextGidesForGideGroup.title, gideList: nextGidesForGideGroup.articles, hasMoreGides: nextGidesForGideGroup.hasMoreGides}};
  }
  return {};
}

export const isValidSearchCriteria = (searchCriteria: SearchCriteria) => {
  return !isNil(searchCriteria.address) ||
    (!isNil(searchCriteria.searchText) && searchCriteria.searchText !== '') ||
    !isNil(searchCriteria.endDate) ||
    !isNil(searchCriteria.latLng) ||
    !isNil(searchCriteria.startDate);
};
export const shareGide = (title: string, url: string, showNotification: (toasterMessageInfo: ToasterMessageInfo) => void, messageTarget?: string) => {

  const gideUrl = `${GIDES_URL_PREFIX}${url}`;
  if (!window.cordova) {
    // Not running in Native, so share with web UI, however that is decided to be done.
    const copiedTextToClipboard = copyToClipboard(gideUrl);
    // Currently it was this 'coming soon' toaster message.
    // setTimeout(() => {
      if(copiedTextToClipboard === true) {
        showNotification({
          title: 'Copied to clipboard!',
          message: gideUrl,
          type: NotificationType.SUCCESS,
          timeoutMilliseconds: 5000,
          target: messageTarget,
        });
      } else {
        showNotification({
          title: 'Unable to copy text to clipboard',
          message: gideUrl,
          type: NotificationType.ERROR,
          timeoutMilliseconds: 5000,
        });
      }
    // }, 100);
  } else {
    // Native share dialog.
    // Pass the appropriate message, url, etc in the options below and details here: https://github.com/eddyverbruggen/socialsharing-phonegap-plugin
    const options = {
      message: title, // not supported on some apps (Facebook, Instagram)
      subject: 'Check out my gide', // fi. for email
      // files: ['', ''], // an array of filenames either locally or remotely
      url: `${process.env.REACT_APP__CLIENT_ROOT}${url}`,
      // chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
      // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
      // iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
    };

    const onSuccess = function(result: object) {
      // console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
      // console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    };

    const onError = function(msg: string) {
      // console.log("Sharing failed with message: " + msg);
    };

    window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
  }
};
export const copyToClipboard = (value: string): boolean => {
  let success = true;
  // For IE.
  if (window.clipboardData) {
    window.clipboardData.setData("Text", value);
    return true;
  } else {
    const copyText = document.getElementById("clipboardElement");

    /* Select the text field */
    (copyText as any).value = value;
    (copyText as any).select();
    (copyText as any).setSelectionRange(0, 99999); /*For mobile devices*/

    // Lets copy.
    try {
      // success =
      document.execCommand ("copy", false);
    }
    catch (e) {
      success = false;
    }
  }
  return success;
}
export const noop = () => {};

// TODO: Need to remove the check for the port and return the port whaterver it is. Had to temporarily bypass this because it was returing the port even when it should be 80 or 443
export const GIDES_URL_PREFIX = (window.location.port === '80' || window.location.port === '443')
  ? `${window.location.protocol}//${window.location.hostname}`
  : window.location.port === '4100' ? (`${window.location.protocol}//${window.location.hostname}:${window.location.port}`) : (`${window.location.protocol}//${window.location.hostname}`);

export const getUrlForTarget = (src: string): string => {
  return src && (window as any).cordova && src.startsWith('/')
  ? src.substr(1, src.length - 1)
  : src;
}

export const getSlideFileCaptionPlaceholder = (slideType: string): string => {
  const vowels = ['a', 'e', 'i', 'o', 'u'];
  const loweredSlideType = slideType.toLowerCase();
  if(contains(loweredSlideType.substr(0, 1), vowels)) {
    return `Add an ${loweredSlideType} caption`;
  } else {
    return `Add a ${loweredSlideType} caption`;
  }
};


export const openModalForType = (modalProps: { modalType: string, modalProps?: any},
  routeToModal: (modalProps: { modalType: string, modalProps?: any}) => void,
  openModal: (modalProps: { modalType: string, modalProps?: any}) => void) => {

  if(isRoutedModal(modalProps.modalType)) {
    routeToModal(modalProps)
  } else {
    openModal(modalProps);
  }
}

export const closeModalForType = (closeModal: () => void, history: History<any>, modalType?: string) => {
  if(modalType && isRoutedModal(modalType)) {
    history.goBack();
  } else {
    closeModal();
  }
}

export const displayGideTitle = (title: string, type: string) => {
  if (!title) {
    return "Untitled";   
  } else if (title === "Trash" && type === "SPECIAL") {
    return "Trashed Slides";
  } else {
    return title; // Return the title "as-is".
  }
}

export const isReadOnlyDevice = (): boolean => {
  if(isMobileDevice() && !(window as any).cordova) {
    return true;
  }
  return false;
}

export const humanizedDurationBetweenDates = (date1: Date, date2: Date): string => {
  const m1 = moment(date1), m2 = moment(date2);
  const duration = moment.duration(m1.diff(m2));
  return duration.humanize(true);
}
