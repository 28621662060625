import React from 'react';
import styles from './_gide-title.module.scss';
import icons from '../../../../assets/icons';
import classNames from 'classnames';
import { v4 } from 'uuid';
import GideImage from '../../../Shared/Image/GideImage';
export interface GideTitleProps {
  authorViewing: boolean;
  gideImage?: string;
  viewMode: string | null;
  gideTitle?: string;
  isMobile: boolean;
  dropdownTitleOpen?: boolean;
  leftSidebarOpen: boolean;
  onSetSlideZero: () => void;
  onExpandDropdownTitle?: () => void;
  onCollapseDropdownTitle?: () => void;
}

export default function GideTitle(props: GideTitleProps) {
   
  return (
    <div className={styles.gideTitleContainer}>
      { // props.authorViewing &&
        (props.viewMode === 'SLIDE' || props.viewMode === 'SCROLL') &&
        props.dropdownTitleOpen === true && (
        <div className={classNames(props.authorViewing ? styles.hideExpanderInDesktop : undefined)}
          onClick={props.onCollapseDropdownTitle}
        >
          <icons.Nav_Arrow_ArrowUp color="var(--COLOR-SECONDARY-500)" />
        </div>
      )}
      { // props.authorViewing &&
        (props.viewMode === 'SLIDE' || props.viewMode === 'SCROLL') &&
        props.dropdownTitleOpen === false &&
        (!props.authorViewing || props.isMobile === false) &&
        props.gideImage &&
        props.gideImage && (
          <div className={classNames(props.authorViewing ? styles.hideExpanderInDesktop : undefined)}
            onClick={props.onExpandDropdownTitle}>
            <icons.Nav_Arrow_ArrowDown color="var(--COLOR-SECONDARY-500)" />
          </div>
        )}
      <div className={classNames(styles.gideImageDropZone,
              props.dropdownTitleOpen === true ? styles.hideImageInMobile: undefined)}>
        <div
          className={classNames(
            styles.gideImageDropZone,
            props.gideImage && props.gideImage ? styles.hasImage : undefined,
          )}
        >
          {(!props.gideImage) && props.authorViewing && (
            <>
              <icons.SlideType_Image_Main color="var(--COLOR-SECONDARY-500)" style={{ marginLeft: '3px' }}
              onClick={() => { props.onSetSlideZero(); }}
              />
              <icons.Nav_Accordion_Colapsed color="var(--COLOR-SECONDARY-500)" width={18} height={18} />
            </>
          )}
          {props.gideImage && (
            <div className={styles.gideBarImageContainer}
              onClick={() => {
                if (props.authorViewing) {
                  props.onSetSlideZero();
                } else {
                  if(props.dropdownTitleOpen === true) {
                    props.onCollapseDropdownTitle && props.onCollapseDropdownTitle();
                  } else {
                    props.onExpandDropdownTitle && props.onExpandDropdownTitle();
                  }
                  
                }
              }}
            >
              <GideImage src={props.gideImage} className={styles.gideBarGideImage} alt={`□`} />
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.gideBarGideTitle)}
      >
        <span
          className={classNames(
            styles.gideBarTitleText,
            props.isMobile ? styles.mobileView : props.authorViewing ? styles.hideGideTitleOnMobile : undefined,
            !props.authorViewing ? styles.guestViewer : undefined,
            props.leftSidebarOpen ? styles.leftSidebarOpen : undefined,
          )}
        >
          {props.gideTitle}
        </span>
      </div>
    </div>
  );
}
