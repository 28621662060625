import React, { useState, useLayoutEffect, useRef, CSSProperties, useEffect } from 'react';
import styles from './_LabeledTextInput.module.scss';
import { CircleIconButton } from '../../../Shared/CircleIconButton/CircleIconButton';
import classNames from 'classnames';
import icons from '../../../../assets/icons';

export interface LabeledTextInputApi {
  focus?: (preventScroll?: boolean) => void;
}

export interface LabeledTextInputProps {
  theme?: 'light' | 'dark',
  style?: CSSProperties,
  className?: string,
  text: string;
  placeholder: string;
  label: string;
  sublabel: string;
  skipAcceptStep?: boolean;
  isAcceptable: (text: string) => boolean;
  onAccept: (text: string) => Promise<boolean>;
  onConfirm: (text: string) => void;
  onChange?: (text: string) => void;
  onClear: () => void;
  onFocus?: (text: string) => void;
  onBlur?: (text: string) => void;
  api?: LabeledTextInputApi;
  additionalActions?: {
    label: string;
    button: React.ReactNode;
  } [];
  submitOnEnterKey?: boolean;
  hideAcceptButton?: boolean;
}

export const LabeledTextInput: React.FC<LabeledTextInputProps> = (props) => {
  const [text, setText] = useState(props.text || "");
  const [isModified, setIsModified] = useState(false);
  let textareaRef = useRef<HTMLTextAreaElement>(null);
  useLayoutEffect(() => {
    // Use the textarea's scroll height to allow it to grow on long string or multiple lines.
    if (textareaRef.current) {      
      textareaRef.current.style.height = "24px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  });
  useEffect(() => {
    focus();
  }, [textareaRef]);

  const focus = () => {
    if (textareaRef.current) {
      textareaRef.current.focus(); 
      textareaRef.current.setSelectionRange(text.length, text.length);
    }
  };

  // Setup the API.
  if (props.api && !props.api.focus) {
    props.api.focus = focus;
  }

  const isAcceptable = props.isAcceptable(text);

  const onKeyDown = async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (props.submitOnEnterKey && event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      await onAccept();
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
    setIsModified(true);
    props.onChange && props.onChange(event.target.value);
  }

  const onFocus = () => {
    props.onFocus && props.onFocus(text);
  }

  const onBlur = () => {
    props.onBlur && props.onBlur(text);
  }

  const onClear = () => {
    setText("");
    focus();
    props.onClear && props.onClear();
  };

  const onAccept = async () => {
    if (isModified && !props.skipAcceptStep) {
      const accepted = await props.onAccept(text);
      if (accepted) {
        setIsModified(false);
      }
    } else if (isAcceptable) {
      props.onConfirm(text);
    } else {
      await props.onAccept(text);
    }
  }

  const clearButton = text && (
    <CircleIconButton 
      className={styles.clearButton}
      onClick={onClear}
      backgroundColor={""}
      alt="clear"
      image="/icons/nav/exit/alternative.svg"
    />
  );

  const acceptButtonColor = isAcceptable ? 'whites-normal-1000-svg' : '';
  const acceptButtonBackgroundColor = isAcceptable ? "var(--COLOR-PRIMARY-600)" : "var(--WHITES-NORMAL-100)";
  const acceptButtonImage = isAcceptable && (!isModified || props.skipAcceptStep) ? "/icons/content-alteration/check/main.svg" : "/icons/nav/arrow/arrow-right.svg";
  const acceptButton = (
    <CircleIconButton
      className={classNames(styles.acceptButton)}
      onClick={onAccept}
      backgroundColor={acceptButtonBackgroundColor}
      alt="accept changes"
      image={acceptButtonImage}
      iconCssClass={acceptButtonColor}
    />
  );
  
  const additionalActions = props.additionalActions && props.additionalActions.map(
    (additionalAction: { label: string, button: React.ReactNode}, index: number) => {
    return (
      <div className={styles.labeledButtonContainer}      
        key={index}
        style={{
          marginLeft: "11px",
          marginRight: "11px",
        }}
      >
        {additionalAction.button}        
        <span className={styles.uploadLabel}>{additionalAction.label}</span>
      </div>
    );
  });

  const rightActions = (props.additionalActions) && (
    <div className={styles.rightActions}>
      {additionalActions}
    </div>
  );

  const rightActionsDesktop = (props.additionalActions) && (
    <div className={styles.rightActionsContainer}>
      <div className={styles.otherOptions}>
        Other options
      </div>
      <div className={styles.rightActionsInnerContainer}>        
        {rightActions}
      </div>
    </div>
  );

  const rightActionsMobile = (props.additionalActions) && (
    <div className={classNames(styles.actionContainer, styles.mobileActionContainer)}>
      {rightActions}
      <div className={styles.otherOptions}>
        Other options
      </div>
    </div>
  );

  return (
    <div className={classNames(styles.labeledTextInput, props.className, props.theme === 'light' ? styles.light : null)} 
      style={props.style}>
      { (props.label || props.sublabel || (props.additionalActions && props.additionalActions.length)) &&
        <div style={{
          display: "flex"
        }}>
          <div style={{
            flex: "1 1",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            }}>
            <div className={styles.label}>{props.label}</div>
            <div className={styles.sublabel}>{props.sublabel}</div>
          </div>
          {rightActionsDesktop}
        </div>
      }
      <div className={styles.row}>
        <div className={styles.textInput}>
          <textarea ref={textareaRef}
            placeholder={props.placeholder}
            value={text}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            rows={3}
          />
          {clearButton}
        </div>
        {!props.hideAcceptButton && acceptButton}
      </div>
      {rightActionsMobile}
    </div>
  );
}
