import React from 'react';
import ResizeableModal from '../../modals/ResizeableModal/ResizeableModal';
import styles  from './_new-slide-type-chooser-modal.module.scss';
import icons from '../../../assets/icons';
import { slideEditorModalLookup } from '../../../utils/helperFunctions';
import { User } from '../../../models/User';
import { Article } from '../../../models/Article';
import { Slide } from '../../../models/Slide';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';

export interface  NewSlideTypeChooserModalProps {
  closeModal: () => void;
  openModal: (payload: {modalType: string, modalProps: any}) => void;
  onSubmitSlide: (slide: Slide) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  currentUser: User;
  article: Article;
  slidePosition: number;
  className?: string;
}

export const AllSlideTypes = (props: {openModal: (slideType: string) => void}) => <>
  <icons.SlideType_Image_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Image');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Video_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Video");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Audio_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Audio");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Link_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Link");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Location_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Location');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_List_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('List');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_File_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('File');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Gallery_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('ImageGallery');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Slideshow_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Slideshow');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Authorize_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Authorize');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Input_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Input');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Socialmedia_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Embed');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Contact_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Contacts');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Insert_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Gide');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Doodle_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Doodle');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Map_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Map');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Columns_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Column');
    }}
    className={styles.slideTypeItem}
  />
                          
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
  <span className="emptySpan" />
</>;

export const LimitedSlideTypes = (props: {openModal: (slideType: string) => void}) => <>
  <icons.SlideType_Image_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal('Image');
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Video_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Video");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Audio_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Audio");
    }}
    className={styles.slideTypeItem}
  />
  <icons.SlideType_Link_ProductIcon
    style={{width: '72px', height: '72px'}}
    doNotFillFromColor={true}
    onClick={() => {
      props.openModal("Link");
    }}
    className={styles.slideTypeItem}
  />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
    <span className="emptySpan" />
</>;


export default function NewSlideTypeChooserModal (props:  NewSlideTypeChooserModalProps) {
  const openModal = (modalType: string) => {
    props.closeModal();
    if (slideEditorModalLookup[modalType]) {
      props.openModal({
        modalType: slideEditorModalLookup[modalType],
        modalProps: {
          currentUser: props.currentUser,
          article: props.article,
          position: props.slidePosition,
          startCamera: modalType === 'IMAGECAMERA' ? true : false,
          onSubmitSlide: props.onSubmitSlide,
          modalType: slideEditorModalLookup[modalType]
        }
      });
    } else {
      props.openModal({
        modalType: `${modalType}Modal`,
        modalProps: {
          slideType: modalType,
          article: props.article,
          onSubmitSlide: props.onSubmitSlide,
          currentSlidePosition: props.slidePosition,
          position: props.slidePosition,
          modalType: `${modalType}Modal`,
        },
      });
    }
  }
  return (
    <ResizeableModal
      canResize={true}
      closeModal={() => props.closeModal()}
      className={`useMaxWidth${props.className ? ' ' + props.className : ''}`}
      showInputBar={true}
      mode='auto'
      width={752}
      hideCommandBar={true}
      closeOnDimmer={true}
    >
      <div className={styles.newSlideTypeChooser}>
        {(props.currentUser.allowFullAccess === true) && (
          <AllSlideTypes openModal={openModal} />
        )}
        {(props.currentUser.allowFullAccess !== true) &&
          <LimitedSlideTypes openModal={openModal} />
        }
      </div>
    </ResizeableModal>
  );
}
