import React, { useState, useRef } from 'react';
import ResizeableModal from '../ResizeableModal/ResizeableModal';
import { Label, Input } from 'semantic-ui-react';
import { EditorState } from '../../Navigation/CreationProcess/EditorState/EditorState';
import icons from '../../../assets/icons';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { NotificationType, ASSET_VERSION_NUMBER } from '../../../constants/strings';
import { hasValue, noop } from '../../../utils/helperFunctions';
import styles from './_mailchimp-signup-modal.module.scss';
import { RequiredTextboxWithLabel } from '../../AccountProfile/ProfileEditor/ProfileEditor';
import { RoundedCornerButton } from '../../Shared/RoundedCornerButton/RoundedCornerButton';
import classNames from 'classnames';
import MultiImage from '../../Shared/Image/MultiImage/MultiImage';
import { homeCarouselImageSet } from '../../Viewers/ImageViewers/ComponentCarousel/CarouselHelper';
import { CarouselLabeledButtonComponent } from '../../Viewers/ImageViewers/ComponentCarousel/component';

export interface  MailChimpSignupModalProps {
  closeModal: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
}

export default function MailChimpSignupModal (props:  MailChimpSignupModalProps) {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  let chimpForm = useRef<HTMLInputElement>(null);
  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const isValid = false;
    let invalidFields = '';
    if(!hasValue(firstName)) {
      invalidFields = 'First Name'      
    }
    if(!hasValue(lastName)) {
      invalidFields = hasValue(invalidFields) ? `${invalidFields}, First Name`  : 'First Name'
    }
    if(!hasValue(emailAddress)) {
      invalidFields = hasValue(invalidFields) ? `${invalidFields}, Email`  : 'Email'
    }
    if(!hasValue(username)) {
      invalidFields = hasValue(invalidFields) ? `${invalidFields}, Username`  : 'Username'
    }

    if(!hasValue(invalidFields)) {    
      const data = new FormData(event.target as any);
      const response = await fetch('https://gides.us20.list-manage.com/subscribe/post?u=544f5a283d5e571a2babf7fbd&id=1e64535164', {
        mode: 'no-cors',
        method: 'POST',
        body: data,
      }).catch((reason: any) => {
        // Eat exception since we do not receive the response. 
        console.log('Mailchimp');
      });
      
      props.showNotification({
        message: `Thank you for your interest`,
        title: "Submitted: ",
        type: NotificationType.SUCCESS,
        timeoutMilliseconds: 6000
      });
      props.closeModal();
    } else {
      props.showNotification({
        message: `The following have errors: ${invalidFields}`,
        title: "Errors: ",
        type: NotificationType.ERROR,
        timeoutMilliseconds: 6000
      });
    }
  }
  return (
    <ResizeableModal
      className={classNames(styles.mailChimpModalContainer, 'free' )}
      closeModal={props.closeModal}
      canResize={false}
      mode={"free"}
      style={{ maxWidth: '528px' }}
      navigationState='closeModal'
      rightToolbarContent={
        <div style={{display: 'flex', alignItems: 'center', marginRight: '12px', marginLeft: '26px', flex: 1, justifyContent: 'flex-end'}}>
          <span className='TEXTSUBTITLEblackmedium-emphasisright'>
            Request Access
          </span>
          <EditorState title="" icon={<icons.ContentAlteration_Follower color="var(--COLOR-PRIMARY-700)" />} backgroundColor="var(--WHITES-NORMAL-1000)" />
        </div>
      }
    >
      <div className={styles.mailChimModalOuterFormContainer}>
        <MultiImage
          style={{height: '195px'}}
          imageInfo={{
            imageSet: [
              // {
              //   position: 'center bottom',
              //   repeat: 'no-repeat',
              //   desktopUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/curved-up-background.png?v=${ASSET_VERSION_NUMBER}`,
              //   desktopSize: 'contain',
              //   visibilityMode: 'all'
              // },
              // Top Left Corner Image
              {
                position: 'left top',
                repeat: 'no-repeat',
                desktopUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.2/desktop&tablet.png?v=${ASSET_VERSION_NUMBER}`,
                mobileUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.2/mobile.png?v=${ASSET_VERSION_NUMBER}`,
                // mobileUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/HomeCarousel/Carousel.2/Layer.2/Mobile.png?v=${ASSET_VERSION_NUMBER}`,
                desktopSize: '257px 96px',
                mobileSize: '200px 81px',
                visibilityMode: 'all'
              },
              // Bottom Right Corner Image
              {
                position: 'right bottom',
                repeat: 'no-repeat',
                desktopUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.1/desktop&tablet.png?v=${ASSET_VERSION_NUMBER}`,
                visibilityMode: 'desktop and tablet',
                desktopSize: 'contain',
              },              
              // Background Full Image
              {
                position: 'center',
                desktopVerticalOffset: 58.4,
                repeat: 'repeat',
                desktopUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.3/desktop&tablet.png?v=${ASSET_VERSION_NUMBER}`,
                mobileUrl: `https://d2ettyk3s9h19.cloudfront.net/assets/images/brand-assets/sign-up/step.1/layer.3/mobile.png?v=${ASSET_VERSION_NUMBER}`,
                desktopSize: 'cover',
                visibilityMode: 'all'
              }
            ],
            positionedComponent: {component: 
            <span className={styles.imageLabel}>The only thing missing is U!</span>
            , bottom: 42, left: 18}
          }}
        />
        <div className={styles.mailChimpFormContainer}>
          <span className={styles.subHeading}>
            Join the wave of early adopters, influencers, creators, and leaders who have 
            become a part of our movement to help to create a truly World-Wide Web. 
            Fill in the form below to request your username and be added to our waitlist.
          </span>
          <form onSubmit={submitForm} style={{minWidth: '310px', width: '100%'}}
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
              <div id="mc_embed_signup_scroll">                            
                <div className={styles.row}>
                  <RequiredTextboxWithLabel
                    className={styles.inputField}
                    name="FNAME"
                    type="input"
                    onChange={(value: string | number | undefined) => setFirstName(value ? value as string : '')}
                    value={firstName}
                    label="First Name"
                  />
                  <RequiredTextboxWithLabel 
                    className={styles.inputField}
                    name="LNAME"
                    type="input"
                    onChange={(value: string | number | undefined) => setLastName(value ? value as string : '')}
                    value={lastName}
                    label="Last Name"
                  />
                </div>
                <div className={styles.row}>
                  <RequiredTextboxWithLabel 
                    className={styles.inputField}
                    name="EMAIL"
                    type="input"
                    onChange={(value: string | number | undefined) => setEmailAddress(value ? value as string : '')}
                    value={emailAddress}
                    label="Email"
                  />
                  <RequiredTextboxWithLabel 
                    className={styles.inputField}
                    name="MMERGE3"
                    type="input"
                    onChange={(value: string | number | undefined) => setUsername(value ? value as string : '')}
                    value={username}
                    label="Requested Username (no spaces) "
                  />                
                </div>
                  <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                    <div className="response" id="mce-success-response" style={{display:'none'}}></div>
                  </div>    
                  {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                    <div style={{position: 'absolute', left: '-5000px'}}>
                      <input type="text" name="b_544f5a283d5e571a2babf7fbd_1e64535164" tabIndex={-1} value="" />
                    </div>
                  <div className={styles.actions}>
                    <RoundedCornerButton
                      style={{backgroundColor: 'var(--COLOR-PRIMARY-700)', width: '100%', marginRight: '5px'}}
                      onClick={() => chimpForm.current ? chimpForm.current.click() : noop}
                      label="Confirm"
                    />
                    <div className="clear" style={{display: 'none'}}>
                      <input type="submit" style={{display: 'none'}} ref={chimpForm} value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
                    </div> 
                  </div>
              </div>
          </form>
        </div>
        
      </div>
    </ResizeableModal>
  );
}
