import React, { Component, CSSProperties } from 'react';
import agent from '../../../agent';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { Slide } from '../../../models/Slide';
import { User, AnonymousUser } from '../../../models/User';
import { append, contains, reject } from 'ramda';
import { SlideFileReference } from '../../../models/SlideFileReference';
import { SlideFile, SlideReferenceFile, ImageFile, AudioSlideFile, VideoFile, MediaFile } from '../../../models/SlideFile';
import ImageView from '../../Viewers/ImageViewers/ImageView/ImageView';
import VideoViewer from '../../Viewers/VideoViewers/VideoViewer/VideoViewer';
import AudioView from '../../Viewers/AudioViewers/AudioView/AudioView';
import { RoundedCornerButton } from '../RoundedCornerButton/RoundedCornerButton';
import { SlideView } from '../../Gide/SlideView/SlideView';
import GideImage from '../Image/GideImage';
import { SlideEditorCommandBar } from '../../SlideEditors/SlideEditorCommandBar/SlideEditorCommandBar';
import { BackIconMode } from '../../modals/SlideEditorModal/SlideEditorModal';
import { CircleIconButton } from '../CircleIconButton/CircleIconButton';
import icons from '../../../assets/icons';
import GideChooser from '../GideChooser/GideChooser';
import { ItemSelectionMode } from '../Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import { Agent } from '../../../models/Agent';
import { GideSearchElement } from '../GideElementSearch/GideElementSearch';


export interface SlideFileChooserProps {
  hideActionBar?: boolean;
  hideCommandBar?: boolean;
  style?: CSSProperties;
  currentUser: User;
  slideTypes: string[];
  chooseCompleteSlide?: boolean;
  title?: string;
  subtitle?: string;
  singleSelectionOnly: boolean;
  agent: Agent;
  files?: FileInfo[];
  slides?: Slide[];
  selectedSlideIds?: string[];
  mode?: 'distribution';
  onNavigateBack?: () => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  addSlideFileReferences: (slideFileReferences: SlideFileReference[]) => void;
  removeSlideFileReferences?: () => void;
}
interface FileInfo {
  file: SlideFile;
  type: string;
}
export interface SlideFileChooserState {
  gides: GideSearchElement[];
  searchText: string;
  selectedArticle?: GideSearchElement;
  files?: FileInfo[];
  slides?: Slide[];
  selectedSlideFiles: FileInfo[];
  selectedSlides: Slide[];
}

export default class SlideFileChooser extends Component<SlideFileChooserProps, SlideFileChooserState> {
  constructor(props: SlideFileChooserProps) {
    super(props);

    this.state = {
      gides: [],
      searchText: '',
      selectedSlideFiles: [],
      selectedSlides: props.selectedSlideIds && props.slides ? props.slides.filter(s => contains(s.id, (props.selectedSlideIds as string[]))) : [],
      slides: props.slides,
      files: props.files,
    };
  }

  async searchForGides() {
    const searchResults: GideSearchElement[] = await this.props.agent.Users.gideSearch({ searchText: this.state.searchText });
    this.setState({ gides: searchResults });
  }
  async loadArticleSlidesOrSlideFiles(article: GideSearchElement) {
    const response: { slides: Slide[] } = await this.props.agent.Slides.forArticle({
      id: article.id,
    });
    if (this.props.chooseCompleteSlide) {
      this.setState({
        slides: response.slides.filter(s => s.slideType !== 'LINKS'),
        selectedArticle: article,
      });
    } else {
      const files: FileInfo[] = [];
      response.slides
        .filter(s => contains(s.slideType, this.props.slideTypes))
        .forEach(s => {
          s.data.files.forEach((f: SlideFile) => {
            // TODO: Handler reference file types. Get the reference instead of the
            files.push({
              file: f,
              type: s.slideType,
            });
          });
        });
      this.setState({ files: files, selectedArticle: article });
    }
  }

  selectFile(file: FileInfo) {
    this.setState({
      selectedSlideFiles: append(file, this.state.selectedSlideFiles),
    });
  }
  removeFile(file: FileInfo) {
    this.setState({
      selectedSlideFiles: reject(i => i === file, this.state.selectedSlideFiles),
    });
  }
  isFileSelected(file: FileInfo): boolean {
    return contains(file, this.state.selectedSlideFiles);
  }
  removeSlide(slide: Slide) {
    this.setState({
      selectedSlides: reject(i => i === slide, this.state.selectedSlides),
    });
  }
  selectSlide(slide: Slide) {
    if (this.props.singleSelectionOnly) {
      this.setState({ selectedSlides: [slide] });
    } else {
      this.setState({
        selectedSlides: append(slide, this.state.selectedSlides),
      });
    }
  }
  isSlideSelected(slide: Slide): boolean {
    return contains(slide, this.state.selectedSlides);
  }
  slideTypeAction = () => {
    if (this.state.selectedArticle) {
      return (
        <div className="gideSummaryPreview">
          <div className="gideSummaryInfo">
            <img className="gideImage" src={this.state.selectedArticle.imageUrl} alt={this.state.selectedArticle.displayName} />
            <span className="gideTitle">{this.state.selectedArticle.displayName}</span>
          </div>
          <div className="gideSummaryActions">
            <img
              className="hoverIcon24px"
              style={{ marginLeft: '20px', marginRight: '10px' }}
              src="/icons/nav/threedots/vertical.svg"
              alt="share"
            />
          </div>
        </div>
      );
    } else {
      const title = contains('IMAGE', this.props.slideTypes)
        ? 'Find an image in a gide'
        : contains('VIDEO', this.props.slideTypes)
        ? 'Find a video in a gide'
        : contains('AUDIO', this.props.slideTypes)
        ? 'Find an audio track in a gide'
        : 'Find a slide in a gide';
      return (
        <div className="headerActionsFlexEnd TEXTSUBTITLEblackmedium-emphasisright">
          <div
            style={{
              marginRight: '6px',
            }}
          >
            {title}
          </div>
          <CircleIconButton
            width={30}
            height={30}
            alt={title}
            backgroundColor="var(--COLOR-PRIMARY-700)"
            iconCssClass="whites-normal-1000-svg"
            image={'/icons/nav/logo/logo-icon-md.svg'}
          />
        </div>
      );
    }
  };

  getSlideFileReference = (reference: Slide | MediaFile, gide: GideSearchElement): SlideFileReference => {
    return {
      referenceSlide: (reference as Slide).slideType ? reference as Slide: undefined,
      referenceFile: (reference as Slide).slideType ? undefined : reference as MediaFile,
      article: {
        id: gide.id,
        slug: gide.slug,
        title: gide.displayName,
        description: gide.description,
        hasGeotag: gide.hasGeotag,
        viewCount: gide.viewCount ? gide.viewCount : 0,
        type: gide.type,
        author: gide.authorUsername,
        authorImage: gide.authorImageUrl,
        image: gide.imageUrl,
        numberOfSlides: gide.slidesLength,
        updatedAt: gide.updatedAt,
        createdAt: gide.createdAt,
      },
    };
  }

  public render() {
    const slideViewSlideStyle = this.props.mode === 'distribution' ? {
      width: '100%', 
      maxHeight: '140px', 
      overflow: 'hidden', 
      background: 'var(--WHITE-BACKGROUND-03)'
    } : undefined;
    return (
      <div className="slideFileChooser" style={this.props.style}>
        {this.props.onNavigateBack && !this.props.hideCommandBar && (
          <SlideEditorCommandBar
            style={{ borderBottom: 'solid var(--GREYS-NORMAL-50) 2px' }}
            showBackNavigationButton={true}
            onNavigateBack={() => {
              // First check to see if the article viewer is displayed. If so then
              // back 1 step to the search.
              if (this.state.files || this.state.slides) {
                this.setState({
                  files: undefined,
                  slides: undefined,
                  selectedSlides: [],
                  selectedSlideFiles: [],
                  selectedArticle: undefined,
                });
              } else if (this.props.onNavigateBack) {
                this.props.onNavigateBack();
              }
            }}
            showDefaultActions={false}
            backIconMode={BackIconMode.Light}
          >
            {<this.slideTypeAction />}
          </SlideEditorCommandBar>
        )}
        <div
          className="articleChooser"
          style={{
            display: `${this.state.files || this.state.slides ? 'none' : 'inherit'}`,
          }}
        >
          <GideChooser
            agent={this.props.agent}
            slideTypes={this.props.slideTypes}
            title={this.props.title}
            subtitle={this.props.subtitle}
            onSelectGide={(gideSummary: GideSearchElement) => {
              this.loadArticleSlidesOrSlideFiles(gideSummary);
            }}
            selectionMode={ItemSelectionMode.SingleDisplayNone}
          />
        </div>
        {(this.state.files || this.state.slides) && (
          <div className="slideFileChooserImageListSelection">
            <div className="slideFileChooserImageListContainer">
              <div className="slideFileChooserImageList">
                {this.state.files &&
                  this.state.files.map((fileInfo: FileInfo, i: number) => (
                    <div className="slideFileChooserSlideViewCard">
                      <div className="slideFileChooserFileContainer" key={`slideFileChooserGideImage${i}`}>
                      {/* TODO: Try to use slideview for this. */}
                      {fileInfo.type === 'AUDIO' && (
                        <AudioView audioFile={fileInfo.file as AudioSlideFile} isReferenceType={false} autoPlay={false} />
                      )}
                      {fileInfo.type === 'IMAGE' && (
                        <ImageView
                          className={this.state.files && i < this.state.files.length - 1 ? 'hasEnsuingItem' : undefined}
                          imageFile={fileInfo.file as ImageFile}
                          isReferenceType={(fileInfo.file as ImageFile).type === 'SLIDEFILE'}
                        />
                      )}
                      {fileInfo.type === 'VIDEO' && (
                        <VideoViewer
                          videoFile={fileInfo.file as VideoFile}
                          className={this.state.files && i < this.state.files.length - 1 ? 'hasEnsuingItem' : undefined}
                        />
                      )}
                      <div
                        className="slideFileChooserFooter"
                        onClick={() => {
                          if (this.isFileSelected(fileInfo)) {
                            this.removeFile(fileInfo);
                          } else {
                            this.selectFile(fileInfo);
                          }
                        }}
                      >
                        {!this.isFileSelected(fileInfo) && (
                          <>
                            {!this.props.singleSelectionOnly && (
                              <GideImage
                                src="/icons/content-alteration/checkbox/checkbox-deactive.svg"
                                className="unselectedImageBox color-secondary-600-svg"
                                onClick={() => this.selectFile(fileInfo)}
                                alt="select slide"
                              />
                            )}
                            {this.props.singleSelectionOnly && (
                              <icons.ContentAlteration_Radio_Deactive/>
                            )}
                          </>
                        )}
                        {this.isFileSelected(fileInfo) && (
                          <>
                            {!this.props.singleSelectionOnly && (
                              <GideImage
                                src="/icons/content-alteration/checkbox/checkbox-active.svg"
                                className="selectedImageBox color-primary-500-svg"
                                onClick={() => this.removeFile(fileInfo)}
                                alt="remove slide"
                              />
                            )}
                            {this.props.singleSelectionOnly && (
                              <icons.ContentAlteration_Radio_Active color="var(--COLOR-PRIMARY-600)"/>
                            )}
                          </>
                        )}
                        <span className="TEXTSUBTITLEblackmedium-emphasisleft">Select</span>
                      </div>
                    </div>
                    </div>
                  ))}

                {this.state.slides &&
                  this.state.slides.map((slide: Slide, i: number) => (
                    <div className="slideFileChooserSlideViewCard" key={`slideFileChooserGideImage${i}`}>
                      <div className="slideFileChooserFileContainer">
                      <SlideView
                        style={slideViewSlideStyle}
                        slide={slide}
                        slidePosition={i}
                        currentUser={AnonymousUser}
                        viewMode={'SCROLL'}
                        view={'website'}
                        disableExpiration={true}
                      />
                      <div
                        className="slideFileChooserFooter"
                        onClick={() => {
                          if (this.isSlideSelected(slide)) {
                            this.removeSlide(slide);
                          } else {
                            this.selectSlide(slide);
                          }
                        }}
                      >
                        <div className="selectorAndLabel">
                          {!this.isSlideSelected(slide) && (
                            <>
                              {!this.props.singleSelectionOnly && (
                                <GideImage
                                  src="/icons/content-alteration/checkbox/checkbox-deactive.svg"
                                  className="unselectedImageBox color-secondary-600-svg"
                                  onClick={() => this.selectSlide(slide)}
                                  alt="select slide"
                                />
                              )}
                              {this.props.singleSelectionOnly && (
                                  <icons.ContentAlteration_Radio_Deactive/>
                              )}
                            </>
                          )}
                          {this.isSlideSelected(slide) && (
                            <>
                              {!this.props.singleSelectionOnly && (
                                <GideImage
                                  src="/icons/content-alteration/checkbox/checkbox-active.svg"
                                  className="selectedImageBox color-primary-500-svg"
                                  onClick={() => this.removeSlide(slide)}
                                  alt="remove slide"
                                />
                              )}
                              {this.props.singleSelectionOnly && (
                                <icons.ContentAlteration_Radio_Active color="var(--COLOR-PRIMARY-600)"/>
                              )}
                            </>
                          )}
                          <span className="TEXTSUBTITLEblackmedium-emphasisleft">Select</span>
                        </div>
                        <span className="slideSelectorSlideNumber" style={{ marginRight: '5px' }}>
                          {slide.position + 1}
                        </span>
                      </div>
                    </div>
                    </div>
                  ))}
              </div>
              <div className="slideFileChooserImageSelectionActions">
                <RoundedCornerButton
                  label="Uncheck all"
                  labelColor="var(--COLOR-SECONDARY-500)"
                  style={{ width: '124px', backgroundColor: '#fafafa' }}
                  icon={<icons.ContentAlteration_Checkbox_Uncheck color="var(--COLOR-SECONDARY-500)" />}
                  iconCssClass="color-secondary-600-svg"
                  fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
                  imagePosition="right"
                  onClick={() => {
                    this.props.removeSlideFileReferences && this.props.removeSlideFileReferences();
                    this.setState({ selectedSlideFiles: [], selectedSlides: [] });
                  }}
                  disabled={this.state.selectedSlideFiles.length === 0 && this.state.selectedSlides.length === 0}
                />
                <RoundedCornerButton
                  label="Done"
                  labelColor="var(--WHITES-NORMAL-1000)"
                  style={{ width: '84px', marginLeft: '12px', backgroundColor: 'var(--COLOR-PRIMARY-600)' }}
                  icon={<icons.ContentAlteration_Check_Main color="var(--WHITES-NORMAL-1000)" />}
                  iconCssClass="whites-normal-1000-svg"
                  fontCssClass="TEXTSUBTITLEwhitehigh-emphasisleft"
                  imagePosition="right"
                  onClick={() => {
                    const selectedSlides = this.state.selectedSlides;
                    if (this.state.selectedArticle) {
                      const selectedGide = this.state.selectedArticle;                      
                      const selectedSlideFiles = this.state.selectedSlideFiles;

                      if (selectedSlides && selectedSlides.length > 0) {
                        const slideFileReferences = selectedSlides.map(slide => this.getSlideFileReference(slide, selectedGide));
                        this.props.addSlideFileReferences(
                          slideFileReferences
                        );
                      } else {
                        this.props.addSlideFileReferences(
                          selectedSlideFiles.map(i => this.getSlideFileReference({...(i.file as SlideReferenceFile),}, selectedGide))
                        );
                      }
                    } else if (selectedSlides) {
                      this.props.addSlideFileReferences(
                        selectedSlides.map(slide => {
                          return {
                            referenceSlide: slide
                          };
                        }),
                      );
                    }
                  }}
                  disabled={this.state.selectedSlideFiles.length === 0 && this.state.selectedSlides.length === 0}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
