import {
    MODAL_CLOSE,
    MODAL_OPEN,
    ARTICLE_PAGE_UNLOADED,
    ARTICLE_PAGE_SELECTED_FROM_SIDEBAR,
    LOGOUT,
    SETTINGS_SAVED,
    CHANNEL_SUBMITTED
} from "../constants/actionTypes";
import { contains, reject, append } from 'ramda';
import { ROUTER_LOCATION_CHANGE } from "./common/common.actions";
import produce from "immer";
import {  isRoutedModal } from "../utils/helperFunctions";

export default (state = [], action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return state.concat({
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      });
    case LOGOUT: {
      let stateCopy = state.slice();
      return reject(d => contains(d.modalType, action.payload.closeDialogList), stateCopy);
    }
    case ARTICLE_PAGE_UNLOADED:
    case ARTICLE_PAGE_SELECTED_FROM_SIDEBAR:
    case MODAL_CLOSE: {
      let stateCopy = state.slice();
      if (action.payload && action.payload.closeDialogList) {
        stateCopy = reject(d => contains(d.modalType, action.payload.closeDialogList), stateCopy);
      } else {
        stateCopy.pop();
      }
      return stateCopy;
    }
    case SETTINGS_SAVED : {
      const modals = state.map(m => {
        if(m.modalType === 'AccountProfileModal') {
          return {
            ...m,
            modalProps: {
              ...m.modalProps,
              profile: {
                ...m.modalProps.profile,
                username: action.payload.user.username,
                firstName: action.payload.user.firstName,
                secondName: action.payload.user.secondName,
                bio: action.payload.user.bio,
                image: action.payload.user.image,
                business: action.payload.user.business,
                businessFocus: action.payload.user.businessFocus,
                brandingImage: action.payload.user.brandingImage,
                phone: action.payload.user.phone,
                email: action.payload.user.email,
                allowEmailContact: action.payload.user.allowEmailContact,
                language: action.payload.user.language,
                country: action.payload.user.country,
              }
            }
          }
        } else {
          return m;
        }
      });
      return modals;
    }
    case CHANNEL_SUBMITTED : {
      const modals = state.map(m => {
        if(m.modalType === 'AccountProfileModal') {
          return produce(m, draft => {
            const existingChannelIndex = draft.modalProps.profile.channels.findIndex(x => x._id === action.payload.channel._id);
            if (existingChannelIndex >= 0) {
              draft.modalProps.profile.channels[existingChannelIndex] = action.payload.channel;
            } else {
              draft.modalProps.profile.channels.push(action.payload.channel);
            }
          });
        } else {
          return m;
        }
      });
      return modals;
    }
    case ROUTER_LOCATION_CHANGE: {
      const modalType = state.length > 0 ? state[0].modalType : undefined;      
      if(action.payload.action === 'POP' && modalType && isRoutedModal(modalType)) {
        return [];
      }
      
      const modals = state.map(m => {
        if(m.modalType === 'AccountProfileModal') {
          const paths = action.payload.location
            && action.payload.location.search 
            && action.payload.location.search.split('=');
          const page = paths.length > 2
            ? paths[2].split('&')[0]
            : undefined;
          return {
            ...m,
            modalProps: {
              ...m.modalProps,
              page: page
            }
          }
        } else {
          return m;
        }
      });
      return modals;
    }
    default:
      return state;
  }
};
