import './main.css';
// Polyfill libs for IE - polyfill.io is another option
import 'core-js';
import 'url-search-params-polyfill';
// End Polyfill libs
import './socket';
import './imageViewer';
import 'flag-icon-css/css/flag-icon.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MaterialThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { store, history } from './store';
import App from './components/App';
import { lightTheme } from './themes/custom-theme';
import { NotificationProvider } from './components/Shared/Notification/NotificationContext';

import { NotFound } from './components/NotFound/NotFound';



let muiTheme = createMuiTheme({
  palette: {
    primary: { 
      main: "#ffb213" 
    },
    secondary: { 
      main: "#ffb213"
    },
  },
});

export const rootPath = window.cordova ? '' : process.env.PUBLIC_URL;
const startApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <MaterialThemeProvider theme={muiTheme}>
          <NotificationProvider>
            <ConnectedRouter history={history}>
              <Switch>
                <Route path={`${rootPath}/404`} component={NotFound} />
                <Route path={`${rootPath}/`} component={App} />
              </Switch>
            </ConnectedRouter>
          </NotificationProvider>
        </MaterialThemeProvider>
      </ThemeProvider>
    </Provider>,

    document.getElementById('root'),
  );
}
if(!window.cordova) {
  startApp()
} else {
  document.addEventListener('deviceready', startApp, false)
}
