// HOST neccesary to know the origin of deployment during custom domain
// serving -- window.location.host will return various hosts, but it's actually
// running on and potentially referencing data from gides.com, slideswiper.com,
// or localhost if in dev.  So it's important to set this.
export const HOST = 'slideswiper.com';
export const VERSION_NUMBER = process.env.REACT_APP__VERSION || "UNKNOWN";
export const ASSET_VERSION_NUMBER = '2020-04-02';
  //'0.1006'; // Optionally bumped for smoke test

// List Slide Checkable Item Types
export const CHECKABLE_LISTITEM_USER = 'USER';
export const CHECKABLE_LISTITEM_EXHIBIT = 'EXHIBIT';
export const CHECKABLE_LISTITEM_TODO = 'TODO';
export const CHECKABLE_LISTITEM_NUMBERED = 'NUMBERED';
export enum InputBarOpenMenu {
  NONE = 1,
  SLIDE = 2,
  HEADER = 3,
}
export enum InputBarShortcutMode {
  Header = 1,
  Position = 2,
  Collapse = 3,
}
export enum ViewBarExpanderType {
  None = 0,
  Navigide = 1,
  ViewSettings = 2,
  Search = 3,
  Note = 4,
  Distribute = 5,
  Reshare = 6,
  NewGide = 7,
}
export enum HeaderLevel {
  UNSET = -1,
  Ti = 0,
  H1 = 1,
  H2 = 2,
  H3 = 3,
  H4 = 4,
  H5 = 5,
  // H6 = 6,
};
export enum InputBarMenuTypes {
  IMAGE = 0,
  AUDIO = 1,
  HEADER = 2,
  CLEAR = 3,
  TEXT = 4,
  COLLAPSE_BEGIN = 5,
  COLLAPSE_END = 6,
  HEADER_END = 7,
  HEADER_RESET = 8,
  TITLE = 9,
}
export enum NotificationType {
  INFO = 1,
  WARNING = 2,
  MESSAGE = 3,
  ERROR = 4,
  SUCCESS = 5,
}
export const getEnumName = (enumType: any, value: number): string => {
  return enumType[value];
};
export const DEFAULT_FONT = 'Arial, Helvetica, sans-serif';

export const EXCLUDED_SLUG_CHARS: string[] = [
  ' ',
  '?',
  ',',
  '#',
  '>',
  '<',
  '/',
  '\\',
  '[',
  ']',
  '{',
  '}',
  '|',
  ':',
  ';',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '(',
  ')',
  '`',
  '~',
];
