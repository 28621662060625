import React from 'react';
import { hasValue, extractHostname, getSlideFileUrl, getHost, addHttpIfAbsentUrl } from '../../../../utils/helperFunctions';
import { SlideFileReference } from '../../../../models/SlideFileReference';
import { ArticleSummary } from '../../../../models/ArticleSummary';
import { ArticleSlideReferenceHeader } from '../../../Shared/ArticleSlideReferenceHeader/ArticleSlideReferenceHeader';

// TODO: Maybe pass this in as props???
import { createBrowserHistory } from 'history';
import { SlideItemSelectionActionType, ImageFile } from '../../../../models/SlideFile';
import { Slide } from '../../../../models/Slide';
import SlideFileCaption from '../../../Slides/SlideFileCaption/SlideFileCaption';
import GideImage from '../../../Shared/Image/GideImage';
export const history = createBrowserHistory();

export interface ImageViewProps {
  imageFile: ImageFile;
  isReferenceType: boolean;
  className?: string;
  slide?: Slide;
  openModal?: (payload: any) => void;
  closeModal?: () => void;
  onClick?: () => void;
}

export default function ImageView(props: ImageViewProps) {
  let linkURL: string | undefined = undefined;
  let target = '';
  if (props.imageFile.onSelect && props.imageFile.onSelect.slideItemSelectionActionType === SlideItemSelectionActionType.OpenLink) {
    target = props.imageFile.onSelect.openLinkInNewWindow === true ? '_blank' : '_self';
    if ((props.imageFile.onSelect.linkItemType === "ARTICLE"
      || props.imageFile.onSelect.linkItemType === "SLIDE")
      && hasValue(props.imageFile.onSelect.linkItemReference)) {
      linkURL = `${getHost()}${props.imageFile.onSelect.linkItemReference}`;
    } else { // URL
      const { updatedUrl } = addHttpIfAbsentUrl(props.imageFile.onSelect.linkItemReference ? props.imageFile.onSelect.linkItemReference : '');
      linkURL = updatedUrl;
    }
  }
  return (
    <div className={`imageViewOuterContainer ${props.className ? props.className : ''}`}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <div className={`imageViewImageContainer`}>
        {!props.isReferenceType && (
          <>
            {linkURL && (
              <a href={`${linkURL}`} target={`${target}`}>
                <GideImage className={`sourceImage${props.slide && props.slide.useNativeResolution ? ' useNativeResolution' : ''}`}
                  src={getSlideFileUrl(props.imageFile)}
                  alt={getSlideFileUrl(props.imageFile)}
                />
              </a>
            )}
            {!linkURL && (
              <GideImage className={`sourceImage${props.slide && props.slide.useNativeResolution ? ' useNativeResolution' : ''}`}
                src={getSlideFileUrl(props.imageFile)}
                alt={getSlideFileUrl(props.imageFile)}
              />
            )}

          </>
        )}
        {props.isReferenceType && props.imageFile.slideFileReference && (
          <div className='slideReferenceContainer'>
            <ArticleSlideReferenceHeader
              articleAuthorImageUrl={((props.imageFile.slideFileReference as SlideFileReference).article as ArticleSummary).authorImage}
              articleAuthor={((props.imageFile.slideFileReference as SlideFileReference).article as ArticleSummary).author}
              articleTitle={((props.imageFile.slideFileReference as SlideFileReference).article as ArticleSummary).title}
              articleImage={((props.imageFile.slideFileReference as SlideFileReference).article as ArticleSummary).image}
              createdAt={((props.imageFile.slideFileReference as SlideFileReference).article as ArticleSummary).createdAt}
              numberOfSlides={((props.imageFile.slideFileReference as SlideFileReference).article as ArticleSummary).numberOfSlides}
            />
            <ImageView
              imageFile={{
                ...((props.imageFile.slideFileReference as SlideFileReference).referenceFile as ImageFile),
                onSelect: props.imageFile.onSelect
              }}
              isReferenceType={false}
            />
          </div>
        )}
        {props.isReferenceType && !props.imageFile.slideFileReference && (
          <div className='noLongerAvailable'>
            <GideImage src="/icons/delete-when-replaced/content-unavailable.png"
              alt="content is no longer available"
            />
          </div>
        )}
        <SlideFileCaption
          slideType="Image"
          slideFile={props.imageFile}
          linkedFileAttribution={props.imageFile.type === 'LINK' ? extractHostname(getSlideFileUrl(props.imageFile)) : undefined}
        />
      </div>
    </div>
  );
}
