import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Modal, Image, Button, Icon } from 'semantic-ui-react';
import classNames from 'classnames';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import agent from '../../agent';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';
import slideTools from '../../slideTools';
import { stripTags } from '../../utils/helperFunctions';
import { history } from '../../store';
import { Loading } from '../Shared/Loading/Loading';
import GideImage from '../Shared/Image/GideImage';

const RichTextEditor = lazy(() => import('../Editor/RichTextEditor'));

const mapStateToProps = (state, ownProps) => {
  return {
    ...slideTools.mapStateToProps(state, ownProps),
    footer: state.footer,
    textSlideEditing: state.common.textSlideEditing,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class TextModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusEditor: true,
      textEditorValue: '',
      slide: {
        slideType: 'TEXT',
        allowComments: props.editSlide ? props.editSlide.allowComments : props.article.allowSlideComments,
        allowQuestions: props.editSlide ? props.editSlide.allowQuestions : props.article.allowSlideQuestions,
        data: {
          audioCaption: null,
          caption: '',
          body: '',
          fullEditor: true,
        },
      },
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = {
        ...props.editSlide,
        data: {
          ...props.editSlide.data,
        },
      };
      this.state.textEditorValue = this.state.slide.data.body;
    } else if (props.textSlideEditing && props.footer.value) {
      // In this case the user clicked edit on a normal text slide
      // so the text went to the footer. Then the user selected the full

      this.state.slide = {
        ...props.textSlideEditing,
        data: {
          ...props.textSlideEditing.data,
        },
      };
      this.state.textEditorValue = props.footer.value;
    } else {
      if (this.props.footer && this.props.footer.value) {
        this.state.textEditorValue = stripTags(this.props.footer.value);
      }
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateStateCheckbox = field => (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide[field] = data.checked;
      this.setState({ slide });
    };

    this.updateDataStateCheckbox = field => (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = data.checked;
      this.setState({ slide });
    };

    this.setBackgroundColor = color => {
      const slide = Object.assign({}, this.state.slide);
      slide.backgroundColor = color.hex;
      this.setState({ slide });
    };

    this.setFontColor = color => {
      const slide = Object.assign({}, this.state.slide);
      slide.fontColor = color.hex;
      this.setState({ slide });
    };

    this.setFont = (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.font = data.value;
      this.setState({ slide });
    };

    this.handleEditorClick = e => {
      this.setState({ focusEditor: true });
    };

    this.createSlide = async () => {
      // const position = this.props.editSlide
      //   ? this.props.editSlide.position
      //   : this.props.textSlideEditing
      //     ? this.props.textSlideEditing.position
      //     : this.props.position;
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        data: {
          ...this.state.slide.data,
          fullEditor: true, // This is necessary because text slides can now be upgraded to Text Block
          body: this.state.textEditorValue,
        },
        slide: this.props.slide,
        createMode: this.props.mode,
        allowComments: this.state.slide.allowComments,
        allowQuestions: this.state.slide.allowQuestions,
        // allowComments: this.props.editSlide
        //   ? this.state.slide.allowComments
        //   : this.props.article.allowSlideComments,
        // allowQuestions: this.props.editSlide
        //   ? this.state.slide.allowQuestions
        //   : this.props.article.allowSlideQuestions,
        selection: props.selection,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.props.editSlide.position
          : this.props.textSlideEditing ?
          this.props.textSlideEditing.position
          : slidePosition,
      };

      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else if (props.textSlideEditing && props.footer.value) {
          payload = await agent.Slides.update(
            this.props.textSlideEditing.id,
            slide,
          );
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
      this.props.onSubmit(this.state.replaceMode, {
        ...payload,
        mode: this.props.mode,
      });
      history.goBack()
    };

    this.handleEditorChange = textEditorValue => {
      this.setState({ textEditorValue: textEditorValue });
    };
  }

  render() {
    const { slide } = this.state;
    const modalClasses = classNames('modal', 'TEXT');
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        className={modalClasses}
        size="large"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(43, 43, 43)' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content style={{ background: 'rgb(43, 43, 43)' }}>
          <div
            className="modalHeader"
            style={{ background: 'rgb(43, 43, 43)' }}
          >
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <span>Advanced Text</span>
            <GideImage src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description className="dataEntry">
              <section onClick={this.handleEditorClick}>
                <Suspense fallback={<Loading />}>
                  <RichTextEditor
                    style={{ display: 'flex', flex: 1 }}
                    onChange={this.handleEditorChange}
                    value={this.state.textEditorValue}
                    showToolbar={true}
                    limitHeight={true}
                    isReadOnly={false}
                    focusInputOnLoad={true}
                    classes={'defaultRichTextEdit'}
                  />
                </Suspense>
              </section>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={this.updateDataState('caption').bind(this)}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            canNavigateBack={false}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextModal);
