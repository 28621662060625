import React, { CSSProperties } from 'react';
import MaterialButton from '@material-ui/core/Button';

export interface ButtonProps {
  style?: CSSProperties;
  cssClass?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <MaterialButton 
      style={props.style}
      className={props.cssClass} 
      disabled={props.disabled}
      onClick={() => props.onClick && props.onClick()}
    >
      {props.children}
    </MaterialButton>
  );
}
