import React from 'react';
import { isMobileDevice, getPreviewOverlayIconSvg, SlideType, getSlideFileUrl } from '../../../utils/helperFunctions';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import { CommonState, CommonDerivedState, CommonContext, CommonModes, CommonSlideEditorProps } from './Common';
import * as Common from './Common';
import { SlideReferenceFile, LinkSlideFile } from '../../../models/SlideFile';
import { NotificationType } from '../../../constants/strings';

export interface LinkSlideEditorProps extends CommonSlideEditorProps {
  set?: { mode: string };
  url?: string;
}

export const LinkModes = {
  Preview: 'Preview' as 'Preview',
  Link: 'Link' as 'Link',
  Edit: 'Edit' as 'Edit',
  Delete: 'Delete' as 'Delete',
  Tag: 'Tag' as 'Tag',
  Dimensions: 'Dimensions' as 'Dimensions',
  Attribute: 'Attribute' as 'Attribute',
  Audio: 'Audio' as 'Audio',
  ChooseChannel: 'ChooseChannel' as 'ChooseChannel',
  ChooseGide: 'ChooseGide' as 'ChooseGide',
  ChooseSlide: 'ChooseSlide' as 'ChooseSlide',
};

export type State = CommonState & { mode: keyof typeof LinkModes };
export type DerivedState = CommonDerivedState & { mode: keyof typeof LinkModes };
export type Context = Common.Context<State, LinkSlideEditorProps>;

export const getCreateActionsForLink = (context: CommonContext) => {
  const { state, setState, commonSlideEditorModalProps:{ showNotification } } = context;

  const allowedToAdd = () => {
    // Default for Mobile. TODO
    if (state.slide.data.files.length > 6) {
      showNotification({
        message: `You can only add 7 links.`,
        type: NotificationType.INFO,
      });
      return false;
    } else {
      return true;
    }
  };
  
  const actionLinkToGide = () => {
    if (allowedToAdd()) {
      setState({
        ...state,
        mode: LinkModes.ChooseGide,
      });
    }
  };
  const actionLinkToChannel = () => {
    if (allowedToAdd()) {
      setState({
        ...state,
        mode: LinkModes.ChooseChannel,
      });
    }
  };
  const actionLinkToSlide = () => {
    if (allowedToAdd()) {
      setState({
        ...state,
        mode: LinkModes.ChooseSlide,
      });
    }
  };
  const actionLinkToUrl = () => {
    if (allowedToAdd()) {
      setState({
        ...state,
        mode: LinkModes.Link,
      });
    }
  };
  
  const createActions = (
    <>
      <CircleIconButton
        style={{ margin: '5px 10px 5px 5px' }}
        alt="link to gide"
        onClick={actionLinkToGide}
        iconCssClass="whites-normal-1000-svg"
        backgroundColor="var(--COLOR-BLUE-100)"
        image="/icons/nav/logo/logo-icon-md.svg"
      />
      <CircleIconButton
        style={{ margin: '5px 10px 5px 5px' }}
        alt="link to channel"
        onClick={actionLinkToChannel}
        iconCssClass="whites-normal-1000-svg"
        backgroundColor="var(--COLOR-BLUE-100)"
        image="/icons/nav/channels.svg"
      />
      <CircleIconButton
        style={{ margin: '5px 10px 5px 5px' }}
        alt="link to slide"
        onClick={actionLinkToSlide}
        iconCssClass="whites-normal-1000-svg"
        backgroundColor="var(--COLOR-BLUE-100)"
        image="/icons/creationprocess/slide.svg"
      />
      <CircleIconButton
        style={{ margin: '5px 18px 5px 5px' }}
        alt="link to url"
        onClick={actionLinkToUrl}
        iconCssClass="whites-normal-1000-svg"
        backgroundColor="var(--COLOR-BLUE-100)"
        image="/icons/slidetype/links/main.svg"
      />
    </>
  );
  return createActions;
}

export const getLinkPreviewItems = (context: CommonContext, selectedItemIndex: number, setFileSelection: (selectedItemIndex: number, context: CommonContext) => void) => {
  return context.state.slide ? context.state.slide.data.files.map((file: LinkSlideFile, i: number) => {
    let Svg = getPreviewOverlayIconSvg(SlideType.Link, file.type);
    return {
      id: `${file.id}`,
      content: (
        <div className="slideFileSelectionContainer" key={`slideFileSelectionContainer${i}`}>
          <Svg style={{ width: 17, height: 17, visibility: 'hidden' }} />
              <div className={`linkPreview${i === selectedItemIndex ? ' active' : ''}`} key={`ip${i}`}>
                <Svg
                  className="pointer linkPreviewOverlayIcon"
                  color={i === selectedItemIndex ? 'var(--WHITES-NORMAL-1000)' : 'var(--COLOR-SECONDARY-300)'}
                  onClick={() => setFileSelection(i, context)}
                />
              </div>
              <div className={`itemPreviewLabel${selectedItemIndex === i ? ' active' : ''}`} key={`ipl${i}`}>
                <span>{i + 1}</span>
              </div>
        </div>
      )
    };
  }) : [];
}
