import React, { Component, Dispatch } from 'react';

import SlideEditorModal, {
  ProgressIndicatorType,
  SlideEditorProps,
  EditorState,
  LoadingInfo,
  ModalOverlayState,
  ModalLocation,
  QuestionInfo,
  QuestionType,
  QuestionResponse,
  QuestionResponseInfo,
  BackIconMode,
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import 'cropperjs/dist/cropper.css';
import { ensureImageFileOrientation, isMobileDevice } from '../../../utils/helperFunctions';
import ImageEditor from '../../Viewers/ImageViewers/ImageEditor/ImageEditor';
import DropZone from 'react-dropzone';
import { API_ROOT } from '../../../constants/paths';
import { NotificationType } from '../../../constants/strings';

import { DraggableListItem } from '../../Shared/DragReorderableList/DragReorderableList';
import { TextArea, TextAreaProps } from 'semantic-ui-react';
import { v4 } from 'uuid';
import ExpandingIconButton, { HorizontalAlignment } from '../../Shared/ExpandingIconButton/ExpandingIconButton';
import ImageDraw, { DrawingTool } from '../../Viewers/ImageViewers/ImageDraw/ImageDraw';
import ImageView from '../../Viewers/ImageViewers/ImageView/ImageView';
import { SlideFile, ImageFile } from '../../../models/SlideFile';
import SlideView from '../../Gide/SlideView/SlideView';
import { Slide, SlideModel, SlideUpdateInfo } from '../../../models/Slide';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';
import { connect } from 'react-redux';
import { GideAction } from '../../../models/GideAction';
import { ADD_SLIDE, MODAL_CLOSE, SET_TOASTER_MESSAGE } from '../../../constants/actionTypes';
import { ImageEditType } from '../../../models/CommonEnums';
import ImageGallery, { ImageGalleryImageSelectEvent } from '../../Viewers/ImageViewers/ImageGallery/ImageGallery';
import Swipeable from 'react-swipeable';
import { reject } from 'ramda';
import icons from '../../../assets/icons';
import SlideFileTypeSelector from '../SlideFileTypeSelector/SlideFileTypeSelector';
import { withNewTheme, darkTheme } from '../../../themes/custom-theme';
import GideImage from '../../Shared/Image/GideImage';
import { GallerySlideFileProcessorInfo } from '../../../reducers/FileProcessing/fileProcessing';
import { ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING } from '../../../reducers/FileProcessing/fileProcessing.actions';
import { AnonymousUser } from '../../../models/User';
import { history } from '../../../store';

export enum ImageGalleryViewerMode {
  LargeThumbnails = 1,
  MediumThumbnails = 2,
  SmallThumbnails = 3
}
export enum ImageGalleryAddType {
  PhotoLibrary = 1,
  Camera = 2,
  None = 3,
}

export interface ImageGalleryFile extends SlideFile {
  title?: string;
  imageFiles: ImageFile[];
  imageGalleryViewMode: ImageGalleryViewerMode;
}
export interface ImageGallerySlideEditorState {
  imageGalleryViewerMode: ImageGalleryViewerMode;
  videoSrc?: any;
  imageGalleryAddType: ImageGalleryAddType;
  imageEditType?: ImageEditType;
  stream?: any;
  slide: SlideModel;
  editorState: EditorState;
  selectedItemIndex: number;
  loadingInfo?: LoadingInfo;
  displaySlideAttribution: boolean;
  questionInfo?: QuestionInfo;
  imageGalleryImageEditorInfo?: ImageGalleryImageSelectEvent;
}

const mapDispatchToProps = (dispatch: Dispatch<GideAction>, ownProps: any) => ({
  onSubmitSlide: ownProps.onSubmitSlide ? ownProps.onSubmitSlide : (payload: SlideUpdateInfo) =>
  dispatch({ type: ADD_SLIDE, payload }),
  showNotification: (toasterMessageInfo: ToasterMessageInfo) =>
    dispatch({ type: SET_TOASTER_MESSAGE, payload: { toasterMessageInfo } }),
  addSlideGalleryFileProcessingInfo: (gallerySlideFileProcessingInfoList: GallerySlideFileProcessorInfo[]) =>
    dispatch({type: ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING, payload: {gallerySlideFileProcessingInfoList}}),
});
export interface ImageGallerySlideEditorProps extends SlideEditorProps {
  addSlideGalleryFileProcessingInfo: (gallerySlideFileProcessingInfoList: GallerySlideFileProcessorInfo[]) => void;
}
export class ImageGallerySlideEditor extends Component<ImageGallerySlideEditorProps, ImageGallerySlideEditorState> {
  constructor(props: ImageGallerySlideEditorProps) {
    super(props);
    const slide = props.editSlide ? {
      ...props.editSlide,
    } : {
        slideType: 'IMAGEGALLERY',
        data: {
          audioCaption: null,
          files: [
            // {
            //   id: v4(),
            //   title: '',
            //   imageFiles: [],
            //   imageGalleryViewMode: ImageGalleryViewerMode.SmallThumbnails
            // }
          ],
          caption: '',
          slideTypeSpecificSettings: {
            displayType: ImageGalleryViewerMode.LargeThumbnails,
          }
        },
        position: this.props.position,
      };
    this.state = {
      imageGalleryViewerMode: slide.data.slideTypeSpecificSettings.displayType,
      // Default ImageAddType to Camera or Upload based on the screen width.
      imageGalleryAddType: window.innerWidth <= 520 ? ImageGalleryAddType.Camera : ImageGalleryAddType.PhotoLibrary,
      imageEditType: ImageEditType.Selection,
      slide: slide,
      editorState: (slide as Slide).id
        ? slide.data.files.length === 1
          ? EditorState.Edit
          : EditorState.Preview
        : EditorState.New,
      selectedItemIndex: slide.data.files.length === 1 ? 0 : -1,
      displaySlideAttribution: false,
    };
  }
  _video: any;
  _canvas: any;
  _mobileCameraUpload: any;

  uploadSettings = {
    saveUrl: `${API_ROOT}/util/upload`,
    removeUrl: `${API_ROOT}/util/remove`,
  }

  handleVideo(stream: any) {
    // Update the state, triggering the component to re-render with the correct stream
    // let video: any = document.querySelector('video');
    // handle the depricated createObjectURL method in new browser versions
    if (this._video) {
      if ('srcObject' in this._video) {
        this._video.srcObject = stream;
      } else {
        this._video.src = window.URL.createObjectURL(stream);
      }
      this.setState({
        stream,
        videoSrc: this._video.srcObject ? this._video.srcObject : this._video.src,
        imageGalleryAddType: ImageGalleryAddType.Camera,
      });
      this.setState({ editorState: EditorState.Add });
      this._video.play();
    }
  }

  onTakePhoto() {
    this.setState({
      loadingInfo: {
        message: 'Creating Image from Camera',
        progressIndicatorType: ProgressIndicatorType.Indeterminate,
      }
    });
    const ctx = this._canvas.getContext('2d');
    // TODO: The actual aspect ratio of my webcam is 640 X 480. which means the width is 1.33 times as wide as the height.
    // This may differ on different devices. I may need to get the aspect ratio or resolution of the camera for the given
    // device.
    ctx.canvas.width = 600 * 1.33;
    ctx.canvas.height = 600;
    ctx.drawImage(this._video ? this._video : this._video, 0, 0, 600 * 1.33, 600);
    let dataURL = this._canvas.toDataURL('image/png');
    this.stopCamera();
    ensureImageFileOrientation(dataURL).then(imageFile => {
      // Create ImageGallery File if none.
      const slideData = this.addImageGallerySlideFiles([imageFile]);      
      this.setState({
        slide: slideData.slide,
        loadingInfo: undefined,
        imageEditType: ImageEditType.Selection,
        editorState: EditorState.Edit,
        selectedItemIndex: slideData.index,
      });
    });
  }

  /**
   * Initialize the webcam if one exists. TODO: What about if on phone??
   */
  startCamera(): void {
    if (!this.state.stream) {
      const n: any = navigator;
      n.getUserMedia =
        n.getUserMedia ||
        n.webkitGetUserMedia ||
        n.mozGetUserMedia ||
        n.msGetUserMedia ||
        n.oGetUserMedia;
      if (n.getUserMedia) {
        n.getUserMedia(
          { video: true },
          this.handleVideo.bind(this),
          (error: any) => {
            this.props.showNotification({
              message: `The following error occurred when attempting to turn on the camera: ${error}`,
              type: NotificationType.ERROR,
            })
          },
        );
      }
    } else {
      // TODO: Check on why this is set this way. It was copied from ImageSlideEditor.tsx
      // this.setState({
      //   imageGalleryAddType: ImageGalleryAddType.Camera,
      // });
      // this.setState({ editorState: EditorState.Add});
    }
  }

  stopCamera(): void {
    if (this.state.stream) {
      this.state.stream.getTracks().forEach((track: any) => {
        track.stop();
      });
      this.setState({ stream: null, videoSrc: null });
    }
  }

  onDropFiles(acceptedFiles: any[], rejectedFiles: any) {
    if (acceptedFiles.length) {
      this.setState({
        loadingInfo: {
          message: 'Saving Images',
          progressIndicatorType: ProgressIndicatorType.Indeterminate,
        }
      });

      acceptedFiles.forEach(async (f: any, index: number) => {
        ensureImageFileOrientation(f).then(imageFile => {
          // Create ImageGallery File if none.
          const slideData = this.addImageGallerySlideFiles([imageFile]);

          if (index === acceptedFiles.length - 1) {
            this.setState({
              slide: slideData.slide,
              loadingInfo: undefined,
              editorState: EditorState.Edit,
              imageGalleryAddType: ImageGalleryAddType.None,              
              selectedItemIndex: slideData.index,
            });
          } else {
            this.setState({
              slide: slideData.slide,
              selectedItemIndex: slideData.index,
            });
          }
        });
      })
    }
  }

  componentWillUnmount() {
    this.stopCamera();
  }

  componentDidMount() {
    if (this.state.editorState === EditorState.Add
      && this.state.imageGalleryAddType === ImageGalleryAddType.Camera) { // Means this is a new ImageSlide and user is in mobile. (See constuctor)
      if (window.innerWidth <= 520 && !isMobileDevice()) {
        this.startCamera();
      } else {
        this._mobileCameraUpload.fileInputEl.click();
      }
    }
  }

  updatePreviewItems(items: DraggableListItem[]) {
    const reorderedFiles: ImageGalleryFile[] = [];
    items.forEach((item: DraggableListItem) => {
      const imageFile = this.state.slide.data.files.find((i: ImageGalleryFile) => item.id === i.id);
      reorderedFiles.push(imageFile);
    });

    const slide = {
      ...this.state.slide,
      data: {
        ...this.state.slide.data,
        files: reorderedFiles,
      }
    };
    this.setState({ slide });
  }

  /**
   * Sets the selected image details in state for the given index. If the selected image is a LinkImage then it initializes
   * the linkImage property in state so that the user can edit the linkImage. It also can conditionally set the slide property in state
   * so that 2 separate calls to setState won't need to be made. Cases are when an image file gets added or deleted from the slide.
   * @param index - the selected image index
   * @param slide - optional slide to update the slide in state if necessary.
   */
  setImageSelection(index: number) {
    this.setState({
      imageEditType: ImageEditType.Selection,
      editorState: EditorState.Edit,
      selectedItemIndex: index
    });
  }
  setImageGallerySelection(index: number) {
    this.setState({
      imageEditType: ImageEditType.Selection,
      editorState: EditorState.Edit,
      selectedItemIndex: index
    });
  }
  private showBackNavigation(): boolean {
    return this.state.editorState === EditorState.Add
      || this.state.imageEditType === ImageEditType.Delete
      || this.state.imageEditType === ImageEditType.Credit
      || this.state.imageEditType === ImageEditType.Touch
      || this.state.imageEditType === ImageEditType.GeoLocation
      || this.state.imageEditType === ImageEditType.Sticker
      || this.state.imageEditType === ImageEditType.Crop
      || this.state.imageEditType === ImageEditType.Draw
      || this.state.imageEditType === ImageEditType.Text;
  }

  private showImageGallerySelection(): boolean {
    return this.state.editorState === EditorState.Edit
      // && !this.state.imageGalleryImageEditorInfo
      && this.state.slide.data.files[this.state.selectedItemIndex]
      && (this.state.imageEditType === ImageEditType.Selection
        || this.state.imageEditType === ImageEditType.Credit
        || this.state.imageEditType === ImageEditType.Touch
        || this.state.imageEditType === ImageEditType.GeoLocation
        || this.state.imageEditType === ImageEditType.Sticker
        || this.state.imageEditType === ImageEditType.Delete);
  }
  private showImageGalleryImageSelection(): boolean {
    return !this.showDarkModalBackground() && this.state.imageGalleryImageEditorInfo !== undefined;
  }
  private showDarkModalBackground(): boolean {
    return this.state.editorState === EditorState.Edit
      &&
      (this.state.imageEditType === ImageEditType.Crop
        || this.state.imageEditType === ImageEditType.Draw
        || this.state.imageEditType === ImageEditType.Text);
  }
  private hideActionContainer(): boolean {
    return this.state.editorState === EditorState.New
      || this.state.editorState === EditorState.Add
      || (this.state.editorState === EditorState.Edit
        && (this.state.imageEditType === ImageEditType.Crop
          || this.state.imageEditType === ImageEditType.Draw
          || this.state.imageEditType === ImageEditType.Text));
  }

  private hideFooter = ():boolean => {
    return this.state.editorState === EditorState.New
      || this.state.editorState === EditorState.Add
      || (this.state.editorState === EditorState.Edit
          && (this.state.imageEditType === ImageEditType.Crop
            || this.state.imageEditType === ImageEditType.Text
            || this.state.imageEditType === ImageEditType.Draw));
  }
  imageEditorAddActions = () => <></>;
  headerActions = () => {
    const title = this.state.editorState === EditorState.New ? "Image gallery"
      : this.state.imageGalleryAddType === ImageGalleryAddType.Camera ? "Open camera"
      : this.state.imageGalleryAddType === ImageGalleryAddType.PhotoLibrary ? "Upload image"
      : "Image gallery";
    const icon = this.state.editorState === EditorState.New ? "/icons/slidetype/imagegallery/main.svg"
      : this.state.imageGalleryAddType === ImageGalleryAddType.Camera ? "/icons/content-alteration/camera/default.svg"
      : this.state.imageGalleryAddType === ImageGalleryAddType.PhotoLibrary ? "/icons/slidetype/image/main.svg"
      : "/icons/slidetype/imagegallery/main.svg";

    return (this.state.editorState === EditorState.New || this.state.editorState === EditorState.Add)
    ? (
      <div className="headerActionsFlexEnd TEXTSUBTITLEwhitehigh-emphasisleft">
        <div style={{marginRight: 6}}>{title}</div>
        <CircleIconButton
          width={30}
          height={30}
          alt={title}
          backgroundColor="var(--COLOR-BLUE-100)"
          iconCssClass="whites-normal-1000-svg"
          image={icon} />
      </div>
    ) : (
      <>
        {(this.state.editorState === EditorState.Edit && this.state.imageGalleryImageEditorInfo) &&
          (this.state.imageEditType === ImageEditType.Draw || this.state.imageEditType === ImageEditType.Text) && (
            <GideImage 
              className="hoverIcon whites-normal-1000-svg"
              style={{ marginLeft: '50px', width: '100%' }}
              src="/icons/content-alteration/undo.svg"
              onClick={() => {
                if (this.imageDraw && this.imageDraw.canUndo()) {
                  this.imageDraw.undo();
                } else {
                  this.props.showNotification({ message: 'no changes to undo', type: NotificationType.INFO })
                }
              }}
              alt="undo"
            />
          )}
        {this.state.editorState === EditorState.Edit && this.state.imageGalleryImageEditorInfo && (
          <div className="headerActionsFlexEnd TEXTSUBTITLEblackhigh-emphasisleft">
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="Draw"
              src="/icons/slidetype/doodle/main.svg"
              expandedSrc="/icons/slidetype/doodle/main.svg"
              iconCssClass='color-secondary-600-svg'
              expandedIconCssClass='whites-normal-1000-svg'
              expandWidth={80}
              label="draw"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                this.setState({ imageEditType: ImageEditType.Draw });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.imageEditType === ImageEditType.Draw}
              visible={this.state.imageEditType === ImageEditType.Selection || this.state.imageEditType === ImageEditType.Draw}
            />
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="Crop and Rotate"
              src="/icons/creationprocess/cropandrotate/rotate.svg"
              expandWidth={125}
              label="crop image"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                this.setState({ imageEditType: ImageEditType.Crop });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.imageEditType === ImageEditType.Crop}
              visible={this.state.imageEditType === ImageEditType.Selection || this.state.imageEditType === ImageEditType.Crop}
            />
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="text attach"
              src="/icons/creationprocess/edit-text.svg"
              expandedSrc="/icons/creationprocess/edit-text.svg"
              expandWidth={80}
              label="Text"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                this.setState({ imageEditType: ImageEditType.Text });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.imageEditType === ImageEditType.Text}
              visible={this.state.imageEditType === ImageEditType.Selection || this.state.imageEditType === ImageEditType.Text}
            />
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="Location"
              src="/icons/slidetype/location/location.svg"
              expandWidth={100}
              label="Location"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                this.setState({ imageEditType: ImageEditType.GeoLocation });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.imageEditType === ImageEditType.GeoLocation}
              visible={this.state.imageEditType === ImageEditType.Selection || this.state.imageEditType === ImageEditType.GeoLocation}
            />
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="Attribute"
              src="/icons/content-alteration/@.svg"
              expandWidth={100}
              label="Attribute"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                this.setState({ imageEditType: ImageEditType.Credit, displaySlideAttribution: true });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.imageEditType === ImageEditType.Credit}
              visible={this.state.imageEditType === ImageEditType.Selection || this.state.imageEditType === ImageEditType.Credit}
            />
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="hashtag"
              src="/icons/content-alteration/hashtag.svg"
              expandWidth={100}
              label="sticker"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                this.setState({ imageEditType: ImageEditType.HashTag });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.imageEditType === ImageEditType.HashTag}
              visible={this.state.imageEditType === ImageEditType.Selection || this.state.imageEditType === ImageEditType.HashTag}
            />
            <ExpandingIconButton
              style={{ marginLeft: '17px' }}
              alt="delete"
              src="/icons/content-alteration/delete.svg"
              expandWidth={100}
              label="delete"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const questionInfo: QuestionInfo = {
                  questionId: 'DELETE_IMAGE',
                  title: `Are you sure you want to delete this image?`,
                  location: ModalLocation.TopRight,
                  okLabel: 'Delete It',
                  questionType: QuestionType.OkCancel
                };
                this.setState({ questionInfo: questionInfo, imageEditType: ImageEditType.Delete })
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={false}
              visible={this.state.imageEditType === ImageEditType.Selection || this.state.imageEditType === ImageEditType.Delete}
            />

          </div>
        )}
        {(this.state.editorState === EditorState.Preview
         || (this.state.editorState === EditorState.Edit && this.state.slide.data.files.length === 1)) && (
          <div className="headerActionsFlexEnd">
            <ExpandingIconButton
              alt="small thumbnails"
              src="/icons/slidetype/imagegallery/4-col.svg"
              expandedSrc="/icons/slidetype/imagegallery/4-col.svg"
              iconBackgroundColor="transparent"
              style={{
                marginLeft: '24px',
                height: '32px',
                backgroundImage: this.state.imageGalleryViewerMode === ImageGalleryViewerMode.SmallThumbnails
                  ? 'linear-gradient(290deg, #faa114, #fab317)' : ''
              }}
              expandedIconCssClass='whites-normal-1000-svg'
              expandWidth={110}
              label="Small"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const slide = {
                  ...this.state.slide,
                  data: {
                    ...this.state.slide.data,
                    slideTypeSpecificSettings: {
                      ...this.state.slide.data.slideTypeSpecificSettings,
                      displayType: ImageGalleryViewerMode.SmallThumbnails,
                    }
                  }
                }
                this.setState({ slide, imageGalleryViewerMode: ImageGalleryViewerMode.SmallThumbnails });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.imageGalleryViewerMode === ImageGalleryViewerMode.SmallThumbnails}
              visible={!this.state.imageGalleryImageEditorInfo}
            />

            <ExpandingIconButton
              alt="medium thumbnails"
              src="/icons/slidetype/imagegallery/3-col.svg"
              expandedSrc="/icons/slidetype/imagegallery/3-col.svg"
              iconBackgroundColor="transparent"
              style={{
                marginLeft: '24px',
                height: '32px',
                backgroundImage: this.state.imageGalleryViewerMode === ImageGalleryViewerMode.MediumThumbnails
                  ? 'linear-gradient(290deg, #faa114, #fab317)' : ''
              }}
              expandedIconCssClass='whites-normal-1000-svg'
              expandWidth={110}
              label="Medium"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const slide = {
                  ...this.state.slide,
                  data: {
                    ...this.state.slide.data,
                    slideTypeSpecificSettings: {
                      ...this.state.slide.data.slideTypeSpecificSettings,
                      displayType: ImageGalleryViewerMode.MediumThumbnails,
                    }
                  }
                }
                this.setState({ slide, imageGalleryViewerMode: ImageGalleryViewerMode.MediumThumbnails });
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.imageGalleryViewerMode === ImageGalleryViewerMode.MediumThumbnails}
              visible={!this.state.imageGalleryImageEditorInfo}
            />

            <ExpandingIconButton
              alt="large thumbnails"
              src="/icons/slidetype/imagegallery/2-col.svg"
              expandedSrc="/icons/slidetype/imagegallery/2-col.svg"
              iconBackgroundColor="transparent"
              style={{
                marginLeft: '24px',
                height: '32px',
                backgroundImage: this.state.imageGalleryViewerMode === ImageGalleryViewerMode.LargeThumbnails
                  ? 'linear-gradient(290deg, #faa114, #fab317)' : ''
              }}
              expandedIconCssClass='whites-normal-1000-svg'
              expandWidth={111}
              label="Large"
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const slide = {
                  ...this.state.slide,
                  data: {
                    ...this.state.slide.data,
                    slideTypeSpecificSettings: {
                      ...this.state.slide.data.slideTypeSpecificSettings,
                      displayType: ImageGalleryViewerMode.LargeThumbnails,
                    }
                  }
                }
                if (this.state.imageGalleryViewerMode !== ImageGalleryViewerMode.LargeThumbnails) {
                  this.setState({ slide, imageGalleryViewerMode: ImageGalleryViewerMode.LargeThumbnails });
                }
              }}
              labelLocation={HorizontalAlignment.Left}
              expanded={this.state.imageGalleryViewerMode === ImageGalleryViewerMode.LargeThumbnails}
              visible={!this.state.imageGalleryImageEditorInfo}
            />
          </div>
        )}
      </>
    );
  }
  navigateBack = (): void => {
    this.stopCamera();
    if (this.state.editorState === EditorState.New) {
      history.goBack();
    } else if (!this.state.slide.data.files.length) {
      this.setState({ ...this.hideModalsState, editorState: EditorState.New });
    } else if (this.state.selectedItemIndex >= 0) {
      this.setState({ ...this.hideModalsState, editorState: EditorState.Edit });
    } else {
      this.setState({ ...this.hideModalsState, editorState: EditorState.Preview });
    }
  }

  getModalOverlayState = (): ModalOverlayState => {
    if (this.showDarkModalBackground()) {
      return ModalOverlayState.Footer;
    }
    return ModalOverlayState.None;
  }
  getSlideWithNewImageGallery = () => {
    const gallerySlideFile: ImageGalleryFile = {
      id: v4(),
      title: '',
      imageFiles: [],
      imageGalleryViewMode: ImageGalleryViewerMode.SmallThumbnails
    };
    const slide: SlideModel = {
      ...this.state.slide,
      data: {
        ...this.state.slide.data,
        files: [...this.state.slide.data.files, gallerySlideFile]
      }
    };
    return slide;
  }
  addNewImageGallery = (): void => {
    // Default for Mobile.
    // TODO: Currently you can get around this limit by using the multi-
    // upload feature. Need to fix that or decide what to do. Also applies
    // to other slide types such as IMAGE.
    if (this.state.slide.data.files.length > 6) {
      this.props.showNotification({
        message: `You can only add 7 galleries.`,
        type: NotificationType.INFO,
      });
    } else {
      const slide = this.getSlideWithNewImageGallery();
      this.setState({
        slide, selectedItemIndex: slide.data.files.length - 1,
        imageEditType: ImageEditType.Selection, editorState: EditorState.Edit
      });
    }
  }
  moveNext = () => {
    const nextIndex = this.state.selectedItemIndex === this.state.slide.data.files.length - 1
      ? 0
      : this.state.selectedItemIndex + 1;

    this.setState({ selectedItemIndex: nextIndex });
  }
  movePrevious = () => {
    const previousIndex = this.state.selectedItemIndex === 0
      ? this.state.slide.data.files.length - 1
      : this.state.selectedItemIndex - 1;

    this.setState({ selectedItemIndex: previousIndex });
  }
  hideModalsState = {
    questionInfo: undefined,
    displaySlideAttribution: false,
    imageEditType: ImageEditType.Selection,
  }
  imageDraw?: ImageDraw | null;

  actionBrowseForImage = () => {
    this.setState({ imageGalleryAddType: ImageGalleryAddType.PhotoLibrary, editorState: EditorState.Add });
    this.stopCamera();
  };

  actionTakePhoto = () => {
    if (!isMobileDevice()) {
      this.startCamera();
    } else {
      this._mobileCameraUpload.fileInputEl.click();
    }
    this.setState({ imageGalleryAddType: ImageGalleryAddType.Camera, editorState: EditorState.Add });
  };

  // TODO: Combine these 
  addImageGallerySlideFiles = (imageFiles: Blob[]): {slide: SlideModel, index: number} => {
    const slide = (!this.state.slide.data.files.length)
    ? this.getSlideWithNewImageGallery()
    : this.state.slide;
    const selectedItemIndex = this.state.selectedItemIndex > 0 ? this.state.selectedItemIndex : 0;
    // Get the selected gallery
    const galleryFile = slide.data.files[selectedItemIndex];;
    const newImageFiles = imageFiles.map((imageFile: Blob) => {
      return {
        id: v4(),
        name: '',
        url: URL.createObjectURL(imageFile),
        dataUrl: imageFile,
        processingId: v4(),
        type: 'UPLOAD',
        caption: '',
      }
    });

    return {
      slide: {
      ...slide,
      data: {
        ...slide.data,
        files: slide.data.files.map((gf: ImageGalleryFile) => {
          if (gf.id === galleryFile.id) {
            return {
              ...gf,
              imageFiles: [...gf.imageFiles, ...newImageFiles]
            };
          } else {
            return gf;
          }
        }),
      }}, 
      index: selectedItemIndex
    };
  }
  // addImageGallerySlideFile = (imageFile: any): {slide: SlideModel, index: number} => {
  //   const slide = (!this.state.slide.data.files.length)
  //     ? this.getSlideWithNewImageGallery()
  //     : this.state.slide;
  //   const selectedItemIndex = this.state.selectedItemIndex > 0 ? this.state.selectedItemIndex : 0;
  //   // Get the selected gallery
  //   const galleryFile: ImageGalleryFile = slide.data.files[selectedItemIndex];
  //   galleryFile.imageFiles = [...galleryFile.imageFiles, {
  //     id: v4(),
  //     name: imageFile.name,
  //     url: URL.createObjectURL(imageFile),
  //     dataUrl: imageFile,
  //     processingId: v4(),
  //     type: 'UPLOAD',
  //     caption: '',
  //   }];
  //   return {
  //     slide: {
  //     ...slide,
  //     data: {
  //       ...slide.data,
  //       files: slide.data.files.map((gf: ImageGalleryFile) => {
  //         if (gf.id === galleryFile.id) {
  //           return galleryFile;
  //         } else {
  //           return gf;
  //         }
  //       }),
  //     }}, 
  //     index: selectedItemIndex
  //   };
  // }
  updateImageGalleryImage = (image: any) => {
    ensureImageFileOrientation(image).then(imageFile => {
      const imageUrl = URL.createObjectURL(imageFile);
      const slide = {
        ...this.state.slide,
        data: {
          ...this.state.slide.data,
          files: this.state.slide.data.files.map((imageGalleryFile: ImageGalleryFile) => {
            if (this.state.imageGalleryImageEditorInfo && imageGalleryFile.id === this.state.imageGalleryImageEditorInfo.imageGalleryFileId) {
              return {
                ...imageGalleryFile,
                imageFiles: imageGalleryFile.imageFiles.map((imageGalleryFile: ImageFile) => {
                  if (this.state.imageGalleryImageEditorInfo !== undefined
                    && imageGalleryFile.id === this.state.imageGalleryImageEditorInfo.imageFile.id) {
                    return {
                      ...imageGalleryFile,
                      url: imageUrl,
                      dataUrl: imageFile,
                      processingId: v4(),
                    }
                  } else {
                    return imageGalleryFile;
                  }
                })
              }
            } else {
              return imageGalleryFile;
            }
          }),
        },
      };

      this.setState({
        slide, imageEditType: ImageEditType.Selection,
        loadingInfo: undefined,
        imageGalleryImageEditorInfo: {
          ...(this.state.imageGalleryImageEditorInfo as ImageGalleryImageSelectEvent),
          imageFile: {
            ...(this.state.imageGalleryImageEditorInfo as ImageGalleryImageSelectEvent).imageFile,
            url: imageUrl
          }
        }
      });
    });
  }
  public render() {
    const imageGalleryPreviewItems = this.state.slide ? this.state.slide.data.files.map((image: ImageGalleryFile, i: number) => {
      const that = this;
      const Svg = icons.SlideType_Gallery_3Col;
      return {
        id: `${image.id}`,
        content: <div className="slideFileSelectionContainer"
          key={`slideFileSelectionContainer${i}`}
        >
          <Svg style={{ width: 17, height: 17, visibility: "hidden"}} />
          <div
            className={`galleryPreview${this.state.selectedItemIndex === i ? ' active' : ''}`}
            key={`ip${i}`}>
            <Svg
              className="slideImage"
              color={
                (i < 0) ? 'var(--WHITES-NORMAL-300)'
                : i === this.state.selectedItemIndex
                ? 'var(--WHITES-NORMAL-1000)'
                : 'var(--COLOR-SECONDARY-500)'
              }
              style={{ width: '24px', height: '24px' }}
              key={`iep${i}`}
              xlinkTitle={'image gallery overlay'}
              onClick={() => { that.setImageGallerySelection(i); }}
            />
          </div>
          <div className={`itemPreviewLabel${this.state.selectedItemIndex === i ? ' active' : ''}`}
            key={`ipl${i}`} >
            <span>{i + 1}</span>
          </div>
        </div>
      };
    }) : [];

    return (
      <SlideEditorModal
        article={this.props.article}
        backIconMode={this.showBackNavigation()
          ? this.showDarkModalBackground()
            ? BackIconMode.Dark
            : BackIconMode.Light
          : BackIconMode.None}
        currentUser={this.props.currentUser}
        hideFooter={this.hideFooter()}
        displaySlideAttribution={this.state.displaySlideAttribution}
        editorState={this.state.editorState}
        loadingInfo={this.state.loadingInfo}
        modalOverlayState={this.getModalOverlayState()}
        selectedItemIndex={this.state.selectedItemIndex}
        hideActionContainer={this.hideActionContainer()}
        showBackNavigation={this.showBackNavigation()}
        addSlideGalleryFileProcessingInfo={this.props.addSlideGalleryFileProcessingInfo}
        showDefaultActions={
          !this.showBackNavigation()
          && !this.state.imageGalleryImageEditorInfo
        }
        showDeleteFile={
          !this.showBackNavigation()
          && this.state.editorState === EditorState.Edit
          && !this.state.imageGalleryImageEditorInfo // This has its own delete button
        }
        slide={this.state.slide as Slide}
        position={this.props.position}
        question={this.state.questionInfo}
        onAnswerQuestion={((response: QuestionResponseInfo) => {
          if (response.questionResponse === QuestionResponse.Ok) {
            const slide = {
              ...this.state.slide,
              data: {
                ...this.state.slide.data,
                files: this.state.slide.data.files.map((f: ImageGalleryFile) => {
                  if (this.state.imageGalleryImageEditorInfo && f.id === this.state.imageGalleryImageEditorInfo.imageGalleryFileId) {
                    return {
                      ...f,
                      imageFiles: reject((imageFile: ImageFile) => this.state.imageGalleryImageEditorInfo !== undefined
                        && imageFile.id === this.state.imageGalleryImageEditorInfo.imageFile.id, f.imageFiles)
                    }
                  } else {
                    return f;
                  }
                }),
              },
            };
            this.setState({
              slide,
              questionInfo: undefined,
              imageEditType: ImageEditType.Selection,
              imageGalleryImageEditorInfo: undefined,
            });
          } else {
            this.setState({ questionInfo: undefined });
          }
        })}
        createActions={
          <this.imageEditorAddActions />
        }
        headerActions={
          <this.headerActions />
        }
        previewItems={
          imageGalleryPreviewItems
        }

        closeModal={() => history.goBack()}
        onCloseAllModals={() => {
          this.setState({
            ...this.hideModalsState
          });
        }}
        onNavigateBack={() => this.navigateBack()}
        onShowPreview={() => {
          this.stopCamera();
          this.setState({ editorState: EditorState.Preview, selectedItemIndex: -1 })
        }}
        onSlideUpdated={async (slide: Slide) => {
          const selectedSlideIndex = (this.state.selectedItemIndex < slide.data.files.length)
            ? this.state.selectedItemIndex : (slide.data.files.length - 1);
          this.setState({ slide, ...this.hideModalsState, selectedItemIndex: selectedSlideIndex });
        }}
        onSubmitSlide={this.props.onSubmitSlide}
        onCreate={this.addNewImageGallery}
        showNotification={this.props.showNotification}
      >
        <div className={`imageGallerySlideEditor${this.showDarkModalBackground() ? ' showModalBackground' : ''}`}>
          {(this.state.editorState === EditorState.New || (this.state.editorState === EditorState.Edit && !this.state.slide.data.files[this.state.selectedItemIndex].imageFiles.length)) && (
            <div style={{ flex: "1 1", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <SlideFileTypeSelector slideFileTypes={[
                {
                  title: "Open camera",
                  message: "Share your gide to your friends.",
                  alt: "take photo",
                  image: "/icons/content-alteration/camera/default.svg",
                  onClick: this.actionTakePhoto,
                },
                {
                  title: "Upload image",
                  message: "Share your gide to your friends.",
                  alt: "upload image",
                  image: "/icons/slidetype/image/main.svg",
                  onClick: this.actionBrowseForImage,
                },
              ]} />
              {/* <div className="imageGallerySlideEditorNewMenu">
                <RoundedCornerButton
                  label="Upload"
                  alt="Upload"
                  image="/icons/slidetype/image/main.svg"
                  imagePosition="right"
                  labelColor="var(--WHITES-NORMAL-900)"
                  backgroundColor="var(--COLOR-SECONDARY-500)"
                  iconCssClass="whites-normal-1000-svg"
                  style={{width:96, height: 32}}
                  onClick={this.actionBrowseForImage}
                  />
                <div className="or">—or—</div>
                <CircleIconButton
                    style={{ width: 38, height: 38 }}
                    alt="take photo"
                    onClick={this.actionTakePhoto}
                    backgroundColor="#565656"
                    iconCssClass="whites-normal-1000-svg"
                    image="/icons/content-alteration/camera/default.svg"
                  />
              </div> */}
            </div>
          )}
          {this.state.editorState === EditorState.Preview && (
            <div className="imageSlideViewerContainer">
              <SlideView
                slide={this.state.slide as Slide}
                slidePosition={(this.state.slide as Slide).position}
                currentUser={AnonymousUser}
                viewMode={"SCROLL"}
                view={'website'} // We want the images to show how they will behave in website
                openModal={() => { }}
                onSubmit={() => { }}
                onEditingText={() => { }}
                disableExpiration={true}
              />
            </div>
          )}
          {/* This has to be hidden with css so I can get a reference to the video
            element when needed.
          */}
          <div className={`imageGalleryEditor${this.state.editorState === EditorState.Add
            || (this.state.editorState === EditorState.Edit && this.state.slide.data.files[this.state.selectedItemIndex].imageFiles.length) ? '' : ' inactive'}`}>
            {this.state.editorState === EditorState.Add
              && this.state.imageGalleryAddType === ImageGalleryAddType.PhotoLibrary && (
                <DropZone className="imageDropZone"
                  onDrop={this.onDropFiles.bind(this)}
                  ref={(mcu: any) => {
                    if (mcu && mcu.fileInputEl) {
                      mcu.fileInputEl.accept = "image/*";
                      // mcu.fileInputEl.capture = "environment";
                      if (isMobileDevice()) {
                        mcu.fileInputEl.click();
                      }
                    }
                    this._mobileCameraUpload = mcu;
                  }}>
                  <SlideFileTypeSelector
                    theme="dark"
                    slideFileTypes={[
                      {
                        title: "Upload",
                        alt: "Upload",
                        image: "/icons/nav/plusicon.svg",
                        message: isMobileDevice() ? "Click to upload" : "Drag and drop or click to upload",
                        onClick: () => {},
                      }
                    ]} />
                </DropZone>
              )}
            <canvas className="imageCanvas"
              id="c"
              ref={c => (this._canvas = c)}
              style={{ display: 'none' }}
            />
            {!isMobileDevice() && (
              <video
                src={this.state.videoSrc}
                autoPlay={true}
                className={`webcamDisplay${this.state.videoSrc
                  && this.state.editorState === EditorState.Add
                  && this.state.imageGalleryAddType === ImageGalleryAddType.Camera ?
                  '' : ' inactive'}`
                }
                ref={v => (this._video = v)}
              />
            )}
            {isMobileDevice() && (
              <DropZone className="imageDropZone hidden"
                onDrop={this.onDropFiles.bind(this)}
                ref={(mcu: any) => {
                  if (mcu && mcu.fileInputEl) {
                    mcu.fileInputEl.accept = "image/*";
                    mcu.fileInputEl.capture = "environment";
                  }
                  this._mobileCameraUpload = mcu;
                }}
              >
              </DropZone>
            )}
            {this.state.editorState === EditorState.Add
              && this.state.imageGalleryAddType === ImageGalleryAddType.Camera
              && !isMobileDevice() && (
                <CircleIconButton
                  style={{ margin: '5px' }}
                  alt="take photo"
                  onClick={() => {
                    this.onTakePhoto();
                  }}
                  backgroundColor="var(--COLOR-BLUE-100)"
                  iconCssClass="whites-normal-1000-svg"
                  image="/icons/content-alteration/camera/default.svg"
                />
              )}
            {this.state.editorState === EditorState.Edit
              && this.state.imageGalleryImageEditorInfo
              && this.state.imageEditType === ImageEditType.Crop && (
                <ImageEditor
                  image={(this.state.imageGalleryImageEditorInfo as ImageGalleryImageSelectEvent).imageFile}
                  saveImage={(image: any) => {
                    this.setState({
                      loadingInfo: {
                        message: 'Saving changes to image',
                        progressIndicatorType: ProgressIndicatorType.Indeterminate,
                      }
                    });
                    // catch the returned data and add to list. will need to save it                    
                    this.updateImageGalleryImage(image);
                  }}
                  // responseType='blob'
                  responseType='base64'
                  cropperOptions={{
                    guides : true,
                    autoCropArea: 1,
                    rotatable: true
                  }}
                />
              )
            }
            {this.state.editorState === EditorState.Edit
              && this.state.imageGalleryImageEditorInfo
              && (this.state.imageEditType === ImageEditType.Draw
                || this.state.imageEditType === ImageEditType.Text) && (
                <ImageDraw
                  ref={(imageDraw: ImageDraw) => this.imageDraw = imageDraw}
                  image={(this.state.imageGalleryImageEditorInfo as ImageGalleryImageSelectEvent).imageFile}
                  imageDrawTool={this.state.imageEditType === ImageEditType.Draw ? DrawingTool.Pencil : DrawingTool.Select}
                  width={500}
                  height={500}
                  saveImage={(image: any) => {
                    this.setState({
                      loadingInfo: {
                        message: 'Saving changes to image',
                        progressIndicatorType: ProgressIndicatorType.Indeterminate,
                      }
                    });
                    // catch the returned data and add to list. will need to save it
                    this.updateImageGalleryImage(image);                    
                  }}
                />
              )}
            {this.showImageGallerySelection() && (
              <div className="imageGalleryEditorSelectedImageGalleryOuterContainer">
                {this.state.slide.data.files[this.state.selectedItemIndex] && (
                  <div className="imageGalleryTitleContainer">
                    <TextArea
                      className="imageGalleryTitleTextBox"
                      placeholder={'enter gallery title'}
                      value={this.state.slide.data.files[this.state.selectedItemIndex].title}
                      onChange={(event: React.FormEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
                        const selectedImageId = this.state.slide.data.files[this.state.selectedItemIndex].id;
                        const slide = {
                          ...this.state.slide,
                          data: {
                            ...this.state.slide.data,
                            files: this.state.slide.data.files.map((f: ImageGalleryFile) => {
                              if (f.id === selectedImageId) {
                                return {
                                  ...f,
                                  title: data.value
                                }
                              } else {
                                return f;
                              }
                            }),
                          },
                        }
                        this.setState({ slide });
                      }}
                    />
                  </div>
                )}

                <Swipeable
                  className="swipeableContainer"
                  onSwipedLeft={this.moveNext}
                  onSwipedRight={this.movePrevious}
                >
                  <ImageGallery
                    imageGallery={this.state.slide.data.files[this.state.selectedItemIndex]}
                    imageGalleryViewerMode={this.state.slide.data.slideTypeSpecificSettings.displayType}
                    marginBottom="0px"
                    onClick={(event: ImageGalleryImageSelectEvent) => {
                      this.setState({ imageGalleryImageEditorInfo: event });
                    }}
                  />
                </Swipeable>
                {this.state.slide.data.files[this.state.selectedItemIndex] && this.state.slide.data.files[this.state.selectedItemIndex].caption && (
                  <span className="imageGalleryFileCaption">{this.state.slide.data.files[this.state.selectedItemIndex].caption}</span>
                )}
                <div className="imageGalleryAddImageActions">
                  <CircleIconButton
                    style={{ margin: '5px 18px 5px 5px' }}
                    alt="take photo"
                    onClick={this.actionTakePhoto}
                    backgroundColor="var(--COLOR-BLUE-100)"
                    iconCssClass="whites-normal-1000-svg"
                    image="/icons/content-alteration/camera/default.svg"
                  />
                  <CircleIconButton
                    style={{ margin: '5px 10px 5px 5px' }}
                    alt="browse for image"
                    onClick={this.actionBrowseForImage}
                    backgroundColor="var(--COLOR-BLUE-100)"
                    iconCssClass="whites-normal-1000-svg"
                    image="/icons/slidetype/image/main.svg"
                  />
                </div>

                {this.showImageGalleryImageSelection() && (
                  <div className="selectedImageGalleryImage"
                    onClick={() => this.setState({ imageGalleryImageEditorInfo: undefined })}
                  >
                    <ImageView
                      imageFile={(this.state.imageGalleryImageEditorInfo as ImageGalleryImageSelectEvent).imageFile}
                      isReferenceType={false}
                      className="containImage"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </SlideEditorModal>
    );
  }
}


export default connect(null, mapDispatchToProps)(
  withNewTheme(ImageGallerySlideEditor, darkTheme),
);
