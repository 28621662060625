import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Input, Image, Button, Icon, Checkbox } from 'semantic-ui-react';
import classNames from 'classnames';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';
import { titleToPath } from '../../utils/helperFunctions';

import agent from '../../agent';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  ARTICLE_TITLE_IMAGE_UPDATED,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';

import slideTools from '../../slideTools';
import { history } from '../../store';
const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  onUpdateArticle: payload =>
    dispatch({ type: ARTICLE_TITLE_IMAGE_UPDATED, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

export class HeaderModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'HEADER',
        data: {
          audioCaption: null,
          caption: '',
          level: 1,
          title: '',
          notCollapsible: false,
          defaultCollapsed: false,
        },
      },
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.setCollapsedByDefault = defaultCollapsed => {
      this.setState({
        ...this.state,
        slide: {
          ...this.state.slide,
          data: {
            ...this.state.slide.data,
            defaultCollapsed: defaultCollapsed,
          },
        },
      });
    };

    this.setNotCollapsible = notCollapsible => {
      this.setState({
        ...this.state,
        slide: {
          ...this.state.slide,
          data: {
            ...this.state.slide.data,
            notCollapsible: notCollapsible,
          },
        },
      });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = {
        ...this.state.slide,
        data: {
          ...this.state.slide.data,
          audioCaption: audioFile
        }
      };
      this.setState({ slide });
    };





    // TODO: Move to a common location. It is used in the following
    // Footer.js
    // HeaderModal.jsx
    // Article/index.js
    this.updateArticle = async title => {
      let article = {
        id: this.props.article.id,
        title: title,
      };
      if (title) {
        article.slug = titleToPath(title);
      }
      const result = await agent.Articles.update(article);
      this.props.onUpdateArticle({title: result.article.title, image: result.article.image, slug: result.article.slug});
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = slide.slide;
        slide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          slide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide && !this.props.isNew) {
          payload = await agent.Slides.update(this.props.editSlide.id, slide);
        } else {
          payload = await agent.Slides.create(this.props.article, slide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      this.props.onSubmitSlide ?
        this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
        this.props.onSubmit(this.state.replaceMode, {
          ...payload,
          mode: this.props.mode,
        });

      // If this is a new title slide created from modal then update the name of the article
      if (
        !this.props.editSlide ||
        (this.props.isNew && slide.data.level === 0)
      ) {
        this.updateArticle(slide.data.title);
      }
      history.goBack();
    };

    this.watchForEnter = ev => {
      if (ev.keyCode === 13) {
        this.createSlide();
      }
    };
  }

  render() {
    const { slide } = this.state;
    const modalClasses = classNames('modal', 'HEADER');
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        className={modalClasses}
        size="small"
        dimmer="inverted"
        open={true}
        style={{ background: 'rgb(24, 120, 126)' }}
        closeOnDimmerClick={false}
      >
        <Modal.Content style={{ background: 'rgb(24, 120, 126)' }}>
          <div
            className="modalHeader"
            style={{ background: 'rgb(24, 120, 126)' }}
          >
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <span>Header Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-header.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description className="dataEntry">
              <section
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly',
                  height: '200px',
                }}
              >
                <Input
                  className="headerText"
                  fluid
                  placeholder="ex: Chapter 1"
                  value={slide.data.title}
                  onChange={(ev) => {
                    const slide = {
                      ...this.state.slide,
                      data: {
                        ...this.state.slide.data,
                        title: ev.target.value
                      }
                    };

                    this.setState({ slide });
                  }}

                  autoFocus
                  onKeyDown={this.watchForEnter}
                />
                {slide &&
                  slide.data.level > 0 && (
                    <section style={{ display: 'flex', flexWrap: 'nowrap' }}>
                      <section
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '200px',
                        }}
                      >
                        <h3>Not Collapsible</h3>
                        <Checkbox
                          onChange={(evt, data) =>
                            this.setNotCollapsible(data.checked)
                          }
                          toggle
                          checked={this.state.slide.data.notCollapsible}
                          label=""
                        />
                      </section>
                      {!this.state.slide.data.notCollapsible && (
                        <section
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '240px',
                          }}
                        >
                          <h3>Collapsed By Default</h3>
                          <Checkbox
                            onChange={(evt, data) =>
                              this.setCollapsedByDefault(data.checked)
                            }
                            toggle
                            checked={this.state.slide.data.defaultCollapsed}
                            label=""
                          />
                        </section>
                      )}
                    </section>
                  )}
              </section>
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <SlideModalActions
            caption={slide.data.caption}
            captionChanged={(ev) => {
              const slide = {
                ...this.state.slide,
                data: {
                  ...this.state.slide.data,
                  caption: ev.target.value
                }
              };
              this.setState({ slide });
            }}
            audioCaption={slide.data.audioCaption}
            onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
            showSettings={this.state.showSettings}
            settingsClicked={() =>
              this.setState({ showSettings: !this.state.showSettings })
            }
            nextClicked={this.createSlide}
            settings={getSlideSettings(
              this.state.slide,
              this.props.article.type,
            )}
            onSettingsChanged={this.updateSlideSettings.bind(this)}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderModal);
