import React, { Component } from 'react';
import { User } from '../../models/User';
import classNames from 'classnames';
import { sort, isNil } from 'ramda';
import { isNullOrUndefined } from 'util';
import agent from '../../agent';
import {
  hasValue, isValidURL, slideEditorModalLookup, headerIconLookup, getLinkPreviewDetails, ensureImageFileOrientation
} from '../../utils/helperFunctions';
import {
  InputBarOpenMenu,
  HeaderLevel,
  InputBarShortcutMode,
  InputBarMenuTypes,
  NotificationType,
} from '../../constants/strings';
import { Article } from '../../models/Article';
import { TextArea } from 'semantic-ui-react';
import { Slide, GideTitleImageInfo, SlideUpdateInfo } from '../../models/Slide';

import { ToasterMessageInfo } from '../../models/ToasterMessageInfo';
import { MultiSlideInsertDetails } from '../Gide/GideViewerManagerModal/GideViewerManagerModal';
import { SlideTypeButton } from './QuickSlideTypeChooserModal/QuickSlideTypeChooser';
import icons from '../../assets/icons';
import { RoundedCornerButton } from '../Shared/RoundedCornerButton/RoundedCornerButton';
import { HyperLinkLayout } from '../../models/CommonEnums';

import InputBarNew from '../../components/DataEntry/InputBar/InputBar';
import { ImageFile } from '../../models/SlideFile';
import { v4 } from 'uuid';

interface InputBarProps {
  currentUser?: User | null;
  article?: Article | null;
  displayToolBar: boolean;
  currentSlidePosition?: number;
  numberOfSlides: number;
  displayViewBar: boolean;
  placeholderText?: string;
  textSlideEditing: Slide | null;
  slideType: string | null;
  openMenu: InputBarOpenMenu;
  showLeftSidebar: boolean;
  showRightSidebar: boolean;
  ensureInputBarModalsClosedOnFocus?: boolean;
  showSignup: () => void;
  openModal: (payload: {modalType: string, modalProps: any}) => void;
  closeModal: (closeDialogList?: string[]) => void;
  getAvailableHeaderLevelsForCurrentSlidePosition: () => any;
  setFooterInput?: (key: string, value: any) => void;
  onSubmitSlide: (payload: SlideUpdateInfo) => void;
  toggleViewBarDisplay: () => void;
  toggleToolBarDisplay: () => void;
  // onInputBlurred: () => void;
  onSubmitMultipleSlides: (multiSlideInsertDetails: MultiSlideInsertDetails) => void;
  onUpdateArticleTitleAndImage?: (gideTitleImageInfo: GideTitleImageInfo) => void;
  updateSlideNumber: (number: number) => void;
  scrollToSlidePosition: (slidePosition: number) => void;
  createArticle?: () => Promise<Article>;
  setOpenMenu: (menuState: InputBarOpenMenu) => void;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;

}

interface InputBarState {
  inputBarValue?: string;
  inputBarShortcutMode: InputBarShortcutMode;
  inputBarFocused: boolean;
  inputBarMenuImage: string;
  currentHeaderLevel: HeaderLevel;
  focusInputBar: any;
  blurInputBar: any;
}

export default class InputBar extends Component<InputBarProps, InputBarState> {
  constructor(props: InputBarProps) {
    super(props);
    this.state = {
      inputBarValue: undefined,
      inputBarFocused: false,
      inputBarMenuImage: '/icons/nav/settings.svg',
      currentHeaderLevel: HeaderLevel.UNSET,
      inputBarShortcutMode: InputBarShortcutMode.Position,
      focusInputBar: undefined,
      blurInputBar: undefined,
    };
  }

  textEditor?: TextArea | null;

  componentDidUpdate(prevProps: InputBarProps) {
    // Coming from Gide but no longer in Gide need to clear out contents of the footer.
    if (
      !isNullOrUndefined(prevProps.article) &&
      isNullOrUndefined(this.props.article)
    ) {
      this.clearFooterInput();
    }
    if (
      !prevProps.textSlideEditing &&
      this.props.textSlideEditing
    ) {
      this.setState({ inputBarValue: this.props.textSlideEditing.data.body });
      this.setState({focusInputBar: {}});
    }

    if (this.state.inputBarFocused === true && this.textEditor) {
      this.textEditor.focus();
    }
  }

  clearFooterInput = () => {
    this.setState({ inputBarValue: '' });
  };

  enterFullEditor = () => {
    this.props.setFooterInput && this.props.setFooterInput('value', `<p>${this.state.inputBarValue ? this.state.inputBarValue : ''}</p>`);
    this.clearFooterInput();
    this.props.openModal({
      modalType: 'TextModal',
      modalProps: {
        position: this.props.currentSlidePosition,
      },
    });
  };

  createEndHeaderSlide = async (beginSlideId: string) => {
    if (!isNullOrUndefined(this.props.article)) {
      const slide = {
        position: this.props.currentSlidePosition,
        slideType: 'HEADER',
        data: {
          type: 'END',
          beginSlideId: beginSlideId,
          position: this.props.currentSlidePosition,
        },
      };
      const response = await agent.Slides.create(this.props.article, slide);
      const payload = {
        slide: response.slide,
        preventScrollToSlide:
          !isNil(this.props.currentSlidePosition) &&
          this.props.numberOfSlides !== this.props.currentSlidePosition,
      };
      this.props.onSubmitSlide(payload);
    }
  };

  onMenuItemSelected = (menu: {type: InputBarMenuTypes, menuImageSourceOnSelection?: string, headerLevel: number, beginSlideId?: string}) => {
    this.setOpenMenu(InputBarOpenMenu.NONE);
    if (menu.type === InputBarMenuTypes.HEADER) {
      this.setState({
        inputBarMenuImage: menu.menuImageSourceOnSelection as string,
        currentHeaderLevel: menu.headerLevel,
        inputBarShortcutMode: InputBarShortcutMode.Header,
        focusInputBar: true,
      });      
    } else {
      this.setState({
        inputBarMenuImage: menu.menuImageSourceOnSelection ? menu.menuImageSourceOnSelection : this.state.inputBarMenuImage,
        currentHeaderLevel: HeaderLevel.UNSET,
        inputBarShortcutMode: InputBarShortcutMode.Position,
      });
    }
    if (menu.type === InputBarMenuTypes.TEXT) {
      this.enterFullEditor();
    }
    if (menu.type === InputBarMenuTypes.HEADER_END) {
      this.createEndHeaderSlide(menu.beginSlideId as string);
    }
    // Set focus to input if header or CO
    if (
      menu.type === InputBarMenuTypes.HEADER ||
      menu.type === InputBarMenuTypes.COLLAPSE_BEGIN
    ) {
      this.setState({ inputBarFocused: true });
    }
  };

  openSlideMenu = () => {
    this.setOpenMenu(InputBarOpenMenu.SLIDE);
  };

  onInputFocus = () => {
    if(!this.props.currentUser) {
      this.props.showSignup();

      return;
    }
    if(this.props.ensureInputBarModalsClosedOnFocus) {
      this.ensureInputBarModalsClosed();
    }
    if (!this.props.article && this.props.createArticle) {
      this.setState({blurInputBar: {}});
      this.props.createArticle();
    }
  };

  onRemoveInputFocus = () => {
    this.setState({ inputBarFocused: false });
  };

  setOpenMenu = (openMenuOption: InputBarOpenMenu) => {
    this.props.setOpenMenu(openMenuOption);
  };

  openInputBarMenu = async () => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    if(this.props.ensureInputBarModalsClosedOnFocus) {
      this.ensureInputBarModalsClosed();
    }
    this.setOpenMenu(InputBarOpenMenu.HEADER);
    let article = this.props.article;
    if (!this.props.article && this.props.createArticle) {
      article = await this.props.createArticle();
    }
    const className = this.props.showLeftSidebar && this.props.showRightSidebar
    ? ' showLeftSidebar showRightSidebar bindRightOnly'
    : this.props.showLeftSidebar
      ? ' showLeftSidebar bindRightOnly'
      : this.props.showRightSidebar
        ? ' showRightSidebar bindRightOnly'
        : ' bindRightOnly';
    this.props.openModal({
      modalType: 'QuickSlideTypeChooser',
      modalProps: {
        closeModal: this.closeSlideTypeChooserModal,
        openModal: this.props.openModal,
        onSubmitSlide: this.props.onSubmitSlide,
        showNotification: this.props.showNotification,
        currentUser: this.props.currentUser,
        slidePosition: this.props.currentSlidePosition,
        article: article,
        className: className,
        slideTypeButtons: this.getQuickSlideTypeItems()
      }
    });
  };

  closeInputBarMenu = () => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    this.props.setOpenMenu(InputBarOpenMenu.NONE);
    this.props.closeModal();
  };

  openSlideModal = (modalType: string, article?: Article | null) => {
    this.setOpenMenu(InputBarOpenMenu.NONE);
    if (slideEditorModalLookup[modalType]) {
      this.props.openModal({
        modalType: slideEditorModalLookup[modalType],
        modalProps: {
          currentUser: this.props.currentUser,
          article: article,
          position: this.props.currentSlidePosition,
          startCamera: modalType === 'IMAGECAMERA' ? true : false,
          onSubmitSlide: this.props.onSubmitSlide,
          modalType: slideEditorModalLookup[modalType],
        }
      });
    } else {
      this.props.openModal({
        modalType: `${modalType}Modal`,
        modalProps: {
          slideType: modalType,
          article: article,
          onSubmitSlide: this.props.onSubmitSlide,
          currentSlidePosition: this.props.currentSlidePosition,
          position: this.props.currentSlidePosition,
          modalType: `${modalType}Modal`
        },
        // onSubmitSlide: this.props.onSubmitSlide,
        // currentSlidePosition: this.props.currentSlidePosition,
      });
    }
  };
  ensureInputBarModalsClosed = () => {
    this.props.closeModal();
    this.setOpenMenu(InputBarOpenMenu.NONE);
    this.setState({
      inputBarShortcutMode: InputBarShortcutMode.Position,
      inputBarMenuImage: '/icons/nav/settings.svg',
    });
  }

  /**
   * Get the menu items for the add (+) slide vertical menu.
   */
  getSlideMenus = () => {
    const slideMenus = [];

    const slideMenuItem = {
      icon: '/icons/nav/exit/alternative.svg',
      sortIndex: 10,
      label: '',
      modal: '',
      className: 'innerInputMenuLargeIcon noBottomMargin color-primary-500-svg',
      containerClassName: '',
      detailItemClassName: '',
      handler: (slideMenuItem: any) => {
        this.props.setOpenMenu(InputBarOpenMenu.NONE);
        this.props.closeModal();
      },
    };
    slideMenus.push(slideMenuItem);
    return slideMenus;
  };

  /**
   * Determine which header and collapsible menu items are show up in the quick slide
   * type selector modal.
   * This is calculated based on the slide number, header slides and collapsible sections
   * that come before the slide number.
  */
  getQuickSlideTypeItems = () => {
    const menus: SlideTypeButton[] = [];
    // const headers: {}
    let getAvailableHeaderLevels = this.props.getAvailableHeaderLevelsForCurrentSlidePosition();
    if (getAvailableHeaderLevels) {
      if (getAvailableHeaderLevels.headerLevels) {
        getAvailableHeaderLevels.headerLevels.forEach(
          (headerLevel: HeaderLevel) => {

            if (headerLevel === HeaderLevel.Ti) {
              const Item = headerIconLookup['Ti'];
              menus.push({
                icon:
                <RoundedCornerButton
                  label="Title"
                  onClick={() => {
                    this.onMenuItemSelected({type: InputBarMenuTypes.HEADER,
                      menuImageSourceOnSelection: `/icons/slidetype/header/ti.svg`,
                      headerLevel: HeaderLevel.Ti
                    });
                    this.props.closeModal();
                  }}
                  style={{backgroundColor: 'var(--COLOR-SECONDARY-200)', width: '87px', height: '40px', marginTop: '6px', marginRight: '6px'}}
                  fontCssClass="TEXTSUBTITLE-2blackhigh-emphasiscenter"
                  className="columnMode"
                  imagePosition="left"
                  icon={<Item
                    color="var(--COLOR-SECONDARY-500)"
                  />}
                />,
                sortIndex: -1
              });
            } else {
              const Item = headerIconLookup[`H${headerLevel}`];
              menus.push({
                icon:
                  <RoundedCornerButton
                    label={`Header ${headerLevel}`}
                    onClick={() => {
                      this.onMenuItemSelected({type: InputBarMenuTypes.HEADER,
                        menuImageSourceOnSelection: `/icons/slidetype/header/h${headerLevel}.svg`,
                        headerLevel: headerLevel
                      });
                      this.props.closeModal();
                    }}
                    style={{backgroundColor: 'var(--COLOR-SECONDARY-200)', width: '87px', height: '40px', marginTop: '6px', marginRight: '6px'}}
                    fontCssClass="TEXTSUBTITLE-2blackhigh-emphasiscenter"
                    className="columnMode"
                    imagePosition="left"
                    icon={<Item
                      color="var(--COLOR-SECONDARY-500)"
                    />}
                  />,
                  sortIndex: headerLevel
              });
            }
          },
        );
      }
      if (!isNullOrUndefined(getAvailableHeaderLevels.headerSlidesToEnd)) {
        Object.values(HeaderLevel)
          .filter(
            headerLevel =>
              Object.keys(getAvailableHeaderLevels.headerSlidesToEnd).length >
              0 && getAvailableHeaderLevels.headerSlidesToEnd[headerLevel],
          )
          .forEach(headerLevel => {
            menus.push({

              icon:
              <RoundedCornerButton
                label={`H${headerLevel} End`}
                onClick={() => {
                  this.props.closeModal();
                  this.onMenuItemSelected({
                    type: InputBarMenuTypes.HEADER_END,
                    menuImageSourceOnSelection: `/icons/nav/settings.svg`,
                    headerLevel: InputBarMenuTypes.HEADER_END,
                    beginSlideId:
                     getAvailableHeaderLevels.headerSlidesToEnd[headerLevel],
                  });
                }}
                style={{backgroundColor: 'var(--COLOR-SECONDARY-200)', width: '87px', height: '40px', marginTop: '6px', marginRight: '6px'}}
                fontCssClass="TEXTSUBTITLE-2blackhigh-emphasiscenter"
                className="columnMode"
                imagePosition="left"
                icon={<icons.SlideType_Header_He
                  color="var(--COLOR-SECONDARY-500)"
                />}
              />,
              sortIndex: 7 + parseInt(headerLevel.toString(), 10),
            });
          });
      }
    }

    menus.push({
      icon:
        <RoundedCornerButton
          label={`Advanced Text`}
          onClick={() => {
            this.props.closeModal();
            this.onMenuItemSelected({
              type: InputBarMenuTypes.TEXT,
              menuImageSourceOnSelection: `/icons/nav/settings.svg`,
              headerLevel: HeaderLevel.UNSET
            });
          }}
          style={{backgroundColor: 'var(--COLOR-SECONDARY-200)', width: '87px', height: '40px', marginTop: '6px', marginRight: '6px'}}
          fontCssClass="TEXTSUBTITLE-2blackhigh-emphasiscenter"
          className="columnMode"
          imagePosition="left"
          icon={<icons.ContentAlteration_Textblock
            color="var(--COLOR-SECONDARY-500)"
          />}
        />,
        sortIndex: 0,
    });

    // // -2 will be the reset menu, -1 is Ti, Text Block is 0
    if (this.state.inputBarShortcutMode !== InputBarShortcutMode.Position) {
      menus.push({
        icon:
        <RoundedCornerButton
          label={`Exit Header Mode`}
          onClick={() => {
            this.ensureInputBarModalsClosed();
          }}
          style={{backgroundColor: 'var(--COLOR-SECONDARY-200)', width: '87px', height: '40px', marginTop: '6px', marginRight: '6px'}}
          fontCssClass="TEXTSUBTITLE-2blackhigh-emphasiscenter"
          className="columnMode"
          imagePosition="left"
          icon={<icons.ContentAlteration_Undo
            color="var(--COLOR-SECONDARY-500)"
          />}
        />,
        sortIndex: -2,
      });
    }
    const sortedMenus = sort((l, r) => r.sortIndex - l.sortIndex, menus);
    return sortedMenus;
  };
  handlePaste = async (e: any) => {
    const { items } = e.clipboardData;
    const item = items[items.length - 1];
    if (item && /image/.test(item.type)) {
      let blob = item.getAsFile();
      // Verify the size:
      const sizeInMB = Math.round(blob.size / 1048576);

      if(sizeInMB <= 10) {
        const mediaFile = await ensureImageFileOrientation(blob);
        const file: ImageFile = {
          id: v4(),
          name: 'name',
          url: URL.createObjectURL(mediaFile),
          dataUrl: mediaFile,
          processingId: v4(),
          type: 'UPLOAD',
          caption: '',
        };
        this.props.openModal({
          modalType: 'ImageSlideEditor',
          modalProps: {
            file,
            currentUser: this.props.currentUser,
            article: this.props.article,
            position: this.props.currentSlidePosition,
            onSubmitSlide: this.props.onSubmitSlide,
          },
        });
      } else {
        this.props.showNotification({
          message: `The file size of ${sizeInMB}MB is too big. NOTE: When using the clipboard, files can be much larger than their original size in chromium browsers. See: https://bugs.chromium.org/p/chromium/issues/detail?id=361145`,
          title: "SIZE LIMIT EXCEEDED: ",
          type: NotificationType.ERROR,
          timeoutMilliseconds: 5000
        });
      }
    }
  };
  handleCameraClicked = () => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    if(this.props.ensureInputBarModalsClosedOnFocus) {
      this.ensureInputBarModalsClosed();
    }
    this.openSlideModal('IMAGECAMERA',  this.props.article);
  };
  /**
   * Adusts the input bar height based on the scroll height.
   * Stops at a max height of
   */
  setInputBarHeight = (input: any, value?: any) => {
    setTimeout(() => {
      // This line is necessary to force the height back
      input.style.cssText = 'height:24px; padding:0';
      if (value) {
        input.style.cssText = 'height:' + value + 'px';
      } else {
        input.style.cssText = 'height:' + input.scrollHeight + 'px';
      }
    }, 0);
  };

  onInputChanged = (value: string) => {
    this.setState({ inputBarValue: value });
  };

  inputBarConfirmClicked = () => {
    const content = this.state.inputBarValue;
    if (!hasValue(content)) {
      alert('Please enter text first.');
      return;
    }
    if (!this.props.displayToolBar) {
      this.props.toggleToolBarDisplay();
    }
  };

  isUserEnteringMultiSlideInput = (parts: string[], isText: boolean): boolean => {
    return (this.props.slideType === 'HEADER' || this.state.inputBarShortcutMode === InputBarShortcutMode.Header || isText) &&
    this.state.inputBarShortcutMode !== InputBarShortcutMode.Collapse && parts.length > 1
  }

  handleInputBarConfirmClicked = async () => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    const content = this.state.inputBarValue;
    if (!hasValue(content)) {
      this.props.showNotification({message: 'Please enter text first.', type: NotificationType.ERROR});
      return;
    }

    if (this.props.textSlideEditing) {
      let slide = {
        slideType: this.props.textSlideEditing.slideType,
        data: {
          ...this.props.textSlideEditing.data,
          body: content,
        },
      };
      const response = await agent.Slides.update(
        this.props.textSlideEditing.id,
        slide,
      );
      const payload = {
        slide: response.slide,
        preventScrollToSlide:
          !isNil(this.props.currentSlidePosition) &&
          this.props.numberOfSlides !== this.props.currentSlidePosition,
      };
      this.props.onSubmitSlide(payload);
      this.setState({
        inputBarValue: '',
        inputBarShortcutMode: InputBarShortcutMode.Position,
        currentHeaderLevel: HeaderLevel.UNSET,
        inputBarMenuImage: '/icons/nav/settings.svg',
      });
      if (!this.props.displayToolBar) {
        this.props.toggleToolBarDisplay();
      }
    } else if (!isNullOrUndefined(this.props.article)) {
      const isText =
        isNullOrUndefined(this.props.slideType) &&
        this.state.inputBarShortcutMode !== InputBarShortcutMode.Header;
      // For Collapse we don't allow multiple slide inserts at the same time.
      let parts = content ? content.split('\n') : [];
      if (this.isUserEnteringMultiSlideInput(parts, isText)) {
        const headerLevel = this.state.currentHeaderLevel;
        const slide = {
          position: this.props.currentSlidePosition,
          slideType: isText ? 'TEXT' : 'HEADER',
          data: isText
            ? {
              body: content,
              values: parts,
            }
            : {
              level: headerLevel,
              values: parts,
            },
        };
        const response = await agent.Slides.createMultipleSlides(
          this.props.article,
          slide,
        );
        const slidesToAdd: Slide[] = response.slides;
        const multiSlideInsertDetails: MultiSlideInsertDetails =
         {
          slidesToInsert: slidesToAdd,
          insertedPosition: !isNullOrUndefined(this.props.currentSlidePosition)
            ? this.props.currentSlidePosition
            : this.props.numberOfSlides,
          slideCount: parts.length,
        };
        this.props.onSubmitMultipleSlides(multiSlideInsertDetails);
      } else {
        let slide;
        if (
          this.props.slideType === 'HEADER' ||
          this.state.inputBarShortcutMode === InputBarShortcutMode.Header
        ) {
          slide = {
            position: this.props.currentSlidePosition,
            slideType: 'HEADER',
            data: {
              level: this.state.currentHeaderLevel,
              title: content,
            },
          };
        } else {
          // Determine if this is a link.
          const isValidUrl = isValidURL(content as string);
          if (isValidUrl) {
            const linkFileDetails = await getLinkPreviewDetails(content as string);
            slide = {
              slideType: 'LINKS',
              position: this.props.currentSlidePosition,
              data: {
                files: [{
                  url: linkFileDetails.url,
                  title: linkFileDetails.title,
                  image: linkFileDetails.image,
                  description: linkFileDetails.description,
                  type: 'URL',
                  displayMode: HyperLinkLayout.BlogImageLeft
                }],
              }
            }
          } else {
            slide = {
              position: this.props.currentSlidePosition,
              slideType: 'TEXT',
              data: {
                body: content,
              },
            };
          }
        }
        const response = await agent.Slides.create(this.props.article, slide);
        const payload = {
          slide: response.slide,
          preventScrollToSlide:
            !isNil(this.props.currentSlidePosition) &&
            this.props.numberOfSlides !== this.props.currentSlidePosition,
        };
        this.props.onSubmitSlide(payload);
      }
      this.setState({
        inputBarValue: '',
        inputBarShortcutMode: InputBarShortcutMode.Position,
        currentHeaderLevel: HeaderLevel.UNSET,
        inputBarMenuImage: '/icons/nav/settings.svg',
        focusInputBar: {},
      });
      if (!this.props.displayToolBar) {
        this.props.toggleToolBarDisplay();
      }
    }
  };

  handleFocusSlideNumber = (ev: any) => {
    ev.target.select();
  };

  closeSlideTypeChooserModal = () => {
    this.props.closeModal();
    this.setOpenMenu(InputBarOpenMenu.NONE);
  }

  onSelectSlideType = async() => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    this.props.closeModal(['QuickSlideTypeChooser']);
    this.setOpenMenu(InputBarOpenMenu.NONE);
    this.setState({
      inputBarShortcutMode: InputBarShortcutMode.Position,
      inputBarMenuImage: '/icons/nav/settings.svg',
    });

    let article = this.props.article;
    if (!this.props.article && this.props.createArticle) {
      article = await this.props.createArticle();
    }
    this.openSlideMenu();
    const className = this.props.showLeftSidebar && this.props.showRightSidebar
    ? ' showLeftSidebar showRightSidebar'
    : this.props.showLeftSidebar
      ? ' showLeftSidebar'
      : this.props.showRightSidebar
        ? ' showRightSidebar'
        : undefined;

    this.props.openModal({
      modalType: 'NewSlideTypeChooserModal',
      modalProps: {
        closeModal: this.closeSlideTypeChooserModal,
        openModal: this.props.openModal,
        onSubmitSlide: this.props.onSubmitSlide,
        showNotification: this.props.showNotification,
        currentUser: this.props.currentUser,
        slidePosition: this.props.currentSlidePosition,
        article: article,
        className: className
      }
    });

  };

  closeSlideMenu = () => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    this.props.setOpenMenu(InputBarOpenMenu.NONE);
    this.props.closeModal();
  };

  handleSlideNumberClicked = (displaySlidePosition: number) => {
    if(!this.props.currentUser) {
      this.props.showSignup();
      return;
    }
    if (this.props.article) {
      if(this.props.ensureInputBarModalsClosedOnFocus) {
        this.ensureInputBarModalsClosed();
      }

      this.props.openModal({
        modalType: 'SlideNumberModal',
        modalProps: {
          slideNumber: displaySlidePosition,
          lastPosition: this.props.numberOfSlides + 1,
          updateSlideNumber: this.props.updateSlideNumber,
          closeModal: this.props.closeModal,
          scrollToSlidePosition: this.props.scrollToSlidePosition,
        },
      });
    }
  };

  render() {
    const displaySlidePosition = !isNil(this.props.currentSlidePosition)
      ? this.props.currentSlidePosition + 1
      : this.props.numberOfSlides + 1;
    const hasText = hasValue(this.state.inputBarValue);
    const slideNumberClasses = classNames(
      'slideNumberCreate',
      displaySlidePosition < 10 ? 'oneToNine' : 'tenToMax',
      displaySlidePosition < this.props.numberOfSlides ? 'outOfOrder' : null,
    );
    const slideNumberInputBarClasses = classNames(
      'slideNumberDisplayContainer',
      displaySlidePosition < this.props.numberOfSlides ? 'outOfOrder' : null,
    );
    const classes = classNames(
      'Footer',
      this.props.displayToolBar ? 'toolbarOn' : 'toolbarOff',
      this.props.openMenu === InputBarOpenMenu.SLIDE
        || this.props.openMenu === InputBarOpenMenu.HEADER ? 'showOverModalBackdrop' : '',
     );
    const inputBarMenuButtonClasses = classNames(
      'pointer',
      'inputBarMenuButton',
      'smallRightMargin',
      hasText ? 'hasText' : null,
    );
    const isNewGide = this.props.numberOfSlides < 1;

    return (
      <div className={classes}>
        <span className="flexRowFullCenter footerMenu">
          <InputBarNew
            slideCount={this.props.numberOfSlides}
            currentSlidePosition={this.props.currentSlidePosition}
            inputBarState={this.props.openMenu}
            inputBarValue={this.state.inputBarValue}
            onAcceptInput={() => this.handleInputBarConfirmClicked()}
            onChange={this.onInputChanged}
            onOpenInputBarSlideMenu={this.onSelectSlideType}
            onCloseInputBarSlideMenu={this.closeSlideMenu}
            onInputEditorFocus={this.onInputFocus}
            focusInputBar={this.state.focusInputBar}
            blurInputBar={this.state.blurInputBar}
            placeholderText={ this.props.placeholderText
              ? this.props.placeholderText
              : isNewGide || !this.props.article
                ? 'Create a Gide...'
                : 'Next please...'
            }
            onOpenTextSlideMenu={this.openInputBarMenu}
            onCloseTextSlideMenu={this.closeInputBarMenu}
            onPaste={this.handlePaste}
            onCameraClicked={this.handleCameraClicked}
            onEmojiClicked={() => this.props.showNotification({
              title: 'Coming Soon!',
              message: 'Emojis are coming soon',
              type: NotificationType.INFO
            })}
            onSlideNumberClicked={() => {
              if(!this.props.currentUser) {
                this.props.showSignup();
                return;
              }
              if (this.props.article) {
                if(this.props.ensureInputBarModalsClosedOnFocus) {
                  this.ensureInputBarModalsClosed();
                }

                this.props.openModal({
                  modalType: 'SlideNumberModal',
                  modalProps: {
                    slideNumber: displaySlidePosition,
                    lastPosition: this.props.numberOfSlides + 1,
                    updateSlideNumber: this.props.updateSlideNumber,
                    closeModal: this.props.closeModal,
                    scrollToSlidePosition: this.props.scrollToSlidePosition,
                  },
                });
              }
            }}
            disabled={isNil(this.props.currentUser)}
            onSelectDisabled={() => this.props.showSignup()}
            currentHeaderLevel={this.state.currentHeaderLevel}
          />
        </span>
      </div>
    );
  }
}
