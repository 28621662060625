import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Input,
  Image,
  Menu,
  Dropdown,
  Button,
  Icon,
  DropdownProps,
} from 'semantic-ui-react';
import SlideModalActions from './SlideModalActions';
import SlideSettings, { Settings } from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import agent from '../../agent';
import {
  ADD_SLIDE,
  MODAL_CLOSE,
  USERS_LOADED,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';

import Datetime from 'react-datetime';

import '../../lib/Datetime/_Datetime.scss';
import { contentMetaData } from '../../constants/contentMetaData';

import MapWithACenteredMarker from '../maps/MapWithACenteredMarker';
import { GOOGLE_MAPS_URL } from '../../constants/paths';
import {
  hasValue
} from '../../utils/helperFunctions';
import slideTools from '../../slideTools';
import { SlideModel, Slide } from '../../models/Slide';
import { User } from '../../models/User';
import { Moment } from 'moment';
import { Article } from '../../models/Article';
import { ChildArticleEditorInfo } from '../../models/ChildArticleEditorInfo';
import isNil from 'ramda/es/isNil';
import { history } from '../../store';
import GideImage from '../Shared/Image/GideImage';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ...slideTools.mapStateToProps(state, ownProps),
    users: state.common.users,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onSubmit: (payload: any) => dispatch({ type: ADD_SLIDE, payload }),
  onUsersLoaded: (payload: any) => dispatch({ type: USERS_LOADED, payload }),
  updateSlideAttachmentInfo: (payload: any) =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

const unitDistanceOptions = [
  { key: 'FEET', value: 'FEET', text: 'feet' },
  { key: 'MILES', value: 'MILES', text: 'miles' },
  { key: 'METERS', value: 'METERS', text: 'meters' },
  { key: 'KILOMETERS', value: 'KILOMETERS', text: 'km' },
];

const unitTimeOptions = [
  { key: 'SECONDS', value: 'SECONDS', text: 'Seconds' },
  { key: 'MINUTES', value: 'MINUTES', text: 'Minutes' },
  { key: 'HOURS', value: 'HOURS', text: 'Hours' },
  { key: 'DAYS', value: 'DAYS', text: 'Days' },
];

const maxAttemptOptions: { key: number; value?: number; text: string }[] = [
  { key: 0, value: undefined, text: 'Unlimited' },
  { key: 1, value: 1, text: '1' },
  { key: 2, value: 2, text: '2' },
  { key: 3, value: 3, text: '3' },
  { key: 4, value: 4, text: '4' },
  { key: 5, value: 5, text: '5' },
];
interface AuthorizeModalProps {
  article: Article;
  slides: Slide[];
  editSlide: SlideModel;
  slide: Slide;
  mode: string;
  selection: string;
  position: number;
  currentSlidePosition: number;
  childArticleEditInfo: ChildArticleEditorInfo;
  users: User[];
  onSubmit: (payload: any) => void;
  onSubmitSlide?: (payload: any) => void;
  onUsersLoaded: (payload: any) => void;
  updateSlideAttachmentInfo: (payload: any) => void;
}
interface AuthorizeModalState {
  slide: SlideModel;
  slideType: string | undefined;
  showSettings: boolean;
  center: { lat: number; lng: number };
  selectedAuthorizedUsers: string[];
}
export class AuthorizeModal extends Component<
  AuthorizeModalProps,
  AuthorizeModalState
> {
  _map: any;
  _marker: any;
  constructor(props: AuthorizeModalProps) {
    super(props);
    if(props.editSlide) {
      this.state = {
        slide: {
          ...props.editSlide,
          data: { ...props.editSlide.data },
        },
        showSettings: false,
        center: {
          lat: props.editSlide.data.lat,
          lng: props.editSlide.data.long,
        },
        selectedAuthorizedUsers: props.editSlide.data.authorizedUsers
          ? props.editSlide.data.authorizedUsers
          : [],
        slideType: props.editSlide.slideType,
      };
    } else {
      this.state = {
        slide: {
          slideType: '',
          data: {
            audioCaption: null,
            caption: '',
            passcodeType: '',
            password: '',
            passwordConfirm: '',
            maxAttempts: '',
            date: '',
            bufferBefore: '',
            bufferAfter: '',
            bufferBeforeUnit: '',
            bufferAfterUnit: '',
            lat: 32.776475,
            long: -79.931051,
            showMarker: '',
            distanceUnit: '',
            distance: '',
            authorizedUsers: [],
          },
        },
        slideType: undefined,
        showSettings: false,
        center: { lat: 32.776475, lng: -79.931051 },
        selectedAuthorizedUsers: [],
      };
    }

    if (props.editSlide) {
      if (props.editSlide.data.passcodeType === 'USER') {
        this.props.onUsersLoaded(agent.Users.getAll());
      }
    }
  }

  submitDisabled(slide: SlideModel): boolean {
    if (slide.data.passcodeType === 'TIME') {
      return (
        !hasValue(slide.data.date) ||
        !hasValue(slide.data.bufferAfter) ||
        !hasValue(slide.data.bufferAfterUnit) ||
        !hasValue(slide.data.bufferBefore) ||
        !hasValue(slide.data.bufferBeforeUnit)
      );
    } else if (slide.data.passcodeType === 'TEXT') {
      return (
        !hasValue(slide.data.password) || !hasValue(slide.data.passwordConfirm)
      );
    } else if (slide.data.passcodeType === 'GPS') {
      return isNaN(slide.data.lat) || isNaN(slide.data.long);
    } else if (slide.data.passcodeType === 'USER') {
      //   return !(slide.data.authorizedUsers && slide.data.authorizedUsers.length > 0);
      return false;
    }
    return true;
  }

  updateSlideSettings(settings: Settings) {
    this.setState({ slide: updateSlideSettings(this.state.slide as Slide, settings) });
  }

  closeSettings() {
    this.setState({ showSettings: false });
  }

  audioCaptionChanged(audioFile: any) {
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    slide.data.audioCaption = audioFile;
    this.setState({ slide });
  }

  userAuthorizeClicked(field: string) {
    this.props.onUsersLoaded(agent.Users.getAll());
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    this.setDefaultValues(field, slide, this.props.editSlide);
    slide.data.passcodeType = field;
    this.setState({
      slide,
      center: { lat: 32.776475, lng: -79.931051 },
    });
  }

  setPasscodeType(field: string) {
    // const slide = Object.assign({}, this.state.slide);
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    this.setDefaultValues(field, slide, this.props.editSlide);
    slide.data.passcodeType = field;
    this.setState({
      slide,
      center: { lat: 32.776475, lng: -79.931051 },
    });
  }

  updateDataState(field: string, ev: any) {
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    if (field === 'long' || field === 'lat') {
      slide.data[field] = parseFloat(ev.target.value);
      this.setState({
        slide,
        center: { lat: slide.data.lat, lng: slide.data.long },
      });
    } else {
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    }
  }

  // This is not being called correctly.
  updateDataStateDropdown(field: string, data: any, ev: any) {
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    slide.data[field] = data.value;
    this.setState({ slide });
  }

  changeDate(moment: Moment | string) {
    if (moment) {
      const date = (moment as Moment).toDate();
      const slide = Object.assign({}, this.state.slide);
      slide.data.date = date;
      this.setState({ slide });
    }
  }

  async createSlide() {
    let slidePosition = !isNil(this.props.currentSlidePosition) ? this.props.currentSlidePosition : this.props.position;
    let slide: SlideModel = {
      ...this.state.slide,
      slideType: this.state.slideType ? this.state.slideType : 'ERROR',
      createMode: this.props.mode,
      selection: this.props.selection,
      allowComments: false,
      allowQuestions: false,
      // If editing, don't change the position.
      position: this.props.editSlide && this.state.slide.position
        ? this.state.slide.position
        : slidePosition,
    };
    if (
      slide.data.passcodeType === 'TEXT' &&
      slide.data.password !== slide.data.passwordConfirm
    ) {
      return alert('Passwords do not match.');
    }
    if (slide.data.passcodeType === 'USER') {
      if (
        !(
          this.state.selectedAuthorizedUsers &&
          this.state.selectedAuthorizedUsers.length > 0
        )
      ) {
        return alert('Must add authorized users.');
      }
      slide.data.authorizedUsers = this.state.selectedAuthorizedUsers;
    }
    this.clearUnusedValues(slide.data.passcodeType, slide);
    let payload;
    if (this.props.editSlide) {
      payload = await agent.Slides.update((this.props.editSlide as Slide).id, slide);
    } else {
      payload = await agent.Slides.create(this.props.article, slide);
      if (this.props.childArticleEditInfo) {
        slideTools.getSlideAttachmentInfo(
          this.props.childArticleEditInfo.ownerSlide.id,
          this.props.updateSlideAttachmentInfo,
        );
      }
    }
    this.props.onSubmitSlide ?
      this.props.onSubmitSlide({ ...payload, mode: this.props.mode }) :
    this.props.onSubmit({ ...payload, mode: this.props.mode });
    history.goBack();
  }

  handleSelectAuthorizedUsers(ev: any, data: DropdownProps) {
    this.setState({ selectedAuthorizedUsers: data.value as string[] });
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    slide.data.authorizedUsers = this.state.selectedAuthorizedUsers;
    this.setState({ slide });
  }

  handleAddAuthorizedUsers(ev: any) {
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    slide.data.authorizedUsers = this.state.selectedAuthorizedUsers;
    this.setState({ slide });
  }

  handleUserAuthorizeClicked(ev: any) {
    this.props.onUsersLoaded(agent.Users.getAll());
    this.setPasscodeType('USER');
  }

  onDrag() {
    const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
    slide.data.lat = this._map.getCenter().lat();
    slide.data.long = this._map.getCenter().lng();
    this.setState({ slide });
  }

  // Used to update the default values when user clicks back and then next and returns to the original type.
  setDefaultValues(passcodeType: string, slide: SlideModel, editSlide: SlideModel) {
    if (passcodeType === 'TEXT') {
      slide.data.password =
        editSlide && hasValue(editSlide.data.password)
          ? editSlide.data.password
          : '';
      slide.data.passwordConfirm =
        editSlide && hasValue(editSlide.data.passwordConfirm)
          ? editSlide.data.passwordConfirm
          : '';
      slide.data.maxAttempts =
        editSlide && hasValue(editSlide.data.maxAttempts)
          ? editSlide.data.maxAttempts
          : null;
    } else if (passcodeType === 'GPS') {
      slide.data.lat =
        editSlide && !isNaN(editSlide.data.lat)
          ? editSlide.data.lat
          : 32.776475;
      slide.data.long =
        editSlide && !isNaN(editSlide.data.long)
          ? editSlide.data.long
          : -79.931051;
      slide.data.showMarker =
        editSlide && hasValue(editSlide.data.showMarker)
          ? editSlide.data.showMarker
          : true;
      slide.data.distanceUnit =
        editSlide && hasValue(editSlide.data.distanceUnit)
          ? editSlide.data.distanceUnit
          : '';
      slide.data.distance =
        editSlide && hasValue(editSlide.data.distance)
          ? editSlide.data.distance && editSlide.data.distance
          : '';
    } else if (passcodeType === 'TIME') {
      slide.data.bufferBefore =
        editSlide && hasValue(editSlide.data.bufferBefore)
          ? editSlide.data.bufferBefore
          : '';
      slide.data.bufferAfter =
        editSlide && hasValue(editSlide.data.bufferAfter)
          ? editSlide.data.bufferAfter
          : '';
      slide.data.bufferBeforeUnit =
        editSlide && hasValue(editSlide.data.bufferBeforeUnit)
          ? editSlide.data.bufferBeforeUnit
          : '';
      slide.data.bufferAfterUnit =
        editSlide && hasValue(editSlide.data.bufferAfterUnit)
          ? editSlide.data.bufferAfterUnit
          : '';
      slide.data.date =
        editSlide && editSlide.data.date && hasValue(editSlide.data.date)
          ? editSlide.data.date
          : '';
    } else if (passcodeType === 'USER') {
      slide.data.authorizedUsers =
        editSlide && editSlide.data && editSlide.data.authorizedUsers
          ? editSlide.data.authorizedUsers
          : [];
    }
  }
  // Depending on the type we want to clear the other that don't make sense for this type of slide.
  // Otherwise they will be stored in the database.
  clearUnusedValues(passcodeType: string, slide: SlideModel) {
    if (passcodeType === 'TEXT') {
      slide.data.bufferBefore = undefined;
      slide.data.bufferAfter = undefined;
      slide.data.bufferBeforeUnit = undefined;
      slide.data.bufferAfterUnit = undefined;
      slide.data.date = undefined;
      slide.data.lat = undefined;
      slide.data.long = undefined;
      slide.data.showMarker = undefined;
      slide.data.distanceUnit = undefined;
      slide.data.distance = undefined;
      slide.data.authorizedUsers = [];
    } else if (passcodeType === 'GPS') {
      slide.data.password = undefined;
      slide.data.passwordConfirm = undefined;
      slide.data.maxAttempts = undefined;
      slide.data.bufferBefore = undefined;
      slide.data.bufferAfter = undefined;
      slide.data.bufferBeforeUnit = undefined;
      slide.data.bufferAfterUnit = undefined;
      slide.data.date = undefined;
      slide.data.authorizedUsers = [];
    } else if (passcodeType === 'TIME') {
      slide.data.password = undefined;
      slide.data.passwordConfirm = undefined;
      slide.data.maxAttempts = undefined;
      slide.data.lat = undefined;
      slide.data.long = undefined;
      slide.data.showMarker = undefined;
      slide.data.distanceUnit = undefined;
      slide.data.distance = undefined;
      slide.data.authorizedUsers = [];
    } else if (passcodeType === 'USER') {
      slide.data.bufferBefore = undefined;
      slide.data.bufferAfter = undefined;
      slide.data.bufferBeforeUnit = undefined;
      slide.data.bufferAfterUnit = undefined;
      slide.data.date = undefined;
      slide.data.lat = undefined;
      slide.data.long = undefined;
      slide.data.showMarker = undefined;
      slide.data.distanceUnit = undefined;
      slide.data.distance = undefined;
      slide.data.password = undefined;
      slide.data.passwordConfirm = undefined;
      slide.data.maxAttempts = undefined;
    }
  }

  getGoogleMapsProps = (slide: SlideModel) => ({
    googleMapURL: GOOGLE_MAPS_URL,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    lat: slide.data.lat,
    lng: slide.data.long,
    showMarker: slide.data.showMarker,
    onDrag: () => this.onDrag(),
    refMap: (el: any) => (this._map = el),
    refMarker: (el: any) => (this._marker = el),
    zoom: slide.data.zoom,
    center: this.state.center,
  }) as never;

  render() {
    const { slide } = this.state;
    const { users } = this.props;

    let userOptions: any = [];
    if (users) {
      userOptions = users.map(user => {
        return {
          text: user.username,
          value: user.username,
          image: {
            avatar: true,
            src: user.image,
          },
        };
      });
    }
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        className="authorizeModal"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(24, 120, 126)' }}
          >
            <span>Authorize Type</span>
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <GideImage src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-authorize.svg" />
          </div>
          {!this.state.showSettings &&
            this.state.slideType !== undefined && (
              <Modal.Description>
                {slide.data.passcodeType === '' && (
                  <Menu fluid vertical style={{ margin: '0 0 0 0' }}>
                    <Menu.Item
                      onClick={() => this.setPasscodeType('TEXT')}
                      style={{
                        background: contentMetaData['AUTHORIZE'].colors[2],
                      }}
                    >
                      Text
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        this.setPasscodeType('GPS');
                      }}
                      style={{
                        background: contentMetaData['AUTHORIZE'].colors[1],
                      }}
                    >
                      GPS
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => this.setPasscodeType('TIME')}
                      style={{
                        background: contentMetaData['AUTHORIZE'].colors[0],
                      }}
                    >
                      Time
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => this.userAuthorizeClicked('USER')}
                      style={{
                        background: contentMetaData['AUTHORIZE'].colors[0],
                      }}
                    >
                      User
                    </Menu.Item>
                  </Menu>
                )}
                {slide.data.passcodeType === 'USER' && (
                  <div className="dataEntry">
                    <fieldset>
                      <h2>Gide Users</h2>
                      <Dropdown
                        value={this.state.selectedAuthorizedUsers || []}
                        placeholder="Select User(s)"
                        search
                        fluid
                        selection
                        multiple
                        options={userOptions}
                        onChange={(e: any, data: DropdownProps) =>
                          this.handleSelectAuthorizedUsers(e, data)
                        }
                      />
                    </fieldset>
                  </div>
                )}
                {slide.data.passcodeType === 'TEXT' && (
                  <div>
                    <Input
                      fluid
                      placeholder="Password"
                      value={slide.data.password}
                      onChange={(e: any) => this.updateDataState('password', e)}
                    />
                    <Input
                      fluid
                      placeholder="Confirm Password"
                      value={slide.data.passwordConfirm}
                      onChange={(e: any) =>
                        this.updateDataState('passwordConfirm', e)
                      }
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'center',
                        marginTop: '10px',
                        marginLeft: '15px',
                      }}
                    >
                      <h5>Max Attempts: </h5>
                      <Dropdown
                        style={{ marginLeft: '10px' }}
                        placeholder="Unlimited"
                        options={maxAttemptOptions}
                        value={slide.data.maxAttempts}
                        onChange={(e: any, data: any) =>
                          this.updateDataStateDropdown('maxAttempts', data, e)
                        }
                      />
                    </div>
                  </div>
                )}
                {slide.data.passcodeType === 'GPS' && (
                  <div>
                    <MapWithACenteredMarker
                      {...this.getGoogleMapsProps(slide)}
                    />
                    <div>
                      <Input
                        fluid
                        placeholder="Longitude"
                        value={slide.data.long}
                        onChange={(e: any) => this.updateDataState('long', e)}
                        type="number"
                        min="0"
                      />
                    </div>
                    <div>
                      <Input
                        fluid
                        placeholder="Latitude"
                        value={slide.data.lat}
                        onChange={e => this.updateDataState('lat', e)}
                        type="number"
                        min="0"
                      />
                    </div>
                    <div>
                      <fieldset className="form-group">
                        <div>
                          <h5 style={{ marginLeft: '5px' }}>
                            Select max distance from selected location: (NOTE:
                            accuracy can vary by device)
                          </h5>
                          <Input
                            style={{ marginLeft: '5px' }}
                            placeholder="Within"
                            value={slide.data.distance}
                            onChange={(e: any) =>
                              this.updateDataState('distance', e)
                            }
                            type="number"
                            min="1"
                          />
                          <Dropdown
                            style={{ marginLeft: '5px' }}
                            options={unitDistanceOptions}
                            value={slide.data.distanceUnit}
                            onChange={(e: any, data: any) =>
                              this.updateDataStateDropdown(
                                'distanceUnit',
                                data,
                                e,
                              )
                            }
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}
                {slide.data.passcodeType === 'TIME' && (
                  <div>
                    <div>
                      <Datetime
                        className="form-control form-control-lg"
                        inputProps={{ placeholder: 'Select Date and Time' }}
                        onChange={this.changeDate.bind(this)}
                        value={
                          slide.data.date
                            ? new Date(slide.data.date)
                            : undefined
                        }
                      />
                      <fieldset className="form-group">
                        <div>
                          <Input
                            placeholder="Buffer Before"
                            value={slide.data.bufferBefore}
                            onChange={e =>
                              this.updateDataState('bufferBefore', e)
                            }
                            type="number"
                            min="1"
                          />
                          <Dropdown
                            style={{ marginLeft: '5px' }}
                            options={unitTimeOptions}
                            value={slide.data.bufferBeforeUnit}
                            onChange={(e: any, data: any) =>
                              this.updateDataStateDropdown(
                                'bufferBeforeUnit',
                                data,
                                e,
                              )
                            }
                          />
                        </div>
                      </fieldset>
                      <fieldset className="form-group">
                        <div>
                          <Input
                            placeholder="Buffer After"
                            value={slide.data.bufferAfter}
                            onChange={e =>
                              this.updateDataState('bufferAfter', e)
                            }
                            type="number"
                            min="1"
                          />
                          <Dropdown
                            style={{ marginLeft: '5px' }}
                            options={unitTimeOptions}
                            value={slide.data.bufferAfterUnit}
                            onChange={(e: any, data: any) =>
                              this.updateDataStateDropdown(
                                'bufferAfterUnit',
                                data,
                                e,
                              )
                            }
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}
              </Modal.Description>
            )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                hideTemplateIndicator={this.props.article.type !== 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide as Slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
          {!this.state.showSettings &&
            this.state.slideType === undefined && (
              <Modal.Description>
                <div className="authorizeSlideTypeSelection">
                  {/* <div className={`authorizeSlideType${this.state.slideType === 'AUTHORIZE' ? ' selected' : ''}`}  */}
                  <div
                    className={`authorizeSlideType `}
                    onClick={() => this.setState({ slideType: 'AUTHORIZE' })}
                  >
                    <span>Gide End</span>
                  </div>
                  <div
                    className={`authorizeBlockSlideType`}
                    onClick={() =>
                      this.setState({ slideType: 'AUTHORIZEBLOCK' })
                    }
                  >
                    <span>Authorization Block</span>
                  </div>
                </div>
              </Modal.Description>
            )}
        </Modal.Content>
        {slide.data.passcodeType !== '' && (
          <Modal.Actions
            style={{ background: contentMetaData['AUTHORIZE'].primaryColor }}
          >
            <SlideModalActions
              caption={slide.data.caption}
              captionChanged={(e: any) => this.updateDataState('caption', e)}
              audioCaption={slide.data.audioCaption}
              onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
              showSettings={this.state.showSettings}
              canNavigateBack={true}
              backClicked={() => this.setPasscodeType('')}
              settingsClicked={() =>
                this.setState({ showSettings: !this.state.showSettings })
              }
              disableNextButton={this.submitDisabled(this.state.slide)}
              nextClicked={this.createSlide.bind(this)}
              settings={getSlideSettings(
                this.state.slide as Slide,
                this.props.article.type,
              )}
              onSettingsChanged={this.updateSlideSettings.bind(this)}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeModal);
