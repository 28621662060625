import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';

import { connect } from 'react-redux';
import agent from '../../agent';
import {
  LOAD_GIDE_ELEMENT_SEARCH_RESULTS,
  CLEAR_GIDE_ELEMENT_SEARCH_RESULTS,
  MODAL_CLOSE,
  ADD_USER_SHORTCUTS,
  DELETE_USER_SHORTCUT,
  UPDATE_USER_SHORTCUT,
} from '../../constants/actionTypes';
import icons from '../../assets/icons';
import GideImage from '../Shared/Image/GideImage';
import GideElementSearch, { BaseSearchElement } from '../Shared/GideElementSearch/GideElementSearch';

interface ShortcutManagerState {
  displaySearch: boolean;
  searchText: string;
  selectedShortcutId?: string;
  // shortcuts: Shortcut[];
}
interface ShortutManagerProps {
  closeModal: () => void;
  action: () => void;
  userShortcuts: Shortcut[];
  loadGideElementSearchResults: (
    payload: { searchResults: BaseSearchElement[] },
  ) => void;
  clearGideElementSearchResults: () => void;
  gideElementSearchResults: [];
  addUserShortcuts: (payload: { shortcuts: any[] }) => void;
  deleteUserShortcut: (payload: { shortcutId: string }) => void;
  updateShortcuts: (payload: { shortcutId: string; sortIndex: number }) => void;
}
interface Shortcut {
  id: string;
  shortcutEntityId: string;
  displayName: string;
  sortIndex: number;
  shortcutImageUrl?: string;
  shortcutType: string;
  authorUserName: string;
  createdAt: Date;
}

const mapStateToProps = (state: any) => ({
  gideElementSearchResults: state.common.gideElementSearchResults,
  userShortcuts: state.common.userShortcuts ? state.common.userShortcuts : [],
});
const mapDispatchToProps = (dispatch: any) => ({
  loadGideElementSearchResults: (payload: any) =>
    dispatch({ type: LOAD_GIDE_ELEMENT_SEARCH_RESULTS, payload }),
  clearGideElementSearchResults: () =>
    dispatch({ type: CLEAR_GIDE_ELEMENT_SEARCH_RESULTS }),
  closeModal: () => dispatch({ type: MODAL_CLOSE }),
  addUserShortcuts: (payload: { shortcuts: any[] }) =>
    dispatch({ type: ADD_USER_SHORTCUTS, payload }),
  deleteUserShortcut: (payload: { shortcutId: string }) =>
    dispatch({ type: DELETE_USER_SHORTCUT, payload }),
  updateShortcuts: (payload: { shortcutId: string; sortIndex: number }) =>
    dispatch({ type: UPDATE_USER_SHORTCUT, payload }),
});

export class ShortcutManagerModal extends Component<
  ShortutManagerProps,
  ShortcutManagerState
> {
  constructor(props: Readonly<ShortutManagerProps>) {
    super(props);
    this.state = {
      searchText: '',
      displaySearch: false,
    };
  }
  public shortcutListTemplate(shortcut: Shortcut) {
    return (
      <span className={`shortcutDialogItem`}>
        <GideImage 
          src={`${shortcut.shortcutImageUrl}`}
          className={`shortcutImage ${shortcut.shortcutType}`}
          alt={`${shortcut.displayName}`}
        />
        <span className={`shortcutTitle ${shortcut.shortcutType}`}>
          {shortcut.displayName}
        </span>
      </span>
    );
  }
  private onShortCutsSelected(
    selectedItems: BaseSearchElement[],
    type: string,
  ) {
    // Have to make a separate call because it needs to be async. Don't
    // want to require async however for other users of the GideElementSearch
    // control.
    this.saveShortcuts(
      selectedItems.map((i, index) => {
        return {
          shortcutEntityId: i.id,
          sortIndex: index + 1, // Sort Index starts at 1.
          shortcutEntityType: type,
        };
      }),
    );
    this.props.clearGideElementSearchResults();
    this.setState({ displaySearch: false });
  }
  private markAsSelected(selectedShortcutId: string) {
    this.setState({ selectedShortcutId: selectedShortcutId });
  }
  private moveShortcut(shortcut: Shortcut) {
    const moveToPosition = prompt('Move shortcut position:');
    if (moveToPosition) {
      const sortIndex = parseInt(moveToPosition, 10);
      this.moveShorcutAsync({ shortcutId: shortcut.id, sortIndex: sortIndex });
    }
  }
  private async moveShorcutAsync(shortcutUpdate: {
    shortcutId: string;
    sortIndex: number;
  }) {
    const response = await agent.Users.updateShortcut(shortcutUpdate);
    this.props.updateShortcuts(response);
  }
  private async deleteShortcut(shortcutId: string) {
    if (window.confirm('Are you sure you want to delete the selected shortcut?')) {
      await agent.Users.deleteShortcut(shortcutId);
      this.props.deleteUserShortcut({ shortcutId });
    }
  }
  private async saveShortcuts(shortcuts: any) {
    const response = await agent.Users.saveShortcuts(shortcuts);
    this.props.addUserShortcuts({ shortcuts: response.userShortcuts });
  }

  render() {
    return (
      <Modal
        style={{ width: '350px', height: '500px', display: 'flex !important' }}
        closeOnEscape={true}
        onClose={this.props.closeModal}
        className="shortcutModal"
        size="tiny"
        dimmer="inverted"
        closeOnDocumentClick={false}
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content className="flexColumnFull">
          <Modal.Description
            className="headerLevel3 shortcutHeader"
            style={{ padding: '10px' }}
          >
            {this.state.displaySearch && (
              <span>
                <strong>Search Gides</strong>
              </span>
            )}
            {!this.state.displaySearch && (
              <span className="flexRowSpaceBetween">
                <strong>Manage Shortcuts</strong>
                <icons.Nav_Plusicon
                  className="addShortcutIcon"
                  onClick={() => this.setState({ displaySearch: true })}
                />
                {/* <GideImage 
                  src="/images/add-slide.png"
                  className="addShortcutIcon"
                  onClick={() => this.setState({ displaySearch: true })}
                  alt="display search"
                /> */}
              </span>
            )}
          </Modal.Description>
          {this.state.displaySearch && (
            <div className="shortcutManagerGideElementSearch">
              <GideElementSearch
                loadGideElementSearchResults={
                  this.props.loadGideElementSearchResults
                }
                clearGideElementSearchResults={
                  this.props.clearGideElementSearchResults
                }
                searchResults={this.props.gideElementSearchResults}
                actionLabel="Add Shortcuts"
                action={this.onShortCutsSelected.bind(this)}
              />
            </div>
          )}
          {!this.state.displaySearch && (
            <div className="flexColumnFull">
              <div className="shortcuts">
                {this.props.userShortcuts &&
                  this.props.userShortcuts
                    .sort((s1, s2) => {
                      return s1.sortIndex - s2.sortIndex;
                    })
                    .map((shortcut: Shortcut, key: number) => {
                      return (
                        <span
                          key={key}
                          className={`shortcutDialogItem ${
                            this.state.selectedShortcutId === shortcut.id
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => this.markAsSelected(shortcut.id)}
                        >
                          <GideImage 
                            src={`${shortcut.shortcutImageUrl}`}
                            className={`shortcutImage ${shortcut.shortcutType}`}
                            alt={`${shortcut.displayName}`}
                          />
                          <span
                            className={`shortcutTitle ${shortcut.shortcutType}`}
                          >
                            {shortcut.displayName}
                          </span>
                          <div
                            className="pointer moveIcon"
                            onClick={() => this.moveShortcut(shortcut)}
                          >
                            ↕
                          </div>
                          <icons.ContentAlteration_Delete
                            className="pointer deleteShortcutIco"
                            onClick={() => this.deleteShortcut(shortcut.id)}
                            color="var(--COLOR-SECONDARY-500)"
                          />
                          {/* <Icon
                            className="pointer deleteShortcutIcon"
                            name="trash"
                            onClick={() => this.deleteShortcut(shortcut.id)}
                            size="small"
                            color="grey"
                          /> */}
                        </span>
                      );
                    })}
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions className="preventSemanticActionStyle">
          <div className="flexRowSpaceBetween">
            {this.state.displaySearch && (
              <Button onClick={() => this.setState({ displaySearch: false })}>
                Back
              </Button>
            )}
            <Button onClick={this.props.closeModal}>Close</Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(
  ShortcutManagerModal,
);
