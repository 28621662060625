import { Component } from "react";
import TextSlide from '../components/Slides/TextSlide';
import LinksSlide from '../components/Slides/LinksSlide';
import ContactsSlide from '../components/Slides/ContactsSlide';
import GideSlide from '../components/Slides/GideSlide';
import AudioSlide from '../components/Slides/AudioSlide';
import VideoSlide from '../components/Slides/VideoSlide';
import ImageSlide from '../components/Slides/ImageSlide';
import FileSlide from '../components/Slides/FileSlide';
import ListSlide from '../components/Slides/ListSlide';
import LocationSlide from '../components/Slides/LocationSlide';
import { MapSlide } from '../components/Slides/MapSlide';
import ChooseSlide from '../components/Slides/ChooseSlide';
import AuthorizeSlide from '../components/Slides/AuthorizeSlide/AuthorizeSlide';
import AuthorizeBlockSlide from '../components/Slides/AuthorizeSlide/AuthorizeBlockSlide';
import SlideSlide from '../components/Slides/SlideSlide';
import HeaderSlide from '../components/Slides/HeaderSlide';
import { DiagramSlide } from '../components/Slides/DiagramSlide';
import InputSlide from '../components/Slides/InputSlide';
import ItemSlide from '../components/Slides/ItemSlide';
import LeadSlide from '../components/Slides/LeadSlide';
import DoodleSlide from '../components/Slides/DoodleSlide';
import EmbedSlide from '../components/Slides/EmbedSlide';
import SignatureSlide from '../components/Slides/SignatureSlide';
import ColumnSlide from '../components/Slides/ColumnSlide';
import BlogSlide from '../components/Slides/BlogSlide';
import ButtonSlide from '../components/Slides/ButtonSlide';
import CollapseSlide from '../components/Slides/CollapseSlide';
import HideSlide from '../components/Slides/HideSlide/HideSlide';
import ImageGallerySlide from '../components/Slides/ImageGallerySlide/ImageGallerySlide';
import SlideshowSlide from '../components/Slides/SlideshowSlide/SlideshowSlide';
import GideTitleSlide from '../components/Slides/GideTitleSlide/GideTitleSlide';

export const slideComponentLookup: {
    [key: string]:
    typeof Element | typeof Component | any // Figure out how to remove this any
  } = {
  'TEXT': TextSlide,
  'LINKS': LinksSlide,
  'CONTACTS': ContactsSlide,
  'GIDE': GideSlide,
  'AUDIO': AudioSlide,
  'VIDEO': VideoSlide,
  'IMAGE': ImageSlide,
  'FILE': FileSlide,
  'LIST': ListSlide,
  'LOCATION': LocationSlide,
  'MAP': MapSlide,
  'CHOOSE': ChooseSlide,
  'AUTHORIZE': AuthorizeSlide,
  'AUTHORIZEBLOCK': AuthorizeBlockSlide,
  'SLIDE': SlideSlide,
  'HEADER': HeaderSlide,
  'DIAGRAM': DiagramSlide,
  'INPUT': InputSlide,
  'ITEM': ItemSlide,
  'LEAD': LeadSlide,
  'DOODLE': DoodleSlide,
  'EMBED': EmbedSlide,
  'SIGNATURE': SignatureSlide,
  'COLUMN': ColumnSlide,
  'BLOG': BlogSlide,
  'BUTTON': ButtonSlide,
  'COLLAPSE': CollapseSlide,
  'SLIDESHOW': SlideshowSlide,
  'HIDE': HideSlide,
  'IMAGEGALLERY': ImageGallerySlide,
  'GIDETITLE': GideTitleSlide,
};