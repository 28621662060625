import React, { Component, Dispatch, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { push } from 'connected-react-router';
import AvatarMenu, { Shortcut } from './AvatarMenu/AvatarMenu';
import classNames from 'classnames';
import superagent from 'superagent';
import agent from '../agent';
import Header, { HeaderMode } from './Header/Header';
// import ArticleComponent from './Article';
// import Channel from './Article/Channel';
// import Editor from './Editor';
// import ChannelEditor from './Channel/Editor';
// import ChannelWebsite from './Channel/Website';
// import EditSlide from './EditSlide';
// import Login from './Login';
// import Profile from './Profile';
// import Register from './Register';
// import Settings from './Settings';
// import Account from './Account';
import { store, history } from '../store';
import ModalManager from './modals/ModalManager';
import InlineToolbarGlobal from './Editor/InlineToolbarGlobal';
import SidebarLeft, { SidebarTab } from './SidebarLeft/SidebarLeft';
import SlideViewSlide from './Slides/index';
import localforage from 'localforage';
import { Profile as ProfileModel} from '../models/Profile';
import {
  getCustomDomain,
  urlForArticle,
  getAvailableHeaderLevelsForSlidePosition,
  getParentHeaderSlides,
  getSlideRangeList,
  getChildArticleForSlide,
  hasValue,
  isRoutedModal,
  openModalForType,
  closeModalForType,
  isReadOnlyDevice,
} from '../utils/helperFunctions';
import Footer from './Footer/Footer';
import { VERSION_NUMBER, InputBarOpenMenu, NotificationType } from '../constants/strings';
import { HeaderNavigation } from './Article/HeaderNavigation';
import {
  APP_LOAD,
  REDIRECT,
  MODAL_OPEN,
  MODAL_CLOSE,
  UPDATE_SLIDE_INLINE_SELECTION,
  MAKE_INLINE_SELECTION_ON_SLIDE,
  ARTICLE_SUBMITTED,
  LOGOUT,
  SCROLL_TO_SLIDE,
  TOGGLE_LEFT_SIDEBAR,
  SET_OPEN_FOOTER_MENU,
  SET_TOASTER_MESSAGE,
  TOGGLE_GIDES_MENU,
  CLOSE_AVATAR_MENU,
  LOAD_USER_SHORTCUTS,
  LOAD_USER_FAVORITES,
  SCROLL_TO_SLIDE_ID,
  COLLAPSE_ALL_SLIDES,
  EXPAND_ALL_SLIDES,
  TOGGLE_COLUMNS,
  TOGGLE_BLAME,
  TOGGLE_COLLAPSED_HEADER_DISPLAY,
  TOGGLE_END_SLIDE_DISPLAY,
  ENTER_SLIDE_SELECTION_MODE,
  UPDATE_FOOTER_INPUT,
  ADD_SLIDE,
  INPUT_BLURRED,
  ADD_MULTIPLE_SLIDES,
  ARTICLE_TITLE_IMAGE_UPDATED,
  ARTICLE_FILTER_SLIDES,
  UPDATE_SLIDE_NUMBER,
  COLLAPSE_ALL_CHILD_ARTICLES_BY_TYPE,
  EXIT_SLIDE_SELECTION_MODE,
  ARTICLE_DELETE_SLIDES,
  ARTICLE_COPY_SLIDES,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
  ARTICLE_MOVE_SLIDES_OUT_OF_GIDE,
  UPDATE_SLIDE_CHILD_GIDE_FOR_GIDE_TYPE,
  TOGGLE_RIGHT_SIDEBAR,
  DELETE_VIEW,
  ARTICLE_PAGE_SELECTED_FROM_SIDEBAR,
  SETTINGS_SAVED,
  CHANNEL_SUBMITTED,
  LOGIN,
  UPDATE_FIELD_AUTH,
  ROUTE_TO_MODAL,
  DELETE_ARTICLE_NO_REDIRECT,
} from '../constants/actionTypes';

import './_app.scss';
import SlideshowView from './Slides/SlideshowView/SlideshowView';
import GideViewer from './Gide/GideViewer/GideViewer';
import { withTheme } from 'styled-components';
import { rootPath } from '..';
import { SlideSelectionOperation, SlideSelectionTransferType, SlideSelectionInfo } from '../models/SlideSelectionInfo';
import { contains, isNil, any } from 'ramda';
import SlideTransferGideSelector, { SlideTransferMode, SlideTransferSelectionInfo } from './modals/SlideTransferGideSelector/SlideTransferGideSelector';
import { ChildArticleType, getChildArticleTypeName } from '../models/ArticleLayoutEnum';
import { AppState } from '../reducer';
import { GideAction } from '../models/GideAction';
import { ArticleAction } from '../models/ArticleAction';
import { ToasterMessageInfo } from '../models/ToasterMessageInfo';
import { Slide, SlideUpdateInfo, GideTitleImageInfo } from '../models/Slide';
import { GideSearchElement } from './Shared/GideElementSearch/GideElementSearch';
import { User, LoginModel } from '../models/User';
import { Article } from '../models/Article';
import { Channel as ChannelModel } from '../models/Channel';
import { Collection } from '../models/Collection';
import { MultiSlideInsertDetails } from './Gide/GideViewerManagerModal/GideViewerManagerModal';
import SlideFilter, { SlideFilterState } from './Gide/SlideFilter/SlideFilter';
import { MultiSlideSelectionDetail } from '../reducers/article';
import { Sidebar } from './Sidebar/Sidebar';
import { FooterAction } from '../models/FooterAction';
// import HomeConnect from './Home/Home.connect';
import { AvatarMenuLocation } from './Shared/Avatar/Avatar';
import icons from '../assets/icons';
import { SlideFileProcessorInfo, TitleSlideProcessorInfo, GallerySlideFileProcessorInfo } from '../reducers/FileProcessing/fileProcessing';
import {
  START_SLIDE_FILE_PROCESSING, COMPLETE_SLIDE_FILE_PROCESSING, COMPLETE_TITLE_SLIDE_PROCESSING, START_TITLE_SLIDE_PROCESSING,
  ADD_SLIDE_FILE_FOR_PROCESSING, ADD_TITLE_SLIDE_FOR_PROCESSING, START_GALLERY_SLIDE_FILE_PROCESSING, COMPLETE_GALLERY_SLIDE_FILE_PROCESSING,
  ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING
} from '../reducers/FileProcessing/fileProcessing.actions';
import { API_ROOT } from '../constants/paths';
import { ThemeSettingName } from 'office-ui-fabric-react/lib/Styling';
import { withNotificationDispatch, ToastAction } from './Shared/Notification/NotificationContext';
import { NotificationInline } from './Shared/Notification/NotificationInline';
import { ProfileMode, AccountProfileModalProps } from './modals/AccountProfileModal/AccountProfileModal';
import SwipeHeader from '../components/Header';
import { SigninModalProps } from './modals/SigninModal/SigninModal';
import { CONFIG_LOADED } from '../reducers/common/common.actions';
import { AuthConfig } from '../models/AuthConfig';
import { Loading } from './Shared/Loading/Loading';
import { Helmet } from "react-helmet";
import { CircleIconButton } from './Shared/CircleIconButton/CircleIconButton';

// Lazy-loaded Components (for code-splitting).
const LazyLogin = lazy(() => import('./Login'));
const LazyArticleComponent = lazy(() => import('./Article'));
const LazyHomeConnect = lazy(() => import('./Home/Home.connect'));
const LazyEditor = lazy(() => import('./Editor'));
const LazyChannelEditor = lazy(() => import('./Channel/Editor'));
const LazyProfile = lazy(() => import('./Profile'));
const LazyRegister = lazy(() => import('./Register'));
const LazyAccount = lazy(() => import('./Account'));
const LazyEditSlide = lazy(() => import('./EditSlide'));
const LazyChannelWebsite = lazy(() => import('./Channel/Website'));
const LazyChannel = lazy(() => import('./Article/Channel'));
const LazyUserManagement = lazy(() => import('./Administration/UserManagement/UserManagement'));
const LazySettings = lazy(() => import('./Settings'));

interface ReducerStateProps {
  appLoaded: boolean;
  appName: string;
  currentUser: User | null;
  hideToolbar: boolean;
  inputBarHasFocus: boolean;
  textSlideEditing: Slide | null;
  slideType: string | null;
  slides: Slide[];
  slideNumber: number | null;
  allowBlame: boolean;
  allowSlideComments: boolean;
  allowSlideQuestions: boolean;
  pauseSlideComments: boolean;
  pauseSlideQuestions: boolean;
  renderColumns: boolean;
  showBlame: boolean;
  displayCollapsedHeaders: boolean;
  hideEndSlides: boolean;
  meta: any; // TODO: Figure out and add typing
  redirectTo: string | null;
  showInlineToolbar: boolean;
  inlineToolbarPosition: any; // TODO: Figure out and add typing
  viewMode: string | null;
  showChrome: boolean;
  article?: Article | null;
  percentArticleCompleted: number;
  headerSlides: Slide[];
  showLeftSidebar: boolean;
  showRightSidebar: boolean;
  nextViewMode: string | null;
  openMenu: InputBarOpenMenu;
  toasterMessageInfo: ToasterMessageInfo | null;
  displayGidesMenu: boolean;
  userShortcuts: Shortcut[]; // TODO: Why both?
  userShortcutFavorites: Shortcut[];
  userChannelFavorites: Shortcut[];
  userFavoritesLoaded: boolean;
  gideElementSearchResults: any[]; // TODO: Figure out and add typing;
  slideSelectionModeDetail: SlideSelectionInfo | null;
  slideFilter: SlideFilterState | null;
  multiSlideSelectionDetail: MultiSlideSelectionDetail;
  view?: string | null; // TODO: This is always undefined. Check it out.
  disableFooterInput?: boolean;
  loading: boolean;
  slideFileProcessorList: SlideFileProcessorInfo[];
  titleSlideProcessorList: TitleSlideProcessorInfo[];
  gallerySlideFileProcessorList: GallerySlideFileProcessorInfo[];
  isInCreationProcessModal: boolean;
  isInAdministration: boolean;
  profile: string | null;
  profilePage: ProfileMode;
  profileView: 'activity' | 'channel' | null;
  profileChannelSlug: string | null;
  loadingUserHistory: boolean;
  routedModalInfo?: { modalType: string, modalProps?: any};
  lastCurrentlyOpenedModal?: string;
}
interface ReducerDispatchProps {
  onLoad: (payload: {meta: {
    channels: ChannelModel[];
    collections: Collection[];
    interactions: any[];
    notifications: any[];
    views: any[]
  }, user: User}, token?: string | null) => void;

  onCollapseAllSlides: (slides: Slide[]) => void;
  onExpandAllSlides: () => void;
  onToggleColumns: () => void;
  onToggleBlame: () => void;
  onCollapseAllInlineViewersByType: (payload: any) => void, // TODO: Figure out and add typing
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  toggleCollapsedHeaderDisplay: () => void;
  toggleEndSlideDisplay: () => void;
  enterSlideSelectionMode: (payload: SlideSelectionInfo) => void;
  setFooterInput: (key: string, value: string) => void;
  onInputBlurred: () => void;
  onSubmitMultipleSlides: (payload: MultiSlideInsertDetails) => void;
  onCopySlides: (payload: {insertedPosition: number, slides: Slide[]}) => void;
  onSubmitSlide: (payload: SlideUpdateInfo) => void;
  onUpdateArticleTitleAndImage: (payload: GideTitleImageInfo) => void;
  updateSlideNumber: (slideNumber: number) => void;
  onRedirect: () => void;
  openModal: (payload: { modalType: string, modalProps?: any}) => void;
  routeToModal: (payload: { modalType: string, modalProps?: any}) => void;
  closeModal: (closeDialogList?: string[]) => void;
  hideSlideInlineSelection: () => void;
  makeSlideInlineSelection: (payload: {mode: string}) => void;
  onSubmitArticle: (payload: {article: Article}) => void;
  onClickLogout: () => void;
  scrollToSlidePosition: (position: number) => void;
  onToggleLeftSidebar: () => void;
  onToggleRightSidebar: (isGideMenu: boolean) => void;
  turnOverlayOff: () => void;
  clearToaster: () => void;
  toggleGidesMenu: () => void;
  closeAvatarMenu: () => void;
  loadUserShortcuts: (payload: {userShortcuts: any[] }) => void; // TODO: Figure out and add typing for related types
  loadUserFavorites: (payload: {userShortcuts: any[], userChannels: any[] }) => void; // TODO: Figure out and add typing for related types
  scrollToSlidePositionById: (payload: {slideId: string, parentHeaderSlides: Slide[]}) => void;
  setOpenMenu: (payload: {openMenu: InputBarOpenMenu}) => void;
  exitSlideSelectionMode: () => void;
  filterSlides: (payload: SlideFilter) => void;
  removeSlidesFromArticle: (payload: {slideIds: string[]}) => void;
  updateSlideAttachments: (payload: {
      slideId: string, childArticlesSlideTypes: string[],
      childArticlesSlideDetails: {
        author: string,
        count: number,
        status: string,
        type: string}[],
      }
  ) => void;
  moveSlidesOutOfGide: (payload: {articleId: string, slideIds: string[]}) => void;
  updateChildGidesOnSlideForGideType: (payload: {slideId: string, articleType: string, slides: Slide[]}) => void;
  onDeleteView: (payload: any, id: any) => void;
  onArticlePageSelectedFromSidebar: () => void;
  addMediaFileProcessingInfo: (slideFileProcessorInfo: SlideFileProcessorInfo[]) => void;
  addTitleSlideProcessingInfo: (titleSlideProcessorInfo: TitleSlideProcessorInfo[]) => void;
  addSlideGalleryFileProcessingInfo: (gallerySlideFileProcessorInfo: GallerySlideFileProcessorInfo[]) => void;
  startSlideFileProcessing: (processingIds: string[]) => void;
  startTitleSlideProcessing: (processingIds: string[]) => void;
  startGallerySlideFileProcessing: (processingIds: string[]) => void;
  completeSlideFileProcessing: (slideFileCompletedInfo: {slideFileProcessorInfo: SlideFileProcessorInfo, fileUrl: string}) => void;
  completeTitleSlideProcessing: (titleSlideProcerrorCompletionInfo: {titleSlideProcessorInfo: TitleSlideProcessorInfo, fileUrl: string}) => void;
  completeGallerySlideFileProcessing: (gallerySlideFileCompletedInfo: {gallerySlideFileProcessorInfo: GallerySlideFileProcessorInfo, fileUrl: string}) => void;
  settingsSaved: (profile: ProfileModel) => void;
  channelCreated: (channel: ChannelModel) => void;
  channelUpdated: (channel: ChannelModel) => void;
  onLogin: (payload: any) => void;
  onChangeAuthField: (key: string, value: string) => void;
  onConfigLoaded: (config: AuthConfig) => void;
  onGideDeleted: (gideId: string) => void;
}
type AppProps = ReducerStateProps & ReducerDispatchProps & {theme?: any} & { notificationDispatch: React.Dispatch<ToastAction>};

const mapStateToProps = (state: AppState, ownProps: any): ReducerStateProps => {
  const query = new URLSearchParams(ownProps.location.search);
  const view = query.get('view');
  const profile = query.get('profile');
  const profilePage = query.get('page');
  const profileViewParam = query.get('view');
  const profileView = profileViewParam === null || profileViewParam === 'activity' || profileViewParam === 'channel' ? profileViewParam : 'activity';
  const profileChannelSlug = query.get('channelSlug');
  const titleSlideProcessorList = state.fileProcessor.titleSlideProcessorList;
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    hideToolbar: state.footer.hideToolbar,
    inputBarHasFocus: state.footer.focus,
    textSlideEditing: state.common.textSlideEditing,
    slideType: state.common.slideType,
    slides: state.article.slides,
    slideNumber: !isNil(state.common.slideNumber) ? state.common.slideNumber : state.article.slides ? state.article.slides.length : null,
    allowBlame: state.article.article ? state.article.article.allowBlame : false,
    allowSlideComments: state.article.article ? state.article.article.allowSlideComments : false,
    allowSlideQuestions: state.article.article ? state.article.article.allowSlideQuestions : false,
    pauseSlideComments: state.article.article ? state.article.article.pauseSlideComments : false,
    pauseSlideQuestions: state.article.article ? state.article.article.pauseSlideQuestions : false,
    renderColumns: state.article.renderColumns,
    showBlame: state.article.showBlame,
    displayCollapsedHeaders: state.common.displayCollapsedHeaders,
    hideEndSlides: state.common.hideEndSlides,
    meta: {
      ...state.common.meta,
      views: state.common.meta && state.common.meta.views
        ? state.common.meta.views.map(view => {
          const titleProcessorItem = titleSlideProcessorList.find(t => t.gideId === view.article.id);
            if (titleProcessorItem) {
              return { ...view, article: { ...view.article, image: URL.createObjectURL(titleProcessorItem.dataUrl) } };
            }
            return view;
          })
        : undefined,
    },
    redirectTo: state.common.redirectTo,
    showInlineToolbar: state.common.showInlineToolbar,
    inlineToolbarPosition: state.common.inlineToolbarPosition,
    viewMode: getCustomDomain() ? 'SCROLL' : state.common.viewMode,
    showChrome: state.common.showChrome,
    article: state.article.article,
    percentArticleCompleted: state.article.percentArticleCompleted,
    headerSlides: state.article.slides ? state.article.slides.filter(s => s.slideType === 'HEADER' && s.data.type !== 'END') : [],
    showLeftSidebar: state.common.showLeftSidebar,
    showRightSidebar: state.common.showRightSidebar,
    nextViewMode: state.common.nextViewMode,
    openMenu: state.common.openMenu,
    toasterMessageInfo: state.common.toasterMessageInfo,
    displayGidesMenu: state.common.displayGidesMenu,
    userShortcuts: state.common.userShortcuts,
    userShortcutFavorites: state.common.userShortcutFavorites,
    userChannelFavorites: state.common.userChannelFavorites,
    userFavoritesLoaded: state.common.userFavoritesLoaded,
    gideElementSearchResults: state.common.gideElementSearchResults,
    slideSelectionModeDetail: state.article.slideSelectionModeDetail,
    slideFilter: state.article.slideFilter,
    multiSlideSelectionDetail: state.article.multiSlideSelectionDetail, // TODO: Move this into the slideSelectionModeDetail and rename it
    view: view,
    loading: state.common.loading,
    slideFileProcessorList: state.fileProcessor.slideFileProcessorList,
    titleSlideProcessorList: state.fileProcessor.titleSlideProcessorList,
    gallerySlideFileProcessorList: state.fileProcessor.gallerySlideFileProcessorList,
    isInCreationProcessModal: state.common.isInCreationProcessModal,
    isInAdministration: state.router.location !== undefined && state.router.location.pathname !== undefined && contains('administration', state.router.location.pathname),
    profile: profile,
    profilePage: hasValue(profilePage) ? (profilePage as ProfileMode) : ProfileMode.Profile,
    profileView: profileView,
    profileChannelSlug: profileChannelSlug,
    loadingUserHistory: state.common.currentUser ? state.common.loadingUserHistory : false,
    routedModalInfo: state.common.routedModalInfo,
    lastCurrentlyOpenedModal: state.modalManager.length > 0 ? state.modalManager[0].modalType : undefined,
    disableFooterInput:
      state.modalManager.length > 0 &&
      any(mm => mm.modalType === 'QuickSlideTypeChooser' || mm.modalType === 'NewSlideTypeChooserModal', state.modalManager),
  };
};

type Action = GideAction | ArticleAction | FooterAction;

const mapDispatchToProps = (dispatch: Dispatch<Action>): ReducerDispatchProps => ({
  // TODO: Finish typing the internal types on the any[]. Currently just getting initial type supported
  onLoad: (payload: {meta: {
    channels: ChannelModel[];
    collections: Collection[];
    interactions: any[];
    notifications: any[];
    views: any[]
  }, user: User}, token?: string | null) => dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onCollapseAllSlides: (slides: Slide[]) => dispatch({ type: COLLAPSE_ALL_SLIDES, slides: slides }),
  onExpandAllSlides: () => dispatch({ type: EXPAND_ALL_SLIDES }),
  onToggleColumns: () => dispatch({ type: TOGGLE_COLUMNS }),
  onToggleBlame: () => dispatch({ type: TOGGLE_BLAME }),
  // TODO: Add typing once this is re-implemented from new view bar
  onCollapseAllInlineViewersByType: (payload: any) => dispatch({ type: COLLAPSE_ALL_CHILD_ARTICLES_BY_TYPE, payload }),
  showNotification: (toasterMessageInfo?: ToasterMessageInfo) => dispatch({ type: SET_TOASTER_MESSAGE, payload: {toasterMessageInfo} }),
  toggleCollapsedHeaderDisplay: () => dispatch({ type: TOGGLE_COLLAPSED_HEADER_DISPLAY }),
  toggleEndSlideDisplay: () => dispatch({ type: TOGGLE_END_SLIDE_DISPLAY }),
  enterSlideSelectionMode: (payload: SlideSelectionInfo) => dispatch({ type: ENTER_SLIDE_SELECTION_MODE, payload }),
  setFooterInput: (key: string, value: string) => dispatch({ type: UPDATE_FOOTER_INPUT, payload: {key, value}}),
  onInputBlurred: () => dispatch({ type: INPUT_BLURRED }),
  onSubmitMultipleSlides: (payload: MultiSlideInsertDetails) => dispatch({ type: ADD_MULTIPLE_SLIDES, payload }),
  onCopySlides: (payload: {insertedPosition: number, slides: Slide[]}) => dispatch({type: ARTICLE_COPY_SLIDES, payload}),
  onSubmitSlide: (payload: SlideUpdateInfo) => dispatch({ type: ADD_SLIDE, payload }),
  onUpdateArticleTitleAndImage: (payload: GideTitleImageInfo) => dispatch({ type: ARTICLE_TITLE_IMAGE_UPDATED, payload }),
  updateSlideNumber: (slideNumber: number) => dispatch({ type: UPDATE_SLIDE_NUMBER, payload: {slideNumber} }),
  onRedirect: () => dispatch({ type: REDIRECT }),
  openModal: (payload: { modalType: string, modalProps?: any}) => dispatch({ type: MODAL_OPEN, payload }),
  routeToModal: (payload: { modalType: string, modalProps?: any}) => dispatch({ type: ROUTE_TO_MODAL, payload }),
  closeModal: (closeDialogList?: string[]) => dispatch({ type: MODAL_CLOSE, payload: { closeDialogList } }),
  hideSlideInlineSelection: () =>
    dispatch({
      type: UPDATE_SLIDE_INLINE_SELECTION,
      payload: { show: false, position: {} },
    }),
  makeSlideInlineSelection: (payload: {mode: string}) => dispatch({ type: MAKE_INLINE_SELECTION_ON_SLIDE, payload }),
  onSubmitArticle: (payload: {article: Article}) => dispatch({ type: ARTICLE_SUBMITTED, payload }),
  onClickLogout: () => dispatch({ type: LOGOUT }),
  scrollToSlidePosition: (position: number) => dispatch({ type: SCROLL_TO_SLIDE, payload: { position } }),
  onToggleLeftSidebar: () => dispatch({ type: TOGGLE_LEFT_SIDEBAR }),
  onToggleRightSidebar: (isGideMenu: boolean) => dispatch({ type: TOGGLE_RIGHT_SIDEBAR, payload: { isGideMenu } }),
  turnOverlayOff: () =>
    dispatch({
      type: SET_OPEN_FOOTER_MENU,
      payload: { openMenu: InputBarOpenMenu.NONE },
    }),
  clearToaster: () =>
    dispatch({
      type: SET_TOASTER_MESSAGE,
      payload: { toasterMessageInfo: undefined },
    }),
  toggleGidesMenu: () => dispatch({ type: TOGGLE_GIDES_MENU }),
  closeAvatarMenu:() => dispatch({type: CLOSE_AVATAR_MENU}),
  loadUserShortcuts: (payload: {userShortcuts: any[]}) => dispatch({ type: LOAD_USER_SHORTCUTS, payload }),
  loadUserFavorites: (payload: {userShortcuts: any[], userChannels: any[] }) => dispatch({ type: LOAD_USER_FAVORITES, payload }),
  scrollToSlidePositionById: (payload: {slideId: string, parentHeaderSlides: Slide[]}) => dispatch({ type: SCROLL_TO_SLIDE_ID, payload }),
  setOpenMenu: (payload: {openMenu: InputBarOpenMenu}) => dispatch({ type: SET_OPEN_FOOTER_MENU, payload }),
  exitSlideSelectionMode: () => dispatch({ type: EXIT_SLIDE_SELECTION_MODE }),
  filterSlides: (payload: SlideFilter) => dispatch({ type: ARTICLE_FILTER_SLIDES, payload }),
  removeSlidesFromArticle: (payload: {slideIds: string[]}) => dispatch({type: ARTICLE_DELETE_SLIDES, payload}),
  updateSlideAttachments: (payload: {slideId: string, childArticlesSlideTypes: string[], childArticlesSlideDetails: {author: string, count: number, status: string, type: string}[]}) => dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
  moveSlidesOutOfGide: (payload: {articleId: string, slideIds: string[]}) => dispatch({ type: ARTICLE_MOVE_SLIDES_OUT_OF_GIDE, payload }),
  updateChildGidesOnSlideForGideType: (payload: {slideId: string, articleType: string, slides: Slide[]}) => dispatch({type: UPDATE_SLIDE_CHILD_GIDE_FOR_GIDE_TYPE, payload}),

  // Sidebar History Events
  onDeleteView: (payload, id) => dispatch({ type: DELETE_VIEW, id }),
  onArticlePageSelectedFromSidebar: () => dispatch({type: ARTICLE_PAGE_SELECTED_FROM_SIDEBAR}),
  addMediaFileProcessingInfo: (slidefileProcessingInfoList: SlideFileProcessorInfo[]) =>
    dispatch({type: ADD_SLIDE_FILE_FOR_PROCESSING, payload: {slidefileProcessingInfoList}}),
  addTitleSlideProcessingInfo: (titleSlideProcessorInfo: TitleSlideProcessorInfo[]) =>
    dispatch({type: ADD_TITLE_SLIDE_FOR_PROCESSING, payload: {titleSlideProcessorInfo}}),
  addSlideGalleryFileProcessingInfo: (gallerySlideFileProcessingInfoList: GallerySlideFileProcessorInfo[]) =>
    dispatch({type: ADD_GALLERY_SLIDE_FILE_FOR_PROCESSING, payload: {gallerySlideFileProcessingInfoList}}),
  startSlideFileProcessing: (processingIds: string[]) => dispatch({type: START_SLIDE_FILE_PROCESSING, payload: {processingIds}}),
  startTitleSlideProcessing: (processingIds: string[]) => dispatch({type: START_TITLE_SLIDE_PROCESSING, payload: {processingIds}}),
  startGallerySlideFileProcessing: (processingIds: string[]) => dispatch({type: START_GALLERY_SLIDE_FILE_PROCESSING, payload: {processingIds}}),
  completeSlideFileProcessing: (slideFileCompletedInfo: {slideFileProcessorInfo: SlideFileProcessorInfo, fileUrl: string}) =>
    dispatch({type: COMPLETE_SLIDE_FILE_PROCESSING, payload: {slideFileProcessorInfo: slideFileCompletedInfo.slideFileProcessorInfo, fileUrl: slideFileCompletedInfo.fileUrl}}),
  completeTitleSlideProcessing: (titleSlideProcerrorCompletionInfo: {titleSlideProcessorInfo: TitleSlideProcessorInfo, fileUrl: string}) =>
    dispatch({type: COMPLETE_TITLE_SLIDE_PROCESSING, payload: {titleSlideProcessorInfo: titleSlideProcerrorCompletionInfo.titleSlideProcessorInfo, fileUrl: titleSlideProcerrorCompletionInfo.fileUrl}}),
  completeGallerySlideFileProcessing: (gallerySlideFileCompletedInfo: {gallerySlideFileProcessorInfo: GallerySlideFileProcessorInfo, fileUrl: string}) =>
    dispatch({type: COMPLETE_GALLERY_SLIDE_FILE_PROCESSING, payload: {gallerySlideFileProcessingInfo: gallerySlideFileCompletedInfo.gallerySlideFileProcessorInfo, fileUrl: gallerySlideFileCompletedInfo.fileUrl}}),
  settingsSaved: (profile: ProfileModel) => dispatch({type: SETTINGS_SAVED, payload: {user: profile}, error: ''}),
  channelCreated: (channel: ChannelModel) => dispatch({type: CHANNEL_SUBMITTED, payload: {channel} }),
  channelUpdated: (channel: ChannelModel) => dispatch({ type: CHANNEL_SUBMITTED, payload: {channel} }),
  onGideDeleted: (gideId: string) => dispatch({type: DELETE_ARTICLE_NO_REDIRECT, payload: {id: gideId}}),
  onLogin: (user: User & { token: string }) => dispatch({type: LOGIN, payload: {user}, error: ''}),
  onChangeAuthField: (key: string, value: string) => dispatch({type: UPDATE_FIELD_AUTH, payload: {key: key, value: value}}),
  onConfigLoaded: (config: AuthConfig) => dispatch({type: CONFIG_LOADED, payload: {config}})
});

function PrivateRoute({ component: Component, authed, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={props =>
        authed !== null ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      }
    />
  );
}

function SemiPrivateRoute({ component: Component, authed, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={props =>
        isNil(authed) && process.env.REACT_APP__PRIVATE === 'true' ? <Redirect to={{ pathname: '/login', state: { from: props.location } }} /> : <Component {...props} {...rest}  />
      }
    />
  );
}

function PublicRoute({ component: Component, ...rest }: any) {
  return <Route {...rest} render={props => <Component {...props} {...rest} />} />;
}

function AuthRoute({ component: Component, authed, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={props => (!authed ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)}
    />
  );
}

function PrivateRootRoute({ component: Component, authed, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={props =>
        authed !== null || getCustomDomain() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
}
export interface AppComponentState {
  showTableOfContentsView: boolean;
  sidebarTimeout: number;
  fileProcessingTimeout: number;
  multiEditRanges: string;
  sidebarTab: SidebarTab;
  openAvatarExpanded: boolean;
  avatarMenuClearActiveMenu: {};
  profileEditorOpen: boolean;
  signinOpen: boolean;
}



class App extends Component<AppProps, AppComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      showTableOfContentsView: false,
      sidebarTimeout: NaN,
      fileProcessingTimeout: NaN,
      multiEditRanges: '',
      sidebarTab: SidebarTab.History,
      openAvatarExpanded: false,
      avatarMenuClearActiveMenu: {},
      profileEditorOpen: false,
      signinOpen: false,
    };
  }

  toggleTableOfContents = () => {
    this.setState({
      showTableOfContentsView: !this.state.showTableOfContentsView,
    });
  };

  // Prevent the toaster from showing the same message at the same time
  beforeOpenToaster = (e: any) => {
    // e.cancel = this.preventDuplicate(e);
  };
  toasterClosed = () => {
    this.props.clearToaster();
  };

  onCreateGide = async (): Promise<Article> => {
    const article = {
      title: 'Untitled',
    };
    const response = await agent.Articles.create(article);
    this.props.onSubmitArticle(response);
    return response.article;
  };
  closeSideBarAndNavigateHome = () => {
    this.props.onToggleLeftSidebar();
    history.push('/');
  };
  loadUserFavoritesIfNeeded = async () => {
    if (!this.props.userFavoritesLoaded) {
      const payload = await agent.Users.getFavorites();
      this.props.loadUserFavorites(payload);
    }
  };
  toggleLeftSidebar = async (sidebarTab: SidebarTab) => {
    this.toggleSidebar(AvatarMenuLocation.Left, false, sidebarTab);
  };
  toggleGidesMenu = async (location: AvatarMenuLocation, openExpanded: boolean) => {
    this.toggleSidebar(location, openExpanded, SidebarTab.History);
  };
  toggleSidebar = async (location: AvatarMenuLocation, openExpanded: boolean, sidebarTab: SidebarTab) => {
    if (!this.props.currentUser) {
      this.props.onToggleLeftSidebar();
      this.setState({
        sidebarTab: sidebarTab,
        openAvatarExpanded: true,
      });
    } else {
      await this.loadUserFavoritesIfNeeded();
      if (location === AvatarMenuLocation.Right) {
        this.props.toggleGidesMenu();
        this.setState({
          openAvatarExpanded: openExpanded,
          avatarMenuClearActiveMenu: this.props.showRightSidebar ? {} : this.state.avatarMenuClearActiveMenu,
        });
      } else {
        // In this case the user opened the left sidebar by clicking on the avatar of the user while viewing their own gide
        this.props.onToggleLeftSidebar();
        this.setState({
          sidebarTab: this.props.showLeftSidebar ? sidebarTab : this.state.sidebarTab,
          openAvatarExpanded: this.props.showLeftSidebar ? openExpanded : this.state.openAvatarExpanded,
          avatarMenuClearActiveMenu: this.props.showLeftSidebar ? {} : this.state.avatarMenuClearActiveMenu,
        });
      }
    }
  };

  onNewClick = () => {
    history.push('/editor');
  };

  // Commenting out this code for now; might need in the future in case the web socket connection gets disrupted ?
  refreshSidebar = () => {
    // if (this.state.sidebarTimeout) {
    //   window.clearTimeout(this.state.sidebarTimeout);
    // }
    // const token = window.localStorage.getItem('jwt');
    // this.props.onLoad(token ? agent.Auth.current() : null, token);
    // const sidebarTimeout = window.setTimeout(this.refreshSidebar, 10000);
    // this.setState({ sidebarTimeout });
  };

  processFileUploads = async () => {
    // this.processingFiles = true;
    if (this.state.fileProcessingTimeout) {
      window.clearTimeout(this.state.fileProcessingTimeout);
    }
    if (this.filesPendingProcessing.length > 0) {
      this.filesInProcess = [...this.filesPendingProcessing];
      this.filesPendingProcessing = [];
    }
    if (this.titleSlidesPendingProcessing.length > 0) {
      this.titleSlidesInProcess = [...this.titleSlidesPendingProcessing];
      this.titleSlidesPendingProcessing = [];
    }
    if (this.gallerySlideFilesPendingProcessing.length > 0) {
      this.gallerySlideFilessInProcess = [...this.gallerySlideFilesPendingProcessing];
      this.gallerySlideFilesPendingProcessing = [];
    }
    // Handle slide file processing
    if (this.filesInProcess.length > 0) {
      for (let i = 0; i < this.filesInProcess.length; i++) {
        const response = await superagent
          .post(
            `${API_ROOT}/util/uploadSlideMediaFile/${this.filesInProcess[i].slideId}/${this.filesInProcess[i].slideFileId}/${this.filesInProcess[i].processingId}`,
          )
          .attach('theseNamesMustMatch', this.filesInProcess[i].dataUrl);
        this.props.completeSlideFileProcessing({ slideFileProcessorInfo: this.filesInProcess[i], fileUrl: response.body.url });

        const key = `SMF_${this.filesInProcess[i].processingId}`;
        localforage.removeItem(key, (err: any) => {
          if (err) console.log(`Error removing item with key ${key} : ${err}`);
        });
      }
      this.filesInProcess = [];
    }
    if (this.gallerySlideFilessInProcess.length > 0) {
      for (let i = 0; i < this.gallerySlideFilessInProcess.length; i++) {
        const galleryFileInProcess = this.gallerySlideFilessInProcess[i];
        const response = await superagent
          .post(
            `${API_ROOT}/util/uploadSlideGalleryFile/${galleryFileInProcess.slideId}/${galleryFileInProcess.slideFileId}/${galleryFileInProcess.galleryFileId}/${galleryFileInProcess.processingId}`,
          )
          .attach('theseNamesMustMatch', this.gallerySlideFilessInProcess[i].dataUrl);
        this.props.completeGallerySlideFileProcessing({ gallerySlideFileProcessorInfo: galleryFileInProcess, fileUrl: response.body.url });

        const key = `IGMF_${galleryFileInProcess.processingId}`;
        localforage.removeItem(key, (err: any) => {
          if (err) console.log(`Error removing item with key ${key} : ${err}`);
        });
      }
      this.gallerySlideFilessInProcess = [];
    }
    // Handle title slide processing
    if (this.titleSlidesInProcess.length > 0) {
      for (let i = 0; i < this.titleSlidesInProcess.length; i++) {
        const response = await superagent
          .post(
            `${API_ROOT}/util/updloadTitleSlideImage/${this.titleSlidesInProcess[i].gideId}/${this.titleSlidesInProcess[i].processingId}`,
          )
          .attach('theseNamesMustMatch', this.titleSlidesInProcess[i].dataUrl);
        this.props.completeTitleSlideProcessing({ titleSlideProcessorInfo: this.titleSlidesInProcess[i], fileUrl: response.body.url });

        const key = `TS_${this.titleSlidesInProcess[i].processingId}`;
        localforage.removeItem(key, (err: any) => {
          if (err) console.log(`Error removing item with key ${key} : ${err}`);
        });
      }
      this.titleSlidesInProcess = [];
    }
    const fileProcessingTimeout = window.setTimeout(this.processFileUploads, 10000);
    this.setState({ fileProcessingTimeout });
    // this.processingFiles = false;
  };

  // openModal = (modalProps: { modalType: string, modalProps?: any}) => {

  //   if(isRoutedModal(modalProps.modalType)) {
  //     this.props.routeToModal(modalProps)
  //   } else {
  //     this.props.openModal(modalProps);
  //   }
  // }
  closeModal = (closeDialogList?: string[]) => {
    if(this.props.lastCurrentlyOpenedModal && isRoutedModal(this.props.lastCurrentlyOpenedModal)) {
      history.goBack();
    } else {
      this.props.closeModal(closeDialogList);
    }
  }
  componentWillReceiveProps(nextProps: any) {
    if (nextProps.redirectTo) {
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }
  componentDidUpdate(prevProps: AppProps) {
    if (prevProps.toasterMessageInfo !== this.props.toasterMessageInfo) {
      if (this.props.toasterMessageInfo) {
        this.props.notificationDispatch({ type: 'set', notification: this.props.toasterMessageInfo });
      } else {
        this.props.notificationDispatch({ type: 'clear' });
      }
    }

    if (this.props.appLoaded && prevProps.loading && !this.props.loading) {
      console.info('Hiding splashscreen!');
      (navigator as any)?.splashscreen?.hide();
    }

    if (this.props.routedModalInfo && this.props.routedModalInfo != prevProps.routedModalInfo) {
      history.push({
        ...history.location,
        search: 'rm',
      });
      this.props.openModal(this.props.routedModalInfo);
    }
    //if (this.props.toasterMessageInfo && prevProps.toasterMessageInfo !== this.props.toasterMessageInfo) {
    // if (!isNil(this.props.toasterMessageInfo)
    //   && !this.props.toasterMessageInfo.target
    //   ) {
    //   // const template = getToasterTemplate(this.props.toasterMessageInfo.)
    //   this.toastObj && this.toastObj.show({
    //     title: getNotificationTitle(this.props.toasterMessageInfo.type),
    //     content: this.props.toasterMessageInfo.message,
    //   });
    // }
    //}
    if (prevProps.slideFileProcessorList !== this.props.slideFileProcessorList) {
      const newFiles = [...this.props.slideFileProcessorList.filter(sf => sf.isProcessing !== true)];

      if (newFiles.length > 0) {
        // Update the reducer and set these files to isProcessing : true
        this.props.startSlideFileProcessing(newFiles.map(fpp => fpp.processingId));
        this.filesPendingProcessing.push(...newFiles);
      }
    }
    if (prevProps.titleSlideProcessorList !== this.props.titleSlideProcessorList) {
      const titleSlideInfoList = [...this.props.titleSlideProcessorList.filter(sf => sf.isProcessing !== true)];
      if (titleSlideInfoList.length > 0) {
        // Update the reducer and set these files to isProcessing : true
        this.props.startTitleSlideProcessing(titleSlideInfoList.map(ts => ts.processingId));
        this.titleSlidesPendingProcessing.push(...titleSlideInfoList);
      }
    }

    if (prevProps.gallerySlideFileProcessorList !== this.props.gallerySlideFileProcessorList) {
      const gallerySlideFileInfoList = [...this.props.gallerySlideFileProcessorList.filter(sf => sf.isProcessing !== true)];
      if (gallerySlideFileInfoList.length > 0) {
        // Update the reducer and set these files to isProcessing : true
        this.props.startGallerySlideFileProcessing(gallerySlideFileInfoList.map(gsf => gsf.processingId));
        this.gallerySlideFilesPendingProcessing.push(...gallerySlideFileInfoList);
      }
    }

    if (this.props.profile && !this.state.profileEditorOpen) {
      if (this.props.profilePage !== ProfileMode.Profile) {
        history.replace({
          ...history.location,
          search: history.location.search.replace(this.props.profilePage, ProfileMode.Profile),
        });
      }
      this.showProfile(
        this.props.profile,
        this.props.profilePage && this.state.profileEditorOpen ? (this.props.profilePage as ProfileMode) : ProfileMode.Profile,
        this.props.profileView ? this.props.profileView : 'activity',
        this.props.profileChannelSlug ? this.props.profileChannelSlug : undefined,
      );
    } else if (!hasValue(this.props.profile) && this.state.profileEditorOpen) {
      this.closeProfile();
    }
  }

  // processingFiles: boolean = false;
  filesPendingProcessing: SlideFileProcessorInfo[] = [];
  filesInProcess: SlideFileProcessorInfo[] = [];
  titleSlidesPendingProcessing: TitleSlideProcessorInfo[] = [];
  titleSlidesInProcess: TitleSlideProcessorInfo[] = [];
  gallerySlideFilesPendingProcessing: GallerySlideFileProcessorInfo[] = [];
  gallerySlideFilessInProcess: GallerySlideFileProcessorInfo[] = [];

  getConfig = async () => {
    const response = await agent.Auth.config();
    if(response) {
      this.props.onConfigLoaded(response)
    }
  }
  componentWillMount() {
    const token = window.localStorage.getItem('jwt');
    if (token) {
      agent.setToken(token);
    }

    localforage.config({
      driver: localforage.INDEXEDDB,
      name: 'gides',
      version: 1.0,
      // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName: 'fileProcessing', // Should be alphanumeric, with underscores.
      description: 'Store Files that need to be processed in background',
    });

    const slideMediaFileProcessList: SlideFileProcessorInfo[] = [];
    const titleSlideProcessList: TitleSlideProcessorInfo[] = [];
    const gallerySlideFileProcessList: GallerySlideFileProcessorInfo[] = [];
    localforage.keys(async (err: any, keys: string[]) => {
      for (let i = 0; i < keys.length; i++) {
        if (keys[i].indexOf('TS_') > -1) {
          const item = await localforage.getItem<TitleSlideProcessorInfo>(keys[i]);
          titleSlideProcessList.push(item);
        }
        if (keys[i].indexOf('SMF_') > -1) {
          const item = await localforage.getItem<SlideFileProcessorInfo>(keys[i]);
          slideMediaFileProcessList.push(item);
        }
        if (keys[i].indexOf('IGMF_') > -1) {
          const item = await localforage.getItem<GallerySlideFileProcessorInfo>(keys[i]);
          gallerySlideFileProcessList.push(item);
        }
      }
      if (slideMediaFileProcessList.length > 0) {
        this.props.addMediaFileProcessingInfo(slideMediaFileProcessList);
      }
      if (titleSlideProcessList.length > 0) {
        this.props.addTitleSlideProcessingInfo(titleSlideProcessList);
      }
      if (gallerySlideFileProcessList.length > 0) {
        this.props.addSlideGalleryFileProcessingInfo(gallerySlideFileProcessList);
      }
    });
    // for(let i = 0; i < localforage.keys.length; i++) {
    //   if(localforage.keys[i] === "") {

    //   }
    //   slideMediaFileProcessList.push()
    // }

    // Immediately load the user's gide view history and get other info about the current user.
    this.props.onLoad(token ? agent.Auth.current() : null, token);

    // window.setTimeout(this.refreshSidebar, 10000);
    window.setTimeout(this.processFileUploads, 10000);

    this.getConfig();
  }

  // Leave this for now in case we later want to handle the notch
  // safeAreaInsetNorth = '';
  // safeAreaInsetEast = '';
  // safeAreaInsetSouth = '';
  // safeAreaInsetWest = '';

  // setSafeAreaVariables = () => {

  //   // Notch/North at the top.
  //   switch(window.orientation) {
  //     case 0:
  //     case 180:
  //     case -180:
  //       this.setState({
  //         portraitSafeAreas: {
  //           paddingTop: getComputedStyle(document.documentElement).getPropertyValue("--safe-top"),
  //           paddingRight: getComputedStyle(document.documentElement).getPropertyValue("--safe-right"),
  //           paddingBottom: getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom"),
  //           paddingLeft: getComputedStyle(document.documentElement).getPropertyValue("--safe-left"),
  //         },
  //         landscapeSafeAreas: {
  //           paddingTop: '0px',
  //           paddingRight: getComputedStyle(document.documentElement).getPropertyValue("--safe-top"),
  //           paddingBottom: getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom"),
  //           paddingLeft: getComputedStyle(document.documentElement).getPropertyValue("--safe-top")
  //         }
  //       });
  //         // this.safeAreaInsetNorth = getComputedStyle(document.documentElement).getPropertyValue("--safe-top");
  //         // this.safeAreaInsetEast  = getComputedStyle(document.documentElement).getPropertyValue("--safe-right");
  //         // this.safeAreaInsetSouth = getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom");
  //         // this.safeAreaInsetWest  = getComputedStyle(document.documentElement).getPropertyValue("--safe-left");
  //         break;
  //     // Upside down... Notch/North at the bottom.
  //     // case 180:
  //     // case -180:
  //       // this.safeAreaInsetNorth = getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom");
  //       // this.safeAreaInsetEast  = getComputedStyle(document.documentElement).getPropertyValue("--safe-left");
  //       // this.safeAreaInsetSouth = getComputedStyle(document.documentElement).getPropertyValue("--safe-top");
  //       // this.safeAreaInsetWest  = getComputedStyle(document.documentElement).getPropertyValue("--safe-right")
  //       // break;
  //     // Notch/North to the left.
  //     case 90:
  //       // this.safeAreaInsetNorth = getComputedStyle(document.documentElement).getPropertyValue("--safe-left");
  //       // this.safeAreaInsetEast  = getComputedStyle(document.documentElement).getPropertyValue("--safe-top");
  //       // this.safeAreaInsetSouth = getComputedStyle(document.documentElement).getPropertyValue("--safe-right");
  //       // this.safeAreaInsetWest  = getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom");
  //       this.setState({
  //         landscapeSafeAreas: {
  //           paddingTop: getComputedStyle(document.documentElement).getPropertyValue("--safe-top"),
  //           paddingRight: getComputedStyle(document.documentElement).getPropertyValue("--safe-right"),
  //           paddingBottom: getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom"),
  //           paddingLeft: getComputedStyle(document.documentElement).getPropertyValue("--safe-left"),
  //         },
  //         portraitSafeAreas: {
  //           paddingTop: getComputedStyle(document.documentElement).getPropertyValue("--safe-left"),
  //           paddingRight: getComputedStyle(document.documentElement).getPropertyValue("--safe-top"),
  //           paddingBottom: getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom"),
  //           paddingLeft: getComputedStyle(document.documentElement).getPropertyValue("--safe-top")
  //         },
  //         orientation: 'portrait'
  //       });

  //       break;
  //     // Notch/North to the right.
  //     case -90:
  //         // this.safeAreaInsetNorth = getComputedStyle(document.documentElement).getPropertyValue("--safe-right");
  //         // this.safeAreaInsetEast  = getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom");
  //         // this.safeAreaInsetSouth = getComputedStyle(document.documentElement).getPropertyValue("--safe-left");
  //         // this.safeAreaInsetWest  = getComputedStyle(document.documentElement).getPropertyValue("--safe-top");
  //         this.setState({
  //           landscapeSafeAreas: {
  //             paddingTop: getComputedStyle(document.documentElement).getPropertyValue("--safe-top"),
  //             paddingRight: getComputedStyle(document.documentElement).getPropertyValue("--safe-right"),
  //             paddingBottom: getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom"),
  //             paddingLeft: getComputedStyle(document.documentElement).getPropertyValue("--safe-left"),
  //           },
  //           portraitSafeAreas: {
  //             paddingTop: getComputedStyle(document.documentElement).getPropertyValue("--safe-right"),
  //             paddingRight: getComputedStyle(document.documentElement).getPropertyValue("--safe-top"),
  //             paddingBottom: getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom"),
  //             paddingLeft: getComputedStyle(document.documentElement).getPropertyValue("--safe-top")
  //           },
  //           orientation: 'landscape'
  //         });

  //         break;
  //     default:
  //         console.log("Invalid orientation");
  //     }

  // }

  // handleOrientation = () => {
  //   // console.log('Orientation Change');
  //   // console.log('orientation', window.orientation);
  //   let root = document.getElementById('root');
  //   setTimeout(() => {
  //     console.log('--safe-top', getComputedStyle(document.documentElement).getPropertyValue("--safe-top"));
  //     console.log('--safe-right', getComputedStyle(document.documentElement).getPropertyValue("--safe-right"));
  //     console.log('--safe-bottom', getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom"));
  //     console.log('--safe-left', getComputedStyle(document.documentElement).getPropertyValue("--safe-left"));
  //   }, 5000);


  //   let marginTopDefault = 0;
  //   let newMarginTop = '';
  //   if(this.state.rotationCount > 1 && this.state.rotationCount < 2) {
  //     switch(window.orientation) {
  //       case 0: // Notch/North at the top.
  //           newMarginTop = this.safeAreaInsetNorth;
  //           break;
  //       case 180: // Upside down... Notch/North at the bottom.
  //       case -180:
  //           newMarginTop = this.safeAreaInsetSouth;
  //           break;
  //       case 90: // Notch/North to the left.
  //           newMarginTop = this.safeAreaInsetEast;
  //           break;
  //       case -90: // Notch/North to the right.
  //           newMarginTop = this.safeAreaInsetWest;
  //           break;
  //       default:
  //           console.log('Invalid Orientation');
  //     }
  //   } else if (this.state.rotationCount < 1) {
  //     switch(window.orientation) {
  //       case 0: // Notch/North at the top.
  //       case 180: // Upside down... Notch/North at the bottom.
  //       case -180:
  //           this.setState({
  //             orientation: 'portrait',
  //             rotationCount: this.state.rotationCount + 1
  //           });
  //           break;
  //       case 90: // Notch/North to the left.
  //       case -90: // Notch/North to the right.
  //         this.setState({
  //           orientation: 'landscape',
  //           rotationCount: this.state.rotationCount + 1
  //         });
  //         break;
  //       default:
  //           console.log('Invalid Orientation');
  //     }
  //   }
  //   // $(":root").css("--app-margin-top", Math.max(marginTopDefault, newMarginTop) + "px");
  // };

  componentDidMount() {
    window.document.addEventListener('scroll', () => {
      if (this.props.showInlineToolbar) {
        this.props.hideSlideInlineSelection();
      }
    });
    // setTimeout(() => {
    //   console.log('--safe-top', getComputedStyle(document.documentElement).getPropertyValue("--safe-top"));
    //   console.log('--safe-right', getComputedStyle(document.documentElement).getPropertyValue("--safe-right"));
    //   console.log('--safe-bottom', getComputedStyle(document.documentElement).getPropertyValue("--safe-bottom"));
    //   console.log('--safe-left', getComputedStyle(document.documentElement).getPropertyValue("--safe-left"));
    // }, 10000);

    // if((window as any).cordova) {
    //   this.setSafeAreaVariables();
    // }
    // window.addEventListener("orientationchange", this.handleOrientation, false);


    console.log(`Version Number: ${VERSION_NUMBER}`);
  }

  handleInlineToolbarGlobalSelect = (mode: any) => {
    this.props.makeSlideInlineSelection({
      mode,
    });
    let modalType = '';
    let modalProps = {};
    modalType = 'ChoicesModal';
    modalProps = {
      createType: mode,
    };
    this.props.openModal({
      modalType,
      modalProps,
    });
  };

  navigateToAbout = () => {
    history.push(
      `${urlForArticle({
        slug: 'about-gides',
        author: { username: 'gides' },
      })}`,
    );
  }
  navigateToHowToUseGides = () => {
    history.push(
      `${urlForArticle({
        slug: 'how-to-use-gides',
        author: { username: 'gides' },
      })}`,
    );
  }
  // Get the available header choices for the current slide position
  getAvailableHeaderLevelsForCurrentSlidePosition = () => {
    const nextSlideNumber = !isNil(this.props.slideNumber) ? this.props.slideNumber : this.props.slides ? this.props.slides.length : 0;
    return getAvailableHeaderLevelsForSlidePosition(nextSlideNumber, this.props.slides);
  };

  setOpenMenu = (menuState: InputBarOpenMenu) => {
    this.props.setOpenMenu({ openMenu: menuState });
  };

  transferSlidesToGide = async (gideInfo: SlideTransferSelectionInfo, slideIds: string[]) => {
    if (this.props.article && this.props.slideSelectionModeDetail !== null) {
      let selectedGideId = gideInfo.createNewGide ? undefined : (gideInfo.selectedGide as GideSearchElement).id;
      const slidePosition = gideInfo.createNewGide ? 0 : (gideInfo.selectedGide as GideSearchElement).slidesLength;
      let newGide = undefined;
      if (gideInfo.createNewGide) {
        this.props.showNotification({
          message: `Creating new gide...`,
          type: NotificationType.INFO,
        });
        const gide = {
          title: 'Untitled',
        };
        newGide = await agent.Articles.create(gide);
        selectedGideId = newGide.article.id;
      }

      this.props.showNotification({
        message: `${
          this.props.slideSelectionModeDetail.transferType === SlideSelectionTransferType.Move ? 'Moving' : 'Copying'
        } slides to the selected gide.`,
        type: NotificationType.INFO,
      });
      if (this.props.slideSelectionModeDetail.transferType === SlideSelectionTransferType.Move) {
        await agent.Articles.moveSlidesToArticle(this.props.article.id, selectedGideId, slideIds);
        this.props.removeSlidesFromArticle({ slideIds: slideIds });
      } else {
        await agent.Articles.copySlides(selectedGideId, slidePosition, slideIds);
      }
      this.props.showNotification({
        message: `Successfully ${
          this.props.slideSelectionModeDetail.transferType === SlideSelectionTransferType.Move ? 'Moved' : 'Copied'
        } slides to the selected gide.`,
        type: NotificationType.INFO,
      });
      this.props.exitSlideSelectionMode();

      if (gideInfo.createNewGide) {
        this.props.onSubmitArticle(newGide);
      }
    } else {
      this.props.showNotification({
        message: `Slide Selection Mode is not defined.`,
        type: NotificationType.ERROR,
      });
    }
  };
  copySlidesToPositionInGide = async (slidePosition: number) => {
    if (this.props.article) {
      this.props.showNotification({
        message: `Copying slides to the selected location within this gide.`,
        type: NotificationType.INFO,
      });
      const slideIds =
        this.props.multiSlideSelectionDetail &&
        this.props.slideSelectionModeDetail &&
        !isNil(this.props.slideSelectionModeDetail.originSlideId)
          ? [this.props.slideSelectionModeDetail.originSlideId]
          : this.props.multiSlideSelectionDetail.selectedSlideIds;

      const position = slidePosition > 0 ? slidePosition - 1 : 0;
      const response = await agent.Articles.copySlides(this.props.article.id, position, slideIds);
      this.props.onCopySlides({ slides: response.slides, insertedPosition: position });
      this.props.showNotification({
        message: `Successfully copied slides to the selected location.`,
        type: NotificationType.INFO,
      });
      this.props.exitSlideSelectionMode();
    }
  };

  scrollToSlidePositionById = (slideId: string) => {
    const slide = this.props.slides.find((s: Slide) => s.id === slideId);
    if (slide) {
      this.props.scrollToSlidePositionById({
        slideId,
        parentHeaderSlides: getParentHeaderSlides(slide, this.props.slides),
      });
    }
  };

  scrollToSlidePosition = (slidePosition: number) => {
    this.props.slides[slidePosition]
      ? this.scrollToSlidePositionById(this.props.slides[slidePosition].id)
      : this.props.scrollToSlidePosition(slidePosition);
  };

  moveSlidesAsAttachmentToSlide = async (slide: Slide) => {
    if (!this.props.article) return;
    const slideIds =
      this.props.multiSlideSelectionDetail &&
      this.props.slideSelectionModeDetail &&
      !isNil(this.props.slideSelectionModeDetail.originSlideId)
        ? [this.props.slideSelectionModeDetail.originSlideId]
        : this.props.multiSlideSelectionDetail.selectedSlideIds;

    const response = await agent.Slides.moveSlidesToChildArticle(
      this.props.article.id,
      slide.id,
      getChildArticleTypeName(ChildArticleType.Attachments),
      slideIds,
    );

    this.props.moveSlidesOutOfGide({
      articleId: this.props.article.id,
      slideIds: slideIds,
    });

    const slideChildArticleResponse = await getChildArticleForSlide(slide, ChildArticleType.Attachments);

    this.props.updateChildGidesOnSlideForGideType({
      slideId: slide.id,
      articleType: getChildArticleTypeName(ChildArticleType.Attachments),
      slides: slideChildArticleResponse && slideChildArticleResponse.slides ? slideChildArticleResponse.slides : [],
    });
    // get the slide's attachment details
    this.props.updateSlideAttachments({
      slideId: slide.id,
      childArticlesSlideTypes: response.slide.childArticlesSlideTypes,
      childArticlesSlideDetails: response.slide.childArticlesSlideDetails,
    });
    this.props.exitSlideSelectionMode();
    this.props.showNotification({
      message: `Successfully moved selected slide(s) as attachment(s).`,
      type: NotificationType.SUCCESS,
    });
    this.scrollToSlidePositionById(slide.id);
  };

  copySlidesAsAttachmentToSlide = async (slide: Slide) => {
    const slideIds =
      this.props.multiSlideSelectionDetail &&
      this.props.slideSelectionModeDetail &&
      !isNil(this.props.slideSelectionModeDetail.originSlideId)
        ? [this.props.slideSelectionModeDetail.originSlideId]
        : this.props.multiSlideSelectionDetail.selectedSlideIds;

    const response = await agent.Articles.copySlidesToSlideAttachments(slide.id, slideIds);
    this.props.updateChildGidesOnSlideForGideType({
      slideId: slide.id,
      articleType: 'SETTINGS',
      slides: response.slides,
    });

    const slideChildArticleResponse = await getChildArticleForSlide(
      slide,
      // getChildArticleTypeName(ChildArticleType.Attachments),
      ChildArticleType.Attachments,
    );

    this.props.updateChildGidesOnSlideForGideType({
      slideId: slide.id,
      articleType: getChildArticleTypeName(ChildArticleType.Attachments),
      slides: slideChildArticleResponse && slideChildArticleResponse.slides ? slideChildArticleResponse.slides : [],
    });

    // const slideAttachmentResponse = await agent.Slides.getAttachmentDetails(slide.id);
    this.props.updateSlideAttachments({
      slideId: slide.id,
      childArticlesSlideTypes: response.slide.childArticlesSlideTypes,
      childArticlesSlideDetails: response.slide.childArticlesSlideDetails,
    });
    this.props.exitSlideSelectionMode();
    this.props.showNotification({
      message: `Successfully copied selected slide(s) as attachment(s).`,
      type: NotificationType.SUCCESS,
    });
  };

  showSignUp = () => {
    if(isReadOnlyDevice() === true || process.env.REACT_APP__USE_MAILCHIMP_SIGNUP === 'true') {
      this.props.openModal({
        modalType: 'MailChimpSignupModal',
        modalProps: {
          closeModal: () => this.props.closeModal(),
          showNotification: this.props.showNotification,
        },
      });
    } else {
      history.push('/register')
    }
  };

  closeProfile = () => {
    history.replace({
      ...history.location,
      search: '',
    });
    this.setState({
      profileEditorOpen: false,
    });
    this.props.closeModal();
  };
  showProfile = async (username: string, page: ProfileMode, view: 'channel' | 'activity', channelSlug?: string) => {
    this.setState({
      profileEditorOpen: true,
    });
    const response: { profile: ProfileModel } = await agent.Profile.get(username);
    this.props.openModal({
      modalType: 'AccountProfileModal',
      modalProps: {
        currentUser: this.props.currentUser,
        profile: response.profile,
        closeModal: this.closeProfile,
        getChannel: agent.Channels.get,
        getChannelContent: agent.Channels.getContent,
        showNotification: this.props.showNotification,
        contactUser: agent.Users.contact,
        saveUserProfile: agent.Auth.save,
        settingsSaved: this.props.settingsSaved,
        createChannel: agent.Channels.create,
        onChannelCreated: this.props.channelCreated,
        updateChannel: agent.Channels.update,
        onChannelUpdated: this.props.channelUpdated,
        onGideDeleted: this.props.onGideDeleted,
        page: page,
        view: view,
        channelSlug: channelSlug,
      } as AccountProfileModalProps,
    });
  };
  closeSignin = () => {
    history.replace({
      ...history.location,
      search: '',
    });
    this.setState({
      signinOpen: false,
    });
    this.props.closeModal();
  };
  showSignin = () => {
    this.props.openModal({
      modalType: 'SigninModal',
      modalProps: {
        mode: 'signin',
        closeModal: () => {
          this.props.closeModal();
        },
        showNotification: this.props.showNotification,
        onSignin: this.onSignin,
        onSignUp: this.showSignUp,
        onChangeAuthField: this.props.onChangeAuthField,
      } as SigninModalProps,
    });
  };

  onSignin = async (username: string, password: string) => {
    try {
      const results = await agent.Auth.login(username, password);
      if (results && results.user && results.user.token) {
        this.props.onLogin(results?.user);
        return true;
      }
    } catch (error) {
      console.log(error);
    }
    return false;
  };

  render() {
    const { currentUser, viewMode, showLeftSidebar, showRightSidebar, view, isInCreationProcessModal, isInAdministration } = this.props;
    const webView = view === 'website' && this.props.viewMode === 'SCROLL';
    const customDomain = getCustomDomain();
    const pageClasses = classNames(
      'main',
      'container',
      'flexColumnFull',
      'page',
      this.props.viewMode,
      this.props.article ? this.props.article.type : null,
      customDomain ? 'customDomain' : null,
    );
    const innerPageClasses = classNames('innerPage', 'flexColumnFull', {
      showLeftSidebar: showLeftSidebar,
    });
    const mainSectionClasses = classNames('flexColumnFull', {
      hideWhenSidebarVisible: showLeftSidebar || showRightSidebar,
    });
    const currentSlidePosition = !isNil(this.props.slideNumber) ? this.props.slideNumber : 0;
    const displayFooter = !webView && viewMode !== 'SWIPE' && !customDomain;
    // TODO: Needs to be fixed based on changes Dale made to how the slides return the slide detail information.
    const allowFilterOnAttachments = displayFooter && this.props.slides.find((s: Slide) => !isNil(s.childArticlesSlideDetails)); // && s.childArticlesSlideDetails['SETTING']);
    const allowFilterOnComments = displayFooter && this.props.slides.find((s: Slide) => !isNil(s.childArticlesSlideDetails)); // && s.childArticlesSlideDetails.COMMENTS);
    const allowFilterOnQuestions = displayFooter && this.props.slides.find((s: Slide) => !isNil(s.childArticlesSlideDetails)); // && s.childArticlesSlideDetails.QUESTIONS);
    const allowFilterOnPrivateNotes = displayFooter && this.props.slides.find((s: Slide) => !isNil(s.childArticlesSlideDetails)); // && s.childArticlesSlideDetails.PRIVATENOTES);

    if (this.props.appLoaded) {
      const avatarMenu = (displayCloseButton: boolean, currentUser?: User | null) => (
        <AvatarMenu
          currentUser={currentUser}
          channelFavorites={this.props.userChannelFavorites}
          shortcuts={this.props.userShortcutFavorites}
          closeGidesMenu={() => {
            if (!currentUser) {
              this.props.closeAvatarMenu()
            } else if (displayCloseButton === true) {
              this.props.toggleGidesMenu();
            } else {
              this.props.onArticlePageSelectedFromSidebar()
            }
          }}
          onLogout={() => {
            this.props.onClickLogout();
            if (!displayCloseButton) {
              this.props.onToggleLeftSidebar();
            } else {
              this.props.onToggleRightSidebar(true);
            }
          }}
          onSignUp={() => {
            this.showSignUp();
          }}
          onSignIn={() => {
            this.showSignin();
          }}
          displayCloseButton={displayCloseButton}
          clearActiveMenu={this.state.avatarMenuClearActiveMenu}
          isExpandedOpen={this.state.openAvatarExpanded || !this.props.currentUser}
          setIsExpandedOpen={isExpandedOpen => this.setState({ openAvatarExpanded: isExpandedOpen })}
          onOpenUserShortcutModal={async () => {
            if (displayCloseButton) {
              // Means this is the right sidebar
              this.props.toggleGidesMenu();
            } else {
              this.props.onToggleLeftSidebar();
            }
            const payload = await agent.Users.getShortcuts();
            this.props.loadUserShortcuts(payload);
            this.props.openModal({
              modalType: 'ShortcutManagerModal',
              modalProps: {
                shortcuts: payload.userShortcuts,
              },
            });
          }}
          onCreateGide={() => {
            if (!displayCloseButton) {
              const responsive = window.innerWidth < 1074;
              if (responsive) {
                this.props.onToggleLeftSidebar();
              }
            } else {
              this.props.toggleGidesMenu();
            }
            this.onCreateGide();
          }}
          onNavigateToAbout={() => {
           this.navigateToAbout();
          }}
          onNavigateToContactUs={() => {
            history.push(
              `${urlForArticle({
                slug: 'contact-us',
                author: { username: 'gides' },
              })}`,
            );
          }}
          onNavigateToPrivacyPolicy={() => {
            history.push(
              `${urlForArticle({
                slug: 'privacy-policy',
                author: { username: 'gides' },
              })}`,
            );
          }}
          onNavigateToAllChannels={() => {
            if (this.props.currentUser) {
              this.showProfile(this.props.currentUser.username, ProfileMode.Profile, 'channel');
            }
          }}
          onAvatarMenuOpened={() => this.loadUserFavoritesIfNeeded()}
          onViewProfile={(username: string) => this.showProfile(username, ProfileMode.Profile, 'activity')}
        />
      );
      return (
        <div className="app-container"
        >
          <Helmet>
            <title>Gides: Share smarter</title>
            <meta name="description" content="Gides is a social publishing platform that combines the features of chat, websites, and social media." />
          </Helmet>
          <NotificationInline target="" timeoutMilliseconds={2000} />
          <ModalManager />
          <div className={pageClasses}>
            <div className="row flexColumnFull">
              <div className={innerPageClasses}>
                {!customDomain && (this.props.showChrome === true || this.props.viewMode !== 'SWIPE') && !this.props.loading && (
                  <>
                    {this.props.viewMode === 'SWIPE' && (
                      <SwipeHeader
                        showLeftSidebar={showLeftSidebar}
                        appName={this.props.appName}
                        currentUser={this.props.currentUser}
                        onNewClick={this.onNewClick}
                        nextViewMode={this.props.nextViewMode}
                        toggleGidesMenu={this.toggleGidesMenu.bind(this)}
                        showOutline={this.toggleTableOfContents.bind(this)}
                        percentArticleCompleted={this.props.percentArticleCompleted}
                        closeSideBarAndNavigateHome={this.closeSideBarAndNavigateHome.bind(this)}
                      />
                    )}
                    {this.props.viewMode !== 'SWIPE' && (
                      <Header
                        headerMode={this.props.article ? HeaderMode.InGideHeader : HeaderMode.GideAppHeader}
                        toggleAvatarMenu={this.toggleGidesMenu}
                        showNotification={this.props.showNotification}
                        onOpenNotifications={() => {
                          this.toggleLeftSidebar(SidebarTab.Notifications);
                        }}
                        openLeftSidebar={() => {
                          this.toggleLeftSidebar(SidebarTab.History);
                        }}
                        signUp={this.showSignUp}
                        signIn={this.showSignin}
                      />
                    )}
                  </>
                )}
                <Sidebar
                  className={`innerContentContainer ${showLeftSidebar ? ' leftSidebarDisplayed' : ''} ${
                    showRightSidebar ? ' rightSidebarDisplayed' : ''
                  }`}
                  onOverlayClicked={() => {
                    if (showLeftSidebar) {
                      this.toggleLeftSidebar(SidebarTab.History);
                    } else {
                      this.props.onToggleRightSidebar(this.props.displayGidesMenu);
                    }
                  }}
                  mainContentClassName={mainSectionClasses}
                  leftWidth="340px"
                  leftIsVisible={showLeftSidebar && viewMode !== 'SWIPE' && this.props.currentUser !== undefined}
                  leftContent={
                    <div style={{ backgroundColor: 'var(--COLOR-SECONDARY-200)', height: '100%' }}>
                      <SidebarLeft
                        hideHeaderOnLargerScreen
                        views={this.props.meta ? this.props.meta.views : undefined}
                        leftSidebarOpen={showLeftSidebar}
                        toggleSidebar={() => {
                          this.toggleLeftSidebar(SidebarTab.History);
                        }}
                        loading={this.props.loadingUserHistory}
                        readOnly={isNil(this.props.currentUser)}
                        //readOnly={true}
                        onSelectGideFromSidebar={viewData => {
                          this.props.onArticlePageSelectedFromSidebar();
                        }}
                        selectedTab={this.state.sidebarTab}
                        onSelectedTabChanged={(selectedTab: SidebarTab) => this.setState({ sidebarTab: selectedTab })}
                        avatarMenu={avatarMenu(false, this.props.currentUser)}
                        // avatarMenu={avatarMenu(false, null)}
                      />
                    </div>
                  }
                  overlay={this.props.displayGidesMenu}
                  rightWidth={this.props.displayGidesMenu ? '340px' : '600px'}
                  rightIsVisible={showRightSidebar && viewMode !== 'SWIPE' && this.props.currentUser !== undefined}
                  rightContent={
                    this.props.displayGidesMenu && this.props.currentUser ? (
                      <div style={{ height: '100%', backgroundColor: 'var(--COLOR-SECONDARY-200)' }}>
                        {avatarMenu(true, this.props.currentUser)}
                      </div>
                    ) : (
                      undefined
                    )
                  }
                >
                  <Suspense fallback={<div style={{display: 'flex', justifyContent: 'center'}}>
                    <Loading style={{marginTop: '200px'}} /> </div>}
                  >
                    <div className="flexColumnFull">
                      <Switch>
                        <PrivateRoute authed={currentUser} path={`${rootPath}/administration/users`} component={LazyUserManagement} exact />
                        <PublicRoute authed={currentUser} path={`${rootPath}/login`} component={LazyLogin} />
                        <AuthRoute authed={currentUser} path={`${rootPath}/register`} component={LazyRegister} />
                        <SemiPrivateRoute authed={currentUser} path={`${rootPath}/@:username/:tab?`} component={LazyProfile} />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/channel/:id`} component={LazyChannel} exact />
                        <SemiPrivateRoute
                          authed={currentUser}
                          path={`${rootPath}/gide/:id`}
                          component={LazyArticleComponent}
                          onShowSignup={this.showSignUp}
                          exact
                        />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/editor/:id`} component={LazyEditor} />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/edit/slide/:id`} component={LazyEditSlide} />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/editor`} component={LazyEditor} />
                        <SemiPrivateRoute
                          authed={currentUser}
                          path={`${rootPath}/:username/:slug`}
                          component={LazyArticleComponent}
                          onShowSignup={this.showSignUp}
                          onShowSignin={this.showSignin}
                          exact
                        />
                        <SemiPrivateRoute
                          authed={currentUser}
                          path={`${rootPath}/:username/:slug/slide/:number`}
                          component={SlideViewSlide}
                        />
                        <SemiPrivateRoute
                          authed={currentUser}
                          path={`${rootPath}/:username/:slug/slideshow/:slideId`}
                          component={SlideshowView}
                        />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/gide/new`} component={LazyArticleComponent} />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/:username/:slug/gideviewer`} component={GideViewer} />
                        <PrivateRoute
                          authed={currentUser}
                          path={`${rootPath}/:username/:slug/addition/@:additionUsername`}
                          component={LazyArticleComponent}
                        />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/channel/:slug/website`} component={LazyChannelWebsite} exact />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/channel/:slug/settings`} component={LazyChannelEditor} />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/settings`} component={LazySettings} />
                        <PrivateRoute authed={currentUser} path={`${rootPath}/account`} component={LazyAccount} />
                        {/* <PrivateRootRoute
                              authed={currentUser}
                              path={`${rootPath}/:path?`}
                              component={HomeConnect}
                              exact
                            /> */}
                        <SemiPrivateRoute
                          authed={currentUser}
                          onShowSignup={this.showSignUp}
                          onNavigateToAboutGides={this.navigateToAbout}
                          onNavigateToHowToUseGides={this.navigateToHowToUseGides}
                          component={LazyHomeConnect}
                        />
                        {/* <Route><HomeConnect {...this.props} /></Route> */}
                        <Route path="*" render={
                          (props) => <Redirect to={{ pathname: "/404", state: { from: props.location } }} />
                        } />
                      </Switch>
                    </div>
                    {this.props.article && !webView && viewMode !== 'SWIPE' && !customDomain && !isInCreationProcessModal && !isInAdministration && (
                      <Footer
                        currentUser={this.props.currentUser}
                        article={this.props.article}
                        viewBarAlwaysDisplayed={!isNil(this.props.article) && this.props.article.author !== undefined}
                        viewMode={this.props.viewMode}
                        numberOfSlides={this.props.slides ? this.props.slides.length : 0}
                        headerSlides={this.props.headerSlides}
                        slideRangeList={getSlideRangeList(this.props.slides)}
                        currentSlidePosition={currentSlidePosition}
                        canDisplayViewBar={true}
                        allowBlame={this.props.allowBlame}
                        showBlame={this.props.showBlame}
                        allowSlideComments={this.props.allowSlideComments}
                        allowSlideQuestions={this.props.allowSlideQuestions}
                        pauseSlideComments={this.props.pauseSlideComments}
                        pauseSlideQuestions={this.props.pauseSlideQuestions}
                        renderColumns={this.props.renderColumns}
                        displayCollapsedHeaders={this.props.displayCollapsedHeaders}
                        hideEndSlides={this.props.hideEndSlides}
                        textSlideEditing={this.props.textSlideEditing}
                        slideType={this.props.slideType}
                        openMenu={this.props.openMenu}
                        slideSelectionModeDetail={this.props.slideSelectionModeDetail}
                        onCollapseAllSlides={this.props.onCollapseAllSlides}
                        onExpandAllSlides={this.props.onExpandAllSlides}
                        onToggleColumns={this.props.onToggleColumns}
                        onToggleBlame={this.props.onToggleBlame}
                        toggleTableOfContents={this.toggleTableOfContents.bind(this)}
                        showNotification={this.props.showNotification}
                        toggleCollapsedHeaderDisplay={this.props.toggleCollapsedHeaderDisplay}
                        toggleEndSlideDisplay={this.props.toggleEndSlideDisplay}
                        onCollapseAllInlineViewersByType={this.props.onCollapseAllInlineViewersByType}
                        enterSlideSelectionMode={this.props.enterSlideSelectionMode}
                        createArticle={this.onCreateGide}
                        openModal={(modalProps: { modalType: string, modalProps?: any}) =>
                          openModalForType(modalProps, this.props.routeToModal, this.props.openModal)}
                      	closeModal={() => closeModalForType(this.props.closeModal, history, this.props.lastCurrentlyOpenedModal)}
                        getAvailableHeaderLevelsForCurrentSlidePosition={this.getAvailableHeaderLevelsForCurrentSlidePosition}
                        setFooterInput={this.props.setFooterInput}
                        onSubmitSlide={this.props.onSubmitSlide}
                        // onInputBlurred={this.props.onInputBlurred}
                        onSubmitMultipleSlides={this.props.onSubmitMultipleSlides}
                        onUpdateArticleTitleAndImage={this.props.onUpdateArticleTitleAndImage}
                        updateSlideNumber={this.props.updateSlideNumber}
                        scrollToSlidePosition={this.scrollToSlidePosition}
                        setOpenMenu={this.setOpenMenu}
                        showRightSidebar={this.props.showRightSidebar}
                        showLeftSidebar={this.props.showLeftSidebar}
                        onSlideFilterClicked={() => {
                          this.props.openModal({
                            modalType: 'GideSlideFilterModal',
                            modalProps: {
                              slideTypes: this.props.slides.map((s: Slide) => s.slideType),
                              allowFilterOnComments: allowFilterOnComments,
                              allowFilterOnQuestions: allowFilterOnQuestions,
                              allowFilterOnPrivateNotes: allowFilterOnPrivateNotes,
                              allowFilterOnAttachments: allowFilterOnAttachments,
                              slideFilter: this.props.slideFilter,
                              onFilter: (slideFilter: SlideFilter) => {
                                this.props.filterSlides(slideFilter);
                              },
                            },
                          });
                        }}
                        ensureInputBarModalsClosedOnFocus={this.props.disableFooterInput}
                        showSignup={this.showSignUp}
                      />
                    )}
                    {!this.props.article && !webView && viewMode !== 'SWIPE' 
                      && !customDomain && !isInCreationProcessModal && !isInAdministration
                      && !this.props.loading && (
                      <div className="newGideFloatingButton"
                        onClick={() => {
                          if(this.props.currentUser) {
                            this.onCreateGide();        
                          } else {
                            this.showSignUp();
                          }                 
                        }}
                      >
                       <CircleIconButton
                          alt="create new gide"
                          backgroundColor="var(--COLOR-PRIMARY-400)"
                        >
                          <icons.ContentAlteration_Newgide
                            color='var(--WHITES-NORMAL-1000)'
                          />
                        </CircleIconButton>
                      </div>
                    )}
                  </Suspense>
                </Sidebar>
              </div>
            </div>

            {this.props.slideSelectionModeDetail &&
              this.props.currentUser &&
              this.props.slideSelectionModeDetail.operation === SlideSelectionOperation.Transfer && (
                <SlideTransferGideSelector
                  showAsModal={true}
                  mode={SlideTransferMode.TransferToAnotherGide}
                  slides={this.props.slides.filter((s: Slide) =>
                    contains(
                      s.id,
                      this.props.multiSlideSelectionDetail &&
                        this.props.slideSelectionModeDetail &&
                        !isNil(this.props.slideSelectionModeDetail.originSlideId)
                        ? [this.props.slideSelectionModeDetail.originSlideId]
                        : this.props.multiSlideSelectionDetail.selectedSlideIds,
                    ),
                  )}
                  currentUser={this.props.currentUser}
                  showNotification={this.props.showNotification}
                  onSelectionMade={(gideInfo: SlideTransferSelectionInfo) =>
                    this.transferSlidesToGide(
                      gideInfo,
                      this.props.multiSlideSelectionDetail &&
                        this.props.slideSelectionModeDetail &&
                        !isNil(this.props.slideSelectionModeDetail.originSlideId)
                        ? [this.props.slideSelectionModeDetail.originSlideId]
                        : this.props.multiSlideSelectionDetail.selectedSlideIds,
                    )
                  }
                  onCancel={() => this.props.exitSlideSelectionMode()}
                  slideSelectionLabel=""
                />
              )}
            {this.props.slideSelectionModeDetail &&
              this.props.currentUser &&
              this.props.slideSelectionModeDetail.operation === SlideSelectionOperation.Copy && (
                <SlideTransferGideSelector
                  showAsModal={true}
                  // slides={this.props.slides.filter((s: Slide) =>
                  //   contains(s.id, this.props.multiSlideSelectionDetail && !this.props.slideSelectionModeDetail.originSlideId
                  //     ? this.props.multiSlideSelectionDetail.selectedSlideIds
                  //     : [this.props.slideSelectionModeDetail.originSlideId]
                  //     )
                  //   )}
                  slides={this.props.slides.filter((s: Slide) =>
                    contains(
                      s.id,
                      this.props.multiSlideSelectionDetail &&
                        this.props.slideSelectionModeDetail &&
                        !isNil(this.props.slideSelectionModeDetail.originSlideId)
                        ? [this.props.slideSelectionModeDetail.originSlideId]
                        : this.props.multiSlideSelectionDetail.selectedSlideIds,
                    ),
                  )}
                  currentUser={this.props.currentUser}
                  showNotification={this.props.showNotification}
                  mode={SlideTransferMode.CopyWithinTheSameGide}
                  onSlidePositionAccepted={this.copySlidesToPositionInGide}
                  onCancel={() => this.props.exitSlideSelectionMode()}
                  slideSelectionLabel=""
                />
              )}
            {this.props.slideSelectionModeDetail &&
              this.props.currentUser &&
              this.props.slideSelectionModeDetail.operation === SlideSelectionOperation.AttachToSlide && (
                <SlideTransferGideSelector
                  showAsModal={true}
                  slides={this.props.slides.filter((s: Slide) =>
                    contains(
                      s.id,
                      this.props.multiSlideSelectionDetail &&
                        this.props.slideSelectionModeDetail &&
                        !isNil(this.props.slideSelectionModeDetail.originSlideId)
                        ? [this.props.slideSelectionModeDetail.originSlideId]
                        : this.props.multiSlideSelectionDetail.selectedSlideIds,
                    ),
                  )}
                  selectionSlides={this.props.slides.filter(
                    (s: Slide) =>
                      !contains(
                        s.id,
                        this.props.multiSlideSelectionDetail &&
                          this.props.slideSelectionModeDetail &&
                          !isNil(this.props.slideSelectionModeDetail.originSlideId)
                          ? [this.props.slideSelectionModeDetail.originSlideId]
                          : this.props.multiSlideSelectionDetail.selectedSlideIds,
                      ),
                  )}
                  currentUser={this.props.currentUser}
                  showNotification={this.props.showNotification}
                  mode={SlideTransferMode.AddAsAttachmentToSlide}
                  slideSelectionLabel="Attach to slide"
                  slideSelectionIcon={<icons.ContentAlteration_Attach color="var(--COLOR-SECONDARY-500)" />}
                  onSlideSelected={slide => {
                    if (
                      this.props.slideSelectionModeDetail &&
                      this.props.slideSelectionModeDetail.transferType === SlideSelectionTransferType.Move
                    ) {
                      this.moveSlidesAsAttachmentToSlide(slide);
                    } else {
                      this.copySlidesAsAttachmentToSlide(slide);
                    }
                  }}
                  onCancel={() => this.props.exitSlideSelectionMode()}
                />
              )}
          </div>

          {this.props.showInlineToolbar ? (
            <InlineToolbarGlobal
              isOwner={
                this.props.currentUser && this.props.article && this.props.article.author.username === this.props.currentUser.username
              }
              position={this.props.inlineToolbarPosition}
              onToggle={this.handleInlineToolbarGlobalSelect}
            />
          ) : null}
          {this.state.showTableOfContentsView && this.props.headerSlides && (
            <HeaderNavigation
              slides={this.props.headerSlides}
              onNavigate={this.props.scrollToSlidePositionById}
              closeModal={this.toggleTableOfContents.bind(this)}
            />
          )}
        </div>
      );
    }
    return <>{/* <div>TODO: Handle this case</div> */}</>;
  }
}



export default connect<ReducerStateProps, ReducerDispatchProps, {theme?: any}, AppState>(
  mapStateToProps, mapDispatchToProps
  )(withNotificationDispatch(withTheme(App)));
