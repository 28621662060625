import React, { CSSProperties } from 'react';
import GideImage from '../../../Shared/Image/GideImage';
import { LinkSlideFile } from '../../../../models/SlideFile';

export interface HyperLinkProps {
  url: string;
  style?: CSSProperties;
}

export default function HyperLink(props: HyperLinkProps) {
  return (
    <div className="hyperlinkContainer"
      style={props.style}
    >
      <GideImage src="/icons/slidetype/links/main.svg"
        alt="link"
        className="color-blue-100-svg"
      />
      <a className="hyperlink" target="_blank" href={props.url}>{props.url}</a>
    </div>
  );
}
