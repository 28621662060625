import React, { Component } from 'react';
import MapWithAMarker from '../maps/MapWithAMarker';

import { GOOGLE_MAPS_URL } from '../../constants/paths';

class LocationSlide extends Component {
  render() {
    const props = this.props;
    return (
      <div>
        <h4 style={{ textAlign: 'center' }}>{props.slide.data.title}</h4>
        <MapWithAMarker
          mapId={props.slide.data.mapId}
          googleMapURL={GOOGLE_MAPS_URL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div className={`mapContainer${props.slide.data.title ? ' title' : ' noTitle'}`} />}
          mapElement={<div style={{ height: `100%` }} />}
          lat={Number(props.slide.data.lat)}
          lng={Number(props.slide.data.long || props.slide.data.lng)}
          title={props.slide.data.title}
          showMarker={props.slide.data.showMarker}
          zoom={props.slide.data.zoom}
          options={{gestureHandling: 'cooperative'}}
          defaultMapTypeId={props.slide.data.mapTypeId ? props.slide.data.mapTypeId : 'roadmap'}
        />
      </div>
    );
  }
}

export default LocationSlide;
