import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';
import styles from './_SidebarLeft.module.scss';
import { User } from '../../models/User';
import icons from '../../assets/icons';
import GideListItemPreview, { ItemSelectionMode } from '../Shared/Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import TabMenu from '../Shared/TabMenu/TabMenu';
import { Link } from 'react-router-dom';
import { urlForArticle, displayGideTitle } from '../../utils/helperFunctions';
import Skeleton from '@material-ui/lab/Skeleton';
import { View } from '../../models/Meta';
import { useVirtual, VirtualItem } from 'react-virtual';

export enum SidebarTab {    
    History = 0,
    Notifications = 1,
}

export interface SidebarLeftProps {
    views: View[];
    leftSidebarOpen: boolean;
    hideHeaderOnLargerScreen: boolean;
    selectedTab: SidebarTab;
    avatarMenu: React.ReactNode;
    readOnly?: boolean;
    loading: boolean;
    toggleSidebar: () => void;
    onSelectGideFromSidebar: (viewData: View) => void;
    onSelectedTabChanged: (tab: SidebarTab) => void;
}

const onSelectGideFromSidebar = (v: View, onSelectGideFromSidebar: (viewData: View) => void) => {
    if (!v.article) {
        return alert('Sorry, this Gide is no longer available.');
    }
    onSelectGideFromSidebar(v);
};

export const SidebarLeft: React.FC<SidebarLeftProps> = (props) => {

    const size = props.views?.length;
    const parentRef = useRef<HTMLDivElement>(null);
    const estimateSize = useCallback(() => 61, []);
    const virtual = useVirtual({
      parentRef,
      estimateSize,
      size,
    });

    return (
        <div className={styles.sidebarLeft}>
            <div className={classNames(styles.sidebarLeftHeader, props.hideHeaderOnLargerScreen ? styles.hideHeaderOnLargerScreen : '', props.readOnly === true ? styles.readOnly : '')}>
                {props.leftSidebarOpen && (
                    <icons.Nav_Hamburger_Closed
                        style={{cursor: 'pointer'}}
                        className={styles.hamburger}
                        color="var(--COLOR-SECONDARY-500)"
                        onClick={props.toggleSidebar} // currently this just toggles but we should have an explicit open/close.
                    />
                )}
                {!props.leftSidebarOpen && (
                    <icons.Nav_Hamburger_Open
                        style={{cursor: 'pointer'}}
                        className={styles.hamburger}
                        color="var(--COLOR-SECONDARY-500)"
                        onClick={props.toggleSidebar}
                    />
                )}
                <Link to={'/'} >
                    <icons.Nav_Logo_LogoType doNotFillFromColor={true} className={styles.logo}
                        onClick={props.leftSidebarOpen ? props.toggleSidebar: () => {}}
                    />
                </Link>
            </div>
            <div className={classNames(styles.sidebarLeftAvatarMenu, props.hideHeaderOnLargerScreen ? styles.hideHeaderOnLargerScreen : '')}>
                {props.avatarMenu}
            </div>
            {props.readOnly !== true && (
              <>
                <TabMenu className={styles.sidebarLeftTabs}
                    selectedTab={props.selectedTab}
                    tabs={[
                        // {
                        //     label: "Notifications",
                        //     icon: <icons.Nav_Notifications />,
                        // },
                        {
                            label: "History",
                            icon: <icons.Nav_History />,
                        }
                    ]}
                    onTabClicked={(i: number) => {props.onSelectedTabChanged(i)}} />
                {/* {props.selectedTab === 0 &&
                    <div className={styles.sidebarLeftNotifications}>
                        <div>COMING SOON</div>
                    </div>
                } */}
                {props.selectedTab === 0 &&
                  <div className={styles.sidebarLeftHistory}>
                    {props.loading && (
                      <div className={styles.loadingSkeleton}>
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                        <Skeleton style={{backgroundColor: 'rgba(175, 127, 73, 0.1)'}} animation="wave" height={100} />
                      </div>
                    )}
                    {!props.loading && (
                      <div ref={parentRef} style={{
                        position: 'relative',
                        height: "100%",
                        width: "100%",
                        overflow: 'auto',
                        willChange: 'transform',
                        direction: 'ltr'
                      }}>
                        <div style={{width: "100%", height: virtual.totalSize, position: 'relative'}}>
                          {virtual.virtualItems.map(vi => {
                            const v = props.views[vi.index];
                            return (
                              <Link to={urlForArticle(v.article)} key={v.article.slug} style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: vi.size,
                                transform: `translateY(${vi.start}px)`,
                              }}>
                                  <GideListItemPreview
                                    className={styles.item}
                                    authorImage={v.article.author.image}
                                    author={v.article.author.username}
                                    createdAt={new Date(v.createdAt)}
                                    image={v.article.image}
                                    onClick={() => {onSelectGideFromSidebar(v, props.onSelectGideFromSidebar)}}
                                    title={displayGideTitle(v.article.title, v.article.type)}
                                    updatedAt={new Date(v.updatedAt)}
                                    slideCount={v.article.slideCount}
                                    selectionMode={ItemSelectionMode.SingleDisplayNone}
                                  />
                              </Link>
                            );
                          })}
                        </div>
                      </div>

                      // <AutoSizer>
                      //     {({width, height}) => (
                      //         <List
                      //             height={height || 0}
                      //             itemCount={(props.views && props.views.length) || 0}
                      //             itemSize={61}
                      //             width={width || 0}
                      //             itemKey={(index) => props.views[index]?.article.id}>
                      //             {RenderHistoryItem}
                      //         </List>
                      //     )}
                      // </AutoSizer>
                    )}
                  </div>
                }
              </>
            )}
        </div>
    );
}

export default SidebarLeft;
