
import React from 'react';
import { New } from './New';
import { getPreviewOverlayIconSvg, SlideType, getSlideFileUrl } from '../../../utils/helperFunctions';
import { CircleIconButton } from '../../Shared/CircleIconButton/CircleIconButton';
import { CommonState, CommonDerivedState, CommonContext, CommonModes, CommonSlideEditorProps } from './Common';
import * as Common from './Common';
import { ImageFile } from '../../../models/SlideFile';
import GideImage from '../../Shared/Image/GideImage';

export enum ImageAddType {
  PhotoLibrary = 1,
  Camera = 2,
  Upload = 3,
  Link = 4,
  Slide = 5,
}

export interface ImageSlideEditorProps extends CommonSlideEditorProps {}

export const ImageModes = {
  ...CommonModes,
  CroppingImage: 'CroppingImage' as 'CroppingImage',
  Crop: 'Crop' as 'Crop',
  Doodle: 'Doodle' as 'Doodle',
  OnTap: 'OnTap' as 'OnTap',
};
export type State = CommonState & { mode: keyof typeof ImageModes };
export type DerivedState = CommonDerivedState & { mode: keyof typeof ImageModes };
export type Context = Common.Context<State, ImageSlideEditorProps>;

export const getHiddenMediaChooser = (context: CommonContext) => (
  <div style={{ display: 'none' }}>
    {New.getContent(context, true)}
  </div>
);

export const getCreateActionsForImage = (context: CommonContext) => {
  const hiddenMediaChooser = getHiddenMediaChooser(context);
  const actions = Common.getCreateActionCallbacks(context, "images");
  
  const createActions = (
    <>
      <CircleIconButton
        style={{ margin: '5px 10px 5px 5px' }}
        alt="use link"
        onClick={actions.actionUseLink}
        backgroundColor="var(--COLOR-BLUE-100)"
        iconCssClass="whites-normal-1000-svg"
        image="/icons/slidetype/links/main.svg"
      />
      <CircleIconButton
        style={{ margin: '5px 10px 5px 5px' }}
        alt="choose slide"
        onClick={actions.actionChooseSlide}
        backgroundColor="var(--COLOR-BLUE-100)"
        iconCssClass="whites-normal-1000-svg"
        image="/icons/creationprocess/slide.svg"
      />
      <CircleIconButton
        style={{ margin: '5px 10px 5px 5px' }}
        alt="browse for image"
        onClick={actions.actionBrowseFile}
        backgroundColor="var(--COLOR-BLUE-100)"
        iconCssClass="whites-normal-1000-svg"
        image="/icons/slidetype/image/main.svg"
      />
      <CircleIconButton
        style={{ margin: '5px 18px 5px 5px' }}
        alt="take photo"
        onClick={actions.actionStartCamera}
        backgroundColor="var(--COLOR-BLUE-100)"
        iconCssClass="whites-normal-1000-svg"
        image="/icons/content-alteration/camera/default.svg"
      />
      {hiddenMediaChooser}
    </>
  );
  return createActions;
}

export const getImagePreviewItems = (context: CommonContext, selectedItemIndex: number, setImageSelection: (selectedItemIndex: number, context: CommonContext) => void) => {
  return context.state.slide ? context.state.slide.data.files.map((image: ImageFile, i: number) => {
    let Svg = getPreviewOverlayIconSvg(SlideType.Image, image.type);
    return {
      id: `${image.id}`,
      content: <div className="slideFileSelectionContainer" key={`slideFileSelectionContainer${i}`}>
        <Svg style={{ width: "18px", height: "18px" }}
          color={ (i < 0) ? 'var(--WHITES-NORMAL-300)' : 'var(--COLOR-SECONDARY-500)' } />

        <div className="imagePreview" key={`ip${i}`}>
          <GideImage 
            className="slideImage"
            key={`iep${i}`}
            src={getSlideFileUrl(image)}
            alt={image.name ? image.name : getSlideFileUrl(image)}
            onClick={() => {
              setImageSelection(i, context);
            }}
          />
        </div>
        <div className={`itemPreviewLabel${selectedItemIndex === i ? ' active' : ''}`}
          key={`ipl${i}`} >
          <span>{i + 1}</span>
        </div>
      </div>
    };
  }) : [];
}
