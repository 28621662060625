import React, { CSSProperties } from 'react';
import styles from './_picker.module.scss';
import { IconProps } from '../../../../assets/icons';
import { TertiaryButton } from '../../../Shared/Buttons/TertiaryButton/TertiaryButton';
import { CircleIconButton } from '../../../Shared/CircleIconButton/CircleIconButton';

// Move these enums to where they are used from
export enum GideTitleSlideFormat {
  ImageAndText = 1,
  ImageOnly = 2,
  TextOnly = 3,
  NoTitle = 4,
  Blog = 5
}

export enum PickerDisplayMode {
  CircularTextOutsideUnder = 1,
  RectangularTextInsideUnder = 2,
}
export interface  PickerProps {
  title: string;
  pickerItems: PickerItem[];
  selectedValue: number; // GideTitleSlideFormat | SlideWidthType;
  onChange: (value: number) => void; // (format: GideTitleSlideFormat | SlideWidthType) => void; 
  style?: CSSProperties;
  displayMode?: PickerDisplayMode;
  itemWidth?: number;
}
export interface PickerItem {
  icon: (props: IconProps) => JSX.Element;
  label: string;
  value: number;
}

export default function Picker (props:  PickerProps) {
  const placeholders = [];
  // There are a max of 3 items per row so get the remainder and add place holder elements
  const remainder = props.pickerItems.length % 3;
  if(remainder !== 0) {
    for(let i = 0; i < 3 - remainder; i ++) {
      placeholders.push(i);
    }
  }
  return (
    <div className={styles.pickerContainer}
      style={props.style}
    >
      <span className={styles.title}>{props.title}</span>
      <div className={styles.pickerItems}>
        {props.pickerItems.map((pickerItem: PickerItem, i: number) => 
          <React.Fragment key={i}>
            {props.displayMode !== PickerDisplayMode.CircularTextOutsideUnder && (            
              <TertiaryButton
                icon={pickerItem.icon}
                isSelected={props.selectedValue === pickerItem.value}
                key={i}
                onClick={() => {
                  props.onChange(pickerItem.value);
                }}
                label={pickerItem.label}
                style={{margin: '6px'}}
              />
            )}
            {props.displayMode === PickerDisplayMode.CircularTextOutsideUnder && (
              <div className={styles.circularPickerItem}
                style={props.itemWidth ? {width: `${props.itemWidth}px`} : {}}
              >
                <CircleIconButton
                  backgroundColor={props.selectedValue === pickerItem.value ? 'var(--COLOR-PRIMARY-300)' : 'var(--WHITES-NORMAL-1000)'}
                  onClick={() => {
                    props.onChange(pickerItem.value);
                  }}
                  style={{border: props.selectedValue === pickerItem.value ? 'unset' : 'solid 1px var(--COLOR-SECONDARY-300)'}}
                >
                  <pickerItem.icon 
                    color={props.selectedValue === pickerItem.value ? 'var(--WHITES-NORMAL-1000)' : 'var(--COLOR-SECONDARY-300)'}
                  />
                </CircleIconButton>
                <span className={styles.circularPickerItemFormatLabel}>{pickerItem.label}</span>
              </div>
            )}            
          </React.Fragment>
        )}        
        {/* Need these items to push the content to the left */}
        {props.displayMode !== PickerDisplayMode.CircularTextOutsideUnder && (
          <>
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          <span className={styles.pickerItemAligner} />
          </>
        )}
        {props.displayMode === PickerDisplayMode.CircularTextOutsideUnder && placeholders.length > 0 && (
          placeholders.map(p => <div style={props.itemWidth ? {width: `${props.itemWidth}px`} : {}} className={styles.circularPickerItem}></div>)
        )}
      </div>

    </div>
  );
}
