import React, { ReactNode } from 'react';
import styles from './_styles.module.scss';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { getUrlForTarget } from '../../../../utils/helperFunctions';
type ImageSize = 'auto' | 'cover' | 'contain' | 'inherited' | 'initial' | 'unset' | string;

export interface ImageSetting {
  desktopUrl: string;
  tabletUrl?: string;
  mobileUrl?: string; // When missing use desktopUrl. NOT synonomous with desktopOnly.
  visibilityMode?: 'all' | 'desktop' | 'desktop and tablet';
  position: 'left' | 'left top' | 'left bottom' | 'right' | 'right top' | 'right bottom' | 'center' | 'center top' | 'center bottom';
  desktopVerticalOffset?: number; // TODO: Make it so you can pass in the offset for horizontal as well
  tabletVerticalOffset?: number;
  mobileVerticalOffset?: number;
  repeat: 'repeat' | 'no-repeat';
  desktopSize: ImageSize;
  tabletSize?: ImageSize;
  mobileSize?: ImageSize;
}
export interface PositionedComponent {
  component: ReactNode;
  top?: number;
  bottom?: number;
  left?: number | string;
  right?: number | string;
  mobileTop?: number;
  mobileBottom?: number;
  mobileLeft?: number;
  mobileRight?: number;
  centerHorizontal?: boolean;
}
export interface DivMultiImageInfo {
  imageSet: ImageSetting[];
  // Component that is positioned at a specific location. NOTE: It will pass location for mobile but the content
  // at certain media queries needs to be handled by the styling on the component itself.
  positionedComponent?: PositionedComponent;
}
export interface MultiImageProps {
  imageInfo: DivMultiImageInfo;
  className?: string;
  style?: CSSProperties;
}

export default function MultiImage(props: MultiImageProps) {
  let style={...props.style};
  if(props.imageInfo.positionedComponent) {
    if(props.imageInfo.positionedComponent.top) {
      style = {
        ...style,
        '--top' : `${props.imageInfo.positionedComponent.top}px`
      }
    }
    if(props.imageInfo.positionedComponent.right) {
      style = {
        ...style,
        '--right' : `${props.imageInfo.positionedComponent.right}px`
      }
    }
    if(props.imageInfo.positionedComponent.bottom) {
      style = {
        ...style,
        '--bottom' : `${props.imageInfo.positionedComponent.bottom}px`
      }
    }
    if(props.imageInfo.positionedComponent.left) {
      style = {
        ...style,
        '--left' : `${props.imageInfo.positionedComponent.left}px`
      }
    }
    if(props.imageInfo.positionedComponent.mobileTop || props.imageInfo.positionedComponent.top) {
      style = {
        ...style,
        '--mobileTop' : `${props.imageInfo.positionedComponent.mobileTop ? props.imageInfo.positionedComponent.mobileTop : props.imageInfo.positionedComponent.top}px`
      }
    }
    if(props.imageInfo.positionedComponent.mobileRight || props.imageInfo.positionedComponent.right) {
      style = {
        ...style,
        '--mobileRight' : `${props.imageInfo.positionedComponent.mobileRight ? props.imageInfo.positionedComponent.mobileRight : props.imageInfo.positionedComponent.right}px`
      }
    }
    if(props.imageInfo.positionedComponent.mobileBottom || props.imageInfo.positionedComponent.bottom) {
      style = {
        ...style,
        '--mobileBottom' : `${props.imageInfo.positionedComponent.mobileBottom ? props.imageInfo.positionedComponent.mobileBottom : props.imageInfo.positionedComponent.bottom}px`
      }
    }
    if(props.imageInfo.positionedComponent.mobileLeft || props.imageInfo.positionedComponent.left) {
      style = {
        ...style,
        '--mobileLeft' : `${props.imageInfo.positionedComponent.mobileLeft ? props.imageInfo.positionedComponent.mobileLeft : props.imageInfo.positionedComponent.left}px`
      }
    }
    if(props.imageInfo.positionedComponent.centerHorizontal) {
      style = {
        ...style,
        '--width' : '100%',
        '--alignItems' : 'center'
      }
    }
  }

  // Desktop
  let desktopStyle: CSSProperties = { ...style };
  desktopStyle.backgroundImage = props.imageInfo.imageSet.map(imageInfo => `url(${getUrlForTarget(imageInfo.desktopUrl)})`).join(',');
  desktopStyle.backgroundPosition = props.imageInfo.imageSet
    .map(imageInfo => `${imageInfo.position}${imageInfo.desktopVerticalOffset ? ' ' + imageInfo.desktopVerticalOffset + '%' : ''}`)
    .join(',');
  desktopStyle.backgroundRepeat = props.imageInfo.imageSet.map(imageInfo => `${imageInfo.repeat}`).join(',');
  desktopStyle.backgroundSize = props.imageInfo.imageSet.map(imageInfo => `${imageInfo.desktopSize}`).join(',');

  // Tablet
  let tabletStyle: CSSProperties = { ...style };
  tabletStyle.backgroundImage = props.imageInfo.imageSet
    .filter(is => is.visibilityMode === 'all' || is.visibilityMode === 'desktop and tablet')
    .map(imageInfo => `url(${imageInfo.tabletUrl ? getUrlForTarget(imageInfo.tabletUrl) : getUrlForTarget(imageInfo.desktopUrl)})`)
    .join(',');
  tabletStyle.backgroundPosition = props.imageInfo.imageSet
    .filter(is => is.visibilityMode === 'all' || is.visibilityMode === 'desktop and tablet')
    .map(imageInfo => {
      const verticalOffset = imageInfo.tabletVerticalOffset ? imageInfo.tabletVerticalOffset : imageInfo.desktopVerticalOffset;
      return `${imageInfo.position}${verticalOffset ? ' ' + verticalOffset + '%' : ''}`;
    })
    .join(',');
  tabletStyle.backgroundRepeat = props.imageInfo.imageSet
    .filter(is => is.visibilityMode === 'all' || is.visibilityMode === 'desktop and tablet')
    .map(imageInfo => `${imageInfo.repeat}`)
    .join(',');

  tabletStyle.backgroundSize = props.imageInfo.imageSet
    .filter(is => is.visibilityMode === 'all' || is.visibilityMode === 'desktop and tablet')
    .map(imageInfo => `${imageInfo.tabletSize ? imageInfo.tabletSize : imageInfo.desktopSize}`)
    .join(',');

  // Mobile
  let mobileStyle: CSSProperties = { ...style };

  mobileStyle.backgroundImage = props.imageInfo.imageSet
    .filter(is => is.visibilityMode === 'all')
    .map(imageInfo => `url(${imageInfo.mobileUrl ? getUrlForTarget(imageInfo.mobileUrl) : getUrlForTarget(imageInfo.desktopUrl)})`)
    .join(',');
  mobileStyle.backgroundPosition = props.imageInfo.imageSet
    .filter(is => is.visibilityMode === 'all')
    .map(imageInfo => {
      const verticalOffset = imageInfo.mobileVerticalOffset ? imageInfo.mobileVerticalOffset : imageInfo.desktopVerticalOffset;
      return `${imageInfo.position}${verticalOffset ? ' ' + verticalOffset + '%' : ''}`;
    })
    .join(',');
  mobileStyle.backgroundRepeat = props.imageInfo.imageSet
    .filter(is => is.visibilityMode === 'all')
    .map(imageInfo => `${imageInfo.repeat}`)
    .join(',');

  mobileStyle.backgroundSize = props.imageInfo.imageSet
    .filter(is => is.visibilityMode === 'all')
    .map(imageInfo => `${imageInfo.mobileSize ? imageInfo.mobileSize : imageInfo.desktopSize}`)
    .join(',');


  return (
    <>
      <div className={classNames(styles.multiImageContainer, styles.desktop, props.className)} style={desktopStyle}>
        {props.imageInfo.positionedComponent && (
            <div className={styles.positionedComponent}>
              {props.imageInfo.positionedComponent?.component}
            </div>
        )}
      </div>
      <div className={classNames(styles.multiImageContainer, styles.tablet, props.className)} style={tabletStyle}>
        {props.imageInfo.positionedComponent && (
            <div className={styles.positionedComponent}>
              {props.imageInfo.positionedComponent?.component}
            </div>
        )}
      </div>
      <div className={classNames(styles.multiImageContainer, styles.mobile, props.className)} style={mobileStyle}>
        {props.imageInfo.positionedComponent && (
            <div className={styles.positionedComponent}>
              {props.imageInfo.positionedComponent?.component}
            </div>
        )}
      </div>
    </>
  );
}
