import React, { Component, ReactNode } from 'react';
import Cropper from 'cropperjs';
import { CircleIconButton } from '../../../Shared/CircleIconButton/CircleIconButton';
import Slider from '@material-ui/core/Slider';
import { RoundedCornerButton } from '../../../Shared/RoundedCornerButton/RoundedCornerButton';
import { getImageMeta } from '../../../../utils/helperFunctions';
import { ImageFile } from '../../../../models/SlideFile';

interface CanvasData {
  left: number;
  top: number;
  width: number;
  height: number;
}
interface CanvasCropOptions {
  width: number;
  height: number;
  minWidth: number;
  minHeight: number;
  maxWidth: number;
  maxHeight: number;
  fillColor: string;
  imageSmoothingEnabled: boolean;
  imageSmoothingQuality: 'low' | 'medium' | 'high';
}
interface Data {
  x: number;
  y: number;
  width: number;
  height: number;
  rotate: number
  scaleX: number;
  scaleY: number;
}
interface XYCoordinate {
  x: number;
  y: number;
}



export interface ImageEditorProps {
  // src: null,
  // dragMode: 'crop',
  // data: null,
  // scaleX: 1,
  // scaleY: 1,
  // enable: true,
  // zoomTo: 1,
  // rotateTo: 0,
  // imageName: 'New',
  // Custom props not from cropper
  responseType: string; // Custom prop
  image: ImageFile;
  alt?: string;
  crossOrigin?: boolean;
  cropBoxData?: CanvasData;
  canvasData?: CanvasData;
  zoomTo?: number;
  scaleX?: number;
  scaleY?: number;
  cropperOptions: Cropper.Options;
  saveImage: (image: any) => void;
}
export interface ImageEditorState {
  image: HTMLCanvasElement | undefined;
  rotateAngle: number;
  cropperOptions: Cropper.Options;
  imageAspectRatio: number;
  maxWidth: number;
}

class ImageEditor extends Component<ImageEditorProps, ImageEditorState> {
  cropper: any;
  img: HTMLImageElement | HTMLCanvasElement | undefined | null;

  constructor(props: ImageEditorProps) {
    super(props);
    this.state = {
      rotateAngle: 0,
      image: undefined,
      imageAspectRatio: 1,
      maxWidth: 752,
      cropperOptions: {
        dragMode: props.cropperOptions.dragMode ? props.cropperOptions.dragMode : 'crop',
        aspectRatio: props.cropperOptions.aspectRatio ? props.cropperOptions.aspectRatio : NaN,
        data: props.cropperOptions.data ? props.cropperOptions.data : undefined,
        crop: props.cropperOptions.crop ? props.cropperOptions.crop : undefined,
        viewMode: props.cropperOptions.viewMode ? props.cropperOptions.viewMode : 0,
        preview: props.cropperOptions.preview ? props.cropperOptions.preview : '',
        responsive: props.cropperOptions.responsive ? props.cropperOptions.responsive : true,
        restore: props.cropperOptions.restore ? props.cropperOptions.restore : true,
        checkCrossOrigin: props.cropperOptions.checkCrossOrigin ? props.cropperOptions.checkCrossOrigin : true,
        checkOrientation: props.cropperOptions.checkOrientation ? props.cropperOptions.checkOrientation : true,
        modal: props.cropperOptions.modal ? props.cropperOptions.modal : true,
        guides: props.cropperOptions.guides ? props.cropperOptions.guides : true,
        center: props.cropperOptions.center ? props.cropperOptions.center : true,
        highlight: props.cropperOptions.highlight ? props.cropperOptions.highlight : true,
        background: props.cropperOptions.background ? props.cropperOptions.background : true,
        autoCrop: props.cropperOptions.autoCrop ? props.cropperOptions.autoCrop : true,
        autoCropArea: props.cropperOptions.autoCropArea ? props.cropperOptions.autoCropArea : 1,
        movable: props.cropperOptions.movable ? props.cropperOptions.movable : true,
        rotatable: props.cropperOptions.rotatable ? props.cropperOptions.rotatable : true,
        scalable: props.cropperOptions.scalable ? props.cropperOptions.scalable : true,
        zoomable: props.cropperOptions.zoomable ? props.cropperOptions.zoomable : true,
        zoomOnTouch: props.cropperOptions.zoomOnTouch ? props.cropperOptions.zoomOnTouch : true,
        zoomOnWheel: props.cropperOptions.zoomOnWheel ? props.cropperOptions.zoomOnWheel : true,
        wheelZoomRatio: props.cropperOptions.wheelZoomRatio ? props.cropperOptions.wheelZoomRatio : .1,
        cropBoxMovable: props.cropperOptions.cropBoxMovable ? props.cropperOptions.cropBoxMovable : true,
        cropBoxResizable: props.cropperOptions.cropBoxResizable ? props.cropperOptions.cropBoxResizable : true,
        toggleDragModeOnDblclick: props.cropperOptions.toggleDragModeOnDblclick ? props.cropperOptions.toggleDragModeOnDblclick : true,
        minContainerWidth: props.cropperOptions.minContainerWidth ? props.cropperOptions.minContainerWidth : 200,
        minContainerHeight: props.cropperOptions.minContainerHeight ? props.cropperOptions.minContainerHeight : 100,
        minCanvasWidth: props.cropperOptions.minCanvasWidth ? props.cropperOptions.minCanvasWidth : 0,
        minCanvasHeight: props.cropperOptions.minCanvasHeight ? props.cropperOptions.minCanvasHeight : 0,
        minCropBoxWidth: props.cropperOptions.minCropBoxWidth ? props.cropperOptions.minCropBoxWidth : 0,
        minCropBoxHeight: props.cropperOptions.minCropBoxHeight ? props.cropperOptions.minCropBoxHeight : 0,
        ready: props.cropperOptions.ready ? props.cropperOptions.ready : undefined,
        cropstart: props.cropperOptions.cropstart ? props.cropperOptions.cropstart : undefined,
        cropmove: props.cropperOptions.cropmove ? props.cropperOptions.cropmove : undefined,
        cropend: props.cropperOptions.cropend ? props.cropperOptions.cropend : undefined,
        zoom: props.cropperOptions.zoom ? props.cropperOptions.zoom : undefined,
        initialAspectRatio: props.cropperOptions.initialAspectRatio ? props.cropperOptions.initialAspectRatio : NaN,
      }
    };
  }
  handleResize = () => {
    // determine the max width of the image based on
    // - The original proportions of the image
    // - The width and height of the browser
    const maxContainerHeight = window.innerHeight - 250; // Headers, and bottom actions take this space.
    const maxWidth = maxContainerHeight * this.state.imageAspectRatio;
    this.setState({ maxWidth: Math.min(maxWidth, window.innerWidth) });

  }
  initializeImageSizeState = async () => {
    const image: any = await getImageMeta(this.props.image.url as string);
    this.setState({ imageAspectRatio: image.width / image.height }, this.handleResize);
  }
  componentDidMount() {
    if (this.img) {
      this.cropper = new Cropper(this.img, this.state.cropperOptions);      
    }

    this.initializeImageSizeState();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillReceiveProps(nextProps: ImageEditorProps) {
    if (nextProps.image !== this.props.image) {
      this.cropper.reset().clear().replace(nextProps.image.url);
      this.setState({ rotateAngle: 0 });
    }
    if (nextProps.cropperOptions.aspectRatio && nextProps.cropperOptions.aspectRatio !== this.props.cropperOptions.aspectRatio) {
      this.setAspectRatio(nextProps.cropperOptions.aspectRatio);
    }
    if (nextProps.cropperOptions.data && nextProps.cropperOptions.data !== this.props.cropperOptions.data) {
      this.setData(nextProps.cropperOptions.data);
    }
    if (nextProps.cropperOptions.dragMode && nextProps.cropperOptions.dragMode !== this.props.cropperOptions.dragMode) {
      this.setDragMode(nextProps.cropperOptions.dragMode);
    }
    if (nextProps.cropBoxData && nextProps.cropBoxData !== this.props.cropBoxData) {
      this.setCropBoxData(nextProps.cropBoxData);
    }
    if (nextProps.canvasData && nextProps.canvasData !== this.props.canvasData) {
      this.setCanvasData(nextProps.canvasData);
    }
    // if (nextProps.moveTo !== this.props.moveTo) {
    //   if (nextProps.moveTo) {
    //     this.moveTo(nextProps.moveTo.x, nextProps.moveTo.y);
    //   }
    // }
    if (nextProps.zoomTo && nextProps.zoomTo !== this.props.zoomTo) {
      this.zoomTo(nextProps.zoomTo);
    }
    if (nextProps.scaleX && nextProps.scaleX !== this.props.scaleX) {
      this.scaleX(nextProps.scaleX);
    }
    if (nextProps.scaleY && nextProps.scaleY !== this.props.scaleY) {
      this.scaleY(nextProps.scaleY);
    }
  }

  componentWillUnmount() {
    if (this.img) {
      // Destroy the cropper, this makes sure events such as resize are cleaned up and do not leak
      this.cropper.destroy();
      delete this.img;
      delete this.cropper;
    }
    window.removeEventListener('resize', this.handleResize);
  }

  setDragMode(mode: 'none' | 'crop' | 'move') {
    return this.cropper.setDragMode(mode);
  }

  setAspectRatio(aspectRatio: number) {
    return this.cropper.setAspectRatio(aspectRatio);
  }

  getCroppedCanvas(options: CanvasCropOptions) {
    return this.cropper.getCroppedCanvas(options);
  }

  setCropBoxData(data: CanvasData) {
    return this.cropper.setCropBoxData(data);
  }

  getCropBoxData() {
    return this.cropper.getCropBoxData();
  }

  setCanvasData(data: CanvasData) {
    return this.cropper.setCanvasData(data);
  }

  getCanvasData() {
    return this.cropper.getCanvasData();
  }

  getImageData() {
    return this.cropper.getImageData();
  }

  getContainerData() {
    return this.cropper.getContainerData();
  }

  setData(data: Data) {
    return this.cropper.setData(data);
  }

  getData(rounded: boolean) {
    return this.cropper.getData(rounded);
  }

  crop(options: CanvasCropOptions | undefined, cb: () => void) {
    this.setState({
      image: this.cropper.getCroppedCanvas(options),
    }, cb);
  }

  move(offsetX: number, offsetY: number) {
    return this.cropper.move(offsetX, offsetY);
  }

  moveTo(x: number, y: number) {
    return this.cropper.moveTo(x, y);
  }

  zoom(ratio: number) {
    return this.cropper.zoom(ratio);
  }

  zoomTo(ratio: number) {
    return this.cropper.zoomTo(ratio);
  }

  rotate(degree: number) {
    return this.cropper.rotate(degree);
  }
  /**
   * Rotate the image counter-clockwise by 90 degree increments
   */
  rotateToLeft() {
    let rotateAngle = this.state.rotateAngle;
    if (this.state.rotateAngle <= 180 && this.state.rotateAngle > 90) {
      rotateAngle = 90;
    } else if (this.state.rotateAngle <= 90 && this.state.rotateAngle > 0) {
      rotateAngle = 0;
    } else if (this.state.rotateAngle <= 0 && this.state.rotateAngle > -90) {
      rotateAngle = -90
    } else if (this.state.rotateAngle <= -90 && this.state.rotateAngle > -180) {
      rotateAngle = -180
    } else {
      rotateAngle = 90;
    }

    this.setState({ rotateAngle: rotateAngle });
    return this.cropper.rotateTo(rotateAngle);
  }
  /**
   * Rotate the image clockwise by 90 degree increments
   * NOTE: Not currently used. Needs to be reworked like rotateToLeft
   */
  rotateToRight() {
    this.setState({ rotateAngle: this.state.rotateAngle + 90 });
    return this.cropper.rotateTo(this.state.rotateAngle + 90);
  }

  enable() {
    return this.cropper.enable();
  }

  disable() {
    return this.cropper.disable();
  }

  reset() {
    return this.cropper.reset();
  }

  clear() {
    return this.cropper.clear();
  }

  replace(url: string, onlyColorChanged: boolean) {
    return this.cropper.replace(url, onlyColorChanged);
  }

  scale(scaleX: number, scaleY: number) {
    return this.cropper.scale(scaleX, scaleY);
  }

  scaleX(scaleX: number) {
    return this.cropper.scaleX(scaleX);
  }

  scaleY(scaleY: number) {
    return this.cropper.scaleY(scaleY);
  }

  saveImage() {
    if (this.state.image) {
      switch (this.props.responseType) {
        case 'blob':
          this.state.image.toBlob(
            (blob) => { this.props.saveImage(blob); },
          );
          break;
        case 'base64':
          this.props.saveImage(this.state.image.toDataURL());
          break;
        default:
          this.props.saveImage(this.state.image.toDataURL());
      }
    }
  }

  render(): ReactNode {
    const {
      image,
      alt,
    } = this.props;    
    return (
      <div
        className={'imageEditorCropperContainer'}
      >
        <div className="imageEditorCropper"
          onTouchStart={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
          }}          
        >
          <img 
            // crossOrigin={'anonymous'}
            ref={(img) => { this.img = img; }}
            src={image.url}
            alt={alt === undefined ? 'picture' : alt}
            style={{ opacity: 0 }}
          />
        </div>
        <div className="imageEditorActions">
          <CircleIconButton
            image="/icons/creationprocess/cropandrotate/rotate.svg"
            iconCssClass="whites-normal-1000-svg"
            style={{ margin: '5px' }}
            alt="rotate counter clockwise"
            onClick={() => this.rotateToLeft()}
            backgroundColor={'transparent'}
          />
          <div className="rotationSliderComponent">
            <Slider
              value={this.state.rotateAngle}
              min={-180} max={180}
              // ticks={ticks}
              // tooltip={{
              //   cssClass: 'rotationSliderComponentTooltip',
              //   placement: 'Before',
              //   isVisible: true,
              //   showOn: 'Always'
              // }}
              // tooltipChange={(eventArgs: any) => {
              //   // (eventArgs as SliderTooltipEventArgs).text = `${eventArgs.value}°`;
              // }}
              onChange={(event, value) => {
                this.setState({ rotateAngle: value as number });
                this.cropper.rotateTo(value);
              }}
              valueLabelDisplay="on"
            />
          </div>
          <RoundedCornerButton
            label="Finish"
            image="/icons/content-alteration/check/main.svg"
            labelColor="rgba(255, 255, 255, 0.95)"
            imagePosition="right"
            style={{ margin: '5px', backgroundColor: '#000' }}
            alt="apply changes"
            onClick={() => this.crop(undefined, this.saveImage)}
          />
        </div>
      </div>
    );
  }
}
export default ImageEditor;
