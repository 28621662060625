import React, { CSSProperties } from 'react';
import styles from './_radio-button-list.module.scss';
import RadioButton from '../RadioButton/RadioButton';

export interface RadioButtonListOption {
  value: string | number;
  label: string;  
}
export interface  RadioButtonListProps {
  options: RadioButtonListOption[];
  groupName: string;
  selectedValue: string | number;
  style?: CSSProperties;
  itemStyle?: CSSProperties;
  displaySeparators?: boolean;
  onChange: (value: string | number) => void;
}

export default function RadioButtonList (props:  RadioButtonListProps) {
  const itemStyle = props.itemStyle ? {...props.itemStyle, flex: 1} : {flex: 1};
  return (    
    <div style={props.style} className={styles.radioButtonListContainer}>
      {props.options.map((option, index) =>
        <React.Fragment key={index}>
          <RadioButton
            style={itemStyle}
            checked={props.selectedValue === option.value}
            groupName={props.groupName}
            label={option.label}
            value={option.value}
            onSelected={(value: string | number | undefined) => {
              if(value)
                props.onChange(value);
            }}
          />
          {props.displaySeparators && index !== props.options.length -1 && (
            <div className={styles.separator}></div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}
