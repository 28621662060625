import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import agent from '../../../../../agent';
import { history } from '../../../../../store';
import { useDispatch } from 'react-redux';
import { MODAL_OPEN, DELETE_ARTICLE_NO_REDIRECT, RECOVER_ARTICLE } from '../../../../../constants/actionTypes';
import GideImage from '../../../Image/GideImage';

export interface GidePreviewFooterMenuProps {
  gideId: string;
  isOwner: boolean;
  allowToAddToCollections?: boolean;
  onDeleteGide?: (gideId: string) => void;
}
interface GidePreviewFooterMenuActions {
  openModal: (payload: any) => void;
  onRecoverArticle: (payload: any) => void;
}
export default function GidePreviewFooterMenu(props: GidePreviewFooterMenuProps) {
  const dispatch = useDispatch();
  const actions: GidePreviewFooterMenuActions = {
    openModal: (payload: any) => dispatch({ type: MODAL_OPEN, payload }),
    onRecoverArticle: (payload: any) => dispatch({ type: RECOVER_ARTICLE, payload }),
  };
  const articleMenuTrigger = <GideImage src="/icons/nav/threedots/horizontal.svg" alt={`□`} />;
  const onAddToCollectionClick = (gideId: string) => {
    actions.openModal({
      modalType: 'SelectCollectionModal',
      modalProps: {
        saveCallback: (collectionInfo: { collection: any; collectionId: any }) => {
          agent.Collections.addItem(collectionInfo.collection, { id: gideId });
        },
        saveButtonTitle: 'OK',
      },
    });
  };
  const onDeleteGideClick = async (gideId: string) => {
    if (!window.confirm('Are you sure?')) return;
    await agent.Articles.del({ id: gideId }, { full: false });
    props.onDeleteGide && props.onDeleteGide(gideId);
  };
  return (
    <Dropdown trigger={articleMenuTrigger} icon={null} floating>
      <Dropdown.Menu style={{ left: 'auto', right: '0' }}>
        {/* {props.isOwner && (
          <Dropdown.Item
            text={'Settings'}
            icon="setting"
            onClick={() => {
              history.push(`/editor/${props.gideId}`);
            }}
          />
        )} */}
        {props.isOwner && props.onDeleteGide && (
          <Dropdown.Item
            text={'Trash'}
            icon="trash"
            onClick={() => {
              onDeleteGideClick(props.gideId);
            }}
          />
        )}
        {props.allowToAddToCollections && (
          <Dropdown.Item
            text={'Add to Collection'}
            icon="add"
            onClick={() => {
              onAddToCollectionClick(props.gideId);
            }}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
