import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { lightTheme, darkTheme } from '../../../themes/custom-theme';
import ExpandingIconButton, { HorizontalAlignment } from '../../Shared/ExpandingIconButton/ExpandingIconButton';
import Swipeable from 'react-swipeable';
import { CommonDerivedState, CommonContext, CommonModes } from './Common';
import { ImageModes } from './SharedLogicForImageSlideEditor';
import { VideoModes } from './SharedLogicForVideoSlideEditor';
import VideoViewer from '../../Viewers/VideoViewers/VideoViewer/VideoViewer';
import { getPreviewItems, getCreateActions } from './SharedLogic';
import ImageView from '../../Viewers/ImageViewers/ImageView/ImageView';
import LinkView from '../../Viewers/LinkViewers/LinkView/LinkView';
import { HyperLinkLayout, ArticlePreviewLayout, ChannelPreviewLayout } from '../../../models/CommonEnums';
import { LinkSlideFile } from '../../../models/SlideFile';


const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps, isImageSlide, isVideoSlide, isLinkSlide } = context;  

  const imageHeaderActions = (
    <>
      <div className="headerActionsFlexEnd">
        {/* Attachments */}
        {/* <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="Attachments"
          src="/icons/creationprocess/attachments.svg"
          expandWidth={125}
          label="Attachments"
          onClick={e => {
            // TODO - Implement this feature.
            alert("Coming soon!");
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={true}
        /> */}
        {/* OnTap */}
        <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="On tap"
          src="/icons/creationprocess/touch-activate.svg"
          expandWidth={125}
          label="On tap"
          onClick={e => {
            setState({ ...state, mode: ImageModes.OnTap });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={true}
        />
        {/* Caption */}
        <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="Attribute"
          src="/icons/creationprocess/citing.svg"
          expandWidth={125}
          label="Attribute"
          onClick={e => {
            setState({ ...state, mode: CommonModes.Attribute });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={true}
        />
        {/* Doodle */}
        {/* <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="Doodle"
          src="/icons/slidetype/doodle/main.svg"
          expandWidth={125}
          label="Doodle"
          onClick={e => {
            setState({ ...state, mode: ImageModes.Doodle });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={true}
        /> */}
        {/* Crop */}
        <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="Crop"
          src="/icons/creationprocess/cropandrotate/cropandrotate.svg"
          expandWidth={125}
          label="crop image"
          onClick={e => {
            setState({ ...state, mode: ImageModes.Crop, slide: context.state.slide });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={true}
        />
        {/* Delete */}
        {(state.slide.data.files.length > 1) &&
          <ExpandingIconButton
            style={{
              marginLeft: '12px',
              marginRight: '7px',
              height: '32px',
            }}
            alt="Delete"
            src="/icons/content-alteration/delete.svg"
            expandWidth={80}
            label="Delete"
            onClick={e => {
              setState({ ...state,mode: CommonModes.Delete, slide: context.state.slide });
            }}
            labelLocation={HorizontalAlignment.Left}
            expanded={false}
            visible={true}
          />
        }
      </div>
    </>
  );
  const videoHeaderActions = (
    <>
      <div className="headerActionsFlexEnd">
        {/* Trim */}
        {/* <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="Trim"
          src="/icons/creationprocess/trim.svg"
          expandWidth={125}
          label="Trim"
          onClick={e => {
            setState({ ...state, mode: VideoModes.Trim });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={true}
        /> */}
        {/* Caption */}
        <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="Attribute"
          src="/icons/creationprocess/citing.svg"
          expandWidth={125}
          label="Attribute"
          onClick={e => {
            setState({ ...state, mode: CommonModes.Attribute });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={true}
        />
        {/* Delete */}
        {(state.slide.data.files.length > 1) &&
          <ExpandingIconButton
            style={{
              marginLeft: '12px',
              marginRight: '7px',
              height: '32px',
            }}
            alt="Delete"
            src="/icons/content-alteration/delete.svg"
            expandWidth={80}
            label="Delete"
            onClick={e => {
              setState({ ...state,mode: CommonModes.Delete, slide: context.state.slide });
            }}
            labelLocation={HorizontalAlignment.Left}
            expanded={false}
            visible={true}
          />
        }
      </div>
    </>
  );

  const selectedLinkFile = state.selectedItemIndex >= 0 ? state.slide.data.files[state.selectedItemIndex] : {};
  const linkHeaderActions = (
    <>
      <div className="headerActionsFlexEnd">
        {/* Style Tile Link Display */}
        <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="tile link display"
          src="/icons/creationprocess/tile.svg"
          iconCssClass={selectedLinkFile.displayMode === ArticlePreviewLayout.Tile ? 'color-secondary-800-svg' : 'color-secondary-300-svg'}
          expandWidth={125}
          label="tile link display"
          onClick={e => {
            const updatedSlide = {
              ...state.slide,
              data: {
                ...state.slide.data,
                files: state.slide.data.files.map((linkFile: LinkSlideFile, i: number) => {
                  if (state.selectedItemIndex === i) {
                    return {
                      ...linkFile,
                      displayMode: ArticlePreviewLayout.Tile,
                    };
                  }
                  return linkFile;
                }),
              },
            };
            setState({ ...state, slide: updatedSlide });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={selectedLinkFile.type === 'GIDE'}
        />
        {/* Style Image-Top */}
        <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="blog style preview image top display"
          src="/icons/creationprocess/bloglist/image-top.svg"
          iconCssClass={
            selectedLinkFile.displayMode === ArticlePreviewLayout.BlogImageTop ||
            selectedLinkFile.displayMode === HyperLinkLayout.BlogImageTop            
              ? 'color-secondary-800-svg'
              : 'color-secondary-300-svg'
          }
          expandWidth={125}
          label="blog style preview image top display"
          onClick={e => {
            const updatedSlide = {
              ...state.slide,
              data: {
                ...state.slide.data,
                files: state.slide.data.files.map((linkFile: LinkSlideFile, i: number) => {
                  if (state.selectedItemIndex === i) {
                    return {
                      ...linkFile,
                      displayMode: linkFile.type === 'GIDE' ? ArticlePreviewLayout.BlogImageTop : HyperLinkLayout.BlogImageTop,
                    };
                  }
                  return linkFile;
                }),
              },
            };
            setState({ ...state, slide: updatedSlide });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={(selectedLinkFile.type === 'GIDE' || selectedLinkFile.type === 'URL')}
        />
        {/* Style Image-Left */}
        <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="blog style preview image left display"
          src="/icons/creationprocess/bloglist/image-left.svg"
          iconCssClass={
            selectedLinkFile.displayMode === ArticlePreviewLayout.BlogImageLeft ||
            selectedLinkFile.displayMode === HyperLinkLayout.BlogImageLeft ||
            selectedLinkFile.displayMode === ChannelPreviewLayout.Blog
              ? 'color-secondary-800-svg'
              : 'color-secondary-300-svg'
          }
          expandWidth={125}
          label="blog style preview image left display"
          onClick={e => {
            const updatedSlide = {
              ...state.slide,
              data: {
                ...state.slide.data,
                files: state.slide.data.files.map((linkFile: LinkSlideFile, i: number) => {
                  if (state.selectedItemIndex === i) {
                    return {
                      ...linkFile,
                      displayMode: linkFile.type === 'GIDE' 
                        ? ArticlePreviewLayout.BlogImageLeft 
                        : linkFile.type === 'CHANNEL'
                          ? ChannelPreviewLayout.Blog 
                          : HyperLinkLayout.BlogImageLeft,
                    };
                  }
                  return linkFile;
                }),
              },
            };
            setState({ ...state, slide: updatedSlide });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={(selectedLinkFile.type === 'GIDE' || selectedLinkFile.type === 'URL' || selectedLinkFile.type === 'CHANNEL')}
        />
        {/* Style Text-Only */}
        <ExpandingIconButton
          style={{
            marginLeft: '9px',
            height: '32px',
          }}
          alt="text only hyperlink preview image top display"
          src="/icons/creationprocess/text-only.svg"
          iconCssClass={selectedLinkFile.displayMode === HyperLinkLayout.HyperLink || selectedLinkFile.displayMode === ChannelPreviewLayout.Normal 
            ? 'color-secondary-800-svg'
            : 'color-secondary-300-svg'
          }
          expandWidth={125}
          label="text only hyperlink preview image top display"
          onClick={e => {
            const updatedSlide = {
              ...state.slide,
              data: {
                ...state.slide.data,
                files: state.slide.data.files.map((linkFile: LinkSlideFile, i: number) => {
                  if (state.selectedItemIndex === i) {
                    return {
                      ...linkFile,
                      displayMode: selectedLinkFile.type === 'URL' ? HyperLinkLayout.HyperLink : ChannelPreviewLayout.Normal,
                    };
                  }
                  return linkFile;
                }),
              },
            };
            setState({ ...state, slide: updatedSlide });
          }}
          labelLocation={HorizontalAlignment.Left}
          expanded={false}
          visible={selectedLinkFile.type === 'URL' || selectedLinkFile.type === 'CHANNEL'}
        />
        {/* Delete */}
        {(state.slide.data.files.length > 1) &&
          <ExpandingIconButton
            style={{
              marginLeft: '12px',
              marginRight: '7px',
              height: '32px',
            }}
            alt="Delete"
            src="/icons/content-alteration/delete.svg"
            expandWidth={80}
            label="Delete"
            onClick={e => {
              setState({ ...state,mode: CommonModes.Delete });
            }}
            labelLocation={HorizontalAlignment.Left}
            expanded={false}
            visible={true}
          />
        }
      </div>
    </>
  );

  const headerActions = 
    isImageSlide ? imageHeaderActions :
    isVideoSlide ? videoHeaderActions :
    isLinkSlide ? linkHeaderActions :
        "ERROR";

  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Light,
    hideFooter: false,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: state.selectedItemIndex,
    hideActionContainer: false,
    showBackNavigation: false,
    showDefaultActions: true,
    createActions: getCreateActions(context),
    headerActions,
    onNavigateBack: () => {
      setState({ ...state, mode: CommonModes.New });
    },
    previewItems: getPreviewItems(state.selectedItemIndex, context),
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps): React.ReactNode => {
  const { props, state, setState, isImageSlide, isVideoSlide, isLinkSlide } = context;
  
  if (isImageSlide) {
    const moveNext = () => {
      const nextIndex = state.selectedItemIndex === state.slide.data.files.length - 1
        ? 0
        : state.selectedItemIndex + 1;

      setState({ ...state, selectedItemIndex: nextIndex });
    }
    const movePrevious = () => {
      const previousIndex = state.selectedItemIndex === 0
        ? state.slide.data.files.length - 1
        : state.selectedItemIndex - 1;

      setState({...state, selectedItemIndex: previousIndex });
    }
    return (
      <div className={`imageEditorSelectedImageOuterContainer${state.slide.data.files[state.selectedItemIndex].type === "LINK" ? ' linkImage' : ''}`}>
        <Swipeable
          className="swipeableContainer flexColumnFull"
          onSwipedLeft={moveNext}
          onSwipedRight={movePrevious}
        >
          <ImageView
            imageFile={state.slide.data.files[state.selectedItemIndex]}
            isReferenceType={state.slide.data.files[state.selectedItemIndex].type === 'SLIDEFILE'}
            className="containImage"
          />
        </Swipeable>
      </div>
    );
  } else if (isVideoSlide) {
    return (
      <div className="videoEditorSelectedVideoOuterContainer">  
        <VideoViewer
          videoFile={state.slide.data.files[state.selectedItemIndex]}
          className="containVideo"
        />
      </div>
    );
  } else if (isLinkSlide) {
    const type = state.slide.data.files[state.selectedItemIndex].type;
    return (
      <div className="linkSlideEditorContainer">
        <div className="linkSlideEditorEditContainer">
          <LinkView
            linkFile={state.slide.data.files[state.selectedItemIndex]}
            isReferenceType={type === 'SLIDE'}
            currentUser={props.currentUser}
            view={''}
            viewMode={'SLIDE'}
            overrideWidth={state.slide.width}
          />
        </div>
      </div>
    );
  } else {
    return "ERROR";
  }
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: context.isLinkSlide ? lightTheme : darkTheme,
  };
};

export const Edit = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
