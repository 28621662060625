import React from 'react';
import styles from './_gide-list-item.module.scss';
import icons from '../../../../../assets/icons';
import PreviewAvatar from '../../PreviewAvatar/PreviewAvatar';
import classNames from 'classnames';
import { storyTellingImage } from '../../../../../utils/helperFunctions';
import GideImage from '../../../Image/GideImage';

export interface GideListItemPreviewProps {
  image: string;
  title: string;
  authorImage: string;
  author: string;
  createdAt: Date;
  updatedAt: Date;
  slideCount: number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  selected?: boolean;
  selectionMode: ItemSelectionMode;
}

export enum ItemSelectionMode {
  SingleDisplayNone = 1,
  SingleDisplayRadioButton = 2,
  MultipleDisplayCheckBox = 3
}
export default function GideListItemPreview(props: GideListItemPreviewProps) {
  const createDate = `${props.createdAt.toLocaleDateString('en-US', { month: 'short' })} '${props.createdAt.toLocaleDateString('en-US', {
    year: '2-digit',
  })}`;
  const title = props.title || 'Untitled';
  return (
    <div
      className={classNames(styles.gideListItemPreviewContainer, props.className, props.onClick ? styles.clickable : '')}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.onClick) {
          props.onClick(event);
        }
      }}
    >
      <div className={styles.gideListItemImage}>
        <GideImage src={props.image || storyTellingImage} alt={title} width="76" height="54" hideUploadingIndicator />
      </div>
      <div className={styles.gideListItemPreviewDetails}>
        <div className={`${styles.gideListItemTitle}`}>{title}</div>
        <div className={styles.gideListItemGideDetails}>
          <div className={styles.gideListPreviewAvatar}>
            <PreviewAvatar disabled={true} authorImage={props.authorImage} author={props.author} />
          </div>
          <div className={styles.gideInfo}>
            <div className={styles.gideInfoDetail}>{props.slideCount} slides</div>
            <icons.CreationProcess_Events width={12} height={12} className={styles.calendarImage} />
            <div className={styles.gideInfoDetail}>{createDate}</div>
          </div>
        </div>
      </div>
      {props.selectionMode === ItemSelectionMode.MultipleDisplayCheckBox && (
          <>
          {props.selected && (
            <div className={styles.gideSelection}>
              <icons.ContentAlteration_Checkbox_CheckboxActive color='var(--COLOR-PRIMARY-600)'/>
            </div>
          )}
          {!props.selected && (
            <div className={styles.gideSelection}>
              <icons.ContentAlteration_Checkbox_CheckboxDeactive/>
            </div>
          )}
          </>
        )}
        {props.selectionMode === ItemSelectionMode.SingleDisplayRadioButton && (
          <>
          {props.selected && (
            <div className={styles.gideSelection}>
              <icons.ContentAlteration_Radio_Active color="var(--COLOR-PRIMARY-600)"/>
            </div>
          )}
          {!props.selected && (
            <div className={styles.gideSelection}>
              <icons.ContentAlteration_Radio_Deactive/>
            </div>
          )}
          </>
        )}
      <icons.Nav_Logo_LogoIconSm className={styles.logo} />
      <div className={styles.logoBackground} />
    </div>
  );
}
