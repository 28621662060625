import React, { Component } from 'react';
import SliderComponent from '@material-ui/core/Slider';
import { formatMinuteAndSeconds } from '../../../utils/helperFunctions';

export interface TimeRangeSliderProps {
  sliderMin: number;
  sliderMax: number;
  rangeMin: number;
  rangeMax: number;
  sliderValue: number;
  onUpdateRange: (rangeMin: number, rangeMax: number) => void;
  onTimeSliderChange: (timeValue: number) => void;
  // onSliderCreated: () => void;
}

export class TimeRangeSlider extends Component<TimeRangeSliderProps> {

  onTimeTooltipChangeHandler = (args: any) => {
    if (args.value) {
      args.text = formatMinuteAndSeconds(args.value as number);
    }
  };

  onTimeRangeSliderChange = (rangeValue: number[]) => {
    const rangeMin = (rangeValue as number[])[0];
    const rangeMax = (rangeValue as number[])[1];
    this.props.onUpdateRange(rangeMin, rangeMax);
  };

  onTimeSliderChange = (value: number) => {
    if (value !== this.props.sliderValue) {
      this.props.onTimeSliderChange(value);
    }
  };

  // onRangeTooltipChangeHandler = (rangeValue: number[]) => {
  //   const rangeMin = (rangeValue as number[])[0];
  //   const rangeMax = (rangeValue as number[])[1];

  //   const min = formatMinuteAndSeconds(rangeMin);
  //   const max = formatMinuteAndSeconds(rangeMax);
  //   // Assigning our custom text to the tooltip value.
  //   args.text = min + ' - ' + max;
  // };

  render() {
    return (
      <div
        style={{
          height: '70px',
          width: '100%',
          padding: '2%',
          backgroundColor: '#f1f3f4',
        }}
      >
        <div style={{ position: 'relative', margin: '0, 2%' }}>
          <SliderComponent
            id="range_slider"
            // type="Range"
            min={this.props.sliderMin}
            max={this.props.sliderMax}
            value={[this.props.rangeMin, this.props.rangeMax]}
            // tooltip={{
            //   cssClass: 'e-tooltip-slideshow-slide',
            //   placement: 'Before',
            //   isVisible: true,
            //   showOn: 'Always',
            // }}
            // tooltipChange={this.onRangeTooltipChangeHandler}
            // ticks={{
            //   placement: 'After',
            //   largeStep: 20,
            //   smallStep: 10,
            //   showSmallTicks: true,
            // }}
            step={10}
            marks={true}
            valueLabelDisplay="on"
            getAriaValueText={formatMinuteAndSeconds}            
            onChange={(event, value) => this.onTimeRangeSliderChange(value as number[])}
          />
          <SliderComponent
            id="slide_slider"
            min={this.props.sliderMin}
            max={this.props.sliderMax}
            value={[this.props.sliderValue, this.props.sliderValue]}
            // tooltip={{
            //   cssClass: 'e-tooltip-slideshow-slide',
            //   placement: 'Before',
            //   isVisible: true,
            // }}
            // tooltipChange={this.onTimeTooltipChangeHandler}
            // limits={{
            //   enabled: true,
            //   minStart: this.props.rangeMin,
            //   minEnd: this.props.rangeMax,
            // }}    
            getAriaValueText={formatMinuteAndSeconds}        
            onChange={(event, value) => this.onTimeSliderChange(value as number)}
          />
        </div>
      </div>
    );
  }
}

export interface DurationProps {
  seconds: number;
}

export const Duration = (props: DurationProps) => {
  const { seconds } = props;
  return (
    <time dateTime={`P${Math.round(seconds)}S`}>
      {formatMinuteAndSeconds(seconds)}
    </time>
  );
};
