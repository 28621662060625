import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Input,
  Checkbox,
  Loader,
  Image,
  Menu,
  Button,
  Icon,
} from 'semantic-ui-react';

import SlideModalActions from './SlideModalActions';
import SlideSettings from '../SlideEditors/SlideSettings/SlideSettings';
import { getSlideSettings, updateSlideSettings } from '../SlideEditors/SlideSettings/SlideSettings';

import agent from '../../agent';
import MapWithAMarker from '../maps/MapWithAMarker';
import {
  ADD_SLIDE,
  REPLACE_SLIDE,
  MODAL_CLOSE,
  SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES,
} from '../../constants/actionTypes';

import { GOOGLE_MAPS_URL } from '../../constants/paths';

import { contentMetaData } from '../../constants/contentMetaData';

import slideTools from '../../slideTools';
import { closeModalForType } from '../../utils/helperFunctions';
import { history } from '../../store';
import { v4 } from 'uuid';

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  onSubmit: (replaceSlide, payload) =>
    dispatch({ type: replaceSlide ? REPLACE_SLIDE : ADD_SLIDE, payload }),
  updateSlideAttachmentInfo: payload =>
    dispatch({ type: SLIDE_UPDATE_CHILD_ARTICLE_SLIDE_TYPES, payload }),
});

const SPOOF_LOCATION = false;

export class LocationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slide: {
        slideType: 'LOCATION',
        data: {
          audioCaption: null,
          title: '',
          lat: 32.776475,
          long: -79.931051,
          showMarker: true,
          mapTypeId: 'roadmap',
        },
      },
      locationChooserType: props.editSlide ? 'MAP' : '',
      zip: '',
      fetchingGPS: false,
      showSettings: false,
      replaceMode: false,
    };

    // Populates the new slide with the settings from the parent slide which is this.props.slide
    if (props.mode === 'REPLACE' && props.slide) {
      this.state.replaceMode = true;
      const inputSlide = props.slides.find(s => s.id === props.slide);
      if (inputSlide) {
        const settings = getSlideSettings(inputSlide);
        this.state.slide = updateSlideSettings(this.state.slide, settings);
      }
    }

    if (props.editSlide) {
      this.state.slide = props.editSlide;
    }

    this.updateSlideSettings = settings => {
      this.setState({ slide: updateSlideSettings(this.state.slide, settings) });
    };

    this.closeSettings = () => {
      this.setState({ showSettings: false });
    };

    this.audioCaptionChanged = audioFile => {
      const slide = { ...this.state.slide, data: { ...this.state.slide.data } };
      slide.data.audioCaption = audioFile;
      this.setState({ slide });
    };

    this.updateState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide[field] = ev.target.value;
      this.setState({ slide });
    };

    this.updateDataState = field => ev => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = ev.target.value;
      this.setState({ slide });
    };

    this.setLocationChooserType = field => ev => {
      this.setState({ locationChooserType: field });
      if (field === 'CURRENT') {
        const slide = Object.assign({}, this.state.slide);
        slide.lat = '';
        slide.long = '';
        this.setState({
          slide,
        });
        if (navigator.geolocation.getCurrentPosition) {
          this.setState({ fetchingGPS: true });
          let self = this;
          navigator.geolocation.getCurrentPosition(function(position) {
            let lat, lng;
            if (SPOOF_LOCATION) {
              lat = 37.752523;
              lng = -122.419544;
            } else {
              lat = position.coords.latitude;
              lng = position.coords.longitude;
            }
            const slide = Object.assign({}, self.state.slide);
            slide.data.title = 'My Location';
            slide.data.lat = lat;
            slide.data.long = lng;
            self.setState({ slide, fetchingGPS: false });
          });
        } else {
          this.setState({ fetchingGPS: false });
          alert(
            'Sorry, geolocation does not seem to be supported by your browser.',
          );
        }
      }
    };

    this.updateDataStateCheckbox = field => (ev, data) => {
      const slide = Object.assign({}, this.state.slide);
      slide.data[field] = data.checked;
      this.setState({ slide });
    };

    this.createSlide = async () => {
      let slidePosition = this.props.currentSlidePosition ? this.props.currentSlidePosition : this.props.position;
      let slide = {
        ...this.state.slide,
        slide: this.props.slide,
        createMode: this.props.mode,
        selection: props.selection,
        allowComments: this.props.editSlide
          ? this.state.slide.allowComments
          : this.props.article.allowSlideComments,
        allowQuestions: this.props.editSlide
          ? this.state.slide.allowQuestions
          : this.props.article.allowSlideQuestions,
        // If editing, don't change the position.
        position: this.props.editSlide
          ? this.state.slide.position
          : slidePosition,
      };
      if (this._map) {
        slide.data.zoom = this._map.getZoom();
      }
      // Removing client side mapId that doesn't need to be saved, see comment below
      const updatedSlide = {
        ...slide,
        data: {
          ...slide.data,
          mapId: undefined,
        }
      }
      let payload;
      if (this.state.replaceMode) {
        let replaceSlideId = updatedSlide.slide;
        updatedSlide.slide = null;
        payload = await agent.Slides.replace(
          this.props.article,
          replaceSlideId,
          updatedSlide,
        );
        payload = {
          ...payload,
          slideIdToRemove: replaceSlideId,
        };
      } else {
        if (this.props.editSlide) {
          payload = await agent.Slides.update(this.props.editSlide.id, updatedSlide);
        } else {
          payload = await agent.Slides.create(this.props.article, updatedSlide);
          if (this.props.childArticleEditInfo) {
            slideTools.getSlideAttachmentInfo(
              this.props.childArticleEditInfo.ownerSlide.id,
              this.props.updateSlideAttachmentInfo,
            );
          }
        }
      }
      // The mapId is used on the client for the google maps to refresh properly
      // Anytime that the map options are modified, then a new key needs to be generated.
      // This key serves no other purpose, so it doesn't need to be saved to the server.
      const updatedPayload = {
        ...payload,
        mode: this.props.mode,
        slide: {
          ...payload.slide,
          data: {
            ...payload.slide.data,
            mapId: v4()
          }
        }
      }
      this.props.onSubmitSlide ?
      this.props.onSubmitSlide(updatedPayload) :
      this.props.onSubmit(this.state.replaceMode, updatedPayload);
      history.goBack();
    };
  }

  onDrag() {
    const slide = Object.assign({}, this.state.slide);
    slide.data.lat = this._map.getCenter().lat();
    slide.data.long = this._map.getCenter().lng();
    this.setState({
      slide,
    });
  }

  render() {
    const { slide } = this.state;
    return (
      <Modal
        closeOnEscape={true}
        onClose={() => history.goBack()}
        className="locationModal"
        size="small"
        dimmer="inverted"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <div
            className="modalHeader"
            style={{ background: 'rgb(31, 142, 117)' }}
          >
            <Button id="modalClose" icon onClick={() => history.goBack()}>
              <Icon name="close" />
            </Button>
            <span>GeoTag Type</span>
            <Image src="/images/slide-icons/icon-and-circle/SVGs/Icon-and-circle-location.svg" />
          </div>
          {!this.state.showSettings && (
            <Modal.Description style={{ background: 'rgb(31, 142, 117)' }}>
              {this.state.locationChooserType === '' && (
                <Menu fluid vertical style={{ margin: '0 0 0 0' }}>
                  <Menu.Item onClick={this.setLocationChooserType('MAP')}>
                    Find On Map
                  </Menu.Item>
                  <Menu.Item onClick={this.setLocationChooserType('CURRENT')}>
                    Device Location
                  </Menu.Item>
                  <Menu.Item onClick={this.setLocationChooserType('ADDRESS')}>
                    Type Address
                  </Menu.Item>
                  <Menu.Item onClick={this.setLocationChooserType('GPS')}>
                    Type coordinates
                  </Menu.Item>
                </Menu>
              )}
              {this.state.locationChooserType === 'MAP' && (
                <div>
                  <MapWithAMarker
                    mapId={slide.data.mapId}
                    onMapTypeIdChanged={() => {
                      slide.data.mapTypeId = this._map.getMapTypeId();
                    }}
                    googleMapURL={GOOGLE_MAPS_URL}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    lat={slide.data.lat}
                    lng={slide.data.long}
                    showMarker={slide.data.showMarker}
                    onDrag={this.onDrag.bind(this)}
                    refMap={el => (this._map = el)}
                    refMarker={el => (this._marker = el)}
                    zoom={slide.data.zoom}
                    defaultMapTypeId={slide.data.mapTypeId ? slide.data.mapTypeId : 'roadmap'}
                    options={{gestureHandling: 'auto'}}
                  />
                  <div>
                    <Input
                      fluid
                      placeholder="Title"
                      value={slide.data.title}
                      onChange={this.updateDataState('title')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Latitude"
                      value={slide.data.lat}
                      onChange={this.updateDataState('lat')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Longitude"
                      value={slide.data.long}
                      onChange={this.updateDataState('long')}
                    />
                  </div>
                </div>
              )}
              {this.state.locationChooserType === 'GPS' && (
                <div>
                  <div>
                    <Input
                      fluid
                      placeholder="Title"
                      value={slide.data.title}
                      onChange={this.updateDataState('title')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Latitude"
                      value={slide.data.lat}
                      onChange={this.updateDataState('lat')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Longitude"
                      value={slide.data.long}
                      onChange={this.updateDataState('long')}
                    />
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <Button
                      content="Autofill from current"
                      onClick={this.setLocationChooserType('CURRENT')}
                    />
                  </div>
                </div>
              )}
              {this.state.locationChooserType === 'ADDRESS' && (
                <div>
                  <div>
                    <Input
                      fluid
                      placeholder="Title"
                      value={slide.data.title}
                      onChange={this.updateDataState('title')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Street Address 1"
                      value={slide.data.line1}
                      onChange={this.updateDataState('line1')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Street Address Line 2"
                      value={slide.data.line2}
                      onChange={this.updateDataState('line2')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="City"
                      value={slide.data.city}
                      onChange={this.updateDataState('city')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="State"
                      value={slide.data.state}
                      onChange={this.updateDataState('state')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Zip"
                      value={slide.data.zip}
                      onChange={this.updateDataState('zip')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Country"
                      value={slide.data.country}
                      onChange={this.updateDataState('country')}
                    />
                  </div>
                </div>
              )}
              {this.state.locationChooserType === 'CURRENT' && (
                <div>
                  {this.state.fetchingGPS && (
                    <div>
                      <p>Geolocating you...</p>
                      <div style={{ paddingTop: '50px' }}>
                        <Loader active inline="centered" />
                      </div>
                    </div>
                  )}
                  <div>
                    <Input
                      fluid
                      placeholder="Title"
                      value={slide.data.title}
                      onChange={this.updateDataState('title')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Latitude"
                      value={slide.data.lat}
                      onChange={this.updateDataState('lat')}
                    />
                  </div>
                  <div>
                    <Input
                      fluid
                      placeholder="Longitude"
                      value={slide.data.long}
                      onChange={this.updateDataState('long')}
                    />
                  </div>
                  <Checkbox
                    label="Show marker?"
                    onChange={this.updateDataStateCheckbox('showMarker')}
                    checked={slide.data.showMarker}
                  />
                </div>
              )}
            </Modal.Description>
          )}
          {this.state.showSettings && (
            <Modal.Description className="settingsPanel">
              <SlideSettings
                canSetIsTemplate={this.props.article.type === 'TEMPLATE'}
                settings={getSlideSettings(
                  this.state.slide,
                  this.props.article.type,
                )}
                onSettingsChanged={this.updateSlideSettings.bind(this)}
                onCloseSettings={this.closeSettings.bind(this)}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        {this.state.locationChooserType !== '' && (
          <Modal.Actions
            style={{ background: contentMetaData['LOCATION'].primaryColor }}
          >
            <SlideModalActions
              caption={slide.data.caption}
              captionChanged={this.updateDataState('caption').bind(this)}
              audioCaption={slide.data.audioCaption}
              onAudioCaptionChanged={this.audioCaptionChanged.bind(this)}
              showSettings={this.state.showSettings}
              canNavigateBack={true}
              backClicked={() => this.setLocationChooserType('')}
              settingsClicked={() =>
                this.setState({ showSettings: !this.state.showSettings })
              }
              nextClicked={this.createSlide}
              settings={getSlideSettings(
                this.state.slide,
                this.props.article.type,
              )}
              onSettingsChanged={this.updateSlideSettings.bind(this)}
            />
          </Modal.Actions>
        )}
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationModal);
