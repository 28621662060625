import React, { Component, ReactNode } from 'react';
import { Slide } from '../../../models/Slide';
import SlideView from '../../Gide/SlideView/SlideView';
import { User } from '../../../models/User';
import { ToasterMessageInfo } from '../../../models/ToasterMessageInfo';

import { contains } from 'ramda';
import GideChooser from '../GideChooser/GideChooser';
import { ItemSelectionMode } from '../Previews/GidePreview/GideListItemPreview/GideListItemPreview';
import { Agent } from '../../../models/Agent';
import classNames from 'classnames';
import { GideSearchElement } from '../GideElementSearch/GideElementSearch';

export interface SlideChooserProps {
  currentUser: User;
  slides?: Slide[];
  slideSelectionLabel: string;
  slideSelectionIcon?: ReactNode;
  slideTypes?: string[];
  selectionLocation?: string;
  agent: Agent;
  showNotification: (toasterMessageInfo: ToasterMessageInfo) => void;
  onSelectSlide: (slideSelectionInfo: SlideSelection) => void;
}
export interface SlideSelection {
  slide: Slide;
  articleSummary: GideSearchElement;
}
export interface SlideChooserState {
  slides?: Slide[];
  selectedArticle?: GideSearchElement;
}

export default class SlideChooser extends Component<SlideChooserProps, SlideChooserState> {
  constructor(props: SlideChooserProps) {
    super(props);

    this.state = {
      slides: undefined,
    }
  }
  async loadArticleSlides(articleSummary: GideSearchElement) {
    const response: { slides: Slide[] } =
      await this.props.agent.Slides.forArticle({ id: articleSummary.id });
    this.setState({ selectedArticle: articleSummary, slides: response.slides });
  }
  public render() {
    const slides = this.state.slides ? this.state.slides : this.props.slides ? this.props.slides : undefined;
    
    return (
      <div className="slideChooserContainer">
        {!slides && (
          <GideChooser
            agent={this.props.agent}
            onSelectGide={(gideSummary: GideSearchElement) => {
              this.loadArticleSlides(gideSummary);
            }}
            selectionMode={ItemSelectionMode.SingleDisplayNone}
          />
        )}
        {slides && (
          <div className="slideChooserSlides">
            {slides.filter(s => s.slideType !== 'COLUMN' && this.props.slideTypes ? contains(s.slideType, this.props.slideTypes) : true).map((slide: Slide, i) => (
              <div className="slideChooserSlide"
                key={i}
              >
                <SlideView
                  slide={slide}
                  currentUser={this.props.currentUser}
                  disableInlineTextEditing={true}
                  slidePosition={slide.position}
                  showAuthorHeader={false}
                  view={'SCROLL'}
                  viewMode='SCROLL'
                  collapsed={false}
                />
                <div className={classNames('slideSelectionFooter', this.props.selectionLocation)}
                  onClick={() => this.props.onSelectSlide({slide: slide, articleSummary: (this.state.selectedArticle as GideSearchElement)})}
                >
                  <div className="selector">
                    <span style={{ marginRight: '5px' }}>{this.props.slideSelectionLabel}</span>
                    {this.props.slideSelectionIcon && (
                      this.props.slideSelectionIcon
                    )}
                  </div>
                  <span className="slideSelectorSlideNumber" style={{ marginRight: '5px' }}>
                    {slide.position + 1}
                  </span>

                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}
