import React, { CSSProperties, useState, useCallback, useEffect, useRef } from 'react';
import { useResizedImageUrl, isSrcResizable } from '../../../utils/useResizedImageUrl';
import { storyTellingImage } from '../../../utils/helperFunctions';
import Popper from '@material-ui/core/Popper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Loading } from '../Loading/Loading';

export interface  GideImageProps {
  src: string;
  alt?: string;
  className?: string;
  width?: string;
  height?: string;
  style?: CSSProperties;
  hideUploadingIndicator?: boolean;
  onClick?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

export const GideImage = (props:  GideImageProps) => {
  const options = {
    width: props.width,
    height: props.height,
  };

  const srcResized = useResizedImageUrl(props.src, options);
  const srcResizedFallback = useResizedImageUrl(storyTellingImage, options);
  const srcResized320 = useResizedImageUrl(props.src, { width: '320px' });
  const srcResized640 = useResizedImageUrl(props.src, { width: '640px' });
  const srcResized960 = useResizedImageUrl(props.src, { width: '960px' });
  const srcResized1280 = useResizedImageUrl(props.src, { width: '1280px' });
  const srcResized2560 = useResizedImageUrl(props.src, { width: '2560px' });

  const [src, setSrc] = useState(srcResized || srcResizedFallback);
  const onError = useCallback(() => {
    setSrc(srcResizedFallback);
  }, [srcResizedFallback]);

  const onClick = useCallback((event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    props.onClick && props.onClick(event);
  }, [props.onClick]);

  const isUploadingInBackground = !!src && src.startsWith('blob:');

  const imageRef = useRef<HTMLImageElement>(null);
  
  useEffect(() => {
    setSrc(srcResized || srcResizedFallback);
  }, [props.src]);

  let srcSet: string | undefined = undefined; 
  if (!props.width && !props.height && isSrcResizable(props.src)) {
    srcSet = [
      `${srcResized320} 320w`,
      `${srcResized640} 640w`,
      `${srcResized960} 960w`,
      `${srcResized1280} 1280w`,
      `${srcResized2560} 2560w`,
    ].join(', ');
  }

  return (
      <>
        <img src={src}
          srcSet={srcSet}
          style={props.style}
          alt={props.alt}
          width={props.width}
          height={props.height}
          className={props.className}
          onClick={onClick}
          onError={onError}
          onMouseUp={props.onMouseUp}
          onMouseDown={props.onMouseDown}
          ref={imageRef}
        />
        {imageRef.current &&
          <Popper          
            anchorEl={imageRef.current}
            open={isUploadingInBackground && !props.hideUploadingIndicator}
            placement="right-end"
            popperOptions={{
              modifiers: {
                offset: {
                  enabled: true,
                  offset: "0, -30",
                },
                flip: {
                  enabled: false,
                },
              },
            }}
          >
            <Loading />
          </Popper>
        }
      </>
  );
}

export default GideImage;
