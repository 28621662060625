import React, { useState, useRef, useLayoutEffect, CSSProperties, useEffect } from 'react';
import styles from './_input-bar-editor.module.scss';
import { hasValue } from '../../../../utils/helperFunctions';
import classNames from 'classnames';
import { withTheme } from 'styled-components';
export interface InputBarEditorProps {
  inputBarValue?: string;
  placeholderText?: string;
  style?: CSSProperties;
  displayElipsisOnLostFocus: boolean;
  focusInputBar?: any;
  blurInputBar?: any;
  theme?: any;
  disabled?: boolean;
  onChange: (value: string) => void;
  onAcceptInput: (value: string) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void;
  onInputEditorFocusChanged: (isFocused: boolean) => void;  
  // actions?: () => JSX.Element;
  children?: React.ReactNode;
}

function InputBarEditor(props: InputBarEditorProps) {
  const [inputBarEditorFocused, setInputBarEditorFocused] = useState<boolean>(false);  
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const textEditor = useRef<HTMLTextAreaElement>(null);


  const onInputBarFocused = (isFocused: boolean) => {
    setInputBarEditorFocused(isFocused);
    props.onInputEditorFocusChanged(isFocused);
  };
  /**
   * Adusts the input bar height based on the scroll height.
   * Stops at a max height of
   */
  useLayoutEffect(() => {
    // Use the textarea's scroll height to allow it to grow on long string or multiple lines.    
    if (textEditor.current) {            
      const overflowing = textEditor.current.scrollHeight > 24;
      textEditor.current.style.height = "24px";
      if(inputBarEditorFocused || props.displayElipsisOnLostFocus === false) {
        textEditor.current.style.height = `${textEditor.current.scrollHeight}px`;
        textEditor.current.style.overflow = 'inherit';
        setIsOverflowing(false);
      } else {
        textEditor.current.style.overflow = 'hidden';
        textEditor.current.scrollTop = 0;
        if(overflowing !== isOverflowing) {
          setIsOverflowing(overflowing);
        }
      }
    }
  });

  useEffect(() => {
    if(props.focusInputBar && textEditor.current) {
      textEditor.current.focus();
    }
  }, [props.focusInputBar]);

  useEffect(() => {
    if(props.blurInputBar && textEditor.current) {
      textEditor.current.blur();
    }
  }, [props.blurInputBar])
  
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(event.target.value ? event.target.value : '');    
  }

  return (
    <div className={classNames(styles.inputBarEditorContainer, props.theme.mode === 'dark' ? styles.dark : undefined)} style={props.style}>
      <div className={classNames(styles.inputBarInnerBorder, props.theme.mode === 'dark' ? styles.dark : undefined)}>
        {/* <icons.ContentAlteration_Emoji
          color='var(--COLOR-SECONDARY-500)'
          style={{marginLeft: '3px', marginBottom: '5px'}}
        /> */}
        <textarea    
          onPaste={props.onPaste}
          ref={textEditor}
          placeholder={
            props.placeholderText
          }
          disabled={props.disabled}
          value={props.inputBarValue}
          onFocus={() => onInputBarFocused(true)}
          onBlur={() => onInputBarFocused(false)}
          onKeyDown={(e: any) => {
            if (e.keyCode === 13 && e.ctrlKey === false && e.shiftKey === false) {
              // if (hasValue(props.inputBarValue)) {
                props.onAcceptInput(props.inputBarValue ? props.inputBarValue : '');
              // }
              // Want to prevent the default behavior so the enter key
              // does not add a newline in his case.
              e.preventDefault();
              e.target.focus();
            } else if (e.keyCode === 13 && (e.ctrlKey || e.shiftKey)) {
              // Want to add a new line character here
              if (!e.shiftKey && hasValue(props.inputBarValue)) {
                props.onChange(props.inputBarValue + '\n');
              }
            }
          }}
          onChange={onChange}
        />
        <div className={classNames(styles.ellipsis, isOverflowing ? styles.showEllipsis : undefined, props.theme.mode === 'dark' ? styles.dark : undefined)}>...</div>
        {/* {props.actions && (
          <props.actions />
        )} */}
        {props.children && props.children}
      </div>
    </div>
  );
}
export default withTheme(InputBarEditor);
