import React, { Component } from 'react';
import { hasValue, extractHostname, getSlideFileUrl, getImageMeta } from '../../../../utils/helperFunctions';
import AudioPlayer, { AudioPlayerType } from '../../../media/AudioPlayer/AudioPlayer';
import { ImageFile } from '../../../../models/SlideFile';
import classNames from 'classnames';
import styles from './_image-carousel.module.scss';
import GideImage from '../../../Shared/Image/GideImage';
export interface ImageDetail {
  image: ImageFile;
  style: any;
  imageIndex: number;
}

export enum CarouselNavigationMode {
  Thumbnail = 1,
  RotatingArrows = 2,
  RotatingBullets = 3,
  RotatingArrowsAndBullets = 4,
}

export interface ImageCarouselProps {
  imageList: ImageFile[];
  style?: any;
  maxResponsiveHeight: number;
  maxWidescreenHeight: number;
  preventHeightCalculation?: boolean;
  respectEdge: boolean;
  carouselNavigationMode: CarouselNavigationMode,
  carouselAdvanceSpeed: number;
  onClick?: () => void;
}


export interface CarouselHeightSettings {
  maxResponsiveHeight: number;
  maxWidescreenHeight: number;
  calculatedMaxCarouselHeight: number;
}
export interface ImageCarouselState {
  selectedImageIndex: number;
  // width: number;
  // height: number;
  maxCarouselHeight: number;
  carouselHeightSettings?: CarouselHeightSettings;
  captionHeight: number;
}

export class ImageCarousel extends Component<ImageCarouselProps, ImageCarouselState> {
  constructor(props: ImageCarouselProps) {
    super(props);

    this.state = {
      selectedImageIndex: 0,
      maxCarouselHeight: window.innerWidth <= 520 ? 300 : 400,
      captionHeight: 0,
    }
  }
  private async updateWindowDimensions(): Promise<void> {
    if (this.state.carouselHeightSettings) {
      if (this.state.maxCarouselHeight > this.props.maxResponsiveHeight && window.innerWidth <= 520) {
        this.setState({
          maxCarouselHeight: this.state.carouselHeightSettings.maxResponsiveHeight
        });
        // TODO: I don't understand why this is here. Seems completely wrong
      // } else if (this.state.maxCarouselHeight > this.props.maxResponsiveHeight && window.innerWidth <= 752) {
      //   this.setState({
      //     maxCarouselHeight: 400
      //   });
      } else if (this.state.maxCarouselHeight < this.state.carouselHeightSettings.maxWidescreenHeight && window.innerWidth > 520) {
        this.setState({
          maxCarouselHeight: this.state.carouselHeightSettings.maxWidescreenHeight
        });
      }
    }
  }
  async componentDidMount() {
    const carouselHeightSettings = await this.getmaxCarouselHeight(this.props.imageList);
    this.setState({
      maxCarouselHeight: window.innerWidth <= 520
        ? carouselHeightSettings.maxResponsiveHeight
        : carouselHeightSettings.maxWidescreenHeight,
      carouselHeightSettings
    });
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));

    if (this.props.carouselNavigationMode !== CarouselNavigationMode.Thumbnail && this.props.carouselAdvanceSpeed > 0) {
      this.autoAdvanceCarousel();
    }
  }
  autoAdvanceCarousel() {
    window.setTimeout(() => {
      this.moveNext(undefined);
      if (this.props.carouselAdvanceSpeed > 0 && this.props.carouselNavigationMode !== CarouselNavigationMode.Thumbnail) { // User could have changed it
        this.autoAdvanceCarousel();
      }
    }, this.props.carouselAdvanceSpeed * 1000);
  }
  componentDidUpdate(prevProps: ImageCarouselProps, prevState: ImageCarouselState) {
    if (this.imageCaption && prevState.captionHeight !== this.imageCaption.clientHeight) {
      this.setState({ captionHeight: this.imageCaption.clientHeight })
    }
    if (prevState.carouselHeightSettings && this.props.maxWidescreenHeight !== prevProps.maxWidescreenHeight) {
      const carouselHeightSettings: CarouselHeightSettings = {
        ...prevState.carouselHeightSettings,
        maxWidescreenHeight: Math.min(prevState.carouselHeightSettings.calculatedMaxCarouselHeight, this.props.maxWidescreenHeight),
      }
      this.setState({
        carouselHeightSettings,
        maxCarouselHeight: window.innerWidth <= 520 ? 300 : window.innerWidth <= 752 ? 400 : carouselHeightSettings.maxWidescreenHeight,
      });
    }
    // Going from thumbnail to rotating. 
    if(prevProps.carouselNavigationMode === CarouselNavigationMode.Thumbnail && prevProps.carouselNavigationMode !== this.props.carouselNavigationMode && this.props.carouselAdvanceSpeed > 0) {
      this.autoAdvanceCarousel();
    }
    // Going from 0 in rotating mode (meaning you must advance it yourself) to > 0 which is auto advance
    if(prevProps.carouselNavigationMode !== CarouselNavigationMode.Thumbnail && this.props.carouselNavigationMode !== CarouselNavigationMode.Thumbnail && prevProps.carouselAdvanceSpeed < 1 && this.props.carouselAdvanceSpeed > 1) {
      this.autoAdvanceCarousel();
    }
    
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
  }
  // getImageMeta(url: string) {
  //   return new Promise((resolve, reject) => {
  //     const image = new Image();
  //     image.onload = () => resolve(image);
  //     image.onerror = reject;
  //     image.src = url;
  //   });
  // }
  private async getmaxCarouselHeight(imageList: ImageFile[]): Promise<CarouselHeightSettings> {
    let maxCarouselHeight = this.props.preventHeightCalculation ? this.props.maxResponsiveHeight : 0;
    if(!this.props.preventHeightCalculation) {
      for (let i = 0; i < imageList.length; i++) {
        const imageFile = imageList[i];
        const image: any = await getImageMeta(getSlideFileUrl(imageFile));
        if (image && image.height > maxCarouselHeight) {
          maxCarouselHeight = image.height;
        }
      }
    } 
    return {
      maxWidescreenHeight: Math.min(maxCarouselHeight, this.props.maxWidescreenHeight),
      maxResponsiveHeight: Math.min(maxCarouselHeight, this.props.maxResponsiveHeight),
      calculatedMaxCarouselHeight: maxCarouselHeight,
    };
  }
  movePrevious = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      selectedImageIndex: this.state.selectedImageIndex !== 0 ?
        this.state.selectedImageIndex - 1 : this.props.imageList.length - 1
    });
  }
  moveNext = (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({
      selectedImageIndex: this.state.selectedImageIndex !== this.props.imageList.length - 1 ?
        this.state.selectedImageIndex + 1 : 0
    });
  }

  imageCaption?: HTMLDivElement | null;
  public render() {
    const selectedImage = this.props.imageList[this.state.selectedImageIndex];
    const audioHeight = selectedImage.audioCaption ? 30 : 0;
    const attributionHeight = selectedImage.attribution ? 20 : 0;
    const linkAttributionHeight = selectedImage.type === "LINK" ? 20 : 0;
    let maxHeight = this.state.maxCarouselHeight - this.state.captionHeight - audioHeight - attributionHeight - linkAttributionHeight;
    // Workaround: For some reason when a new carousel is created it can shrink down below 300 (i.e. it is 150px right after saving sometimes)
    maxHeight = maxHeight >= 300 ? maxHeight : window.innerWidth <= 520 ? 300 : 400;
    const style = {
      "--navTop": `${(this.state.maxCarouselHeight / 2) - 20}px`,
      maxHeight: maxHeight,
      height: maxHeight,
      minHeight: maxHeight
    } as React.CSSProperties;

    return (
      <div className={styles.imageCarouselContainer}
      >
        <div className={`${styles.gideCarousel} ${(this.props.respectEdge && this.props.carouselNavigationMode !== CarouselNavigationMode.Thumbnail) ? styles.respectEdge : ''}`}
          onClick={() => {
            if (this.props.onClick) {
              this.props.onClick();
            }
          }}
          style={style}
        >
          <GideImage 
            className={styles.carouselImage}
            src={getSlideFileUrl(selectedImage)}
            alt={selectedImage.name ? selectedImage.name : 'carousel image'}
            style={{maxHeight, width: '100%'}}
          />
          {(this.props.carouselNavigationMode === CarouselNavigationMode.RotatingArrows ||
            this.props.carouselNavigationMode === CarouselNavigationMode.RotatingArrowsAndBullets) && (
              <>
                <div className={styles.leftNavigation}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    this.movePrevious(e);
                  }}
                >
                  <GideImage src="/icons/nav/arrow/arrow-left.svg"
                    className={`whites-normal-1000-svg ${styles.navIcon}`}
                    alt="navigate carousel left"
                    width="32px"
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => this.movePrevious(e)}
                  />
                </div>
                <div className={styles.rightNavigation}
                  onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                    this.movePrevious(e);
                  }}
                >
                  <GideImage src="/icons/nav/arrow/arrow-right.svg"
                    alt="navigate carousel right"
                    width="32px"
                    className={`whites-normal-1000-svg ${styles.navIcon}`}
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                      this.moveNext(e);
                    }}
                  />
                </div>
              </>
            )}
          {(this.props.carouselNavigationMode === CarouselNavigationMode.RotatingBullets ||
            this.props.carouselNavigationMode === CarouselNavigationMode.RotatingArrowsAndBullets) && (
              <div className={styles.bottomNavigation}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {this.props.imageList.map((image: ImageFile, index: number) => {
                  return (
                    // <div className={index === this.state.selectedImageIndex ? `${styles.imageListItemCircle} selected` : styles.imageListItemCircle}
                    <div className={classNames(styles.imageListItemCircle,  index === this.state.selectedImageIndex ? styles.selected : undefined)}
                      key={`carouselItem${index}`}
                      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.setState({ selectedImageIndex: index });
                      }}
                    ></div>
                  )
                })}
              </div>
            )}
        </div>
        {/* {hasValue(selectedImage.caption) && ( */}
        {selectedImage.type === 'LINK' && (
          <span className={`${styles.attributionCaption} TEXTSUBTITLEblackhigh-emphasisleft`}

          >
            From:
                  <span style={{ marginLeft: '5px' }} className="TEXTSUBTITLEblueleft">
              {extractHostname(getSlideFileUrl(selectedImage))}
            </span>
          </span>
        )}
        {hasValue(selectedImage.attribution) && (
          <span className={`${styles.attributionCaption} TEXTSUBTITLEblackhigh-emphasisleft`}

          >
            Photo by:
                  <span style={{ marginLeft: '5px' }} className="TEXTSUBTITLEblackboldlow-emphasisleft">
              {selectedImage.attribution}
            </span>
          </span>
        )}
        <div className={styles.imageCaption}
          ref={imageCaption => (this.imageCaption = imageCaption)}
        >
          {selectedImage.caption}
        </div>
        {selectedImage.audioCaption && (
          <AudioPlayer
            autoPlay={false}
            src={selectedImage.audioCaption.url}
            playerType={AudioPlayerType.AudioCaption}
            color={'blue'}
          />
        )}
        {/* )} */}
        {this.props.carouselNavigationMode === CarouselNavigationMode.Thumbnail && (
          <div className={styles.thumbnailContainer}>
            {this.props.imageList.map((image, i) => {
              return (
                <GideImage 
                  className={`${styles.thumbnailImage} ${i === this.state.selectedImageIndex ? ' active' : ''}`}
                  key={`thumbnail${i}`}
                  src={getSlideFileUrl(image)}
                  alt={image.name ? image.name : 'thumbnail'}
                  onClick={() => {
                    this.setState({ selectedImageIndex: i });
                  }}
                />
              );
            })}
          </div>
        )}

      </div>
    );
  }
}

export default ImageCarousel;
