import React from 'react';
import VideoList from '../Viewers/VideoViewers/VideoList/VideoList';
import { VideoViewerMode } from '../../models/CommonEnums';
import VideoSwipe from '../Viewers/VideoViewers/VideoSwipe/VideoSwipe';
import { isCordovaIOSDevice } from '../../utils/helperFunctions';


const VideoSlide = ({ slide, fileNum, viewMode }) => {
  if (viewMode === 'SWIPE') {
    const video = slide.data.files[fileNum || 0];
    return (
      <div>
        {video &&
          (video.type === 'UPLOAD' || video.type === 'RECORDED') && (
            <video controls className="video"
              preload="metadata"
            >
              <source src={isCordovaIOSDevice() && /blob:/.test(video.url) ? video.url : `${video.url}#t=0.5`} />
            </video>
          )}
        {video &&
          video.type === 'URL' && (
            <iframe
              title={video.url}
              src={video.url}
              frameBorder="0"
              allowFullScreen
              className="video"
            />
          )}
      </div>
    );
  } else {
    return (
      <div>
        {slide.data.files && slide.data.slideTypeSpecificSettings &&
          slide.data.slideTypeSpecificSettings.displayType === VideoViewerMode.Stacked && (
          <VideoList 
            videoList={slide.data.files}
          />
        )}
        {slide.data.files && slide.data.slideTypeSpecificSettings &&
          slide.data.slideTypeSpecificSettings.displayType === VideoViewerMode.Gallery && (
          <VideoSwipe
            videoList={slide.data.files}
          />
        )}
      </div>
    );
  }
};

export default VideoSlide;
