import React from 'react';
import { TimePicker as TimePickerMaterial } from '@material-ui/pickers/TimePicker';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

export interface TimePickerProps {
  value: ParsableDate;
  onChange: (value: any) => void;
}

export const TimePicker: React.FC<TimePickerProps> = (props) => {
  return (
    <TimePickerMaterial
      value={props.value}
      onChange={props.onChange}
    />
  );
}
