import React from 'react';
import { Image } from 'semantic-ui-react';
import GideImage from '../Shared/Image/GideImage';

const DoodleSlide = ({ slide }) => (
  <div>
    <GideImage src={slide.data.dataURL} alt={`□`} fluid />
  </div>
);

export default DoodleSlide;
