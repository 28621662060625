import React from 'react';
import Avatar from '../../Avatar/Avatar';
import styles from './_user-tile.module.scss';
import flagDictionary from '../../../../assets/icons/flag-lookup';
import typography from '../../../../assets/_gides-typography.module.scss';
import classNames from 'classnames';
import { Article } from '../../../../models/Article';
import { Link } from 'react-router-dom';
import { hasValue } from '../../../../utils/helperFunctions';

export enum UserTileStyle {
  ProfileListMain = 1,
  BusinesListMain = 2,
  BusinessHorizontalMain = 3,
  ProfileSlideAttribution = 4
}
export interface  UserTileProps {
  tileStyle: UserTileStyle;
  userImage: string;
  username: string;
  fullName: string;
  userLanguage: string;
  category: string;
  followers: number;
  gides: number;
  channels: number;
  isShortcut: boolean;
  country: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function UserTile (props:  UserTileProps) {
  return (
    <div className={styles.userTileContainer}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(props.onClick) {
          props.onClick(event);
        }
      }}
    >
      <div className={styles.avatarContainer}>
        <Avatar
          avatarImage={props.userImage}
          imageWidthAndHeight={49}
          displayOuterBorder={true}
        />
      </div>
      <div className={styles.userDetails}>
        <div className={styles.userInfo}>
          <Link to={`?profile=${props.username}&page=profile`} style={{color: 'var(--LINK-100)'}}>
            {props.username}
          </Link>
          {hasValue(props.country) && (
            <span style={{marginLeft: '8px', width: '20px', height: '20px', borderRadius: '50%'}}
              className={classNames('flag-icon',`flag-icon-${props.country}`, 'flag-icon-squared')}>  
            </span>
          )}
          <span style={{marginLeft: '12px'}} className={typography.subtitle2IbmRegular}> • {props.fullName}</span> 
        </div>
        <div className={classNames(styles.categoryAndCounts, typography.subtitle2IbmRegular)}>
          <div className={styles.counts} style={{color: 'var(--COLOR-SECONDARY-300)'}}>
            <span style={{fontSize: props.isShortcut ? '10px' : 'inherit' }}>{`${props.followers} Followers ∙ ${props.channels} Channels ∙ ${props.gides} Gides`}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
