import React from 'react';
import { 
  SlideEditorModalProps, 
  BackIconMode, 
  EditorState, 
  ModalOverlayState, 
} from '../../modals/SlideEditorModal/SlideEditorModal';
import { darkTheme } from '../../../themes/custom-theme';
import { 
  CommonContext, 
  CommonDerivedState, 
  getHeaderTitleAndIcon,
  CommonModes, 
} from './Common';
import ImageView from '../../Viewers/ImageViewers/ImageView/ImageView';
import SlideFileTouchActionPicker from '../../DataEntry/Pickers/Modals/SlideFileTouchActionPicker/SlideFileTouchActionPicker';
import { ImageFile, SlideItemSelectionActionType, SlideItemSelectionSettings } from '../../../models/SlideFile';
import { NotificationType } from '../../../constants/strings';
import styles from './_Common.module.scss';
import { LabeledTextInput } from '../../DataEntry/CreationProcess/LabeledTextInput/LabeledTextInput';

const getSlideEditorModalProps = (context: CommonContext): SlideEditorModalProps => {
  const { state, setState, commonSlideEditorModalProps } = context;

  return {
    ...commonSlideEditorModalProps,
    backIconMode: BackIconMode.Dark,
    hideFooter: true,
    hideCommandBar: false,
    editorState: EditorState.Edit,
    loadingInfo: undefined,
    modalOverlayState: ModalOverlayState.None,
    selectedItemIndex: -1,
    hideActionContainer: true,
    showBackNavigation: true,
    showDefaultActions: false,
    createActions: [] as any,
    headerActions: getHeaderTitleAndIcon('On tap', '/icons/creationprocess/touch-activate.svg', 'white'),
    onNavigateBack: () => {
      setState({ ...state, mode: CommonModes.Edit });
    },
  };
}

const getContent = (context: CommonContext, slideEditorModalProps: SlideEditorModalProps) => {
  const { state, setState, commonSlideEditorModalProps } = context;

  const file = state.slide.data.files[state.selectedItemIndex] as ImageFile;

  const slideItemSelectionSettings = file.onSelect || {
    slideItemSelectionActionType: SlideItemSelectionActionType.SwipeView
  };

  const onMessage = (message: string) => {
    commonSlideEditorModalProps.showNotification({ type: NotificationType.INFO, message });
  }

  const getUpdatedSlide = (slideItemSelectionSettings: SlideItemSelectionSettings, url?: string) => {
    const slide = {
      ...state.slide,
      data: {
        ...state.slide.data,
        files: state.slide.data.files.map((file: ImageFile, i: number) => {
          if (state.selectedItemIndex === i) {
            return {
              ...file,
              onSelect: {
                ...slideItemSelectionSettings,
                linkItemReference: url || slideItemSelectionSettings.linkItemReference,
              }
            };
          } else {
            return file;
          }
        }),
      },
    };
    return slide;
  };

  const onSave = (slideItemSelectionSettings: SlideItemSelectionSettings) => {
    const slide = getUpdatedSlide(slideItemSelectionSettings);
    setState(({
      ...state,
      slide,
      mode: CommonModes.Edit,
    }));
  };
  const onCancel = (slideItemSelectionSettings: SlideItemSelectionSettings) => {
    const slide = getUpdatedSlide(slideItemSelectionSettings);
    setState(({
      ...state,
      slide,
      mode: CommonModes.Edit,
    }));
  };
  const onUnhandled = (slideItemSelectionSettings: SlideItemSelectionSettings) => {
    // const slide = getUpdatedSlide(slideItemSelectionSettings);
    // setState(state => ({
    //   ...state,
    //   slide,
    // }));
  };
  const onChange = (slideItemSelectionSettings: SlideItemSelectionSettings) => {
    const slide = getUpdatedSlide(slideItemSelectionSettings);
    setState(state => ({
      ...state,
      slide,
    }));
  };
  const onSaveWithUrl = (url: string) => {
    const slide = getUpdatedSlide(file.onSelect as SlideItemSelectionSettings, url);
    setState(state => ({
      ...state,
      slide,
      mode: CommonModes.Edit,
    }));
  };

  const onClear = () => {};

  const link = file.onSelect && file.onSelect.linkItemReference;

  return (
    <div>
        <SlideFileTouchActionPicker
          className={styles.slideFileTouchActionPicker}
          currentUser={context.props.currentUser}
          fileType={file.type}
          slideItemSelectionSettings={slideItemSelectionSettings}
          onMessage={onMessage}
          onSave={onSave}
          onChange={onChange}
          onCancel={onCancel}
          onUnhandled={onUnhandled}
        />
      <div className={`imageEditorSelectedImageOuterContainer${state.slide.data.files[state.selectedItemIndex].type === "LINK" ? ' linkImage' : ''}`}>
        <ImageView
          imageFile={state.slide.data.files[state.selectedItemIndex]}
          isReferenceType={state.slide.data.files[state.selectedItemIndex].type === 'SLIDEFILE'}
          className="containImage"
        />
      </div>
      { slideItemSelectionSettings.slideItemSelectionActionType === SlideItemSelectionActionType.OpenLink && (
        <div style={{
          display: "flex",
          bottom: "0px",
          left: "0px",
          right: "0px",
          position: "fixed",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <LabeledTextInput 
            skipAcceptStep={true}
            style={{
              padding: "14px 7px",
              width: "100%",
              maxWidth: "500px",
            }}
            label={!link ? "Add a link to your image" : ""}
            sublabel={!link ? "Copy and paste a link from the web to embed it in your image" : ""}
            placeholder="Paste or type a link"
            isAcceptable={text => !!text}
            onAccept={async text => {
              setState({
                ...state,
                url: text,
              });
              return true;
            }}
            onConfirm={onSaveWithUrl}
            onClear={onClear}
            text={link || ""}
          />
        </div>
      )}
  </div>
  );
}

export const getDerivedState = (context: CommonContext): CommonDerivedState => {
  const slideEditorModalProps = getSlideEditorModalProps(context);
  const content = getContent(context, slideEditorModalProps);
  return {
    ...context.state,
    slideEditorModalProps,
    content,
    theme: darkTheme,
  };
};

export const OnTap = {
  getSlideEditorModalProps,
  getContent,
  getDerivedState,
};
