import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import { InlineTextEditor } from '../InlineTextEditor.js';
import { isNullOrUndefined } from 'util';
import agent from '../../agent';
import { formatDisplayTextWithSearchMarkup } from '../../utils/helperFunctions';
import SearchContext from '../Shared/SearchBar/SearchContext';
const createDOMPurify = require('dompurify');
class HeaderSlide extends Component {
  render() {
    const { slide, viewMode } = this.props;
    const onCollapseSlide = () => {
      this.props.collapseSlides(slide);
    };
    const headerClassName = slide.data.level === 0 ? 'ti' : slide.data.type === 'END' ? 'headerEnd' : `headerLevel${slide.data.level}`;
    return (
      <SearchContext.Consumer>
        {({ searchText }) => (
          <div className="paddedCard" style={{ display: 'flex', flex: 1 }}>
            <div style={{display: 'flex', alignItems: 'center'}}
               onClick={onCollapseSlide}
            >
              {this.props.viewMode !== 'SWIPE' &&
                slide.data &&
                slide.data.level > 0 &&
                !slide.data.notCollapsible &&
                this.props.collapseSlides && (
                  <Icon
                   
                    name={this.props.collapsed ? 'caret right' : 'caret down'}
                    color="grey"
                    size="large"
                    style={{ cursor: 'pointer' }}
                  />
                )}
            </div>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'left',
              }}
            >
              <span
                className={headerClassName}
                style={{
                  textAlign: 'left',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onCollapseSlide();
                }}
              >
                {slide.data && slide.data.type === 'END' && !isNullOrUndefined(slide.data.title) && (
                  <div
                    className="paddedCard"
                    style={{ display: 'flex', flex: 1 }}
                    dangerouslySetInnerHTML={{
                      __html: searchText
                        ? formatDisplayTextWithSearchMarkup(createDOMPurify.sanitize(slide.data.title), searchText)
                        : createDOMPurify
                            .sanitize(slide.data.title)
                            .replace('<p>', '')
                            .replace('</p>', ''),
                    }}
                  />
                )}
                {slide.data && slide.data.type === 'END' && isNullOrUndefined(slide.data.title) && (
                  <div className="paddedCard" style={{ display: 'flex', flex: 1 }}>
                    End Header Section
                  </div>
                )}
                {slide.data && !slide.data.type && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: searchText
                        ? formatDisplayTextWithSearchMarkup(createDOMPurify.sanitize(slide.data.title), searchText)
                        : createDOMPurify
                            .sanitize(slide.data.title)
                            .replace('<p>', '')
                            .replace('</p>', ''),
                    }}
                  />
                )}
              </span>
            </div>
          </div>
        )}
      </SearchContext.Consumer>
    );
  }
}

export default HeaderSlide;
