import React, { useState, useEffect } from 'react';
import styles from './_gide-header.module.scss';
import icons from '../../../assets/icons';
import classNames from 'classnames';
import GideActions, { GideAction } from './GideActions/GideActions';
import GideTitle from './GideTitle/GideTitle';
import GideTitleDropdownPanel from './GideDropdownTitlePanel/GideTitleDropdownPanel';
import GideOwnerViewPanel from './GideOwnerViewPanel/GideOwnerViewPanel';
import ownerPanelStyles from './GideOwnerViewPanel/_gide-owner-view-panel.module.scss';
import { Link } from 'react-router-dom';
import { AvatarMenuLocation } from '../../Shared/Avatar/Avatar';
export interface GideViewHeaderProps {
  authorViewing: boolean;
  gideImage?: string;
  gideTitle?: string;
  viewMode: string | null;
  selectedGideAction: GideAction;
  lockInlineTextEdit: boolean;
  leftSidebarOpen: boolean;
  gideAuthorImage: string;
  gideAuthorName: string;
  displayGideOwnerPanel: boolean;
  isFollowing: boolean;
  onOpenLeftSidebar: () => void;
  onDistribute: () => void;
  onShare: () => void;
  onOpenAvatarMenu: (location: AvatarMenuLocation, openExpanded: boolean) => void;
  onCloseAvatarMenu: () => void;
  onLockInlineTextEdit: () => void;
  onUnlockInlineTextEdit: () => void;
  onSetGideViewMode: (action: GideAction) => void;
  followUser?: (username: string) => void;
  unFollowUser: (username: string) => void;
  onSetSlideZero: () => void;
  onSignUp: () => void;
  onSignIn: () => void;
  onDisplayGideOwnerPanel: (display: boolean) => void;
}

export default function GideViewHeader(props: GideViewHeaderProps) {
  const [dropDownTitlePanelOpen, setDropDownTitlePanelOpen] = useState<boolean>(false);
  const [showOwnerPanel, setShowOwnerPanel] = useState(true);

  useEffect(() => {
    if (!props.authorViewing) {
      setShowOwnerPanel(props.displayGideOwnerPanel);
    }
  }, [props.authorViewing, props.displayGideOwnerPanel]);
  return (
    <div className={classNames(styles.headerContainer, dropDownTitlePanelOpen ? styles.dropDownTitlePanelOpen : undefined)}>
      <div className={styles.headerMain}>
        <div className={styles.hamburgerMenu}>
          {props.leftSidebarOpen && (
            <icons.Nav_Hamburger_Closed
              color="var(--COLOR-SECONDARY-500)"
              onClick={props.onOpenLeftSidebar} // currently this just toggles but we should have an explicit open/close.
            />
          )}
          {!props.leftSidebarOpen && <icons.Nav_Hamburger_Open color="var(--COLOR-SECONDARY-500)" onClick={props.onOpenLeftSidebar} />}
        </div>

        {!props.leftSidebarOpen && (
          <div
            className={classNames(
              styles.menuSeparator,
              props.authorViewing ? undefined : styles.guestViewer,
              dropDownTitlePanelOpen ? styles.dropdownMenuOpen : undefined,
            )}
          />
        )}

        {props.leftSidebarOpen && (
          <div id="gideHomeNavigation" className={styles.gidesLogoHomeNavigation}>
            <Link to={'/'}>
              <icons.Nav_Logo_LogoType_InGide
                doNotFillFromColor={true}
                onClick={props.leftSidebarOpen ? props.onOpenLeftSidebar : () => {}} // TODO: Even though this says open, it will toggle it. Prefer to have explicit methods
              />
            </Link>
          </div>
        )}

        <div className={classNames(
            styles.headerMainTitle,
            props.gideImage
              ? props.authorViewing
                ? undefined
                : styles.guestViewer
              : styles.imageNotSet,
          )}
        >
          <GideTitle
            authorViewing={props.authorViewing}
            gideImage={props.gideImage}
            viewMode={props.viewMode}
            gideTitle={props.gideTitle}
            isMobile={false}
            dropdownTitleOpen={dropDownTitlePanelOpen || (!props.authorViewing && showOwnerPanel)}
            onExpandDropdownTitle={() => {
              if(props.authorViewing) {
                setDropDownTitlePanelOpen(true);
              } else {
                props.onDisplayGideOwnerPanel(true);
              }
            }}
            onCollapseDropdownTitle={() => {
              if(props.authorViewing) {
                setDropDownTitlePanelOpen(false);
                props.onCloseAvatarMenu();
              } else {
                props.onDisplayGideOwnerPanel(false);
              }
            }}
            onSetSlideZero={props.onSetSlideZero}
            leftSidebarOpen={props.leftSidebarOpen}
          />
        </div>
        <GideActions
          selectedGideAction={props.selectedGideAction}
          lockInlineTextEdit={props.lockInlineTextEdit}
          onDistribute={props.onDistribute}
          onLockInlineTextEdit={props.onLockInlineTextEdit}
          onUnlockInlineTextEdit={props.onUnlockInlineTextEdit}
          onSetGideViewMode={props.onSetGideViewMode}
          authorViewing={props.authorViewing}
          onShare={props.onShare}
        />
      </div>
      {dropDownTitlePanelOpen && (
        <GideTitleDropdownPanel
          style={{ marginLeft: '8px' }}
          expanded={dropDownTitlePanelOpen}
          leftSidebarOpen={props.leftSidebarOpen}
          authorViewing={props.authorViewing}
          gideImage={props.gideImage}
          gideTitle={props.gideTitle}
          viewMode={props.viewMode}
          onSetSlideZero={props.onSetSlideZero}
          avatarImage={props.gideAuthorImage ? props.gideAuthorImage: ''}
          isMobile={true}
          onOpenAvatarMenu={props.onOpenAvatarMenu}
        />
      )}

      {/* <GideOwnerViewPanel
        className={!props.authorViewing && showOwnerPanel ? ownerPanelStyles.displayOwnerPanel : ownerPanelStyles.doNotDisplayOwnerPanel}
        gideAuthorName={props.gideAuthorName}
        gideAuthorImage={props.gideAuthorImage}
        isVerified={true}
        leftSidebarOpen={props.leftSidebarOpen}
        isFollowing={props.isFollowing}
        followUser={props.followUser}
        unFollowUser={props.unFollowUser}
        signUp={props.onSignUp}
      /> */}
    </div>
  );
}
