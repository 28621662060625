import React, { Component, Suspense, lazy } from 'react';
import { InlineTextEditor } from '../InlineTextEditor.js';
import agent from '../../agent';
import { formatDisplayTextWithSearchMarkup } from '../../utils/helperFunctions';
import SearchContext from '../Shared/SearchBar/SearchContext';
import { Loading } from '../Shared/Loading/Loading';

const RichTextEditor = lazy(() => import('../Editor/RichTextEditor'));

const createDOMPurify = require('dompurify');
class TextSlide extends Component {
  render() {
    const { slide, viewMode, inlineSlideTextEditInfo, inlineEditedSlide, view, viewOnly, currentUser } = this.props;
    const authorViewing = currentUser && currentUser.username === slide.author.username ? true : false;
    return (
      <SearchContext.Consumer>
        {({searchText}) => (
          <div className="paddedCard">
            {slide.data &&
            slide.data.body &&
            (viewOnly ||
              !inlineSlideTextEditInfo ||
              inlineSlideTextEditInfo.inlineSlideTextEditId !== slide.id ||
              inlineSlideTextEditInfo.type !== 'body' ||
              this.props.disableInlineTextEditing) ? (
              <div
                className="flexColumnFull"
                onClick={async () => {
                  if ((viewMode === 'SLIDE' || viewMode === 'SCROLL') && authorViewing && view !== 'website'
                      && !this.props.disableInlineTextEditing) {
                    // First check to see if there is a slide with unsaved edits
                    if (inlineEditedSlide) {
                      // Make sure if the edit is for a caption that the caption has no p tags
                      if (inlineEditedSlide.data && inlineEditedSlide.data.caption) {
                        inlineEditedSlide.data.caption = inlineEditedSlide.data.caption.replace('<p>', '').replace('</p>', '');
                      }
                      const updatedSlide = await agent.Slides.update(inlineEditedSlide.id, inlineEditedSlide);
                      this.props.onSubmit({
                        slide: updatedSlide.slide,
                        isTextEdit: true,
                        preventScrollToSlide: true,
                      });
                    }
                    this.props.setInlineSlideTextEditInfo({
                      inlineSlideTextEditInfo: {
                        inlineSlideTextEditId: slide.id,
                        type: 'body',
                      },
                    });
                  }
                }}
              >
                {slide.data.fullEditor && (
                  <Suspense fallback={<div style={{height: '23px'}} />}>
                    <RichTextEditor
                      style={{ display: 'flex', flex: 1 }}
                      value={
                        searchText
                          ? formatDisplayTextWithSearchMarkup(createDOMPurify.sanitize(slide.data.body), searchText)
                          : createDOMPurify.sanitize(slide.data.body)
                      }
                      showToolbar={false}
                      limitHeight={false}
                      isReadOnly={true}
                      focusInputOnLoad={false}
                      classes={'defaultRichTextEdit'}
                    />
                  </Suspense>
                )}
                {!slide.data.fullEditor && (
                  <span style={{
                    wordBreak: 'break-word',
                  }}
                    dangerouslySetInnerHTML={{
                      __html: searchText
                        ? formatDisplayTextWithSearchMarkup(createDOMPurify.sanitize(slide.data.body), searchText)
                        : createDOMPurify.sanitize(slide.data.body),
                    }}
                  />
                )}
              </div>
            ) : !viewOnly &&
            inlineSlideTextEditInfo &&
            inlineSlideTextEditInfo.inlineSlideTextEditId === slide.id &&
            inlineSlideTextEditInfo.type === 'body' &&
            !this.props.disableInlineTextEditing ? (
              <InlineTextEditor
                inlineEditorClasses={slide.data.fullEditor ? 'inlineRichTextEdit' : 'inlineNormalTextEdit'}
                dataField={'body'}
                onChange={this.props.updateSlideWithInlineEdits}
                slide={this.props.slide}
                saveEdits={this.props.onSubmit}
                setInlineSlideTextEditInfo={this.props.setInlineSlideTextEditInfo}
                isReadOnly={false}
              />
            ) : null}
          </div>
        )}
      </SearchContext.Consumer>
    );
  }
}
export default TextSlide;
