import {
  ARTICLE_FAVORITED,
  ARTICLE_UNFAVORITED,
  ARTICLE_SUBSCRIBED,
  ARTICLE_UNSUBSCRIBED,
  DELETE_ARTICLE_NO_REDIRECT,
  SET_PAGE,
  APPLY_TAG_FILTER,
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  CHANGE_TAB,
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  GIDE_MODAL_LOADED,
  SEARCH_EXECUTED,
  SEARCH_CANCELED,
  GIDE_MODAL_SEARCHED,
  HOME_PAGE_GIDES_LOADED,
  HOME_PAGE_MORE_GIDES_LOADED,
  DELETE_ARTICLE,
} from '../constants/actionTypes';
import { reject, append, concat } from 'ramda';

export default (state = {articles: [], gideGroups: [], searchText: undefined}, action) => {
  switch (action.type) {
    case SEARCH_EXECUTED:
      return {
        ...state,
        articles: action.payload.articles,
        gideGroups: [{ groupTitle: '', gideList: action.payload.articles }],
        searchText: action.payload.searchText,
      };
    case SEARCH_CANCELED:
      return {
        ...state,
        searchText: undefined,
      };
    case GIDE_MODAL_LOADED:
      return {
        ...state,
        articles: action.payload[0].articles,
      };
    case GIDE_MODAL_SEARCHED:
      return {
        ...state,
        articles: action.payload.articles,
      };
    case DELETE_ARTICLE_NO_REDIRECT:
      return {
        ...state,
        articles: reject(a => a.id === action.payload.id, state.articles),
        gideGroups: state.gideGroups ? state.gideGroups.map(gideGroup => {
          return { groupTitle: gideGroup.groupTitle, gideList: reject(g => g.id === action.payload.id, gideGroup.gideList) };
        }) : [],
      };
    case DELETE_ARTICLE:
      return {
        ...state,
        gideGroups: state.gideGroups ? state.gideGroups.map(gideGroup => {
          return { groupTitle: gideGroup.groupTitle, gideList: reject(g => g.id === action.payload.id, gideGroup.gideList) };
        }) : [],
      };
    case ARTICLE_FAVORITED:
    case ARTICLE_UNFAVORITED:
      return {
        ...state,
        articles: state.articles
          ? state.articles.map(article => {
              if (article.slug === action.payload.article.slug) {
                return {
                  ...article,
                  favorited: action.payload.article.favorited,
                  favoritesCount: action.payload.article.favoritesCount,
                };
              }
              return article;
            })
          : null,
      };
    case ARTICLE_SUBSCRIBED:
    case ARTICLE_UNSUBSCRIBED:
      return {
        ...state,
        articles: state.articles
          ? state.articles.map(article => {
              if (article.slug === action.payload.article.slug) {
                return {
                  ...article,
                  subscribed: action.payload.article.subscribed,
                  subscribersCount: action.payload.article.subscribersCount,
                };
              }
              return article;
            })
          : null,
      };
    case SET_PAGE:
      return {
        ...state,
        articles: action.payload.articles,
        articlesCount: action.payload.articlesCount,
        currentPage: action.page,
      };
    case APPLY_TAG_FILTER:
      return {
        ...state,
        pager: action.pager,
        articles: action.payload.articles,
        articlesCount: action.payload.articlesCount,
        tab: null,
        tag: action.tag,
        currentPage: 0,
      };
    case HOME_PAGE_LOADED:
      return {
        ...state,
        pager: action.pager,
        tags: action.payload[0].tags,
        articles: action.payload[1].articles,
        articlesCount: action.payload[1].articlesCount,
        currentPage: 0,
        tab: action.tab,
      };
    case HOME_PAGE_GIDES_LOADED:
      return {
        ...state,
        gideGroups: action.payload.gideGroups,
        searchText: undefined,

      };
    case HOME_PAGE_MORE_GIDES_LOADED:
      if (action.payload.gideGroup) {
        return {
          ...state,
          gideGroups: state.gideGroups.map(gideGroup => {
            if(gideGroup.groupId === action.payload.gideGroup.groupId) {
              return {
                ...gideGroup,
                hasMoreGides: action.payload.gideGroup.hasMoreGides,
                gideList: [...gideGroup.gideList, ...action.payload.gideGroup.gideList]
              };
            } else {
              return gideGroup;
            }
          }),
        }
      } else {
        return state;
      }
    case HOME_PAGE_UNLOADED:
      return {};
    case CHANGE_TAB:
      return {
        ...state,
        pager: action.pager,
        articles: action.payload.articles,
        articlesCount: action.payload.articlesCount,
        tab: action.tab,
        currentPage: 0,
        tag: null,
      };
    case PROFILE_PAGE_LOADED:
      return {
        ...state,
        pager: action.pager,
        articles: action.payload[1].articles,
        articlesCount: action.payload[1].articlesCount,
        currentPage: 0,
      };
    case PROFILE_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
