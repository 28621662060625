
export interface User {
  _id: string;
  id?: string;
  username: string;
  image: string;
  brandingImage?: string;
  description?: string;
  firstName?: string;
  secondName?: string;
  accountType?: 'Business' | 'Person';
  phone?: string;
  email: string;
  allowFullAccess?: boolean;
  publishedGides?: number;
  language?: string;
  country?: string;
  business?: boolean;
}

export interface LoginModel extends User {
  token: string;
}

export const MockUser: User = {
  _id: '5d42930d4310230016e1f799',
  username: 'anonnymous@anonnymous.com',
  image: 'https://gides-user-uploads.s3.us-east-2.amazonaws.com/4650404-blob',
  email: 'test@gides.com',
  brandingImage: 'https://images.unsplash.com/photo-1563136527-c50f274c466b?ixlib=rb-1.2.1&auto=format&fit=crop&w=1568&q=80',
  firstName: 'Jane',
  secondName: 'Doe',
  description: 'There are numerous success stories you will hear about businesses making it good on the internet . I will show you the way.',
  accountType: 'Person',
  business: false,
}

export const AnonymousUser: User = {
  _id: 'anonymous',
  username: 'anonymous',
  image: '',
  email: '',
  business: false,
}
