import React, { useState } from 'react';
import PickerModal from '../PickerModal/PickerModal';
import Picker, { PickerItem, GideTitleSlideFormat, PickerDisplayMode } from '../../Picker/Picker';
import icons from '../../../../../assets/icons';

export interface  GideTitleFormatPickerProps {
  onCancel: (originalValue: GideTitleSlideFormat) => void;
  onSave: (selectedFormatValue: GideTitleSlideFormat) => void;
  onChange: (selectedFormatValue: GideTitleSlideFormat) => void;
  format: GideTitleSlideFormat;
  className?: string;
}
const pickerItems: PickerItem[] = [
  {icon: icons.CreationProcess_Bloglist_ImageTop, label: "Image and text", value: GideTitleSlideFormat.ImageAndText},
  {icon: icons.ContentAlteration_TextAlign_Left, label: "Text only", value: GideTitleSlideFormat.TextOnly},
  {icon: icons.SlideType_Image_Main, label: "Image only", value: GideTitleSlideFormat.ImageOnly},
  {icon: icons.Nav_Media_Player_Stop, label: "No title", value: GideTitleSlideFormat.NoTitle},
  {icon: icons.CreationProcess_Bloglist_ImageTop, label: "Blog", value: GideTitleSlideFormat.Blog},
];
export default function GideTitleFormatPicker (props:  GideTitleFormatPickerProps) {
  const [originalFormat] = useState(props.format || GideTitleSlideFormat.ImageAndText);
  return (
    <PickerModal
      className={props.className}
      onCancel={() => props.onCancel(originalFormat)}
      onSave={() => props.onSave(props.format)}
    >
      <Picker
        pickerItems={pickerItems}
        title="Choose a title format that makes your gide beautiful!"
        selectedValue={props.format}
        onChange={(format: GideTitleSlideFormat) => {
            props.onChange(format);
          }
        }
        displayMode={PickerDisplayMode.CircularTextOutsideUnder}
        itemWidth={118}
      />
    </PickerModal>
  );
}
