import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { MODAL_CLOSE } from '../../constants/actionTypes';
import slideTools from '../../slideTools';
import Tools from '../../lib/SketchField/tools';
import { Loading } from '../Shared/Loading/Loading';

const SketchField = lazy(() => import('../../lib/SketchField/index'));

const mapStateToProps = slideTools.mapStateToProps;

const mapDispatchToProps = dispatch => ({
  closeModal: payload => dispatch({ type: MODAL_CLOSE }),
});

export class ImageEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url:
        'https://gides-user-uploads.s3.us-east-2.amazonaws.com/3557494-a.png',
    };
    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };
    this.onFoo = ev => {
      this._sketch.addImg(this.state.url);
    };
  }

  render() {
    return (
      <Modal
        closeOnEscape={true}
        onClose={this.props.closeModal}
        size="small"
        dimmer="inverted"
        closeOnDocumentClick={true}
        closeIcon="close"
        open={true}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Edit Image</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <button onClick={this.onFoo}>foo</button>
            <Suspense fallback={<Loading />}>
              <SketchField
                width="240px"
                height="468px"
                tool={Tools.Pencil}
                lineColor="black"
                lineWidth={3}
                ref={c => (this._sketch = c)}
              />
            </Suspense>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions />
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageEditModal);
