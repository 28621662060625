import React from 'react';
import { SlideshowSlideInfo } from '../../../models/SlideShowSlide';
import { Slide } from '../../../models/Slide';
import { isNullOrUndefined } from 'util';
import { Image } from 'semantic-ui-react';
import { attachmentMetaData } from '../../../constants/contentMetaData';
import { Duration } from './TimeRangeSlider';
import GideImage from '../../Shared/Image/GideImage';

export interface SlideshowSlideSlideProps {
  slide: Slide;
  slideshowSlideInfo: SlideshowSlideInfo;
  selectedSlideId: string;
  markAsSelected: (selectedSlideId: string) => void;
}
export const SlideshowSlideSlide = (props: SlideshowSlideSlideProps) => {
  const { slide, slideshowSlideInfo, selectedSlideId, markAsSelected } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      className={`slideshowSlide ${
        selectedSlideId === slide.id ? 'selected' : ''
      }`}
      onClick={() => markAsSelected(slide.id ? slide.id : '')}
    >
      <GideImage
        className={`slideshowImage`}
        src={
          (attachmentMetaData as any)[slide.slideType]
            ? (attachmentMetaData as any)[slide.slideType].icon
            : ''
        }
      />
      <div className={`slideshowTitle`}>
        {`Slideshow slide in position: ${slide.position}`}
      </div>
      <div>Slide Start Time:</div>
      <div>
        <Duration
          seconds={
            !isNullOrUndefined(slide.id) && slideshowSlideInfo
              ? slideshowSlideInfo.startTimeInSeconds
              : 0
          }
        />
      </div>
    </div>
  );
};
